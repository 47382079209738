<template>
    <v-container fluid>
        <app-card :fullBlock="true">
            <v-breadcrumbs :items="breadcrumbItems" v-if="breadcrumbItems != null" style="padding: 0px !important;">
                <template slot="item" slot-scope="props">
                    <h2 class="text-capitalize mb-0">{{$t(props.item.title)}}</h2>
                    <div class="spacer" v-if="false"></div>
                    <v-breadcrumbs-item v-if="false">
                        {{props.item.breadcrumb[0].breadcrumbInactive}}
                    </v-breadcrumbs-item>
                    <v-breadcrumbs-item disabled v-if="false">
                        {{props.item.breadcrumb[1].breadcrumbActive}}
                    </v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
        </app-card>
    </v-container>
</template>

<script>
    export default {
    		props: ['documentTitle'],
        data() {
            return {
                breadcrumbItems: []
            }
        },
        created() {
    				console.log(this.documentTitle)
            this.breadcrumbItems[0] = this.$breadcrumbs[0].meta;
        }
    };
</script>
