import {getField, updateField} from "vuex-map-fields";
import { api } from "../../../api";
import _ from 'lodash'

const namespaced = {
	namespaced: true
}

const getDefaultState = () => {
	return {
		allCertifications: [],
		Certification: {
			id: null,
			alt_title: null,
			cert_expiry_date: null,
			cert_no: null,
			title: null,
			type: null
		}
	}
}


const state = {
	allCertifications: [],
	current: {
		Certification: {
			id: null,
			alt_title: null,
			cert_expiry_date: null,
			cert_no: null,
			title: null,
			type: null
		}
	},
	update: {
		Certification: {}
	}
}

const getters = {
	allCertifications: state => {
		return state.allCertifications;
	},
	allFscCertifications: state => {
		let certifications = []
		if(state.allCertifications.length > 0){
			state.allCertifications.forEach(cert => {
				if(cert.Certification.type == 'fsc') certifications.push(cert)
			})
			certifications.sort((a, b) => {
				return a.Certification.alt_title.localeCompare( b.Certification.alt_title)
			})
		}
		return certifications
	},
	allPefcCertifications: state => {
		let certifications = []
		if(state.allCertifications.length > 0){
			state.allCertifications.forEach(cert => {
				if(cert.Certification.type == 'pefc') certifications.push(cert)
			})
			certifications.sort((a, b) => {
				return a.Certification.alt_title.localeCompare( b.Certification.alt_title)
			})
		}
		return certifications
	},
	getField
}

const mutations = {
	setAllCertifications(state,data){
		state.allCertifications = data;
	},
	setCertificationToUpdate(state, data){
		state.update.Certification = data.Certification
	},
	resetAllCertifications(state){
		state.allCertifications = getDefaultState().allCertifications
	},
	resetCertificationToUpdate (state) {
		state.update.Certification = getDefaultState().Certification
	},
	resetCurrentState (state) {
		state.current.Certification = getDefaultState().Certification
	},
	resetState(state){
		state.allCertifications = getDefaultState().allCertifications
	},
	updateField
}

const actions = {
	createCertification({state, rootState}){
		let certification = _.cloneDeep(state.current.Certification)
		return new Promise((resolve, reject) => {
			api
				.post('/certifications',{
						certification: certification,
					}
				)
				.then((response)=>{
					// if(response.data.status == 'success') {
					// 	resolve('done')
					// } else {
					// 	resolve('error')
					// }
					resolve(response.data)
				})
				.catch(error => {
					reject(error)
				})
				// .finally(()=>{
				// 	resolve('error')
				// })
		})
	},
	deleteCertificationById ({commit}, certificationId) {
		return new Promise((resolve, reject) => {
			api
				.delete('/certifications/' + certificationId)
				.then(response => {
					// resolve('done')
					resolve(response.data)
				})
				.catch(error => {
					reject(error);
				})
		})
	},
	getAllCertifications({commit}) {
		return new Promise((resolve, reject) => {
			api
				.get("/certifications")
				.then(response => {
					commit('setAllCertifications',response.data.data);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	getCertificationToUpdateById({commit}, certificationId){
		return new Promise((resolve, reject) => {
			api
				.get('/certifications/' + certificationId )
				.then(response => {
					commit('setCertificationToUpdate', response.data.data[0])
					resolve('done')
				})
				.catch(error => {
					reject(error)
				})
		})
	},
	resetAllCertification({commit}) {
		commit('resetAllCertification')
	},
	resetCurrentState({commit}) {
		commit('resetCurrentState')
	},
	resetCertificationToUpdate({commit}) {
		commit('resetCertificationToUpdate')
	},
	resetState ({commit}) {
		commit('resetState')
	},
	updateCertification({state}){
		let certification = _.cloneDeep(state.update.Certification)
		return new Promise((resolve, reject) => {
			api
				.put("/certifications/" + state.update.Certification.id,{
					certification: certification
				})
				.then(response => {
					// resolve('done')
					resolve(response.data)
				})
				.catch(error => {
					reject(error)
				})
				// .then(()=>{
				// 	resolve('done')
				// });
		})
	}
}

export const certification = {
	namespaced,
	state,
	getters,
	actions,
	mutations
}