<template>
    <v-app
        id="inspire"
        :class="[
                {
                    'box-layout': boxLayout,
                    'collapse-sidebar': collapseSidebar,
                    'rtl-layout': rtlLayout
                }
            ]"
    >
        <router-view :auth="auth" :authenticated="authenticated" :class="printLayout ? 'fill-height' : ''" :key="$route.fullPath"></router-view>
        <notifications group="loggedIn" position="top right" animation-type="velocity"/>
        <confirm ref="confirm"/>
    </v-app>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import AuthService from "./auth/AuthService";
    import confirm from "./components/Appic/Confirm";
    const auth = new AuthService();
    const {login, logout, authenticated, authNotifier} = auth;
    export default {
        components: {confirm},
        data() {
            authNotifier.on("authChange", authState => {
                this.authenticated = authState.authenticated;
            });
            return {
                auth,
                authenticated,
                animation: {
                    enter: {
                        opacity: [1, 0],
                        translateX: [0, -300],
                        scale: [1, 0.2]
                    },
                    leave: {
                        opacity: 0,
                        height: 0
                    }
                },
                printLayout: true
            };
        },
        computed: {
            ...mapGetters([
                "darkMode",
                "collapseSidebar",
                "boxLayout",
                "rtlLayout",
                "selectedLocale"
            ])
        },
        mounted() {
            if (
                this.selectedLocale.locale === "he" ||
                this.selectedLocale.locale === "ar"
            ) {
                this.$store.dispatch("rtlLayout");
            }
            this.$root.$confirm = this.$refs.confirm.open
        }
    };
</script>
