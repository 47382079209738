<!-- Side Structure -->
<template>
    <div>
        <app-logo></app-logo>
        <v-list
            nav
            shaped
       >
            <template v-for="(category, key) in sideMenu">
                <template v-for="item in category">
                    <template v-if="item.items != null">
                        <v-list-group
                            no-action
                            value="item.active"
                            v-if="$can(item.ability.action, item.ability.subject)"
                            v-model="item.active"
                        >
                            <template v-slot:prependIcon>
                                <v-icon :style="item.active ? 'font-size: 36px !important' : ''" :class="item.active ? 'ml-1 icon-active' : 'ml-1'" v-if="item.icon != null">{{ item.icon }}</v-icon>
                                <v-icon :class="item.active ? 'ml-1 icon-active' : 'ml-1'" v-else>{{ item.action }}</v-icon>
                            </template>
                            <v-list-item slot="activator" :key="item.id" class="mt-1">
                                <v-list-item-content>
                                    <v-list-item-title>{{ textTruncate($t(item.title)) }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                                :href="subItem.path == null ? subItem.directLink : null"
                                :key="subItem.id"
                                :target="subItem.path == null ? '_blank' : null"
                                :to="!subItem.exact ? `/${getCurrentAppLayoutHandler() + subItem.path}` : subItem.path"
                                ripple
                                v-for="subItem in item.items"
                                v-if="subItem !== null && $can(subItem.ability.action, subItem.ability.subject)"
                            >
                                <v-list-item-content>
                                    <v-list-item-title ml-2>{{ textTruncate($t(subItem.title)) }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-group>
                    </template>
                    <template v-else>
                        <v-list-group
                            append-icon=""
                            no-action
                            value="item.active"
                            v-if="$can(item.ability.action, item.ability.subject)"
                            v-model="item.active"
                        >
                            <template v-slot:prependIcon>
                                <v-icon :style="item.active ? 'font-size: 36px !important' : ''" :class="item.active ? 'ml-1 icon-active' : 'ml-1'" v-if="item.icon != null">{{ item.icon }}</v-icon>
                                <v-icon :class="item.active ? 'ml-1 icon-active' : 'ml-1'" v-else>{{ item.action }}</v-icon>
                            </template>
                            <v-list-item slot="activator" :key="item.id" v-if="item.path != null">
                                <v-list-item-content>
                                    <v-list-item-title :class="item.active ? 'link-active' : 'link-inactive'">
                                        <router-link :to="!item.exact ? `/${getCurrentAppLayoutHandler() + item.path}` : item.path">
                                            <span>{{ textTruncate($t(item.title)) }}</span>
                                        </router-link>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-group>
                    </template>
                </template>
            </template>
        </v-list>
    </div>
</template>

<script>
    // import UserBlock from "./UserBlock";
    import {textTruncate, getCurrentAppLayout} from "Helpers/helpers";
    import {mapGetters, mapActions} from "vuex";
    import AppLogo from "Components/AppLogo/AppLogo";

    export default {
        data() {
            return {
                settings: {
                    maxScrollbarLength: 160
                },
                sideMenu: []
            };
        },
        components: {
            AppLogo
        },
        computed: {
            ...mapGetters(["sidebarSelectedFilter", "menus", "demo_menu_operations", "demo_menu_sales"]),
            ...mapGetters('user',{
                User : 'User'
            })
        },
        methods: {
            // ...mapActions('sidebar',{
            //     resetState: 'resetState',
            // }),
            textTruncate(text) {
                return textTruncate(text, 18);
            },
            getCurrentAppLayoutHandler() {
                return getCurrentAppLayout(this.$router);
            }
        },
        created() {
            // Resets
            this.$store.dispatch('resetMenus')
            if(this.User.role == 'ROLE_DEMO_OPERATIONS') {
                this.sideMenu = this.demo_menu_operations
            } else if(this.User.role == 'ROLE_DEMO_SALES') {
                this.sideMenu = this.demo_menu_sales
            } else {
                this.sideMenu = this.menus
            }
        }
    };
</script>
<style>
.theme--light .v-icon {
    color: darkgrey !important;
}

.theme--light .v-icon {
    color: darkgrey !important;
}

.theme--light .v-icon.icon-active {
    color: black !important;
}

.theme--light .link-inactive a {
    color: rgba(0,0,0,.87) !important;
}

.theme--light .link-active a {
    color: #3C6932 !important
}
</style>