import {getField, updateField} from "vuex-map-fields";
import { api } from "../../../api";
import _ from 'lodash'

const namespaced = {
	namespaced: true
}

const getDefaultState = () => {
	return {
		allMoistureContent: [],
		Mc: {
			id: null,
			drying: null,
			title: null
		}
	}
}

const state = {
	allMoistureContent: [],
	current: {
		Mc: {
			id: null,
			drying: null,
			title: null
		}
	},
	update: {
		Mc: {}
	}
}

const getters = {
	allMoistureContent: state => {
		return state.allMoistureContent;
	},
	getField
}

const mutations = {
	setAllMoistureContent(state,data){
		const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' })
		const sorted = data.sort((a, b) => collator.compare(a.Mc.title, b.Mc.title))
		state.allMoistureContent = sorted;
	},
	setMoistureContentToUpdate(state, data){
		state.update.Mc = data.Mc
	},
	resetAllMoistureContent(state){
		state.allMoistureContent = getDefaultState().allMoistureContent
	},
	resetMoistureContentToUpdate (state) {
		state.update.Mc = getDefaultState().Mc
	},
	resetCurrentState (state) {
		state.current.Mc = getDefaultState().Mc
	},
	resetState(state){
		state.allMoistureContent = getDefaultState().allMoistureContent
	},
	updateField
}

const actions = {
	createMoistureContent ({state, rootState}) {
		let mc = _.cloneDeep(state.current.Mc)
		return new Promise((resolve, reject) => {
			api
				.post('/mcs',{
						mc: mc,
					}
				)
				.then((response)=>{
					// if(response.data.status == 'success') {
					// 	resolve('done')
					// } else {
					// 	resolve('error')
					// }
					resolve(response.data)
				})
				.catch(error => {
					reject(error)
				})
				// .finally(()=>{
				// 	resolve('error')
				// })
		})
	},
	deleteMoistureContentById ({commit}, moistureContentId) {
		return new Promise((resolve, reject) => {
			api
				.delete('/mcs/' + moistureContentId)
				.then(response => {
					// resolve('done')
					resolve(response.data)
				})
				.catch(error => {
					reject(error);
				})
		})
	},
	getAllMoistureContent({commit}){
		return new Promise((resolve, reject) => {
			api
				.get("/mcs")
				.then(response => {
					commit('setAllMoistureContent',response.data.data);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	getMoistureContentToUpdateById( {commit}, moistureContentId ) {
		return new Promise((resolve, reject) => {
			api
				.get('/mcs/' + moistureContentId )
				.then(response => {
					commit('setMoistureContentToUpdate', response.data.data[0])
					resolve('done')
				})
				.catch(error => {
					reject(error)
				})
		})
	},
	resetAllMoistureContent({commit}){
		commit('resetAllMoistureContent')
	},
	resetMoistureContentToUpdate( {commit} ) {
		commit('resetMoistureContentToUpdate')
	},
	resetCurrentState ({commit}) {
		commit('resetCurrentState')
	},
	resetState ({commit}){
		commit('resetState')
	},
	updateMoistureContent ( {state} ) {
		let mc = _.cloneDeep(state.update.Mc)
		return new Promise((resolve, reject) => {
			api
				.put("/mcs/" + state.update.Mc.id,{
					mc: mc
				})
				.then(response => {
					// resolve('done')
					resolve(response.data)
				})
				.catch(error => {
					reject(error)
				})
				// .then(()=>{
				// 	resolve('done')
				// });
		})
	}
}

export const mc = {
	namespaced,
	state,
	getters,
	actions,
	mutations
}