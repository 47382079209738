import { api } from "../../../api";
import {getField, updateField} from "vuex-map-fields";
import {isNumeric} from "../../../helpers/helpers";

const namespaced = {
    namespaced: true
}

const getDefaultState = () => {
    return {
        index: 0,
        lineNumber: 1,
        Contact: {
            id: null,
            company_department_id: null,
            email: null,
            fax: null,
            hp: null,
            name: null,
            otsnotification: 1,
            otsprocurement: 1,
            otsshipping: 1,
            otspayments: 1,
            position: null,
            prefix: 'Mr',
            skype: null,
            tel: null,
            whatsapp: null
        }
    }
}

const state = {
    current: {
        Contacts: [
            {
                index: 0,
                lineNumber: 1,
                Contact: {
                    id: null,
                    company_department_id: null,
                    email: null,
                    fax: null,
                    hp: null,
                    name: null,
                    otsnotification: 1,
                    otsprocurement: 1,
                    otsshipping: 1,
                    otspayments: 1,
                    position: null,
                    prefix: 'Mr',
                    skype: null,
                    tel: null,
                    whatsapp: null
                }
            }
        ],
        Contact: {
            id: null,
            company_department_id: null,
            email: null,
            fax: null,
            hp: null,
            name: null,
            otsnotification: 1,
            otsprocurement: 1,
            otsshipping: 1,
            otspayments: 1,
            position: null,
            prefix: 'Mr',
            skype: null,
            tel: null,
            whatsapp: null
        }
    },
    update: {
        Contacts: [],
        Contact: {}
    }
}

const getters = {
    getField,
    getContactsByCompany: state => (company_id) => {
        return state.current.Contacts.filter(d => {
            return d.Contact.company_id === company_id
        })
    }
}

const mutations = {
    addContactRow(state, prefix){
        let i = 0;
        let row = getDefaultState();
        state[prefix].Contacts.push(row)
        state[prefix].Contacts.forEach((item) => {
            item.index = i
            item.lineNumber = i + 1
            i++
        })
    },
    addContactToState (state, payload){
        let prefix = payload.prefix
        let contacts = payload.data
        if(prefix == 'current') {
            contacts.forEach((value) => {
                let id = value.Contact.id
                let exists = state[prefix].Contacts.find(contact => contact.Contact.id === id)
                if (!exists) {
                    state[prefix].Contacts.push(value)
                }
            });
        } else if(prefix == 'update'){
            state[prefix].Contacts = contacts
        }
        let i = 0;
        state[prefix].Contacts.forEach((item) => {
            item.index = i
            item.lineNumber = i + 1
            i++
        })
    },
    deleteContactRow (state, payload) {
        state[payload.prefix].Contacts.splice(payload.index, 1)
        let i = 0;
        state[payload.prefix].Contacts.forEach((item) => {
            item.index = i
            item.lineNumber = i + 1
            i++
        })
    },
    resetState (state,prefix){
        state[prefix].Contacts = []
        state[prefix].Contacts.push(getDefaultState())
    },
    updateField,
    updateContacts (state, payload){
        let keys = payload['field'].split('.')
        let prefix = payload['prefix']
        state[prefix].Contacts[payload['index']][keys[0]][keys[1]] = payload['value']
    }
}

const actions = {
    addContactRow ({commit},prefix){
        commit('addContactRow',prefix)
    },
    deleteContactRow ({commit},payload){
        commit('deleteContactRow',payload)
    },
    deleteContact({commit},payload){
        return new Promise((resolve, reject) => {
            let company_id = payload.company_id
            let contact_id = payload.contact_id
            api
                .delete('/companies/' + company_id + '/contacts/' + contact_id)
                .then(response => {
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                })
        })
    },
    getActiveContactsByCompany ({commit},payload){
        let company_id = payload.company_id
        let prefix = payload.prefix

        return new Promise((resolve, reject) => {
            api
                .get("/companies/" + company_id + "/contacts")
                .then(response => {
                    let payload = {
                        prefix: prefix,
                        data: response.data.data
                    }
                    commit('addContactToState',payload);
                    resolve('done');
                })
                .catch(error => {
                    reject(error);
                })
                .finally(()=>{
                    resolve('done');
                })
        })
    },
    resetCurrentContacts ({commit}) {
        return new Promise ((resolve, reject) => {
            commit('resetState', 'current')
            resolve('done');
        })
    },
    resetAllContacts ({commit}, prefix) {
        return new Promise ((resolve, reject) => {
            commit('resetState', prefix)
            resolve('done');
        })
    },
}

export const contact = {
    namespaced,
    state,
    getters,
    actions,
    mutations
}
