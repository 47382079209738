import {getField, updateField} from "vuex-map-fields";
import { api } from "../../../api";
import _ from 'lodash'
import {
	altInvoiceFooterNotes,
	contractFooterNotes,
	coverNoteFooterNotes,
	invoiceFooterNotes, localSalesInvoiceFooterNotes
} from "@/store/modules/appic/constants";
import {log} from "Helpers/helpers";

const namespaced = {
	namespaced: true
}

const getDefaultState = () => {
	return {
		id: null,
		item_id: 1,
		replace: null,
		sort_id: 1,
		type: null,
		text: null,
	}
}

const state = {
	allDocumentFooters: [],
	contractFooterNotes: [],
	coverNoteFooterNotes: [],
	invoiceFooterNotes: [],
	altInvoiceFooterNotes: [],
	localSalesInvoiceFooterNotes: [],
	lcaConditions: [],
	lcaNotes: [],
	purchaseOrderFooterNotes: [],
	salesOrderFooterNotes: [],
	current: {
		id: null,
		item_id: 1,
		replace: null,
		sort_id: 1,
		type: null,
		text: null,
	},
	update: {
		id: null,
		item_id: 1,
		replace: null,
		sort_id: 1,
		type: null,
		text: null,
	}
}

const getters = {
	allDocumentFooters: state => {
		return state.allDocumentFooters
	},
	contractFooterNotes: state => {
		return state.contractFooterNotes
	},
	coverNoteFooterNotes: state => {
		return state.coverNoteFooterNotes
	},
	invoiceFooterNotes: state => {
		return state.invoiceFooterNotes
	},
	altInvoiceFooterNotes: state => {
		return state.altInvoiceFooterNotes
	},
	localSalesInvoiceFooterNotes: state => {
		return state.localSalesInvoiceFooterNotes
	},
	lcaConditions: state => {
		return state.lcaConditions
	},
	lcaNotes: state => {
		return state.lcaNotes
	},
	purchaseOrderFooterNotes: state => {
		return state.purchaseOrderFooterNotes
	},
	salesOrderFooterNotes: state => {
		return state.salesOrderFooterNotes
	},
	getField
}

const mutations = {
	setAllDocumentFooters ( state, data) {
		state.contractFooterNotes = data.contractFooterNotes
		state.coverNoteFooterNotes = data.coverNoteFooterNotes
		state.invoiceFooterNotes = data.invoiceFooterNotes
		state.altInvoiceFooterNotes = data.altInvoiceFooterNotes
		state.localSalesInvoiceFooterNotes = data.localSalesInvoiceFooterNotes
		state.lcaConditions = data.lcaConditions
		state.lcaNotes = data.lcaNotes
		state.purchaseOrderFooterNotes = data.purchaseOrderFooterNotes
		state.salesOrderFooterNotes = data.salesOrderFooterNotes

		let allDocumentFooters = []
		Object.entries(data).forEach((cat) => {
			cat[1].forEach(document => {
				allDocumentFooters.push(document)
			})
		})
		state.allDocumentFooters = allDocumentFooters
	},
	setDocumentFooterToUpdate ( state, documentFooter ){
		state.update = documentFooter
	},
	resetDocumentFooterToUpdate ( state ) {
		state.update = getDefaultState()
	},
	resetCurrentState (state) {
		state.current = getDefaultState()
	},
	resetState (state) {
		state.allDocumentFooters = []
		state.contractFooterNotes = []
		state.coverNoteFooterNotes = []
		state.invoiceFooterNotes = []
		state.altInvoiceFooterNotes = []
		state.localSalesInvoiceFooterNotes = []
		state.lcaConditions = []
		state.lcaNotes = []
		state.purchaseOrderFooterNotes = []
		state.salesOrderFooterNotes = []
	},
	updateField
}

const actions = {
	createDocumentFooter ({ state }) {
		const documentFooter = _.cloneDeep(state.current)
		return new Promise((resolve, reject) => {
			api
				.post('/document-footers',{
						footer: documentFooter,
					}
				)
				.then((response)=>{
					resolve(response.data)
				})
				.catch(error => {
					reject(error)
				})
		})
	},
	deleteDocumentFooterById ({commit}, documentFooterId) {
		return new Promise((resolve, reject) => {
			api
				.delete('/document-footers/' + documentFooterId)
				.then(response => {
					// resolve('done')
					resolve(response.data)
				})
				.catch(error => {
					reject(error);
				})
		})
	},
	getDocumentFooterToUpdateById ({commit}, documentFooterId) {
		return new Promise((resolve, reject) => {
			api
				.get('/document-footers/' + documentFooterId )
				.then(response => {
					commit('setDocumentFooterToUpdate', response.data.data[0])
					resolve('done')
				})
				.catch(error => {
					reject(error)
				})
		})
	},
	getAllDocumentFooters ({commit}){
		return new Promise((resolve, reject) => {
			api
				.get("/document-footers")
				.then(response => {
					commit('setAllDocumentFooters',response.data.data);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	resetDocumentFooterToUpdate ({commit}) {
		commit('resetDocumentFooterToUpdate')
	},
	resetCurrentState ({commit}) {
		commit('resetCurrentState')
	},
	resetState ({commit}){
		commit('resetState')
	},
	updateDocumentFooter ({state} ){
		const documentFooter = _.cloneDeep(state.update)
		return new Promise((resolve, reject) => {
			api
				.put("/document-footers/" + state.update.item_id,{
					footer: documentFooter
				})
				.then(response => {
					resolve(response.data)
				})
				.catch(error => {
					reject(error)
				})
		})
	},
}

export const documentfooter = {
	namespaced,
	state,
	getters,
	actions,
	mutations
}