import {getField, updateField} from "vuex-map-fields";
import { api } from "Api/index";
import _ from 'lodash';
import {isNumeric} from "Helpers/helpers";

const namespaced = {
	namespaced: true
}

const getDefaultState = () => {
	return {
		index: 0,
		lineNumber: 1,
		paymentDateMenu: false,
		AssetDocument: {},
		Paymenttransaction: {
			amount: 0,
			bankcharge: 0,
			currency_id: 2,
			currencyrate: 1.0000,
			doc_id: null,
			id: null,
			income: 1,
			isdeposit: 0,
			key_id: null,
			model: 'c',
			payee_id: null,
			payer_id: null,
			payment_id: null,
			payment_slip: null,
			paymentdate: null
		}
	}
}

const state = {
	current: {
		Payment: {
			PaymentTransactions: [],
			totalPaid: 0,
			totalOutstanding: 0
		},
		PoPayment: {
			PaymentTransactions: [],
			totalPaid: 0,
			totalOutstanding: 0
		}
	},
	update: {
		Payment: {
			PaymentTransactions: [],
			totalPaid: 0,
			totalOutstanding: 0
		},
		PoPayment: {
			PaymentTransactions: [],
			totalPaid: 0,
			totalOutstanding: 0
		}
	},
}

const getters = {
	getField
}

const mutations = {
	addTransactionRow (state, payload) {
		let i = 0;
		let row = _.cloneDeep(getDefaultState());

		row.Paymenttransaction.income = payload.paymentType == 'PoPayment' ? 0 : 1
		row.Paymenttransaction.key_id = payload.keyId
		row.Paymenttransaction.model = payload.paymentType == 'PoPayment' ? 'p' : 'c'
		row.Paymenttransaction.payment_id = payload.paymentId
		row.Paymenttransaction.payer_id = payload.payerId
		row.Paymenttransaction.payee_id = payload.payeeId
		row.Paymenttransaction.paymentdate = new Date().toISOString().substr(0,10)

		state[payload.prefix][payload.paymentType].PaymentTransactions.push(row)
		state[payload.prefix][payload.paymentType].PaymentTransactions.forEach((item) => {
			item.index = i
			item.lineNumber = i + 1
			item.paymentDateMenu = false
			i++
		})
	},
	deleteTransactionRow (state, payload) {
		state[payload.prefix][payload.paymentType].PaymentTransactions.splice(payload.index, 1)
		let i = 0;
		state[payload.prefix][payload.paymentType].PaymentTransactions.forEach((item) => {
			item.index = i
			item.lineNumber = i + 1
			item.paymentDateMenu = false
			i++
		})
	},
	resetState(state,payload) {
		state[payload.prefix]['Payment'].PaymentTransactions = []
		state[payload.prefix]['Payment'].totalPaid = 0,
		state[payload.prefix]['Payment'].totalOutstanding = 0
		state[payload.prefix]['PoPayment'].PaymentTransactions = []
		state[payload.prefix]['PoPayment'].totalPaid = 0,
		state[payload.prefix]['PoPayment'].totalOutstanding = 0
	},
	setPaymentTransactions (state, payload) {
		state[payload.prefix][payload.paymentType].PaymentTransactions = payload.data
		let i = 0;
		state[payload.prefix][payload.paymentType].PaymentTransactions.forEach((item) => {
			item.index = i
			item.lineNumber = i + 1
			item.paymentDateMenu = false
			item.AssetDocument = {}
			i++
		})
	},
	updateField,
	updateTotals (state, payload) {
		let paid = 0
		state[payload.prefix][payload.paymentType].PaymentTransactions.forEach((item) => {
			paid += isNumeric(item.Paymenttransaction.receivable) ? parseFloat(item.Paymenttransaction.receivable) : 0
		})
		paid = Math.abs(paid)
		state[payload.prefix][payload.paymentType].totalPaid = paid
		console.log('updating totals...')
	},
	updateTransactions (state, payload){
		let keys = payload['field'].split('.')
		let prefix = payload['prefix']
		let index = payload['index']
		let value = payload['value']
		let paymentType = payload['paymentType']
		if(keys[1]) {
			state[prefix][paymentType].PaymentTransactions[index][keys[0]][keys[1]] = value
		} else {
			state[prefix][paymentType].PaymentTransactions[index][keys[0]] = value
		}
		let numberFields = ['amount','currencyrate']
		if(keys[0] == 'Paymenttransaction' && numberFields.indexOf(keys[1]) !== -1){
			let receivable = parseFloat(state[prefix][paymentType].PaymentTransactions[index]['Paymenttransaction']['amount']) / parseFloat(state[prefix][paymentType].PaymentTransactions[index]['Paymenttransaction']['currencyrate'])
			state[prefix][paymentType].PaymentTransactions[index]['Paymenttransaction']['receivable'] = receivable
		}
	}
}

const actions = {
	addTransactionRow ({commit},payload) {
		return new Promise((resolve, reject) => {
			commit('addTransactionRow', payload)
			resolve('done')
		})
	},
	deleteTransaction ( {commit}, payload ){
		return new Promise((resolve, reject) => {
			api
				.delete('/payments/' + payload.paymentId + '/transactions/' + payload.itemId)
				.then(()=>{
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
				.finally(()=>{
					resolve('done')
				})
		})
	},
	deleteTransactionRow ({commit},payload){
		commit('deleteTransactionRow',payload)
	},
	getTransactionsToUpdateByDocumentById ({commit}, payload) {
		return new Promise((resolve, reject) => {
			api
				.get('/payments/' + payload.paymentId + '/transactions')
				.then(response => {
					let data = {
						data: response.data.data,
						paymentType: payload.paymentType,
						prefix: payload.prefix
					}
					commit('setPaymentTransactions',data)
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
				.finally(()=>{
					let thisPayload = {
						prefix: payload.prefix,
						paymentType: payload.paymentType
					}
					commit('updateTotals',thisPayload);
					resolve('done')
				})
		})
	},
	resetTransactions({commit},payload) {
		commit('resetState',payload)
	},
	sendPaymentReceivedNotificationToBuyer({ commit}, payload) {
		return new Promise ((resolve, reject) => {
			api
				.put('/payments/' + payload.paymentId + '/transactions/' + payload.itemId + '/send-payment-received-notification',{
					contacts: payload.contacts
				})
				.then(response => {
					if(response.data.status == 'success') {
						resolve('done')
					} else {
						resolve('error')
					}
				})
				.catch(error => {
					reject(error);
				})
		})
	},
	sendTransactionSlipToSupplier ({ commit}, payload) {
		return new Promise ((resolve, reject) => {
			api
				.put('/payments/' + payload.paymentId + '/transactions/' + payload.itemId + '/send-slip',{
					contacts: payload.contacts
				})
				.then(response => {
					if(response.data.status == 'success') {
						resolve('done')
					} else {
						resolve('error')
					}
				})
				.catch(error => {
					reject(error);
				})
		})
	},
	updateTotals ({commit},payload) {
		return new Promise ((resolve, reject) => {
			commit('updateTotals',payload)
			resolve('done')
		})
	}
}

export const paymenttransaction = {
	namespaced,
	state,
	getters,
	actions,
	mutations
}