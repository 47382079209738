import {getField, updateField} from "vuex-map-fields";
import _ from 'lodash'
import {api} from "Api/index";
import {AbilityBuilder, Ability} from "@casl/ability";

const { can } = new AbilityBuilder(Ability)

const namespaced = {
	namespaced: true
}

const getDefaultState = () => {
	return {

	}
}

const state = {
	current: {
		Appic: {
			Sales: [],
			salesAgents: [],
			salesCustomers: [],
			salesDestinations: [],
			salesGrades: [],
			salesGradeGroups: [],
			salesMarketsegments: [],
			salesProductgroups: [],
			salesSalescolleagues: [],
			salesSizes: [],
			salesSpecies: [],
			salesSuppliers: [],
			salesThickness: [],
			scrollingList: false,
			totalSales: 0
		}
	},
	exportContractScheduleParams: {},
	exportDebitNoteScheduleParams: {},
	exportAppicReportParams: {}
}

const getters = {
	getField
}

const mutations = {
	resetState (state, payload){
		state[payload.prefix].Appic[payload.filter] = []
	},
	setAppicReportExportParams(state, payload) {
		state.exportAppicReportParams = payload
	},
	setSalesFiltersOptions (state, payload){
		state[payload.prefix].Appic[payload.filter] = payload.options
		if(payload.filter == 'salesCustomers') {
			if (can('access', 'Eprs')) {
				//add APP EXP
				let tempBuyer = {
					Customer: {
						id: 999999,
						title: 'APP EXP'
					}
				}
				state[payload.prefix].Appic[payload.filter].push(tempBuyer)
				state[payload.prefix].Appic[payload.filter].sort((a, b) => {
					return a.localeCompare(b)
				})
			}
		}
	},
	setItems (state, data){
		state.current.Appic.Sales = data;
	},
	setTotalItems (state, data){
		state.current.Appic.totalSales = data;
	},
	updateField
}

const actions = {
	getAllSales ({commit}, payload) {
		let filterOptions = payload.filterOptions
		let searchTerm = payload.searchTerm
		let conditions = []
		let searchConditions = {
			contracts: [],
			warehousesales: []
		}
		let dateValues = {
			from: null,
			to: null
		}
		if(filterOptions.monthFrom && filterOptions.yearFrom > 0){
			let fromDate = new Date(filterOptions.yearFrom, filterOptions.monthFrom - 1, 1)
			dateValues.from = [
				filterOptions.dateField,
				[fromDate.getFullYear(),(fromDate.getMonth() + 1).toString().padStart(2,'0'), fromDate.getDate().toString().padStart(2,'0')].join('-')
			]
		}
		if(filterOptions.monthTo && filterOptions.yearTo > 0){
			let toDate = new Date(filterOptions.yearTo, filterOptions.monthTo,0)
			dateValues.to = [
				filterOptions.dateField,
				[toDate.getFullYear(),(toDate.getMonth() + 1).toString().padStart(2,'0'), toDate.getDate().toString().padStart(2,'0')].join('-')
			]
		}
		if(dateValues.from?.length > 0 || dateValues.to?.length > 0){
			let obj = {
				field: 'sales.salesdate',
				value: dateValues
			}
			conditions.push(obj)
		}
		if(filterOptions.agents){
			let values = []
			filterOptions.agents.forEach(item => values.push(item.Agent.id))
			if(values.length > 0) {
				let obj = {
					field: 'sales.agent_id',
					value: values
				}
				conditions.push(obj)
			}
		}
		if(filterOptions.customers){
			let values = []
			filterOptions.customers.forEach(item => values.push(item.Customer.id))
			if(values.length > 0) {
				let obj = {
					field: 'sales.customer_id',
					value: values
				}
				conditions.push(obj)
			}
		}
		if(filterOptions.destinations){
			let values = []
			filterOptions.destinations.forEach(item => values.push(item.Destination.id))
			if(values.length > 0) {
				let obj = {
					field: 'sales.destinatione_id',
					value: values
				}
				conditions.push(obj)
			}
		}
		if(filterOptions.grades){
			let values = []
			filterOptions.grades.forEach(item => values.push(item.Grade.id))
			if(values.length > 0) {
				let obj = {
					field: 'sales.grade_id',
					value: values
				}
				conditions.push(obj)
			}
		}
		// if(filterOptions.gradeGroups){
		// 	let values = []
		// 	filterOptions.gradeGroups.forEach(item => values.push(item.GradeGroup.id))
		// 	if(values.length > 0) {
		// 		let obj = {
		// 			field: 'sales.gradegroup_id',
		// 			value: values
		// 		}
		// 		conditions.push(obj)
		// 	}
		// }
		if(filterOptions.marketsegments){
			let values = []
			filterOptions.marketsegments.forEach(item => values.push(item.Marketsegment.id))
			if(values.length > 0) {
				let obj = {
					field: 'sales.marketsegment_id',
					value: values
				}
				conditions.push(obj)
			}
		}
		if(filterOptions.productgroups){
			let values = []
			filterOptions.productgroups.forEach(item => values.push(item.Productgroup.id))
			if(values.length > 0) {
				let obj = {
					field: 'sales.productgroup_id',
					value: values
				}
				conditions.push(obj)
			}
		}
		if(filterOptions.salescolleagues){
			let values = []
			filterOptions.salescolleagues.forEach(item => values.push(item.Salescontact.id))
			if(values.length > 0) {
				let obj = {
					field: 'sales.salescontact_id',
					value: values
				}
				conditions.push(obj)
			}
		}
		if(filterOptions.shipped != null){
			let obj = {
				field: 'sales.shipped',
				value: filterOptions.shipped
			}
			conditions.push(obj)
		}
		if(filterOptions.sizes){
			let values = []
			filterOptions.sizes.forEach(item => values.push(item.Size.id))
			if(values.length > 0) {
				let obj = {
					field: 'sales.size_id',
					value: values
				}
				conditions.push(obj)
			}
		}
		if(filterOptions.species){
			let values = []
			filterOptions.species.forEach(item => values.push(item.Species.id))
			if(values.length > 0) {
				let obj = {
					field: 'sales.species_id',
					value: values
				}
				conditions.push(obj)
			}
		}
		if(filterOptions.status != null){
			let values = []
			filterOptions.status.forEach(item => values.push(item.Salestatus.id))
			if(values.length > 0) {
				let obj = {
					field: 'sales.salestatus_id',
					value: values
				}
				conditions.push(obj)
			}
		}
		// if(filterOptions.thickness){
		// 	let values = []
		// 	filterOptions.thickness.forEach(item => values.push(item.Thickness.id))
		// 	if(values.length > 0) {
		// 		let obj = {
		// 			field: 'sales.thickness_id',
		// 			value: values
		// 		}
		// 		conditions.push(obj)
		// 	}
		// }
		if(filterOptions.types != null){
			let values = []
			filterOptions.types.forEach(item => values.push(item.Salestype.id))
			if(values.length > 0) {
				let obj = {
					field: 'sales.salestype_id',
					value: values
				}
				conditions.push(obj)
			}

			// if(filterOptions.types['Salestype']['id'] == 'all'){
			// 	let obj = {
			// 		field: 'sales.salestype_id',
			// 		value: [1,2,3,5,6,99]
			// 	}
			// 	conditions.push(obj)
			// } else if (filterOptions.types['Salestype']['id'] == 'allcontracts'){
			// 	let obj = {
			// 		field: 'sales.salestype_id',
			// 		value: [1,2,3,5,6]
			// 	}
			// 	conditions.push(obj)
			// } else {
			// 	let obj = {
			// 		field: 'sales.salestype_id',
			// 		value: filterOptions.types['Salestype']['id']
			// 	}
			// 	conditions.push(obj)
			// }
		}
		if(filterOptions.suppliers != null){
			let values = []
			filterOptions.suppliers.forEach(item => values.push(item.Supplier.id))
			if(values.length > 0) {
				let obj = {
					field: 'sales.supplier_id',
					value: values
				}
				conditions.push(obj)
			}
		}
		let order = []
		order.push({field: 'sales.' + filterOptions.dateField, direction: 'ASC'})
		if(searchTerm != null && searchTerm != ''){
			searchConditions.contracts.push({field: 'contracts.title', value: searchTerm})
			searchConditions.contracts.push({field: 'contracts.contractdate', value: searchTerm})
			searchConditions.contracts.push({field: 'contracts.supplier_pi_no', value: searchTerm})
			searchConditions.contracts.push({field: 'contracts.shipweekdate', value: searchTerm})
			searchConditions.contracts.push({field: 'contracts.etsdate', value: searchTerm})
			searchConditions.contracts.push({field: 'contracts.etddate', value: searchTerm})
			searchConditions.contracts.push({field: 'contracts.vessel', value: searchTerm})
			searchConditions.contracts.push({field: 'customers.title', value: searchTerm})
			searchConditions.contracts.push({field: 'debitnotes.title', value: searchTerm})
			searchConditions.contracts.push({field: 'grades.title', value: searchTerm})
			searchConditions.contracts.push({field: 'paymentterms.title', value: searchTerm})
			searchConditions.contracts.push({field: 'sizes.title', value: searchTerm})
			// searchConditions.contracts.push({field: 'thickness.title', value: searchTerm})
			searchConditions.contracts.push({field: 'species.title', value: searchTerm})
			searchConditions.contracts.push({field: 'suppliers.title', value: searchTerm})
			searchConditions.contracts.push({field: 'supplierpaymentterms.title', value: searchTerm})
		}
		return new Promise((resolve, reject) => {
			// if(payload.tableOptions.page != null) {
				api
					.get("/reports/appic", {
						params: {
							conditions: conditions,
							// pagination: {
							// 	skip: (payload.tableOptions.page - 1) * payload.tableOptions.itemsPerPage,
							// 	take: payload.tableOptions.itemsPerPage
							// },
							order: order,
							search_contract_conditions: searchConditions.contracts,
							search_warehousesales_conditions: searchConditions.warehousesales,
							search_epr_conditions: searchConditions.eprs
						}
					})
					.then(response => {
						// commit('setItems', response.data.data)
						// commit('setTotalItems', response.data.totalRows)
						// resolve('done')
						commit('setAppicReportExportParams',{conditions: conditions, searchConditions: searchConditions, order: order})
						let tableData = {
							rows: response.data.data,
							totalRows: response.data.totalRows
						}
						resolve(tableData)
					})
					.catch(error => {
						reject(error);
					});
			// } else {
			// 	resolve('done')
			// }
		})
	},
	getAllSalesV2 ({commit}, payload) {
		let filterOptions = payload.filterOptions
		let searchTerm = payload.searchTerm
		let conditions = []
		let searchConditions = {
			contracts: [],
			warehousesales: [],
			eprs: []
		}
		let dateValues = {
			from: null,
			to: null
		}
		if(filterOptions.monthFrom && filterOptions.yearFrom > 0){
			let fromDate = new Date(filterOptions.yearFrom, filterOptions.monthFrom - 1, 1)
			dateValues.from = [
				filterOptions.dateField,
				[fromDate.getFullYear(),(fromDate.getMonth() + 1).toString().padStart(2,'0'), fromDate.getDate().toString().padStart(2,'0')].join('-')
			]
		}
		if(filterOptions.monthTo && filterOptions.yearTo > 0){
			let toDate = new Date(filterOptions.yearTo, filterOptions.monthTo,0)
			dateValues.to = [
				filterOptions.dateField,
				[toDate.getFullYear(),(toDate.getMonth() + 1).toString().padStart(2,'0'), toDate.getDate().toString().padStart(2,'0')].join('-')
			]
		}
		if(dateValues.from?.length > 0 || dateValues.to?.length > 0){
			let obj = {
				field: 'sales.salesdate',
				value: dateValues
			}
			conditions.push(obj)
		}
		if(filterOptions.agents){
			let values = []
			filterOptions.agents.forEach(item => values.push(item.Agent.id))
			if(values.length > 0) {
				let obj = {
					field: 'sales.agent_id',
					value: values
				}
				conditions.push(obj)
			}
		}
		if(filterOptions.certifications){
			let values = []
			filterOptions.certifications.forEach(item => values.push(item.Certification.id))
			if(values.length > 0) {
				let obj = {
					field: 'sales.certification_id',
					value: values
				}
				conditions.push(obj)
			}
		}
		if(filterOptions.customers){
			let values = []
			filterOptions.customers.forEach(item => values.push(item.Customer.id))
			if(values.length > 0) {
				let obj = {
					field: 'sales.customer_id',
					value: values
				}
				conditions.push(obj)
			}
		}
		if(filterOptions.destinations){
			let values = []
			filterOptions.destinations.forEach(item => values.push(item.Destination.id))
			if(values.length > 0) {
				let obj = {
					field: 'sales.destinatione_id',
					value: values
				}
				conditions.push(obj)
			}
		}
		if(filterOptions.grades){
			let values = []
			filterOptions.grades.forEach(item => values.push(item.Grade.id))
			if(values.length > 0) {
				let obj = {
					field: 'sales.grade_id',
					value: values
				}
				conditions.push(obj)
			}
		}
		if(filterOptions.gradeGroups){
			let values = []
			filterOptions.gradeGroups.forEach(item => values.push(item.GradeGroup.id))
			if(values.length > 0) {
				let obj = {
					field: 'sales.gradegroup_id',
					value: values
				}
				conditions.push(obj)
			}
		}
		if(filterOptions.marketsegments){
			let values = []
			filterOptions.marketsegments.forEach(item => values.push(item.Marketsegment.id))
			if(values.length > 0) {
				let obj = {
					field: 'sales.marketsegment_id',
					value: values
				}
				conditions.push(obj)
			}
		}
		if(filterOptions.productgroups){
			let values = []
			filterOptions.productgroups.forEach(item => values.push(item.Productgroup.id))
			if(values.length > 0) {
				let obj = {
					field: 'sales.productgroup_id',
					value: values
				}
				conditions.push(obj)
			}
		}
		if(filterOptions.salescolleagues){
			let values = []
			filterOptions.salescolleagues.forEach(item => values.push(item.Salescontact.id))
			if(values.length > 0) {
				let obj = {
					field: 'sales.salescontact_id',
					value: values
				}
				conditions.push(obj)
			}
		}
		if(filterOptions.shipped != null){
			let obj = {
				field: 'sales.shipped',
				value: filterOptions.shipped
			}
			conditions.push(obj)
		}
		if(filterOptions.sizes){
			let values = []
			filterOptions.sizes.forEach(item => values.push(item.Size.id))
			if(values.length > 0) {
				let obj = {
					field: 'sales.size_id',
					value: values
				}
				conditions.push(obj)
			}
		}
		if(filterOptions.species){
			let values = []
			filterOptions.species.forEach(item => values.push(item.Species.id))
			if(values.length > 0) {
				let obj = {
					field: 'sales.species_id',
					value: values
				}
				conditions.push(obj)
			}
		}
		if(filterOptions.status != null){
			let values = []
			filterOptions.status.forEach(item => values.push(item.Salestatus.id))
			if(values.length > 0) {
				let obj = {
					field: 'sales.salestatus_id',
					value: values
				}
				conditions.push(obj)
			}
			// let obj = {
			// 	field: 'sales.salestatus_id',
			// 	value: filterOptions.status
			// }
			// conditions.push(obj)
		}
		if(filterOptions.thickness){
			let values = []
			filterOptions.thickness.forEach(item => values.push(item.Thickness.id))
			if(values.length > 0) {
				let obj = {
					field: 'sales.thickness_id',
					value: values
				}
				conditions.push(obj)
			}
		}
		if (filterOptions.types != null){
			let values = []
			filterOptions.types.forEach(item => values.push(item.Salestype.id))
			if(values.length > 0) {
				let obj = {
					field: 'sales.salestype_id',
					value: values
				}
				conditions.push(obj)
			}
		}
		if(filterOptions.suppliers != null){
			let values = []
			filterOptions.suppliers.forEach(item => values.push(item.Supplier.id))
			if(values.length > 0) {
				let obj = {
					field: 'sales.supplier_id',
					value: values
				}
				conditions.push(obj)
			}
		}
		let order = []
		order.push({field: 'sales.' + filterOptions.dateField, direction: 'ASC'})
		if(searchTerm != null && searchTerm != ''){
			searchConditions.contracts.push({field: 'contracts.title', value: searchTerm})
			searchConditions.contracts.push({field: 'contracts.contractdate', value: searchTerm})
			searchConditions.contracts.push({field: 'contracts.supplier_pi_no', value: searchTerm})
			searchConditions.contracts.push({field: 'contracts.shipweekdate', value: searchTerm})
			searchConditions.contracts.push({field: 'contracts.etsdate', value: searchTerm})
			searchConditions.contracts.push({field: 'contracts.etddate', value: searchTerm})
			searchConditions.contracts.push({field: 'contracts.vessel', value: searchTerm})
			searchConditions.contracts.push({field: 'customers.title', value: searchTerm})
			searchConditions.contracts.push({field: 'debitnotes.title', value: searchTerm})
			searchConditions.contracts.push({field: 'grades.title', value: searchTerm})
			searchConditions.contracts.push({field: 'paymentterms.title', value: searchTerm})
			searchConditions.contracts.push({field: 'sizes.title', value: searchTerm})
			searchConditions.contracts.push({field: 'species.title', value: searchTerm})
			searchConditions.contracts.push({field: 'suppliers.title', value: searchTerm})
			searchConditions.contracts.push({field: 'supplierpaymentterms.title', value: searchTerm})
		}
		return new Promise((resolve, reject) => {
			api
				.get("/reports/appic-v2", {
					params: {
						conditions: conditions,
						order: order,
						search_contract_conditions: searchConditions.contracts,
						search_warehousesales_conditions: searchConditions.warehousesales,
						search_epr_conditions: searchConditions.eprs
					}
				})
				.then(response => {
					commit('setAppicReportExportParams',{conditions: conditions, searchConditions: searchConditions, order: order})
					let tableData = {
						rows: response.data.data,
						totalRows: response.data.totalRows
					}
					resolve(tableData)
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	getMarketingReport({commit},payload) {
		return new Promise((resolve, reject) => {
			api
				.get("/reports/marketing", {
					params: {
						conditions: {
							...payload.filterValues,
							resultByFilters: payload.resultByFilters,
							basedOnFilters: payload.basedOnFilters
						}
					}
				})
				.then(response => {
					let reportData = response.data.data
					let reportHash = response.data.reportHash
					resolve({
						reportData: reportData,
						reportHash: reportHash
					})
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	getMarketingReportFilters({commit},payload) {
		return new Promise((resolve, reject) => {
			api
				.get("/reports/marketing/result-by-options/" + payload.filter, {
					params: {
						conditions: payload.filterValues
					}
				})
				.then(response => {
					let tableData = response.data.data
					resolve(tableData)
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	getSalesFiltersOptions ({commit},payload){
		let filter = payload.filter
		let conditions = payload.conditions
		return new Promise((resolve, reject) => {
			api
				.get("/reports/appic/" + filter, {
					params: {
						conditions: conditions
					}
				})
				.then(response => {
					let filterName = null
					switch(filter){
						case 'agents':
							filterName = 'salesAgents'
							break;
						case 'buyers':
							filterName = 'salesCustomers'
							break;
						case 'destinations':
							filterName = 'salesDestinations'
							break;
						case 'grades':
							filterName = 'salesGrades'
						case 'grade-groups':
							filterName = 'salesGradeGroups'
							break;
						case 'market-segments':
							filterName = 'salesMarketsegments'
							break;
						case 'product-groups':
							filterName = 'salesProductgroups'
							break;
						case 'salescolleagues':
							filterName = 'salesSalescolleagues'
							break;
						case 'sizes':
							filterName = 'salesSizes'
							break;
						case 'species':
							filterName = 'salesSpecies'
							break;
						case 'suppliers':
							filterName = 'salesSuppliers'
							break;
						case 'thickness':
							filterName = 'salesThickness'
							break;
					}
					let payload = {
						prefix: 'current',
						filter: filterName,
						options: response.data.data
					}
					commit('setSalesFiltersOptions',payload)
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
				.finally(()=>{
					resolve('done')
				})
		})
	},
	resetSalesFilterOptions ({commit}, payload){
		commit('resetState',payload)
	}
}

export const report = {
	namespaced,
	state,
	getters,
	actions,
	mutations
}