import {getField, updateField} from "vuex-map-fields";
import {api} from "../../../api";
import _ from 'lodash'
import {isNumeric} from "Helpers/helpers";

const namespaced = {
	namespaced: true
}

const getDefaultState = () => {
	return {
		index: 0,
		lineNumber: 1,
		Grade: {
			id: null,
			title: null
		},
		Mc: {
			id: null,
			title: null
		},
		Measurement: {
			id: 1,
			title: 'M3'
		},
		Wpritem: {
			id: null,
			add_desc: null,
			certification_id: 0,
			cost: 0,
			description: null,
			grade_id: 1924, //N/A
			length: null,
			mc_id: null,
			measurement_id: 1,
			productgroup_id: null,
			size_id: null,
			size_text: null,
			spec_id: null,
			species_id: null,
			thickness_id: null,
			unitprice: 0,
			volume: 0,
			width: null,
			wpr_id: null
		},
		Size: {
			id: null,
			title: null
		},
		Spec: {
			id: null,
			title: null
		},
		Species: {
			id: null,
			title: null
		},
		Options: {
			grades: [],
			species: [],
			specs: []
		}
	}
}

const state = {
	current: {
		Wpritems: [
			{
				index: 0,
				lineNumber: 1,
				Grade: {
					id: null,
					title: null
				},
				Mc: {
					id: null,
					title: null
				},
				Measurement: {
					id: 1,
					title: 'M3'
				},
				Wpritem: {
					id: null,
					add_desc: null,
					certification_id: 0,
					cost: 0,
					description: null,
					grade_id: 1924, //N/A
					length: null,
					mc_id: null,
					measurement_id: 1,
					productgroup_id: null,
					size_id: null,
					size_text: null,
					spec_id: null,
					species_id: null,
					thickness_id: null,
					unitprice: 0,
					volume: 0,
					width: null,
					wpr_id: null
				},
				Size: {
					id: null,
					title: null
				},
				Spec: {
					id: null,
					title: null
				},
				Species: {
					id: null,
					title: null
				},
				Options: {
					grades: [],
					species: [],
					specs: []
				}
			}
		],
		itemsTotalAmount: 0,
		itemsTotalQty: []
	},
	update: {
		Wpritems: [],
		itemsTotalAmount: 0,
		itemsTotalQty: []
	}
}

const getters = {
	getField
}

const mutations = {
	addWprItemRow (state, payload){
		let i = 0;
		let wpr = payload.wpr
		let row = getDefaultState();
		row.Wpritem.wpr_id = wpr.id
		state[payload.prefix].Wpritems.push(row)
		state[payload.prefix].Wpritems.forEach((item) => {
			item.index = i
			item.lineNumber = i + 1
			i++
		})
	},
	deleteWprItemRow (state, payload) {
		state[payload.prefix].Wpritems.splice(payload.index, 1)
		let i = 0;
		state[payload.prefix].Wpritems.forEach((item) => {
			item.index = i
			item.lineNumber = i + 1
			i++
		})
	},
	resetState (state, prefix){
		// console.log('Resetting WPR items')
		state[prefix].Wpritems = []
		state[prefix].Wpritems.push(getDefaultState())
		state[prefix].itemsTotalAmount = 0
		state[prefix].itemsTotalQty = [{Total: {qty: 0.0, uofm: 'M3'}}]
	},
	setWprItems (state, payload) {
		state[payload.prefix].Wpritems = payload.data
		let i = 0;
		state[payload.prefix].Wpritems.forEach((item) => {
			item.index = i
			item.lineNumber = i + 1
			//add Options
			item['Options'] = {
				grades: [],
				species: [],
				specs: []
			}
			i++
		})
	},
	updateField,
	updateWprItems (state, payload){
		let keys = payload['field'].split('.')
		let prefix = payload['prefix']
		state[prefix].Wpritems[payload['index']][keys[0]][keys[1]] = payload['value']
		let numberFields = ['volume','unitprice','cost']
		if(keys[0] == 'Wpritem' && numberFields.indexOf(keys[1]) !== -1){
			let cost = parseFloat(state[prefix].Wpritems[payload['index']]['Wpritem']['volume']) * parseFloat(state[prefix].Wpritems[payload['index']]['Wpritem']['unitprice'])
			state[prefix].Wpritems[payload['index']]['Wpritem']['cost'] = cost
		}
	},
	updateTotals (state, prefix) {
		let cost = 0, quantities = []
		state[prefix].Wpritems.forEach((item) => {
			cost += isNumeric(item.Wpritem.cost) ? parseFloat(item.Wpritem.cost) : 0
			let idx = quantities.findIndex(t=>t.Total.uofm == item.Measurement.title);
			if(idx !== -1){
				quantities[idx]['Total']['qty'] += isNumeric(item.Wpritem.volume) ? parseFloat(item.Wpritem.volume) : 0;
			} else {
				let obj = {'Total': {}}
				obj['Total']['uofm'] = item.Measurement.title;
				obj['Total']['qty'] = isNumeric(item.Wpritem.volume) ? parseFloat(item.Wpritem.volume) : 0;
				quantities.push(obj)
			}
		})
		state[prefix].itemsTotalAmount = cost
		state[prefix].itemsTotalQty = quantities
		// console.log('updating totals...')
	}
}

const actions = {
	addWprItemRow ({commit},payload){
		commit('addWprItemRow',payload)
	},
	deleteWprItemRow ({commit},payload){
		commit('deleteWprItemRow',payload)
	},
	deleteWprItem ({commit},payload){
		return new Promise((resolve, reject) => {
			let wpr_id = payload.wpr_id
			let item_id = payload.item_id
			api
				.delete('/wprs/' + wpr_id + '/items/' + item_id)
				.then(response => {
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
		})
	},
	getItemsByWpr ({commit}, payload) {
		let wpr_id = payload.wpr_id
		let prefix = payload.prefix
		return new Promise((resolve, reject) => {
			api
				.get("/wprs/" + wpr_id + "/items")
				.then(response => {
					let payload = {
						data: response.data.data,
						prefix: prefix
					}
					commit('setWprItems',payload)
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
				.finally(function(){
					commit('updateTotals',prefix);
				});
		})
	},
	resetWprItems ({commit},prefix) {
		return new Promise ((resolve, reject) => {
			commit('resetState',prefix)
			resolve('done')
		})
	},
	updateTotals ({commit},prefix) {
		return new Promise ((resolve, reject) => {
			commit('updateTotals',prefix)
			resolve('done')
		})
	}
}

export const wpritem = {
	namespaced,
	state,
	getters,
	actions,
	mutations
}