import {getField, updateField} from "vuex-map-fields";
import {api} from "../../../api";
import _ from 'lodash'

const namespaced = {
	namespaced: true
}

const getDefaultState = () => {
	return {
		SdaDocument: {
			filters: [],
			id: null,
			sdadoctype_id: 1,
			sort_id: 1,
			title: null,
			template: 1
		}
	}
}

const state = {
	allDocuments: {
		standard: [],
		countryBuyerSpecific: [],
		otherRequests: [],
		presentation: []
	},
	current: {
		SdaDocument: {
			filters: [],
			id: null,
			sdadoctype_id: 1,
			sort_id: 1,
			title: null,
			template: 1
		}
	},
	update: {
		SdaDocument: {
			filters: [],
			id: null,
			sdadoctype_id: 1,
			sort_id: 1,
			title: null,
			template: 1
		}
	}
}

const getters = {
	getField
}

const mutations = {
	updateField,
	resetSdaDocumentToUpdate(state) {
		state.update.SdaDocument = getDefaultState()['SdaDocument']
	},
	resetCurrentState(state) {
		state.current.SdaDocument = getDefaultState()['SdaDocument']
	},
	resetState(state) {
		state.allDocuments.standard = []
		state.allDocuments.countryBuyerSpecific = []
		state.allDocuments.otherRequests = []
		state.allDocuments.presentation = []
	},
	setAllSdaDocuments(state, data) {
		// console.log(data)
		state.allDocuments.standard = data.standard
		state.allDocuments.countryBuyerSpecific = data.specific
		state.allDocuments.otherRequests = data.other
		state.allDocuments.presentation = data.presentation
	},
	setSdaDocumentToUpdate(state, data) {
		state.update.SdaDocument.id = data.Clause.id
		state.update.SdaDocument.title = data.Clause.title
		state.update.SdaDocument.sdadoctype_id = data.Clause.sdadoctype_id
		state.update.SdaDocument.template = data.Clause.template
	}
}

const actions = {
	deleteSdaDocumentById({commit},val) {
		return new Promise((resolve, reject) => {
			api
				.delete("/sdadocuments/default/" + val)
				.then(response => {
					// if(response.data.status == 'success') {
					// 	resolve('done')
					// } else {
					// 	resolve('error')
					// }
					resolve(response.data)
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	deleteSdaDocumentFilterById( {commit}, val ) {
		return new Promise((resolve, reject) => {
			api
				.delete("/sdadocuments/filter/" + val)
				.then(response => {
					// if(response.data.status == 'success') {
					// 	resolve('done')
					// } else {
					// 	resolve('error')
					// }
					resolve(response.data)
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	createSdaDocument({state}) {
		let sdaDocument = _.cloneDeep(state.current.SdaDocument)
		delete  sdaDocument.filters

		return new Promise((resolve, reject) => {
			api
				.post('/sdadocuments',{
						sdadocument: sdaDocument
					}
				)
				.then((response) => {
					// if(response.data.status == 'success') {
					// 	resolve('done')
					// } else {
					// 	resolve('error')
					// }
					resolve(response.data)
				})
				.catch(error => {
					reject(error)
				})
				// .finally(()=>{
				// 	resolve('error')
				// })
		})
	},
	getSdaDocuments({commit}){
		return new Promise((resolve, reject) => {
			api
				.get("/sdadocuments/manage")
				.then(response => {
					commit('setAllSdaDocuments',response.data.data);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	getSdaDocumentToUpdateById( {commit}, documentId ) {
		return new Promise((resolve, reject) => {
			api
				.get('/sdadocuments/' + documentId )
				.then(response => {
					commit('setSdaDocumentToUpdate', response.data.data[0])
					resolve('done')
				})
				.catch(error => {
					reject(error)
				})
		})
	},
	resetCurrentState({commit}) {
		return new Promise ((resolve, reject) => {
			commit('resetCurrentState')
			resolve('done')
		})
	},
	resetSdaDocumentToUpdate({commit}) {
		return new Promise ((resolve, reject) => {
			commit('resetSdaDocumentToUpdate')
			resolve('done')
		})
	},
	resetState ({commit}){
		return new Promise ((resolve, reject) => {
			commit('resetState')
			resolve('done')
		})
	},
	updateSdaDocument({state}) {
		let sdaDocument = _.cloneDeep(state.update.SdaDocument)
		delete  sdaDocument.filters

		return new Promise((resolve, reject) => {
			api
				.put("/sdadocuments/" + state.update.SdaDocument.id,{
					sdadocument: sdaDocument
				})
				.then(response => {
					// this.updatedSdaSaved = true
					// this.updatedSdaUnsaved = false
					// resolve('done')
					resolve(response.data)
				})
				.catch(error => {
					reject(error)
					resolve(error)
				})
				// .then(()=>{
				// 	resolve('done')
				// });
		})
	}
}

export const sdadocument = {
	namespaced,
	state,
	getters,
	actions,
	mutations
}