<template>
    <div>
        <v-dialog
                persistent
                transition="dialog-bottom-transition"
                overlay=false
                v-model="dialog"
        >
            <v-card>
                <v-card-title class="warning white--text justify-space-between">
                    <span>{{ $t('message.inactivityDetected') }}</span>
                </v-card-title>
                <v-card-text>
                    <div class="mt-2 font-md">
                        <span>{{ $t('message.youHaveBeenInactive') + ' ' +  '2' + ' hours. ' + $t('message.youWillLogOut') + ' ' + countdownTimerDisplay + ' ' + $t('message.seconds') + '.' }}</span>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="continueSession">{{ $t('message.continueWorking') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
                persistent
                transition="dialog-bottom-transition"
                v-model="loggedOut"
        >
            <v-card>
                <v-card-title class="warning white--text justify-space-between">
                    <span>{{ $t('message.sessionStopped') }}</span>
                </v-card-title>
                <v-card-text>
                    <div class="mt-2 font-md">
                        <span>{{ $t('message.youHaveBeenLoggedOut') }}</span>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: "AppTimedOut",
    props: ['options'],
    data() {
        return {
            appIsIdle: false,
            blinkIntervalId: null,
            countdownTimer: 0,
            intervalId: null,
            isPageTitleBlinking: false,
            loggedOut: false,
            originalPageTitle: null,
            timeOut: null
        }
    },
    computed: {
        countdownTimerDisplay() {
            return Math.floor(this.countdownTimer / 1000)
        },
        dialog() {
            return this.appIsIdle
        },
    },
    methods: {
        resetUserActivity() {
            this.resetTimer();
            return true
        },
        incrementCounter() {
            // checkIfUserLoggedOut();
            if (localStorage.getItem("counter") == "NaN") {
                localStorage.setItem("counter", "0");
            } else {
                const counter = parseInt(localStorage.getItem("counter")) + 1;
                localStorage.setItem("counter", counter.toString());
            }
            // console.log('Counter incremented - ' + new Date()) //temp
            this.resetTimer();
            return true
        },
        handleIdleTimedOut() {
            window.sharedCounter = localStorage.getItem("counter");
            if (window.localCounter == window.sharedCounter) {
                this.startPageTitleBlink()
                localStorage.setItem("timedOut", "true");
                this.removeEventListeners()
                this.appIsIdle = true
                this.logOutTimer()
            }
            return true
        },
        resetTimer() {
            window.localCounter = localStorage.getItem("counter");
            clearTimeout(this.timeOut);
            this.timeOut = setTimeout(this.handleIdleTimedOut, this.options.timeoutPeriod);
            localStorage.removeItem("timedOut")
            // console.log('Timer reset - ' + new Date());
            this.appIsIdle = false
            return true
        },
        logOutTimer() {
            const _this = this
            this.intervalId = setInterval(()=>{
                if(_this.countdownTimer == 0){
                    clearInterval(_this.intervalId)
                    _this.countdownTimer = _this.countdownStart
                    _this.loggedOut = true
                    _this.appIsIdle = false
                    this.$store.dispatch("logoutUserFromFirebase", this.$router);
                } else {
                    _this.countdownTimer -= 1000
                }
            }, 1000)
        },
        continueSession() {
            this.stopPageTitleBlink()
            clearInterval(this.intervalId)
            this.intervalId = null;
            this.countdownTimer = this.options.countdownStart
            this.addEventListeners()
            this.resetTimer()
        },
        addEventListeners() {
            window.onload = this.incrementCounter;
            window.onscroll = this.resetUserActivity;
            window.onmousemove = this.resetUserActivity;
            window.ondblclick = this.resetUserActivity;
            window.oncontextmenu = this.resetUserActivity;
            window.onclick = this.resetUserActivity;
            window.onkeypress = this.resetUserActivity;
            window.onpageshow = this.resetUserActivity;
            window.onresize = this.resetUserActivity;
            window.onfocus = this.incrementCounter;
            window.ondrag = this.resetUserActivity;
            window.oncopy = this.resetUserActivity;
            window.oncut = this.resetUserActivity;
            window.onpaste = this.resetUserActivity;
        },
        removeEventListeners() {
            window.onload = null;
            window.onscroll = null;
            window.onmousemove = null;
            window.ondblclick = null;
            window.oncontextmenu = null;
            window.onclick = null;
            window.onkeypress = null;
            window.onpageshow = null;
            window.onresize = null;
            window.onfocus = null;
            window.ondrag = null;
            window.oncopy = null;
            window.oncut = null;
            window.onpaste = null;
        },
        startPageTitleBlink() {
            let _this = this
            if(this.isPageTitleBlinking) return;
            this.blinkIntervalId = setInterval(() => {
                document.title = document.title === _this.originalPageTitle ? '***' : _this.originalPageTitle
            },750);
            this.isPageTitleBlinking = true
            // console.log(this.blinkIntervalId)
            return;
        },
        stopPageTitleBlink() {
            if (!this.isPageTitleBlinking) return;
            clearInterval(this.blinkIntervalId);
            this.blinkIntervalId = null
            document.title = this.originalPageTitle;
            this.isPageTitleBlinking = false;
            return;
        }
    },
    mounted() {
        this.countdownTimer = this.options.countdownStart
        this.$nextTick(()=>{
            this.addEventListeners()
            this.originalPageTitle = document.title
        })
    }
}
</script>

<style scoped>

</style>