import {getField, updateField} from "vuex-map-fields";
import { api } from "../../../api";
import _ from 'lodash'

const getDefaultState = () => {
    return {
        allAgents: [],
        Agent: {
            id: null,
            scac: null,
            title: null
        },
        Company: {
            address1: null,
            address2: null,
            address3: null,
            city: null,
            country_id: 1,
            email: null,
            fax: null,
            id: null,
            name: null,
            postcode: null,
            state: null,
            tel: null,
            website: null,
        }
    }

}

const namespaced = {
    namespaced: true
}

const state = {
    allAgents: [],
    current: {
        Agent: {
            id: null,
            scac: null,
            title: null
        },
        Company: {
            address1: null,
            address2: null,
            address3: null,
            city: null,
            country_id: 1,
            email: null,
            fax: null,
            id: null,
            name: null,
            postcode: null,
            state: null,
            tel: null,
            website: null,
        }
    },
    update: {
        Agent: {},
        Company: {}
    }
}

const getters = {
    allAgents: state => {
        return state.allAgents;
    },
    findAgentById: state => {
        return id => state.allAgents.find (agent => {
            return agent.Agent.id === id
        })
    },
    getField
}

const actions  = {
    createAgent ({state, rootState}){
        let agent = _.cloneDeep(state.current.Agent)
        let company = _.cloneDeep(state.current.Company)

        return new Promise((resolve, reject) => {
            api
                .post('/agents',{
                        agent: agent,
                        company: company,
                    }
                )
                .then((response)=>{
                    // if(response.data.status == 'success') {
                    //     resolve('done')
                    // } else {
                    //     resolve('error')
                    // }
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
                // .finally(()=>{
                //     resolve('error')
                // })
        })
    },
    deleteAgentById ({commit}, agent_id) {
        return new Promise((resolve, reject) => {
            api
                .delete('/agents/' + agent_id)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error);
                })
        })
    },
    getAllAgents ({commit}) {
        return new Promise ((resolve, reject) => {
            api
                .get("/agents")
                .then(response => {
                    commit('setAllAgents',response.data.data);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getAgentToUpdateById ({commit}, agentId) {
        return new Promise((resolve, reject) => {
            api
                .get('/agents/' + agentId )
                .then(response => {
                    commit('setAgentToUpdate', response.data.data[0])
                    resolve('done')
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    resetAgentToUpdate ({commit}) {
        commit('resetAgentToUpdate')
    },
    resetCurrentState ({commit}) {
        commit('resetCurrentState')
    },
    resetState({commit}) {
        commit('resetState')
    },
    resetAllAgents({commit}) {
        commit('resetState')
    },
    updateAgent ({state} ){
        let agent = _.cloneDeep(state.update.Agent)
        let company = _.cloneDeep(state.update.Company)
        //remove unneeded properties
        return new Promise((resolve, reject) => {
            api
                .put("/agents/" + state.update.Agent.id,{
                    agent: agent,
                    company: company
                })
                .then(response => {
                    // resolve('done')
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
                // .then(()=>{
                //     resolve('done')
                // });
        })
    },
}

const mutations = {
    setAgentToUpdate ( state, agent ){
        state.update.Agent = agent.Agent
        state.update.Company = agent.Company
    },
    resetAgentToUpdate (state) {
        state.update.Agent = getDefaultState().Agent
        state.update.Company = getDefaultState().Company
    },
    resetCurrentState (state) {
        state.current.Agent = getDefaultState().Agent
        state.current.Company = getDefaultState().Company
    },
    resetState (state){
        state.allAgents = getDefaultState().allAgents
    },
    setAllAgents (state,data) {
        state.allAgents = data;
    },
    updateField
}

export const agent = {
    namespaced,
    state,
    getters,
    actions,
    mutations
}