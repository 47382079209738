import {getField, updateField} from "vuex-map-fields";
import {api} from "../../../api";
import _ from 'lodash'
import {isNumeric} from "Helpers/helpers";

const namespaced = {
	namespaced: true
}

const getDefaultState = () => {
	return {
		CoverNote: {
			id: null,
			contract_id: null,
			remark: null,
			title: null,
			ttadate: null,
			Contract: {
				containerocount: null,
				containersize_id: null,
				currency_id: 2,
				partial_no: null,
				revision_no: null,
				salestype_id: 1,
				title: null,
				version: null
			},
			Specifications: [],
			itemsTotalAmount: 0,
			itemsTotalQty: [],
		}
	}
}

const state = {
	allActiveCoverNotes: [],
	current: {
		CoverNote: {
			id: null,
			contract_id: null,
			remark: null,
			title: null,
			ttadate: null,
			Contract: {
				containerocount: null,
				containersize_id: null,
				currency_id: 2,
				partial_no: null,
				revision_no: null,
				salestype_id: 1,
				title: null,
				version: null
			},
			Specifications: [],
			itemsTotalAmount: 0,
			itemsTotalQty: [],
		},
		CoverNotes: [],
		totalCoverNotes: 0
	},
	update: {
		CoverNote: {
			id: null,
			contract_id: null,
			remark: null,
			title: null,
			ttadate: null,
			Contract: {
				containerocount: null,
				containersize_id: null,
				currency_id: 2,
				partial_no: null,
				revision_no: null,
				salestype_id: 1,
				title: null,
				version: null
			},
			Specifications: [],
			itemsTotalAmount: 0,
			itemsTotalQty: [],
		}
	},
	newCoverNoteSaved: false,
	newCoverNoteUnsaved: false,
	updatedCoverNoteSaved: false,
	updatedCoverNoteUnsaved: false
}

const getters = {
	allActiveCoverNotes: state => { return state.allActiveCoverNotes },
	getField
}

const mutations = {
	resetCurrentState (state) {
		console.log('Resetting this Cover Note...')
		state.current.CoverNote = getDefaultState().CoverNote
		state.newCoverNoteUnsaved = false
		state.newCoverNoteSaved = false
	},
	resetState (state){
		console.log('Resetting Cover Notes...')
		state.allActiveCoverNotes = []
	},
	setAllActiveCoverNotes (state, data) {
		state.allActiveCoverNotes = data;
	},
	setCurrentCoverNote (state,payload) {
		let contract = payload
		state.current.CoverNote.contract_id = contract.Contract?.id
		state.current.CoverNote.Contract.currency_id = contract.Contract?.currency_id
		state.current.CoverNote.Contract.containercount = contract.Contract?.containercount
		state.current.CoverNote.Contract.containersize_id = contract.Contract?.containersize_id
		state.current.CoverNote.Contract.partial_no = contract.Contract?.partial_no
		state.current.CoverNote.Contract.revision_no = contract.Contract?.revision_no
		state.current.CoverNote.Contract.salestype_id = contract.Contract?.salestype_id
		state.current.CoverNote.Contract.title = contract.Contract?.title
		state.current.CoverNote.Contract.version = contract.Contract?.version
	},
	setCoverNotes (state,data) {
		state.current.CoverNotes = data;
	},
	setCoverNoteToBeUpdated (state, data) {
		state.update.CoverNote = data.CoverNote
	},
	setSpecifications (state, payload) {
		state[payload.prefix].CoverNote.Specifications = payload.data
		let i = 0;
		state[payload.prefix].CoverNote.Specifications.forEach((item) => {
			item.index = i
			item.lineNumber = i + 1
			i++
		})
	},
	setTotalCoverNotes (state,val) {
		state.current.totalCoverNotes = val
	},
	updateField,
	updateTotals (state, prefix) {
		let amount = 0, quantities = []
		state[prefix].CoverNote.Specifications.forEach((item) => {
			if(item.type == 'credit') {
				if(state[prefix].CoverNote.Contract.salestype_id == 2) {
					amount -= isNumeric(item.Salesitem.amount) ? parseFloat(item.Salesitem.amount) : 0;
				} else {
					amount -= isNumeric(item.Salesitem.invoiceamount) ? parseFloat(item.Salesitem.invoiceamount) : 0;
				}
			} else {
				if(item.type == 'default' || (item.type == 'othercharge' && item.Salesitem.chargeto == 0)) {
					if(state[prefix].CoverNote.Contract.salestype_id == 2) {
						amount += isNumeric(item.Salesitem.amount) ? parseFloat(item.Salesitem.amount) : 0;
					} else {
						amount += isNumeric(item.Salesitem.invoiceamount) ? parseFloat(item.Salesitem.invoiceamount) : 0;
					}
				}
			}
			let idx = quantities.findIndex(t=>t.Total.uofm == item.Measurement.title);
			if(idx !== -1){
				if(state[prefix].CoverNote.Contract.salestype_id == 2) {
					quantities[idx]['Total']['qty'] += isNumeric(item.Salesitem.itemqty) ? parseFloat(item.Salesitem.itemqty) : 0;
				} else {
					quantities[idx]['Total']['qty'] += isNumeric(item.Salesitem.invqty) ? parseFloat(item.Salesitem.invqty) : 0;
				}
			} else {
				if(item.type == 'default') {
					let obj = {'Total': {}}
					obj['Total']['uofm'] = item.Measurement.title;
					if(state[prefix].CoverNote.Contract.salestype_id == 2) {
						obj['Total']['qty'] = isNumeric(item.Salesitem.itemqty) ? parseFloat(item.Salesitem.itemqty) : 0;
					} else {
						obj['Total']['qty'] = isNumeric(item.Salesitem.invqty) ? parseFloat(item.Salesitem.invqty) : 0;
					}
					quantities.push(obj)
				}
			}
		})
		state[prefix].CoverNote.itemsTotalAmount = amount
		state[prefix].CoverNote.itemsTotalQty = quantities
		console.log('updating totals...')
	}
}

const actions = {
	cancelCoverNoteById ({commit},val){
		return new Promise((resolve, reject) => {
			api
				.delete("/covernotes/" + val)
				.then(response => {
					if(response.data.status == 'success') {
						resolve('done')
					} else {
						resolve('error')
					}
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	createCoverNote ({state}) {
		let coverNote = _.cloneDeep(state.current.CoverNote)
		delete coverNote.Contract
		delete coverNote.Specifications
		delete coverNote.itemsTotalAmount
		delete coverNote.itemsTotalQty

		return new Promise((resolve, reject) => {
			api
				.post('/covernotes',{
						covernote: coverNote
					}
				)
				.then((response)=>{
					if(response.data.status == 'success') {
						resolve(response.data)
					} else {
						resolve('error')
					}
				})
				.catch(error => {
					reject(error)
				})
				.finally(()=>{
					resolve('error')
				})
		})
	},
	getAllActiveCoverNotes ({commit}) {
		return new Promise((resolve, reject) => {
			api
				.get("/covernotes/active")
				.then(response => {
					commit('setAllActiveCoverNotes',response.data.data);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	getAllCoverNotes({commit},payload) {
		// let filterOptions = payload?.filterOptions
		let conditions = []
		return new Promise((resolve, reject) => {
			if (payload?.tableOptions.page != null) {
				api
					.get("/covernotes", {
						params: {
							conditions: conditions,
							pagination: {
								skip: (payload.tableOptions.page - 1) * payload.tableOptions.itemsPerPage,
								take: payload.tableOptions.itemsPerPage
							},
							order: [
								{
									field: 'ttas.id',
									direction: 'DESC'
								},
							]
						}
					})
					.then(response => {
						commit('setCoverNotes', response.data.data)
						commit('setTotalCoverNotes', response.data.totalRows)
						resolve('done')
					})
					.catch(error => {
						reject(error);
					});
			}
		})
	},
	getContractSpecifications ({commit}, payload){
		let contract_id = payload.contract_id
		let prefix = payload.prefix
		return new Promise((resolve, reject) => {
			api
				.get("/contracts/"+contract_id+"/salesitems",{

				})
				.then(response => {
					let payload = {
						data: response.data.data,
						prefix: prefix
					}
					commit('setSpecifications', payload);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
				.finally(function(){
					commit('updateTotals',prefix)
					resolve('done')
				});
		})
	},
	getCoverNoteToUpdateById ({commit},covernote_id) {
		return new Promise((resolve, reject) => {
			api
				.get("/covernotes/" + covernote_id)
				.then(response => {
					let covernote = response.data.data[0]
					covernote['CoverNote']['Specifications'] = []
					covernote['CoverNote']['itemsTotalAmount'] = 0,
					covernote['CoverNote']['itemsTotalQty'] = [],
					commit('setCoverNoteToBeUpdated',covernote);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
				.finally(()=>{
					resolve('done')
				})
		})
	},
	loadContractWithoutCoverNote ({commit, rootState},payload) {
		return new Promise((resolve, reject) => {
			commit('setCurrentCoverNote', payload)
			resolve('done')
		})
	},
	resetAllActiveCoverNotes ({commit}){
		return new Promise ((resolve, reject) => {
			commit('resetState')
			resolve('done')
		})
	},
	resetCurrentCoverNote ({commit}){
		return new Promise ((resolve, reject) => {
			commit('resetCurrentState')
			resolve('done')
		})
	},
	searchAllCoverNotes ({commit}, payload) {
		let conditions = []
		let obj = {
			field: payload.search.field,
			value: payload.search.value
		}
		conditions.push(obj)
		return new Promise((resolve, reject) => {
			api
				.get("/covernotes",{
					params: {
						conditions: conditions,
						pagination: {
							skip: (payload.tableOptions.page - 1) * -1,
							take: -1
						},
						order: [
							{
								field: 'ttas.id',
								direction: 'DESC'
							},
						]
					}
				})
				.then(response => {
					commit('setCoverNotes', response.data.data)
					commit('setTotalCoverNotes', response.data.totalRows)
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
				.then(()=>{
					resolve('done')
				});
		})
	},
	updateCoverNote ({state}) {
		let coverNote = _.cloneDeep(state.update.CoverNote)
		delete coverNote.Bank
		delete coverNote.Contract
		delete coverNote.Customer
		delete coverNote.Destination
		delete coverNote.Destinationport
		delete coverNote.Invoice
		delete coverNote.Office
		delete coverNote.Specifications
		delete coverNote.itemsTotalAmount
		delete coverNote.itemsTotalQty

		return new Promise((resolve, reject) => {
			api
				.put('/covernotes/' + state.update.CoverNote.id,{
						covernote: coverNote
					}
				)
				.then((response)=>{
					this.updatedCoverNoteSaved = true
					this.updatedCoverNoteUnsaved = false
					resolve('done')
				})
				.catch(error => {
					reject(error)
				})
				.finally(()=>{
					resolve('error')
				})
		})
	},
	updateTotals ({commit},prefix){
		return new Promise ((resolve, reject) => {
			commit('updateTotals',prefix)
			resolve('done')
		})
	}
}

export const covernote = {
	namespaced,
	state,
	getters,
	actions,
	mutations
}

