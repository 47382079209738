import {getField, updateField} from "vuex-map-fields";
import { api } from "../../../api";
import _ from 'lodash'

const namespaced = {
	namespaced: true
}

const getDefaultState = () => {
	return {
		allFscProductCategories: [],
		allPefcProductCategories: [],
	}
}

const state = {
	allFscProductCategories: [],
	allPefcProductCategories: [],
}

const getters = {
	allFscProductCategories: state => {
		return state.allFscProductCategories;
	},
	allPefcProductCategories: state => {
		return state.allPefcProductCategories;
	},
	getField
}

const mutations = {
	setAllFscProductCategories(state,data){
		state.allFscProductCategories = data;
	},
	setAllPefcProductCategories(state,data){
		state.allPefcProductCategories = data;
	},
	resetState(state){
		state.allFscProductCategories = getDefaultState().allFscProductCategories
		state.allPefcProductCategories = getDefaultState().allPefcProductCategories
	},
	updateField
}

const actions = {
	getAllFscProductCategories ({commit}){
		return new Promise((resolve, reject) => {
			api
				.get("/product-categories/fsc")
				.then(response => {
					commit('setAllFscProductCategories',response.data.data);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	getAllPefcProductCategories ({commit}){
		return new Promise((resolve, reject) => {
			api
				.get("/product-categories/pefc")
				.then(response => {
					commit('setAllPefcProductCategories',response.data.data);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	resetState ({commit}){
		commit('resetState')
	}
}

export const productcategory = {
	namespaced,
	state,
	getters,
	actions,
	mutations
}