import {getField, updateField} from "vuex-map-fields";
import { api } from "../../../api";
import _ from 'lodash'

const namespaced = {
	namespaced: true
}

const getDefaultState = () => {
	return {
		allIntakeItems: [],
		allPaidItems: [],
		allPaidOutItems: [],
		exportConditions: [],
		filters: {
			year: {
				year: new Date().getFullYear()
			},
			quarter: {
				year: new Date().getFullYear(),
				quarters: []
			},
			month: {
				year: new Date().getFullYear(),
				monthFrom: null,
				monthTo: null
			},
			salesColleague: null,
			current: 'year'
		},
	}
}

const state = {
	allIntakeItems: [],
	allPaidItems: [],
	allPaidOutItems: [],
	exportConditions: [],
	filters: {
		year: {
			year: new Date().getFullYear()
		},
		quarter: {
			year: new Date().getFullYear(),
			quarters: []
		},
		month: {
			year: new Date().getFullYear(),
			monthFrom: null,
			monthTo: null
		},
		salesColleague: null,
		current: 'year'
	},
}

const getters = {
	getField
}

const mutations = {
	resetItems (state) {
		state.allIntakeItems = getDefaultState().allIntakeItems
		state.allPaidItems = getDefaultState().allPaidItems
	},
	resetState (state) {
		state.allIntakeItems = getDefaultState().allIntakeItems
		state.allPaidItems = getDefaultState().allPaidItems
		state.filters = getDefaultState().filters
		state.exportConditions = getDefaultState().exportConditions
	},
	setAllIntakeItems (state, data) {
		state.allIntakeItems = data
	},
	setAllPaidItems (state, data) {
		state.allPaidItems = data
	},
	setAllPaidOutItems (state, data) {
		state.allPaidOutItems = data
	},
	setExportConditions (state, conditions) {
		state.exportConditions = conditions
	},
	updateField
}

const actions = {
	getAllIntakeItems({commit, state}) {
		let conditions = []
		switch(state.filters.current){
			case 'year':
				if(state.filters.year.year != null){
					let condition = {
						field: 'filterYearYear',
						value: state.filters.year.year
					}
					conditions.push(condition)
				}
				break
			case 'quarter':
				if(state.filters.quarter.year != null){
					let condition = {
						field: 'filterQuarterYear',
						value: state.filters.quarter.year
					}
					conditions.push(condition)
				}
				if(state.filters.quarter.quarters != null){
					let condition = {
						field: 'filterQuarterQuarter',
						value: state.filters.quarter.quarters
					}
					conditions.push(condition)
				}
				break
			case 'month':
				if(state.filters.month.year != null){
					let condition = {
						field: 'filterMonthYear',
						value: state.filters.month.year
					}
					conditions.push(condition)
				}
				if(state.filters.month.monthFrom != null){
					let condition = {
						field: 'filterMonthMonthFrom',
						value: state.filters.month.monthFrom
					}
					conditions.push(condition)
				}
				if(state.filters.month.monthTo != null){
					let condition = {
						field: 'filterMonthMonthTo',
						value: state.filters.month.monthTo
					}
					conditions.push(condition)
				}
				break
		}
		if(state.filters.salesColleague != null){
			let condition = {
				field: 'filterSalesContact',
				value: state.filters.salesColleague
			}
			conditions.push(condition)
		}
		return new Promise((resolve, reject) => {
			api
				.get("/commissions/intake", {
					params: {
						conditions: conditions
					}
				})
				.then(response => {
					commit('setExportConditions',conditions)
					commit('setAllIntakeItems',response.data.data);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	getAllPaidItems({commit, state}) {
		let conditions = []
		switch(state.filters.current){
			case 'year':
				if(state.filters.year.year != null){
					let condition = {
						field: 'filterYearYear',
						value: state.filters.year.year
					}
					conditions.push(condition)
				}
				break
			case 'quarter':
				if(state.filters.quarter.year != null){
					let condition = {
						field: 'filterQuarterYear',
						value: state.filters.quarter.year
					}
					conditions.push(condition)
				}
				if(state.filters.quarter.quarters != null){
					let condition = {
						field: 'filterQuarterQuarter',
						value: state.filters.quarter.quarters
					}
					conditions.push(condition)
				}
				break
			case 'month':
				if(state.filters.month.year != null){
					let condition = {
						field: 'filterMonthYear',
						value: state.filters.month.year
					}
					conditions.push(condition)
				}
				if(state.filters.month.monthFrom != null){
					let condition = {
						field: 'filterMonthMonthFrom',
						value: state.filters.month.monthFrom
					}
					conditions.push(condition)
				}
				if(state.filters.month.monthTo != null){
					let condition = {
						field: 'filterMonthMonthTo',
						value: state.filters.month.monthTo
					}
					conditions.push(condition)
				}
				break
		}
		if(state.filters.salesColleague != null){
			let condition = {
				field: 'filterSalesContact',
				value: state.filters.salesColleague
			}
			conditions.push(condition)
		}
		return new Promise((resolve, reject) => {
			api
				.get("/commissions/paid", {
					params: {
						conditions: conditions
					}
				})
				.then(response => {
					commit('setExportConditions',conditions)
					commit('setAllPaidItems',response.data.data);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	getAllPaidOutItems({commit, state}) {
		let conditions = []
		switch(state.filters.current){
			case 'year':
				if(state.filters.year.year != null){
					let condition = {
						field: 'filterYearYear',
						value: state.filters.year.year
					}
					conditions.push(condition)
				}
				break
			case 'quarter':
				if(state.filters.quarter.year != null){
					let condition = {
						field: 'filterQuarterYear',
						value: state.filters.quarter.year
					}
					conditions.push(condition)
				}
				if(state.filters.quarter.quarters != null){
					let condition = {
						field: 'filterQuarterQuarter',
						value: state.filters.quarter.quarters
					}
					conditions.push(condition)
				}
				break
			case 'month':
				if(state.filters.month.year != null){
					let condition = {
						field: 'filterMonthYear',
						value: state.filters.month.year
					}
					conditions.push(condition)
				}
				if(state.filters.month.monthFrom != null){
					let condition = {
						field: 'filterMonthMonthFrom',
						value: state.filters.month.monthFrom
					}
					conditions.push(condition)
				}
				if(state.filters.month.monthTo != null){
					let condition = {
						field: 'filterMonthMonthTo',
						value: state.filters.month.monthTo
					}
					conditions.push(condition)
				}
				break
		}
		if(state.filters.salesColleague != null){
			let condition = {
				field: 'filterSalesContact',
				value: state.filters.salesColleague
			}
			conditions.push(condition)
		}
		return new Promise((resolve, reject) => {
			api
				.get("/commissions/paid-out", {
					params: {
						conditions: conditions
					}
				})
				.then(response => {
					commit('setExportConditions',conditions)
					commit('setAllPaidOutItems',response.data.data);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	resetItems ({commit}){
		commit('resetItems')
	},
	resetState ({commit}){
		commit('resetState')
	},
}

export const commission = {
	namespaced,
	state,
	getters,
	actions,
	mutations
}