import { api } from "../../../api";
import {getField, updateField} from "vuex-map-fields";

const namespaced = {
	namespaced: true
}

const getDefaultState = () => {
	return {
		Clause: {
			docorder: 1,
			index: 0,
			id: null,
			lca_id: null,
			title: null,
		},
		defaultClauses:  [
			{Clause: {id: 1, docorder: 1, title: "Signed & stamped commercial invoice"}},
			{Clause: {id: 2, docorder: 2, title: "Signed & stamped packing list "}},
			{Clause: {id: 3, docorder: 3, title: "Certificate of origin in 1 original & 1 copy"}},
			{Clause: {id: 4, docorder: 4, title: "Delivery Order"}},
			{Clause: {id: 5, docorder: 5, title: "Phytosanitary certificate in 1 original & 1 copy"}},
			{Clause: {id: 13, docorder: 6, title: "Full set of original clean on board bill of lading and 3 copies non-negotiable & marked \"Freight Prepaid\" and indicate shipping agent detail at destination"}},
			{Clause: {id: 14, docorder: 7, title: "Insurance policy or certificate issued by insurance company, blank endorsed, for 100% the full invoice value"}}
		],
	}
}

const state = {
	current: {
		Clauses: [
			{
				index: 0,
				Clause: {
					docorder: 1,
					id: null,
					lca_id: null,
					title: null,
				}
			}
		],
		Clause: {
			docorder: 1,
			id: null,
			lca_id: null,
			title: null,
		}
	},
	update: {
		Clauses: [],
		Clause: {
			docorder: 1,
			id: null,
			lca_id: null,
			title: null,
		}
	},
	defaultClauses:  [
		{Clause: {id: 1, docorder: 1, title: "Signed & stamped commercial invoice"}},
		{Clause: {id: 2, docorder: 2, title: "Signed & stamped packing list "}},
		{Clause: {id: 3, docorder: 3, title: "Certificate of origin in 1 original & 1 copy"}},
		{Clause: {id: 4, docorder: 4, title: "Delivery Order"}},
		{Clause: {id: 5, docorder: 5, title: "Phytosanitary certificate in 1 original & 1 copy"}},
		{Clause: {id: 13, docorder: 6, title: "Full set of original clean on board bill of lading and 3 copies non-negotiable & marked \"Freight Prepaid\" and indicate shipping agent detail at destination"}},
		{Clause: {id: 14, docorder: 7, title: "Insurance policy or certificate issued by insurance company, blank endorsed, for 100% the full invoice value"}}
	],
}
const getters = {
	getField
}
const mutations = {
	addClause (state, payload) {
		let newClause = {
			Clause: {
				id: null,
				docorder: null,
				title: payload.value
			}
		}
		state[payload.prefix].Clauses.push(newClause)
		let i = 0;
		state[payload.prefix].Clauses.forEach(clause => {
			clause.index = i
			clause.Clause.docorder = i + 1
			i++
		})
	},
	deleteClause (state, payload) {
		state[payload.prefix].Clauses.splice(payload.index, 1)
		let i = 0;
		state[payload.prefix].Clauses.forEach(clause => {
			clause.index = i
			clause.Clause.docorder = i + 1
			i++
		})
	},
	reCalculateListIndices (state, prefix) {
		let i = 0;
		state[prefix].Clauses.forEach(clause => {
			clause.index = i
			clause.Clause.docorder = i + 1
			i++
		})
	},
	resetState (state) {
		state.defaultClauses = getDefaultState().defaultClauses
	},
	resetCurrentState (state) {
		state.current.Clauses = getDefaultState().defaultClauses
		let i = 0;
		state.current.Clauses.forEach(clause => {
			clause.index = i
			clause.Clause.docorder = i + 1
			i++
		})
	},
	setClauses (state, payload) {
		state[payload.prefix].Clauses = payload.data
		let i = 0;
		state[payload.prefix].Clauses.forEach(clause => {
			clause.index = i
			i++
		})
	},
	updateField
}
const actions = {
	loadDefaultClauses ({commit}) {
		commit('resetCurrentState')
	},
	addClauseToList ({commit},payload) {
		commit('addClause', payload)
	},
	deleteClause ({commit},payload) {
		return new Promise((resolve, reject) => {
			let lca_id = payload.lca_id
			let clause_id = payload.clause_id
			api
				.delete('/lcas/' + lca_id + '/clauses/' + clause_id)
				.then(response => {
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
		})
	},
	deleteClauseFromList ({commit},payload) {
		commit('deleteClause', payload)
	},
	getClausesByLca ({commit},payload) {
		let lca_id = payload.lca_id
		let prefix = payload.prefix
		return new Promise((resolve, reject) => {
			api
				.get("/lcas/" + lca_id + "/clauses",{

				})
				.then(response => {
					let payload = {
						data: response.data.data,
						prefix: prefix
					}
					commit('setClauses', payload)
					resolve('done')
				})
				.catch(error => {
					reject(error)
				})
				.finally(function(){
					resolve('done')
				});
		})
	},
	resetCurrentClauses ({commit}) {
		commit('resetCurrentState')
	}
}

export const lcaclause = {
	namespaced,
	state,
	getters,
	actions,
	mutations
}