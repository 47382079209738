import {getField, updateField} from "vuex-map-fields";
import {api} from "../../../api";
import _ from 'lodash'

const namespaced = {
	namespaced: true
}

const getDefaultState = () => {
	return {
		Ipa: {
			alt_address: null,
			consignedfrom_id: null,
			contract_id: null,
			eta: null,
			id: null,
			ipasent_date: null,
			loadingport_id: null,
			measurement_id: 1,
			productorigin_id: null,
			quarantine_qty: null,
			shippingport_id: null,
			species_id: null,
			tariff_qty: null,
			use_alt_address: 0,
			Customer: {
				id: null,
				company_id: null
			},
			Contract: {
				id: null,
				destinatione_id: 1,
				etadate: null,
				etddate: null,
				partial_no: null,
				revision_no: null,
				shipmentstatus_id: null,
				shipmentstatus_text: null,
				title: null,
				version: null
			},
			Species: {
				id: null,
				hscode: null,
				hscode_veneer: null,
				latin_name: null
			},
			Supplier: {
				id: null,
				address1: null,
				address2: null,
				address3: null,
				city: null,
				country: null,
				postcode: null,
				state: null,
				title: null
			},
		}
	}
}

const state = {
	allActiveIpas: [],
	current: {
		Ipa: {
			alt_address: null,
			consignedfrom_id: null,
			contract_id: null,
			eta: null,
			id: null,
			ipasent_date: null,
			loadingport_id: null,
			measurement_id: 1,
			productorigin_id: null,
			quarantine_qty: null,
			shippingport_id: null,
			species_id: null,
			supplier_id: null,
			tariff_qty: null,
			use_alt_address: 0,
			Customer: {
				id: null,
				company_id: null
			},
			Contract: {
				id: null,
				destinatione_id: 1,
				etadate: null,
				etddate: null,
				partial_no: null,
				revision_no: null,
				shipmentstatus_id: null,
				shipmentstatus_text: null,
				title: null,
				version: null
			},
			Species: {
				id: null,
				hscode: null,
				hscode_veneer: null,
				latin_name: null
			},
			Supplier: {
				id: null,
				address1: null,
				address2: null,
				address3: null,
				city: null,
				country: null,
				postcode: null,
				state: null,
				title: null
			},
		},
		Ipas: [],
		totalIpas: 0,
	},
	update: {
		Ipa: {
			Customer: {},
			Contract: {},
			Species: {},
			Supplier: {}
		}
	},
	newIpaSaved: false,
	newIpaUnsaved: false,
	updatedIpaSaved: false,
	updatedIpaUnsaved: false
}
const getters = {
	allActiveIpas: state => { return state.allActiveIpas },
	getField,
}
const mutations = {
	resetState (state){
		console.log('Resetting IPAs...')
		state.allActiveIpas = []
	},
	resetCurrentState (state) {
		console.log('Resetting this IPA...')
		state.current.Ipa = getDefaultState().Ipa
		state.newIpaUnsaved = false
		state.newIpaSaved = false
	},
	setAllActiveIpas (state, data) {
		state.allActiveIpas = data;
	},
	setCurrentIpa(state, payload){
		let contract = payload
		state.current.Ipa.contract_id = contract.Contract?.id
		state.current.Ipa.consignedfrom_id = contract.Contract?.productorigin_id
		state.current.Ipa.eta = contract.Contract?.etadate
		state.current.Ipa.ipasent_date = null
		state.current.Ipa.loadingport_id = contract.Contract?.loadingport_id
		state.current.Ipa.measurement_id = 1,
		state.current.Ipa.productorigin_id = contract.Contract?.productorigin_id
		state.current.Ipa.quarantine_qty = contract.Contract?.quarantine_qty
		state.current.Ipa.shippingport_id = contract.Contract?.shippingport_id
		state.current.Ipa.tariff_qty = contract.Contract?.tariff_qty

		// if(contract.Contract.version != null){
		//	state.current.Ipa.species_id = contract.Contract.species_ids[0]
		// } else {
		state.current.Ipa.species_id = contract.Contract.species_id
		// }
		state.current.Ipa.supplier_id = contract.Supplier?.id
		state.current.Ipa.Contract.id = contract.Contract?.id
		state.current.Ipa.Contract.destinatione_id = contract.Contract?.destinatione_id
		state.current.Ipa.Contract.etadate = contract.Contract?.etadate
		state.current.Ipa.Contract.etddate = contract.Contract?.etddate
		state.current.Ipa.Contract.partial_no = contract.Contract?.partial_no
		state.current.Ipa.Contract.revision_no = contract.Contract?.revision_no
		state.current.Ipa.Contract.shipmentstatus_id = contract.Contract?.shipmentstatus_id
		state.current.Ipa.Contract.shipmentstatus_text = contract.Contract?.shipmentstatus_text
		state.current.Ipa.Contract.title = contract.Contract?.title
		state.current.Ipa.Contract.version = contract.Contract?.version
		state.current.Ipa.Species.id = contract.Contract.species_id
		state.current.Ipa.Species.hscode = contract.Species.hscode
		state.current.Ipa.Species.hscode_veneer = contract.Species.hscode_veneer
		state.current.Ipa.Species.latin_name = contract.Species.latin_name
		state.current.Ipa.Supplier.id = contract.Supplier?.id
		state.current.Ipa.Supplier.title = contract.Supplier?.title
		state.current.Ipa.supplier_id = contract.Supplier?.id
	},
	setIpas (state,data) {
		state.current.Ipas = data;
	},
	setIpaToBeUpdated (state, data) {
		state.update.Ipa = data.Ipa
	},
	setTotalIpas (state,val) {
		state.current.totalIpas = val
	},
	updateField,
}
const actions = {
	cancelIpaById ({commit},val) {
		return new Promise((resolve, reject) => {
			api
				.delete("/ipas/" + val)
				.then(response => {
					// if(response.data.status == 'success') {
					// 	resolve('done')
					// } else {
					// 	resolve('error')
					// }
					resolve(response.data)
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	createIpa ({state, rootState}){
		let ipa = _.cloneDeep(state.current.Ipa)
		//remove unneeded properties
		delete ipa.Customer
		delete ipa.Contract
		delete ipa.Species
		delete ipa.Supplier
		return new Promise((resolve, reject) => {
			api
				.post('/ipas',{
						ipa: ipa,
					}
				)
				.then((response)=>{
					// if(response.data.status == 'success') {
					// 	resolve(response.data)
					// } else {
					// 	resolve('error')
					// }
					resolve(response.data)
				})
				.catch(error => {
					reject(error)
				})
				// .finally(()=>{
				// 	resolve('error')
				// })
		})
	},
	getAllActiveIpas ({commit}) {
		return new Promise((resolve, reject) => {
			api
				.get("/ipas/active")
				.then(response => {
					commit('setAllActiveIpas',response.data.data);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	getAllIpas ({commit},payload) {
		// let filterOptions = payload.filterOptions
		// let conditions = []
		return new Promise((resolve, reject) => {
			if(payload.tableOptions.page != null) {
				api
					.get("/ipas", {
						params: {
							// conditions: conditions,
							pagination: {
								skip: (payload.tableOptions.page - 1) * payload.tableOptions.itemsPerPage,
								take: payload.tableOptions.itemsPerPage
							},
							order: [
								{
									field: 'ipas.ipasent_date',
									direction: 'DESC'
								},
							]
						}
					})
					.then(response => {
						commit('setIpas', response.data.data)
						commit('setTotalIpas', response.data.totalRows)
						resolve('done')
					})
					.catch(error => {
						reject(error);
					});
			} else {
				resolve('done')
			}
		})
	},
	getIpaToUpdateById ({commit},ipa_id) {
		return new Promise((resolve, reject) => {
			api
				.get("/ipas/" + ipa_id,{
				})
				.then(response => {
					commit('setIpaToBeUpdated',response.data.data[0]);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
				.finally(()=>{
					resolve('done')
				})
		})
	},
	loadUnshippedContract({commit, rootState},contract){
		let payload = _.cloneDeep(contract)
		return new Promise((resolve, reject) => {
			api
				.get("/contracts/" + payload.Contract.id,{
					params: {
						fields: [
							'contracts.etddate AS contracts__etddate',
							'contracts.etadate AS contracts__etadate',
							'contracts.shipmentstatus_id AS contracts__shipmentstatus_id',
							'contracts.shipmentstatus_text AS contracts__shipmentstatus_text'
						]
					}
				}).then(response => {
					// console.log('do something 1')
					payload.Contract.etddate = response.data.data[0].Contract.etddate
					payload.Contract.etadate = response.data.data[0].Contract.etadate
					payload.Contract.shipmentstatus_id = response.data.data[0].Contract.shipmentstatus_id
					payload.Contract.shipmentstatus_text= response.data.data[0].Contract.shipmentstatus_text
					// TODO get the species
					// console.log(payload.Contract.species)
					//get sales items for this contract
					api
						.get('/contracts/' + payload.Contract.id + '/salesitems')
						.then(response => {
							let firstItem = response.data.data[0]
							// console.log(firstItem)
							if(firstItem?.Species){
								payload.Contract.species = firstItem?.Species.title
								payload.Contract.species_id = firstItem?.Species.id
								payload['Species'] = {}
								payload.Species.hscode = firstItem?.Species.hscode
								payload.Species.hscode_veneer = firstItem?.Species.hscode_veneer
								payload.Species.latin_name = firstItem?.Species.latin_name
								// payload.Species.hscode = rootState.appic.species.allSpecies.find(species => species.Species.id == payload.Contract.species_id).Species?.hscode
								// payload.Species.hscode_veneer = rootState.appic.species.allSpecies.find(species => species.Species.id == payload.Contract.species_id).Species?.hscode_veneer
							}
							payload.Contract.quarantine_qty = contract.Contract.containercount ? contract.Contract.containercount * 60 : 60
							payload.Contract.tariff_qty = contract.Contract.containercount ? contract.Contract.containercount * 60 : 60
							commit('setCurrentIpa',payload)
							resolve('done')

							// // payload.Contract.species_id = rootState.appic.species.allSpecies.find(species => species.Species.title == payload.Contract.species).Species.id
							// // payload['Species'] = {}
							// // payload.Species.hscode = rootState.appic.species.allSpecies.find(species => species.Species.title == payload.Contract.species).Species.hscode
							// // payload.Species.hscode_veneer = rootState.appic.species.allSpecies.find(species => species.Species.title == payload.Contract.species).Species.hscode_veneer
							//
							//
							// api.get('/contracts/' + payload.Contract.id + '/quantities')
							// 	.then( response => {
							// 		// console.log('do something 2')
							// 		// payload.Contract.quarantine_qty = response.data.quantities.po
							// 		// payload.Contract.tariff_qty = response.data.quantities.po
							// 		payload.Contract.quarantine_qty = contract.Contract.containercount ? contract.Contract.containercount * 60 : 60
							// 		payload.Contract.tariff_qty = contract.Contract.containercount ? contract.Contract.containercount * 60 : 60
							// 		commit('setCurrentIpa',payload)
							// 		resolve('done')
							// 	})
							// 	.catch( error => reject(error) )
							// 	.then( () => resolve('done') )
						})
						.catch( error => reject(error) )
						.then( () => resolve('done') )
				}).catch(error => {
					reject(error);
				})
		})
	},
	resetAllActiveIpas ({commit}){
		return new Promise ((resolve, reject) => {
			commit('resetState')
			resolve('done')
		})
	},
	resetCurrentIpa ({commit}) {
		return new Promise ((resolve, reject) => {
			commit('resetCurrentState')
			resolve('done')
		})
	},
	searchAllIpas ({commit}, payload) {
		// let conditions = []
		// let obj = {
		// 	field: payload.search.field,
		// 	value: payload.search.value
		// }
		// conditions.push(obj)
		return new Promise((resolve, reject) => {
			api
				.get("/ipas",{
					params: {
						conditions: payload.conditions,
						pagination: {
							skip: (payload.tableOptions.page - 1) * payload.tableOptions.itemsPerPage,
							take: payload.tableOptions.itemsPerPage
							// skip: (payload.tableOptions.page - 1) * -1,
							// take: -1
						},
						order: payload.order
					}
				})
				.then(response => {
					commit('setIpas', response.data.data)
					commit('setTotalIpas', response.data.totalRows)
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
				.then(()=>{
					resolve('done')
				});
		})
	},
	sendImportPermitToBuyer ({commit}, payload) {
		return new Promise ((resolve, reject) => {
			api
				.put('/ipas/' + payload.ipaId + '/send-to-buyer',{
					contacts: payload.contacts
				})
				.then(response => {
					if(response.data.status == 'success') {
						resolve('done')
					} else {
						resolve('error')
					}
				})
				.catch(error => {
					reject(error);
				})
		})
	},
	updateIpa ({state, rootState}) {
		let ipa = _.cloneDeep(state.update.Ipa)
		//remove unneeded properties
		delete ipa.Agent
		delete ipa.Consignment
		delete ipa.Contract
		delete ipa.Customer
		delete ipa.Destination
		delete ipa.Entryport
		delete ipa.Loadingport
		delete ipa.Origin
		delete ipa.Species
		delete ipa.Supplier
		return new Promise ((resolve, reject) => {
			api
				.put("/ipas/" + state.update.Ipa.id,{
					ipa: ipa,
				})
				.then(response => {
					this.updatedIpaSaved = true
					this.updatedIpaUnsaved = false
					// resolve('done')
					resolve(response.data)
				})
				.catch(error => {
					reject(error)
					// resolve(error)
				})
				// .then(()=>{
				// 	resolve('done')
				// });
		})
	}
}

export const ipa = {
	namespaced,
	state,
	getters,
	actions,
	mutations
}