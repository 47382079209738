import {getField, updateField} from "vuex-map-fields";
import { api } from "../../../api";
import _ from 'lodash'

const namespaced = {
	namespaced: true
}

const getDefaultState = () => {
	return {
		EtdAdjustment: {
			id: null,
			country_id: null,
			days: null
		}
	}
}

const state = {
	allEtdAdjustments: [],
	current: {
		EtdAdjustment: {
			id: null,
			country_id: null,
			days: null
		}
	},
	update: {
		EtdAdjustment: {}
	}
}

const getters = {
	allEtdAdjustments: state => {
		return state.allEtdAdjustments
	},
	getField
}

const mutations = {
	setAllEtdAdjustments(state, data){
		state.allEtdAdjustments = data;
	},
	setEtdAdjustmentToUpdate( state, etdAjustment ) {
		state.update.EtdAdjustment = etdAjustment.EtdAdjustment
	},
	resetCurrentState (state) {
		state.current.EtdAdjustment = getDefaultState().EtdAdjustment
	},
	resetEtdAdjustmentToUpdate (state) {
		state.update.EtdAdjustment = getDefaultState().EtdAdjustment
	},
	resetState(state){
		state.allEtdAdjustments = []
	},
	updateField
}

const actions = {
	createEtdAdjustment ( {state} ) {
		let etdAdjustment = _.cloneDeep(state.current.EtdAdjustment)
		return new Promise((resolve, reject) => {
			api
				.post('/etd-adjustments',{
					etdAdjustment: etdAdjustment,
				})
				.then((response)=>{
					// if(response.data.status == 'success') {
					// 	resolve('done')
					// } else {
					// 	resolve('error')
					// }
					resolve(response.data)
				})
				.catch(error => {
					reject(error)
				})
				// .finally(()=>{
				// 	resolve('error')
				// })
		})
	},
	deleteEtdAdjustmentById ({commit}, etdAdjustmentId) {
		return new Promise((resolve, reject) => {
			api
				.delete('/etd-adjustments/' + etdAdjustmentId)
				.then(response => {
					// resolve('done')
					resolve(response.data)
				})
				.catch(error => {
					reject(error);
				})
		})
	},
	getAllEtdAdjustments ({commit}) {
		return new Promise((resolve, reject) => {
			api
				.get("/etd-adjustments")
				.then(response => {
					commit('setAllEtdAdjustments',response.data.data);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	getEtdAdjustmentToUpdateById ( {commit}, etdAdjustmentId ) {
		return new Promise((resolve, reject) => {
			api
				.get('/etd-adjustments/' + etdAdjustmentId )
				.then(response => {
					commit('setEtdAdjustmentToUpdate', response.data.data[0])
					resolve('done')
				})
				.catch(error => {
					reject(error)
				})
		})
	},
	resetCurrentState ( {commit} ) {
		commit('resetCurrentState')
	},
	resetEtdAdjustmentToUpdate ( {commit} ) {
		commit('resetEtdAdjustmentToUpdate')
	},
	resetState ( {commit} ){
		commit('resetState')
	},
	updateEtdAdjustment ( {state} ) {
		let etdAdjustment = _.cloneDeep(state.update.EtdAdjustment)
		//remove unneeded properties
		delete etdAdjustment.Country

		return new Promise((resolve, reject) => {
			api
				.put("/etd-adjustments/" + state.update.EtdAdjustment.id,{
					etdAdjustment: etdAdjustment
				})
				.then(response => {
					// resolve('done')
					resolve(response.data)
				})
				.catch(error => {
					reject(error)
				})
				// .then(()=>{
				// 	resolve('done')
				// });
		})
	}
}

export const etdadjustment = {
	namespaced,
	state,
	getters,
	actions,
	mutations
}
