import { api } from "../../../api";
import {getField, updateField} from "vuex-map-fields";
import {isNumeric} from "../../../helpers/helpers";

const namespaced = {
    namespaced: true
}

const getOtherChargesRow = () => {
    return {
        index: 0,
        lineNumber: 1,
        Grade: {
            id: 39,
            title: 'Other Charges'
        },
        Mc: {
            id: null,
            title: ''
        },
        Measurement: {
            id: null,
            title: '',
        },
        Salesdescription: {
            id: null,
            spec_id: null,
            species_id: null,
            title: ''
        },
        Salesitem: {
            id: null,
            amount: 0,
            certification_id: 0,
            chargeto: 1,
            commission: 0,
            comm_pct: null,
            comm_unit: null,
            costprice: 0,
            description: null,
            grade_id: 39,
            itemqty: 1,
            length: null,
            margin: null,
            measurement_id: null,
            othercharge: 1,
            poamount: 0,
            pograde_id: 39,
            poqty: 1,
            pomeasurement_id: null,
            productgroup_id: null,
            salesdescription_id: null,
            size: 'Other Charges',
            size_id: null,
            thickness_id: null,
            unitmargin: null,
            unitprice: null,
            width: null
        },
        Size: {
            id: null,
            title: 'Other Charges'
        },
        Spec: {
            id: null,
            title: ''
        },
        Species: {
            id: null,
            title: null
        },
        type: 'othercharge'
    }
}

const getDefaultState = () => {
    return {
        index: 0,
        lineNumber: 1,
        Salesitem: {},
        type: 'default'
    }
}

const state = {
    //current data. can either be a loaded existing contract or a new unsaved contract
    current: {
        PurchaseOrderItems: [],
        itemsCurrencyRate: 1,
        itemsTotalAmount: 0,
        itemsTotalAmountConverted: 0,
        itemsTotalQty: [],
    },
    update: {
        PurchaseOrderItems: [],
        itemsCurrencyRate: 1,
        itemsTotalAmount: 0,
        itemsTotalAmountConverted: 0,
        itemsTotalQty: [],
    }
}

const getters = {
    getField,
}

const mutations = {
    addOtherChargesItemRow (state, prefix) {
        let i = 0;
        state[prefix].PurchaseOrderItems.push(getOtherChargesRow())
        state[prefix].PurchaseOrderItems.forEach((item) => {
            item.index = i
            item.lineNumber = i + 1
            i++
        })
    },
    convertPriceAndAmount(state, prefix) {
        state[prefix].PurchaseOrderItems.forEach((item, index) => {
            state[prefix].PurchaseOrderItems[index]['Salesitem']['costprice_converted'] = parseFloat(state[prefix].PurchaseOrderItems[index]['Salesitem']['costprice']) * state[prefix].itemsCurrencyRate
            state[prefix].PurchaseOrderItems[index]['Salesitem']['poamount_converted'] = parseFloat(state[prefix].PurchaseOrderItems[index]['Salesitem']['poqty']) * (parseFloat(state[prefix].PurchaseOrderItems[index]['Salesitem']['costprice']) * state[prefix].itemsCurrencyRate)
        })
    },
    deletePurchaseOrderItemRow (state, payload) {
        state[payload.prefix].PurchaseOrderItems.splice(payload.index, 1)
        let i = 0;
        state[payload.prefix].PurchaseOrderItems.forEach((item) => {
            item.index = i
            item.lineNumber = i + 1
            i++
        })
    },
    resetState (state, prefix){
        state[prefix].PurchaseOrderItems = []
        state[prefix].itemsCurrencyRate = 1
        state[prefix].itemsTotalAmount = 0
        state[prefix].itemsTotalAmountConverted = 0
        state[prefix].itemsTotalQty = [{Total: {qty: 0.0, uofm: 'M3'}}]
    },
    setPurchaseOrderItems(state,payload){
        state[payload.prefix].PurchaseOrderItems = payload.data
        let i = 0;
        state[payload.prefix].PurchaseOrderItems.forEach((item) => {
            item.index = i
            item.lineNumber = i + 1
            i++
        })
    },
    updateField,
    updatePurchaseOrderItems (state, payload) {
        let keys = payload['field'].split('.')
        let prefix = payload['prefix']
        state[prefix].PurchaseOrderItems[payload['index']][keys[0]][keys[1]] = payload['value']
        let numberFields = ['poqty','costprice']
        if(keys[0] == 'Salesitem' && numberFields.includes(keys[1]) && state[prefix].PurchaseOrderItems[payload['index']]['type'] == 'default'){
            let amount = parseFloat(state[prefix].PurchaseOrderItems[payload['index']]['Salesitem']['itemqty']) * parseFloat(state[prefix].PurchaseOrderItems[payload['index']]['Salesitem']['unitprice'])
            let cost, poamount = parseFloat(state[prefix].PurchaseOrderItems[payload['index']]['Salesitem']['poqty']) * parseFloat(state[prefix].PurchaseOrderItems[payload['index']]['Salesitem']['costprice'])
            state[prefix].PurchaseOrderItems[payload['index']]['Salesitem']['poamount'] = poamount
            state[prefix].PurchaseOrderItems[payload['index']]['Salesitem']['margin'] = amount - cost
            //update converted fields
            state[prefix].PurchaseOrderItems[payload['index']]['Salesitem']['costprice_converted'] = parseFloat(state[prefix].PurchaseOrderItems[payload['index']]['Salesitem']['costprice']) * state[prefix].itemsCurrencyRate
            state[prefix].PurchaseOrderItems[payload['index']]['Salesitem']['poamount_converted'] = parseFloat(state[prefix].PurchaseOrderItems[payload['index']]['Salesitem']['poqty']) * (parseFloat(state[prefix].PurchaseOrderItems[payload['index']]['Salesitem']['costprice']) * state[prefix].itemsCurrencyRate)
        } else if(keys[0] == 'Salesitem' && keys[1] == 'amount' && state[prefix].PurchaseOrderItems[payload['index']]['type'] == 'othercharge'){
            state[prefix].PurchaseOrderItems[payload['index']]['Salesitem']['unitprice'] = 0
            state[prefix].PurchaseOrderItems[payload['index']]['Salesitem']['costprice'] = state[prefix].PurchaseOrderItems[payload['index']]['Salesitem']['amount']
            state[prefix].PurchaseOrderItems[payload['index']]['Salesitem']['poamount'] = state[prefix].PurchaseOrderItems[payload['index']]['Salesitem']['amount']
            //update converted fields
            state[prefix].PurchaseOrderItems[payload['index']]['Salesitem']['unitprice_converted'] = 0
            state[prefix].PurchaseOrderItems[payload['index']]['Salesitem']['costprice_converted'] = state[prefix].PurchaseOrderItems[payload['index']]['Salesitem']['amount'] * state[prefix].itemsCurrencyRate
            state[prefix].PurchaseOrderItems[payload['index']]['Salesitem']['poamount_converted'] = state[prefix].PurchaseOrderItems[payload['index']]['Salesitem']['amount'] * state[prefix].itemsCurrencyRate
        }
    },
    updateTotals (state, prefix) {
        let poAmount = 0, poAmountConverted = 0, quantities = []
        state[prefix].PurchaseOrderItems.forEach((item) => {
            if(item.type == 'default') {
                poAmount += isNumeric(item.Salesitem.poamount) ? parseFloat(item.Salesitem.poamount) : 0;
                poAmountConverted += isNumeric(item.Salesitem.poamount_converted) ? parseFloat(item.Salesitem.poamount_converted) : 0;
            } else if(item.type == 'othercharge'){
                poAmount += isNumeric(item.Salesitem.poamount) ? parseFloat(item.Salesitem.poamount) : 0;
                poAmountConverted += isNumeric(item.Salesitem.poamount) ? parseFloat(item.Salesitem.poamount) * state[prefix].itemsCurrencyRate : 0;
            }

            let idx = quantities.findIndex(t=>t.Total.uofm == item.Measurement.title);
            if(idx !== -1){
                quantities[idx]['Total']['qty'] += isNumeric(item.Salesitem.poqty) ? parseFloat(item.Salesitem.poqty) : 0;
            } else {
                if(item.type == 'default') {
                    let obj = {'Total': {}}
                    obj['Total']['uofm'] = item.Measurement.title;
                    obj['Total']['qty'] = isNumeric(item.Salesitem.poqty) ? parseFloat(item.Salesitem.poqty) : 0;
                    quantities.push(obj)
                }
            }
        })
        state[prefix].itemsTotalAmount = poAmount
        state[prefix].itemsTotalAmountConverted = poAmountConverted
        state[prefix].itemsTotalQty = quantities
        console.log('updating totals...')
    }
}

const actions = {
    convertPriceAndAmount ({commit},prefix) {
        console.log('Converting')
        commit('convertPriceAndAmount',prefix)
        commit('updateTotals',prefix)
    },
    deletePurchaseOrderItemRow ({commit},payload){
        commit('deletePurchaseOrderItemRow',payload)
    },
    deletePurchaseOrderItem ({commit},payload){
        return new Promise((resolve, reject) => {
            let contract_id = payload.contract_id
            let salesitem_id = payload.salesitem_id
            api
                .delete('/contracts/' + contract_id + '/salesitems/' + salesitem_id)
                .then(response => {
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                })
        })
    },
    getSalesItemsByContract ({commit},payload) {
        let contract_id = payload.contract_id
        let prefix = payload.prefix
        let module = 'contracts'
        if(prefix == 'update'){
            module = 'purchaseorders'
        }
        return new Promise((resolve, reject) => {
            api
                .get("/"+ module +"/"+contract_id+"/salesitems")
                .then(response => {
                    let payload = {
                        data: response.data.data,
                        prefix: prefix
                    }
                    commit('setPurchaseOrderItems', payload);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                })
                .finally(function(){
                    commit('updateTotals',prefix);
                });
        })
    },
    resetPurchaseOrderItems ({commit},prefix) {
        return new Promise ((resolve, reject) => {
            commit('resetState',prefix)
            resolve('done')
        })
    },
    updateTotals ({commit},prefix) {
        commit('updateTotals',prefix)
    }
}

export const purchaseorderitem = {
    namespaced,
    state,
    getters,
    mutations,
    actions
}