import {getField, updateField} from "vuex-map-fields";
import { api } from "../../../api";
import _ from 'lodash'

const namespaced = {
    namespaced: true
}

const getDefaultState = () => {
    return {
        InternalRemark: {
            id: null,
            title: null,
            freetext1: null,
            freetext1_type: null,
            freetext1_label: null,
            freetext1_prefix: null,
            freetext2: null,
            freetext2_type: null,
            freetext2_label: null,
            freetext2_prefix: null,
            freetext3: null,
            freetext3_type: null,
            freetext3_label: null,
            freetext3_prefix: null,
            freetext4: null,
            freetext4_type: null,
            freetext4_label: null,
            freetext4_prefix: null,
        }
    }
}

const state = {
    allInternalRemarks: [],
    current: {
        InternalRemark: {
            id: null,
            title: null,
            freetext1: null,
            freetext1_type: null,
            freetext1_label: null,
            freetext1_prefix: null,
            freetext2: null,
            freetext2_type: null,
            freetext2_label: null,
            freetext2_prefix: null,
            freetext3: null,
            freetext3_type: null,
            freetext3_label: null,
            freetext3_prefix: null,
            freetext4: null,
            freetext4_type: null,
            freetext4_label: null,
            freetext4_prefix: null,
        }
    },
    update: {
        InternalRemark: {}
    }
}

const getters = {
    allInternalRemarks: state => {
        return state.allInternalRemarks;
    },
    getField
}

const mutations = {
    setAllInternalRemarks (state,data) {
        state.allInternalRemarks = data;
    },
    setInternalRemarkToUpdate ( state, internalRemark ){
        state.update.InternalRemark = internalRemark.InternalRemark
    },
    resetInternalRemarkToUpdate(state) {
        state.update.InternalRemark = getDefaultState().InternalRemark
    },
    resetCurrentState(state) {
        state.current.InternalRemark = getDefaultState().InternalRemark
    },
    resetState(state) {
        state.allInternalRemarks = []
    },
    updateField
}

const actions = {
    createInternalRemark({state}) {
        const InternalRemark = _.cloneDeep(state.current.InternalRemark)
        return new Promise((resolve, reject) => {
            api
                .post('/internal-remarks',{
                        internal_remark: InternalRemark,
                    }
                )
                .then((response)=>{
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    deleteInternalRemarkById ({commit}, internalRemarkId) {
        return new Promise((resolve, reject) => {
            api
                .delete('/internal-remarks/' + internalRemarkId)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error);
                })
        })
    },
    getAllInternalRemarks ({commit}){
        return new Promise((resolve, reject) => {
            api
                .get("/internal-remarks")
                .then(response => {
                    commit('setAllInternalRemarks',response.data.data);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getInternalRemarkToUpdateById({commit}, internalRemarkId) {
        return new Promise((resolve, reject) => {
            api
                .get('/internal-remarks/' + internalRemarkId )
                .then(response => {
                    commit('setInternalRemarkToUpdate', response.data.data[0])
                    resolve('done')
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    resetInternalRemarkToUpdate({commit}) {
        commit('resetInternalRemarkToUpdate')
    },
    resetCurrentState ({commit}) {
        commit('resetCurrentState')
    },
    resetState ({commit}){
        commit('resetState')
    },
    updateInternalRemark({state}) {
        const internalRemark = _.cloneDeep(state.update.InternalRemark)
        return new Promise((resolve, reject) => {
            api
                .put("/internal-remarks/" + state.update.InternalRemark.id,{
                    internal_remark: internalRemark
                })
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}

export const internalremark = {
    namespaced,
    state,
    getters,
    actions,
    mutations
}