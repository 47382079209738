import { api } from "../../../api";
import {getField, updateField} from "vuex-map-fields";
import {isNumeric} from "../../../helpers/helpers";

const namespaced = {
    namespaced: true
}

const getDefaultState = () => {
    return {
        id: null,
        description: '',
        invoiceamount: 0,
        invoiceamount_converted: 0,
        index: 0,
        lineNumber: 1,
        quantity: 0,
        type: 'default',
        unitprice: 0,
        unitprice_converted: 0
    }
}

const state = {
    current: {
        Salesitems: [
            {
                id: null,
                description: '',
                invoiceamount: 0,
                invoiceamount_converted: 0,
                index: 0,
                lineNumber: 1,
                quantity: 0,
                type: 'default',
                unitprice: 0,
                unitprice_converted: 0
            }
        ],
        itemsCurrencyRate: 1,
        itemsGrandTotal: 0,
        itemsGrandTotalConverted: 0,
        itemsTotalAmount: 0,
        itemsTotalAmountConverted: 0,
        itemsTotalQty: [],
        itemsTotalTax: 0,
        itemsTotalTaxConverted: 0,
        gstpercent: 0
    }
}

const getters = {
    getField,
    itemsCurrencyRate: state => {return state.current.itemsCurrencyRate },
    itemsTotalAmount: state => { return state.current.itemsTotalAmount },
    itemsTotalQty: state => { return state.current.itemsTotalQty },
}

const mutations = {
    addManualInvoiceItemRow (state) {
        let i = 0;
        state.current.Salesitems.push(getDefaultState())
        state.current.Salesitems.forEach((item) => {
            item.index = i
            item.lineNumber = i + 1
            i++
        })
    },
    deleteManualInvoiceItemRow (state, index) {
        state.current.Salesitems.splice(index, 1)
        let i = 0;
        state.current.Salesitems.forEach((item) => {
            item.index = i
            item.lineNumber = i + 1
            i++
        })
    },
    resetState (state){
        state.current.Salesitems = []
        state.current.Salesitems.push(getDefaultState())
        state.current.itemsCurrencyRate = 1
        state.current.itemsGrandTotal = 0
        state.current.itemsGrandTotalConverted = 0
        state.current.itemsTotalAmount = 0
        state.current.itemsTotalAmountConverted = 0
        state.current.itemsTotalTax = 0
        state.current.itemsTotalTaxConverted = 0
        state.current.itemsTotalQty = []
    },
    updateField,
    updateSalesItems (state, payload) {
        let keys = payload['field'].split('.')
        state.current.Salesitems[payload['index']][keys[0]][keys[1]] = payload['value']
    },
    updateTotals (state) {
        let amount = 0, amountConverted = 0, tax = 0, taxConverted = 0, grandTotal = 0, grandTotalConverted = 0, quantities = []
        state.current.Salesitems.forEach((item) => {
            amount += isNumeric(item.invoiceamount) ? parseFloat(item.invoiceamount) : 0;
        })
        state.current.itemsTotalAmount = amount
        state.current.itemsTotalAmountConverted = amountConverted
        tax = amount * state.current.gstpercent/100
        taxConverted = amountConverted * state.current.gstpercent/100
        state.current.itemsTotalTax = tax
        state.current.itemsTotalTaxConverted = taxConverted
        grandTotal = amount + tax
        grandTotalConverted = amountConverted + taxConverted
        state.current.itemsGrandTotal = grandTotal
        state.current.itemsGrandTotalConverted = grandTotalConverted
        state.current.itemsTotalQty = quantities
    }
}

const actions = {
    addManualInvoiceItemRow ({commit}) {
        commit('addManualInvoiceItemRow')
    },
    deleteManualInvoiceItemRow ({commit},index){
        commit('deleteManualInvoiceItemRow',index)
    },
    resetSalesItems ({commit}) {
        return new Promise ((resolve, reject) => {
            commit('resetState')
            resolve('done')
        })
    },
    updateTotals ({commit}) {
        commit('updateTotals')
    }
}

export const manualinvoiceitem = {
    namespaced,
    state,
    getters,
    mutations,
    actions
}