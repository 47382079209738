import {getField, updateField} from "vuex-map-fields";
import {api} from "../../../api";
import _ from 'lodash'

const namespaced = {
	namespaced: true
}

const getDefaultState = () => {
	return {
		ItemDescriptions: [
			{
				index: 0,
				lineNumber: 1,
				Salesdescription: {}
			}
		],
		Lca: {
			id: null,
			bank_id: null,
			contract_id: null,
			docrequired: 'triplicate',
			expirydate: null,
			file: null,
			latestshipdate: null,
			lcadate: null,
			lcalatestdate: null,
			negotiable: 'SIGHT',
			partialshipment: 'Not Allowed',
			transhipment: 'Not Allowed',
			Banks: [],
			Contract: {
				currency: 'USD',
				currency_id: 1,
				customer_id: null,
				final_destination: null,
				incoterm: null,
				incoterm_id: null,
				loadingport_id: null,
				office_id: null,
				port_of_loading: null,
				salestype_id: null,
				shippingport_id: null,
				title: null,
				value: 0,
				version: null
			},
			Customer: {
				id: null,
				company_id: null,
				title: null
			},
			Json: {
				deposit: 0
			},
			Office: {
				id: null,
				title: null,
			},
			Supplier: {
				id: null,
				title: null
			}
		}
	}
}

const state = {
	allActiveLcas: [],
	current: {
		ItemDescriptions: [
			{
				index: 0,
				lineNumber: 1,
				Salesdescription: {}
			}
		],
		Lca: {
			id: null,
			bank_id: null,
			contract_id: null,
			docrequired: 'triplicate',
			expirydate: null,
			file: null,
			latestshipdate: null,
			lcadate: null,
			lcalatestdate: null,
			negotiable: 'SIGHT',
			partialshipment: 'Not Allowed',
			title: null,
			transhipment: 'Not Allowed',
			Banks: [],
			Contract: {
				currency: 'USD',
				currency_id: 1,
				customer_id: null,
				final_destination: null,
				incoterm: null,
				incoterm_id: null,
				loadingport_id: null,
				office_id: null,
				port_of_loading: null,
				salestype_id: 1,
				shippingport_id: null,
				title: null,
				value: 0,
				version: null
			},
			Customer: {
				id: null,
				company_id: null,
				title: null
			},
			Json: {
				deposit: 0
			},
			Office: {
				id: null,
				title: null,
			},
			Supplier: {
				id: null,
				title: null
			}
		},
		Lcas: [],
		totalLcas: 0,
	},
	update: {
		ItemDescriptions: [
			{
				index: 0,
				lineNumber: 1,
				Salesdescription: {}
			}
		],
		Lca: {
			Banks: [],
			Contract: {},
			Customer: {},
			Destination: {},
			Json: {},
			Loadingport: {},
			Office: {},
			Origin: {},
			Shippingport: {},
			Supplier: {}
		}
	},
	newLcaSaved: false,
	newLcaUnsaved: false,
	updatedLcaSaved: false,
	updatedLcaUnsaved: false
}
const getters = {
	allActiveLcas: state => { return state.allActiveLcas },
	getField
}
const mutations = {
	resetCurrentState (state) {
		console.log('Resetting this LCA...')
		state.current.Lca = getDefaultState().Lca
		state.current.ItemDescriptions = getDefaultState().ItemDescriptions
		state.newLcaUnsaved = false
		state.newLcaSaved = false
	},
	resetState (state){
		console.log('Resetting LCAs...')
		state.allActiveLcas = []
	},
	setAllActiveLcas (state, data) {
		state.allActiveLcas = data;
	},
	setCurrentLca (state,payload) {
		let contract = payload
		state.current.Lca.contract_id = contract.Contract?.id
		state.current.Lca.Supplier.id = contract.Supplier?.id
		state.current.Lca.Supplier.title = contract.Supplier?.title
		let contract_title = contract.Contract?.version != null ? [contract.Contract.title, contract.Contract.revision_no , contract.Contract.partial_no].filter(Boolean).join('-') : contract.Contract.title
		state.current.Lca.title = contract_title
		state.current.Lca.file = 'APP LCA ' + contract_title + ' ' + contract.Customer.title
		state.current.Lca.bank_id= contract.Bank.id
		state.current.Lca.Contract.currency_id= contract.Contract.currency_id
		state.current.Lca.Contract.incoterm_id = contract.Contract.incoterm_id
		state.current.Lca.Contract.loadingport_id = contract.Contract.loadingport_id
		state.current.Lca.Contract.office_id = contract.Office.id
		state.current.Lca.Contract.partial_no = contract.Contract.partial_no
		state.current.Lca.Contract.revision_no = contract.Contract.revision_no
		state.current.Lca.Contract.salestype_id = contract.Contract.salestype_id
		state.current.Lca.Contract.shippingport_id = contract.Contract.shippingport_id
		state.current.Lca.Contract.title = contract.Contract.title
		state.current.Lca.Contract.version = contract.Contract.version
		state.current.Lca.Office.id = contract.Office.id
		state.current.Lca.Office.title = contract.Office.title
	},
	setLcas (state,data) {
		state.current.Lcas = data;
	},
	setLcaToBeUpdated (state, data) {
		state.update.Lca = data.Lca
		let contract_title = state.update.Lca?.Contract?.version != null ? [state.update.Lca.Contract.title, state.update.Lca.Contract.revision_no , state.update.Lca.Contract.partial_no].filter(Boolean).join('-') : state.update.Lca.Contract.title
		state.update.Lca.file = 'APP LCA ' + contract_title + ' ' + state.update.Lca.Customer.title
	},
	setSalesDescriptions (state, payload) {
		let prefix = payload.prefix
		let descriptions = payload.data
		state[prefix].ItemDescriptions = []
		let index = 0
		descriptions.forEach((description)=>{
			description['index'] = index
			description['lineNumber'] = index + 1
			state[prefix].ItemDescriptions.push(description)
			index++
		})
	},
	setTotalLcas (state,val) {
		state.current.totalLcas = val
	},
	updateDescription (state, payload) {
		let keys = payload['field'].split('.')
		let prefix = payload['prefix']
		state[prefix].ItemDescriptions[payload['index']][keys[0]][keys[1]] = payload['value']
	},
	updateField
}
const actions = {
	cancelLcaById ({commit},val){
		return new Promise((resolve, reject) => {
			api
				.delete("/lcas/" + val)
				.then(response => {
					// if(response.data.status == 'success') {
					// 	resolve('done')
					// } else {
					// 	resolve('error')
					// }
					resolve(response.data)
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	createLca ({state, rootState}){
		let descriptions =  _.cloneDeep(state.current.ItemDescriptions)
		let lca = _.cloneDeep(state.current.Lca)
		let json = _.cloneDeep(state.current.Lca.Json)
		//remove unneeded properties
		delete lca.Contract
		delete lca.Customer
		delete lca.Banks
		delete lca.Json
		delete lca.Office
		delete lca.Supplier
		return new Promise((resolve, reject) => {
			api
				.post('/lcas',{
						lca: lca,
						clauses: rootState.appic.lcaclause.current.Clauses,
						descriptions: descriptions,
						json: json
					}
				)
				.then((response)=>{
					// if(response.data.status == 'success') {
					// 	resolve(response.data)
					// } else {
					// 	resolve('error')
					// }
					resolve(response.data)
				})
				.catch(error => {
					reject(error)
				})
				// .finally(()=>{
				// 	resolve('error')
				// })
		})
	},
	getAllActiveLcas ({commit}) {
		return new Promise((resolve, reject) => {
			api
				.get("/lcas/active")
				.then(response => {
					commit('setAllActiveLcas',response.data.data);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	getAllLcas ({commit},payload) {
		// let filterOptions = payload.filterOptions
		// let conditions = []
		return new Promise((resolve, reject) => {
			if(payload.tableOptions.page != null) {
				api
					.get("/lcas", {
						params: {
							// conditions: conditions,
							pagination: {
								skip: (payload.tableOptions.page - 1) * payload.tableOptions.itemsPerPage,
								take: payload.tableOptions.itemsPerPage
							},
							order: [
								{
									field: 'lcas.id',
									direction: 'DESC'
								},
							]
						}
					})
					.then(response => {
						commit('setLcas', response.data.data)
						commit('setTotalLcas', response.data.totalRows)
						resolve('done')
					})
					.catch(error => {
						reject(error);
					});
			} else {
				resolve('done')
			}
		})
	},
	getItemDescriptionsByContract ( {commit} , payload) {
		let contract_id = payload.contract_id
		let prefix = payload.prefix
		return new Promise((resolve, reject) => {
			api
				.get("/contracts/" + contract_id + "/salesdescriptions",{

				})
				.then(response => {
					let payload_salesdescriptions = {
						prefix: prefix,
						data: response.data.data
					}
					commit('setSalesDescriptions',payload_salesdescriptions);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
				.finally(()=>{
					resolve('done')
				})
		})
	},
	getLcaToUpdateById ({commit},lca_id) {
		return new Promise((resolve, reject) => {
			api
				.get("/lcas/" + lca_id)
				.then(response => {
					let lca = response.data.data[0]
					lca['Lca']['Banks'] = []
					lca['Lca']['Contract']['final_destination'] = null
					lca['Lca']['Contract']['incoterm'] = null
					lca['Lca']['Contract']['port_of_loading'] = null
					lca['Lca']['Contract']['currency'] = 'USD'
					lca['Lca']['Contract']['value'] = 0
					if (lca['Lca']['Json'] == null) {
						lca['Lca']['Json'] = {
							deposit: 0
						}
					}
					commit('setLcaToBeUpdated',lca);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
				.finally(()=>{
					resolve('done')
				})
		})
	},
	loadContractWithoutLca ({commit, rootState},payload) {
		return new Promise((resolve, reject) => {
			commit('setCurrentLca', payload)
			resolve('done')
		})
	},
	resetAllActiveLcas ({commit}){
		return new Promise ((resolve, reject) => {
			commit('resetState')
			resolve('done')
		})
	},
	resetCurrentLca ({commit}){
		return new Promise ((resolve, reject) => {
			commit('resetCurrentState')
			resolve('done')
		})
	},
	searchAllLcas ({commit}, payload) {
		// let conditions = []
		// let obj = {
		// 	field: payload.search.field,
		// 	value: payload.search.value
		// }
		// conditions.push(obj)
		return new Promise((resolve, reject) => {
			api
				.get("/lcas",{
					params: {
						conditions: payload.conditions,
						pagination: {
							skip: (payload.tableOptions.page - 1) * payload.tableOptions.itemsPerPage,
							take: payload.tableOptions.itemsPerPage
							// skip: (payload.tableOptions.page - 1) * -1,
							// take: -1
						},
						order: payload.order
					}
				})
				.then(response => {
					commit('setLcas', response.data.data)
					commit('setTotalLcas', response.data.totalRows)
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
				.then(()=>{
					resolve('done')
				});
		})
	},
	sendLetterOfCreditAdviceToBuyer ({commit}, payload) {
		return new Promise ((resolve, reject) => {
			api
				.put('/lcas/' + payload.lcaId + '/send-to-buyer',{
					contacts: payload.contacts
				})
				.then(response => {
					if(response.data.status == 'success') {
						resolve('done')
					} else {
						resolve('error')
					}
				})
				.catch(error => {
					reject(error);
				})
		})
	},
	updateLca ({state, rootState}) {
		let descriptions =  _.cloneDeep(state.update.ItemDescriptions)
		let lca = _.cloneDeep(state.update.Lca)
		let json = _.cloneDeep(state.update.Lca.Json)
		//remove unneeded properties
		delete lca.Banks
		delete lca.Bank
		delete lca.Contract
		delete lca.Customer
		delete lca.Destination
		delete lca.Loadingport
		delete lca.Shippingport
		delete lca.Office
		delete lca.Origin
		delete lca.Json
		delete lca.Supplier
		return new Promise((resolve, reject) => {
			api
				.put("/lcas/" + state.update.Lca.id,{
					lca: lca,
					clauses: rootState.appic.lcaclause.update.Clauses,
					descriptions: descriptions,
					json: json
				})
				.then(response => {
					this.updatedLcaSaved = true
					this.updatedLcaUnsaved = false
					// resolve('done')
					resolve(response.data)
				})
				.catch(error => {
					reject(error)
					// resolve(error)
				})
				// .then(()=>{
				// 	resolve('done')
				// });
		})
	}
}

export const lca = {
	namespaced,
	state,
	getters,
	actions,
	mutations
}