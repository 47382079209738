import {getField, updateField} from "vuex-map-fields";
import { api } from "../../../api";
import _ from 'lodash'

const namespaced = {
    namespaced: true
}

const getDefaultState = () => {
    return {
        allSalesColleagues: [],
        notSalesColleagues: [],
        Salesperson: {
            id: null,
            cm: null,
            cm_salescontact_ids: [],
            lastname: null,
            name: null,
            phone: null,
            skype: null,
            title: null,
            userid: null
        }
    }
}

const state = {
    allSalesColleagues: [],
    notSalesColleagues: [],
    current: {
        Salesperson: {
            id: null,
            cm: null,
            cm_salescontact_ids: [],
            lastname: null,
            name: null,
            phone: null,
            skype: null,
            title: null,
            userid: null
        }
    },
    update: {
        Salesperson: {}
    }
}

const getters = {
    allSalesColleagues: state => {
        return state.allSalesColleagues;
    },
    notSalesColleagues: state => {
        return state.notSalesColleagues;
    },
    findSalesColleagueById: (state) => (id) => {
        return state.allSalesColleagues.find(salesColleague => salesColleague.Salesperson.id === id)
    },
    getField
}

const actions = {
    createSalesColleague ({state}){
        let salesperson = _.cloneDeep(state.current.Salesperson)

        return new Promise((resolve, reject) => {
            api
                .post('/salescolleagues',{
                        salesperson: salesperson
                })
                .then((response)=>{
                    // if(response.data.status == 'success') {
                    //     resolve('done')
                    // } else {
                    //     resolve('error')
                    // }
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
                // .finally(()=>{
                //     resolve('error')
                // })
        })
    },
    deleteSalesColleagueById ({commit}, salespersonId) {
        return new Promise((resolve, reject) => {
            api
                .delete('/salescolleagues/' + salespersonId)
                .then(response => {
                    // resolve('done')
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error);
                })
        })
    },
    getSalesColleagueToUpdateById ({commit}, salespersonId) {
        return new Promise((resolve, reject) => {
            api
                .get('/salescolleagues/' + salespersonId )
                .then(response => {
                    commit('setSalesColleagueToUpdate', response.data.data[0])
                    resolve('done')
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getAllSalesColleagues({commit}){
        return new Promise((resolve, reject) => {
            api
                .get("/salescolleagues")
                .then(response => {
                    commit('setAllSalesColleagues',response.data.data);
                    resolve('done');
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getNotSalesColleagues({commit}){
        return new Promise((resolve, reject) => {
            api
                .get("/salescolleagues/not")
                .then(response => {
                    commit('setNotSalesColleagues',response.data.data);
                    resolve('done');
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    resetSalesColleagueToUpdate ({commit}) {
        commit('resetSalesColleagueToUpdate')
    },
    resetCurrentState ({commit}) {
        commit('resetCurrentState')
    },
    resetState ({commit}){
        commit('resetState')
    },
    updateSalesColleague({state}) {
        let salesperson = _.cloneDeep(state.update.Salesperson)
        //remove unneeded properties
        return new Promise((resolve, reject) => {
            api
                .put("/salescolleagues/" + state.update.Salesperson.id,{
                    salesperson: salesperson
                })
                .then(response => {
                    // resolve('done')
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
                // .then(()=>{
                //     resolve('done')
                // });
        })
    }
}

const mutations = {
    resetSalesColleagueToUpdate (state) {
        state.update.Salesperson = getDefaultState().Salesperson
    },
    resetCurrentState (state) {
        state.current.Salesperson = getDefaultState().Salesperson
    },
    resetState(state){
        state.allSalesColleagues = getDefaultState().allSalesColleagues
        state.notSalesColleagues = getDefaultState().notSalesColleagues
    },
    setAllSalesColleagues(state,data){
        state.allSalesColleagues = data;
    },
    setNotSalesColleagues(state,data) {
        let notSalesColleagues = []
        data.forEach(sc => {
            notSalesColleagues.push(sc.Salesperson.id)
        })
        state.notSalesColleagues = notSalesColleagues;
    },
    setSalesColleagueToUpdate ( state, salesperson ){
        state.update.Salesperson = salesperson.Salesperson
    },
    updateField
}

export const salescolleague = {
    namespaced,
    state,
    getters,
    actions,
    mutations
}