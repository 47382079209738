import {getField, updateField} from "vuex-map-fields";
import {api} from "../../../api";
import _ from 'lodash'

const namespaced = {
	namespaced: true
}

const getDefaultState = () => {
	return {
		Purchaseorder: {
			id: null,
			title: null
		},
		Wpr: {
			approved: null,
			approved_by: null,
			id: null,
			cfrvalue: 0,
			containercount: 1,
			containersize_id: 1,
			contract_id: null,
			convertdate: null,
			currency_id: 2,
			current_stock: null,
			customer_id: 100, //APP Timber Sdn Bhd
			eta_current_stock: null,
			etadate: null,
			etddate: null,
			expected_salesdate_current: null,
			expected_salesdate_newpo: null,
			explanation: null,
			incoterm_id: 2,
			ms_id: 1,
			office_id: 1,
			paymentstatement_id: null,
			poetadate: null,
			ponumber: null,
			purchaseorder_id: null,
			rejected: null,
			rejected_by: null,
			reopened: null,
			reopened_by: null,
			remarks: null,
			status_id: 1,
			supplier_id: null,
			title: null,
			transit_eta_stock: null,
			transit_expected_salesdate: null,
			transit_ponumber: null,
			transit_stock: null,
			wprdate: null,
			wprsubmit_date: null
		}
	}
}

const state = {
	allActiveWprs: [],
	current: {
		Purchaseorder: {
			id: null,
			title: null
		},
		Wpr: {
			approved: null,
			approved_by: null,
			id: null,
			cfrvalue: 0,
			containercount: 1,
			containersize_id: 1,
			contract_id: null,
			convertdate: null,
			currency_id: 2,
			current_stock: null,
			customer_id: null,
			eta_current_stock: null,
			etadate: null,
			etddate: null,
			expected_salesdate_current: null,
			expected_salesdate_newpo: null,
			explanation: null,
			incoterm_id: 2,
			ms_id: 1,
			office_id: 1,
			paymentstatement_id: null,
			poetadate: null,
			ponumber: null,
			purchaseorder_id: null,
			rejected: null,
			rejected_by: null,
			reopened: null,
			reopened_by: null,
			remarks: null,
			status_id: 1,
			supplier_id: null,
			title: null,
			transit_eta_stock: null,
			transit_expected_salesdate: null,
			transit_ponumber: null,
			transit_stock: null,
			wprdate: null,
			wprsubmit_date: null
		},
		WarehousePurchaseRequests: [],
		totalWarehousePurchaseRequests: 0,
		wprSuppliers: [],
		wprStatuses: []
	},
	update: {
		Wpr: {}
	},
	newWprSaved: false,
	newWprUnsaved: false,
	updatedWprUnsaved: false,
	updatedWprSaved: false
}

const getters = {
	allActiveWprs: state => { return state.allActiveWprs },
	getField,
	wprStatuses: state => { return state.current.wprStatuses },
	wprSuppliers: state => { return state.current.wprSuppliers },
}

const mutations = {
	resetCurrentState (state) {
		// console.log('Resetting this Wpr...')
		state.current.Wpr = getDefaultState().Wpr
		state.current.Purchaseorder = getDefaultState().Purchaseorder
		state.newWprUnsaved = false
		state.newWprSaved = false
	},
	resetState (state){
		// console.log('Resetting Wprs...')
		state.allActiveWprs = []
	},
	setCurrentWpr (state,payload) {

	},
	setAllActiveWprs (state,data) {
		state.allActiveWprs = data
	},
	setPurchaseOrder (state, payload){
		// console.log('Setting the related PO')
		state[payload.prefix].Wpr.purchaseorder_id = payload.purchaseorder.id
		state[payload.prefix].Purchaseorder = payload.purchaseorder
	},
	setWprs (state,data) {
		state.current.WarehousePurchaseRequests = data;
	},
	setWprStatuses (state,data) {
		state.current.wprStatuses = data;
	},
	setWprSuppliers (state,data) {
		state.current.wprSuppliers = data;
	},
	setWprToBeUpdated (state, data) {
		state.update.Wpr = data.Wpr
		state.update.Purchaseorder = data.Purchaseorder
	},
	setTotalWprs (state,val) {
		state.current.totalWarehousePurchaseRequests = val
	},
	updateField,
	updateWprStatus (state, payload) {
		state[payload.prefix].Wpr.status_id = payload.status
	}
}

const actions = {
	approveWprById ({commit},val) {
		return new Promise((resolve, reject) => {
			api
				.put("/wprs/" + val + "/approve")
				.then(response => {
					if(response.data.status == 'success') {
						let payload = {
							status: 3,
							prefix: 'update'
						}
						commit('updateWprStatus',payload)
						resolve('done')
					} else {
						resolve('error')
					}
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	cancelWprById ({commit},val){
		return new Promise((resolve, reject) => {
			api
				.delete("/wprs/" + val)
				.then(response => {
					// if(response.data.status == 'success') {
					// 	resolve('done')
					// } else {
					// 	resolve('error')
					// }
					resolve(response.data)
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	convertWprToPoById({commit},payload) {
		return new Promise((resolve, reject) => {
			api
				.put("/wprs/" + payload.wpr_id + '/convert',{
					new_po_number: payload.po_number
				})
				.then(response => {
					if(response.data.status == 'success') {
						if(payload.setPurchaseOrder) {
							let payload2 = {
								prefix: payload.prefix,
								purchaseorder: {
									id: response.data.purchaseorder_id,
									title: response.data.purchaseorder_title
								}
							}
							commit('setPurchaseOrder', payload2)
						}
						resolve('done')
					} else {
						resolve('error')
					}
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	createWpr ({state, rootState}){
		let wpr = _.cloneDeep(state.current.Wpr)
		//remove unneeded properties

		let wprItems = _.cloneDeep(rootState.appic.wpritem.current.Wpritems)
		wprItems.forEach((item,idx) => {
			delete wprItems[idx]['Options']
		})

		return new Promise((resolve, reject) => {
			api
				.post('/wprs',{
						wpr: wpr,
						wpritems: wprItems
					}
				)
				.then((response) => {
					// if(response.data.status == 'success') {
					// 	resolve(response.data)
					// } else {
					// 	resolve('error')
					// }
					resolve(response.data)
				})
				.catch(error => {
					reject(error)
				})
				// .finally(()=>{
				// 	resolve('error')
				// })
		})
	},
	getAllActiveWprs ({commit}){
		return new Promise((resolve, reject) => {
			api
				.get("/wprs/active")
				.then(response => {
					commit('setAllActiveWprs',response.data.data);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	getAllWprs ({commit},payload) {
		// let filterOptions = payload?.filterOptions
		// let conditions = []
		// if(filterOptions.status?.Status?.id){
		// 	let obj = {
		// 		field: 'wprs.status_id',
		// 		value: filterOptions.status.Status.id
		// 	}
		// 	conditions.push(obj)
		// }
		// if(filterOptions.supplier?.Supplier?.id){
		// 	let obj = {
		// 		field: 'wprs.supplier_id',
		// 		value: filterOptions.supplier.Supplier.id
		// 	}
		// 	conditions.push(obj)
		// }
		return new Promise((resolve, reject) => {
			if(payload.tableOptions.page != null) {
				api
					.get("/wprs", {
						params: {
							// conditions: conditions,
							pagination: {
								skip: (payload.tableOptions.page - 1) * payload.tableOptions.itemsPerPage,
								take: payload.tableOptions.itemsPerPage
							},
							order: [
								{
									field: 'Wpr.wprdate',
									direction: 'DESC'
								},
							]
						}
					})
					.then(response => {
						commit('setWprs', response.data.data)
						commit('setTotalWprs', response.data.totalRows)
						resolve('done')
					})
					.catch(error => {
						reject(error);
					});
			} else {
				resolve('done')
			}
		})
	},
	getAllWprStatuses ({commit}) {
		return new Promise((resolve, reject) => {
			api
				.get("/wprs/status",{
				})
				.then(response => {
					commit('setWprStatuses',response.data.data);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	getAllWprSuppliers ({commit}) {
		return new Promise((resolve, reject) => {
			api
				.get("/wprs/suppliers",{
				})
				.then(response => {
					commit('setWprSuppliers',response.data.data);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	getWprToUpdateById ({commit},wpr_id) {
		return new Promise((resolve, reject) => {
			api
				.get('/wprs/' + wpr_id)
				.then(response => {
					commit('setWprToBeUpdated', response.data.data[0]);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
				// .finally(()=>{
				// 	resolve('done')
				// })
		})
	},
	rejectWprById ({commit},val) {
		return new Promise((resolve, reject) => {
			api
				.put("/wprs/" + val + "/reject")
				.then(response => {
					if(response.data.status == 'success') {
						let payload = {
							status: 4,
							prefix: 'update'
						}
						commit('updateWprStatus',payload)
						resolve('done')
					} else {
						resolve('error')
					}
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	resetAllActiveWprs ({commit}){
		return new Promise ((resolve, reject) => {
			commit('resetState')
			resolve('done')
		})
	},
	resetCurrentWpr ({commit}){
		return new Promise ((resolve, reject) => {
			commit('resetCurrentState')
			resolve('done')
		})
	},
	searchAllWprs ({commit}, payload){
		// let conditions = []
		// let obj = {
		// 	field: payload.search.field,
		// 	value: payload.search.value
		// }
		// conditions.push(obj)
		return new Promise((resolve, reject) => {
			api
				.get("/wprs",{
					params: {
						conditions: payload.conditions,
						pagination: {
							skip: (payload.tableOptions.page - 1) * payload.tableOptions.itemsPerPage,
							take: payload.tableOptions.itemsPerPage
							// kip: (payload.tableOptions.page - 1) * -1,
							// take: -1
						},
						order: payload.order
					}
				})
				.then(response => {
					commit('setWprs',response.data.data);
					commit('setTotalWprs',response.data.totalRows);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
				// .then(()=>{
				// 	resolve('done')
				// });
		})
	},
	submitWprById ({commit},val) {
		return new Promise((resolve, reject) => {
			api
				.put("/wprs/" + val + "/submit")
				.then(response => {
					if(response.data.status == 'success') {
						let payload = {
							status: 2,
							prefix: 'update'
						}
						commit('updateWprStatus',payload)
						resolve('done')
					} else {
						resolve('error')
					}
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	updateWpr ({state, rootState}){
		let wpr = _.cloneDeep(state.update.Wpr)
		//remove unneeded properties
		delete wpr.Customer
		delete wpr.Paymentstatement
		delete wpr.Purchaseorder
		delete wpr.Status
		delete wpr.Supplier

		let wprItems = _.cloneDeep(rootState.appic.wpritem.update.Wpritems)
		wprItems.forEach((item,idx) => {
			delete wprItems[idx]['Options']
		})

		return new Promise((resolve, reject) => {
			api
				.put('/wprs/' + state.update.Wpr.id,{
						wpr: wpr,
						wpritems: wprItems
					}
				)
				.then((response)=>{
					this.updatedWprSaved = true
					this.updatedWprUnsaved = false
					// resolve('done')
					resolve(response.data)
				})
				.catch(error => {
					reject(error)
				})
				// .finally(()=>{
				// 	resolve('done')
				// })
		})
	}
}

export const wpr = {
	namespaced,
	state,
	getters,
	actions,
	mutations
}