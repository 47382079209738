<template>
    <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon class="mr-3 py-0" v-on="on" @click="goToHelpPage(helpPageId)">
                <v-icon class="black--text darken-1">mdi-help-circle-outline</v-icon>
            </v-btn>
        </template>
        <span>{{ $t('message.help') }}</span>
    </v-tooltip>
</template>

<script>
import {mapFields} from "vuex-map-fields";

export default {
    name: "HelpPopUp",
    props: ['helpPageId'],
    computed: {
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
    },
    methods: {
        goToHelpPage (helpPageId) {
            let tab = window.open('/v1/help?page=' + helpPageId,'_blank')
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        }
    }
}
</script>

<style scoped>

</style>