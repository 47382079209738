import { api } from "../../../api";
import {getField, updateField} from "vuex-map-fields";

const getDefaultState = () => {
    return {
        User: {},
        Country: {},
        ZohoDashboards: [],
        ZohoReports: [],
        AbilityRules: {},
        AppicVars: {
            gmLatePaymentCutoffDate: '2022-08-01'
        },
        s3Keys: {},
        api2Pdf: {},
        superAdmin: false,
        newUser: {
            abbreviation: null,
            appic_timeout: 2,
            country_id: 1,
            email: null,
            fb_verified: null,
            id: null,
            is_superadmin: null,
            photo: null,
            role: '',
            title: null,
            userstatus_id: 1,
        },
        tinyMce: {}
    }
}

const namespaced = {
    namespaced: true
}

const state = {
    allUsers: [],
    current: {
        User: {},
        Country: {},
        ZohoDashboards: [],
        ZohoReports: [],
        AbilityRules: {},
        AppicVars: {
            gmLatePaymentCutoffDate: '2022-08-01'
        },
        s3Keys: {},
        api2Pdf: {},
        superAdmin: false,
        tinyMce: {},
        google: {}
    },
    new: {
        User: {
            abbreviation: null,
            appic_timeout: 2,
            country_id: 1,
            email: null,
            fb_verified: null,
            id: null,
            is_superadmin: null,
            photo: null,
            role: '',
            title: null,
            userstatus_id: 1,
        },
        superAdmin: false
    },
    update: {
        User: {},
        Country: {},
        ZohoDashboards: [],
        ZohoReports: [],
        AbilityRules: {},
        AppicVars: {
            gmLatePaymentCutoffDate: '2022-08-01'
        },
        s3Keys: {},
        api2Pdf: {},
        superAdmin: false,
        tinyMce: {},
        google: {}
    },
    newUserSaved: false,
    newUserUnsaved: false,
    updatedUserUnsaved: false,
    updatedUserSaved: false
}

const getters = {
    AbilityRules: state => { return state.current.AbilityRules },
    AppicVars: state => { return state.current.AppicVars },
    User: state => { return state.current.User },
    ZohoDashboards: state => { return state.current.ZohoDashboards },
    ZohoReports: state => { return state.current.ZohoReports },
    allUsers: state => {return state.allUsers},
    api2Pdf: state => { return state.current.api2Pdf },
    s3Keys: state => { return state.current.s3Keys },
    superAdmin: state => {return state.current.superAdmin},
    tinyMce: state => {return state.current.tinyMce},
    google: state => {return state.current.google},
    getField
}

const mutations = {
    resetUpdateState(state){
        state.update.User = getDefaultState().User
    },
    resetAllUsersState(state) {
        state.allUsers = []
    },
    resetNewState (state) {
        console.log('Resetting this User...')
        state.new.User = getDefaultState().newUser
        state.new.superAdmin = false
        state.newUserUnsaved = false
        state.newUserSaved = false
    },
    resetState (state) {
        state.allUsers = []
        state.current.User = getDefaultState().User
        state.current.Country = getDefaultState().Country
        state.current.ZohoDashboards = []
        state.current.ZohoReports = []
        state.current.AbilityRules = {}
        state.current.AppicVars = {}
        state.current.s3Keys = {}
        state.current.api2Pdf = {}
        state.current.tinyMce = {}
        state.current.google = {}
        state.current.superAdmin = getDefaultState().superAdmin
    },
    setAllUsers(state, data) {
        state.allUsers = data;
    },
    setUser (state, data) {
        state.current.User = data.User
        state.current.Country = data.Country
        state.current.ZohoDashboards = data.ZohoDashboards
        state.current.ZohoReports = data.ZohoReports
        state.current.AbilityRules = data.AbilityRules
        state.current.AppicVars = data.AppicVars
        state.current.s3Keys = data.s3Keys
        state.current.api2Pdf = data.api2Pdf
        state.current.tinyMce = data.tinyMce
        state.current.google = data.google
        if(data.superAdmin == 1) state.current.superAdmin = true
    },
    setUserToBeUpdated(state, data) {
        state.update.User = data.User
    },
    updateField
}

const actions = {
    activateUserByEmailId({commit}, email) {
        return new Promise((resolve, reject) => {
            api
                .put("/users/" + email + "/activate")
                .then(response => {
                    if(response.data.status == 'success') {
                        resolve('done')
                    } else {
                        resolve('error')
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    createUser({state}) {
        let user = _.cloneDeep(state.new.User)
        //remove unneeded properties

        return new Promise((resolve, reject) => {
            api
                .post('/users',{
                    user: user
                })
                .then((response)=>{
                    // if(response.data.status == 'success') {
                    //     resolve(response.data)
                    // } else {
                    //     resolve(response.data)
                    // }
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
                .finally(()=>{
                    resolve('error')
                })
        })
    },
    deleteUserByEmailId({commit}, email) {
        return new Promise((resolve, reject) => {
            api
                .delete("/users/" + email)
                .then(response => {
                    if(response.data.status == 'success') {
                        resolve('done')
                    } else {
                        resolve('error')
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getAllUsers ({commit}) {
        let conditions = [
            {field: 'users.usergroup_id', value: [0,7,8,9], not: true}
        ]
        let order = [
            {field: 'users.userstatus_id', direction: 'ASC'},
            {field: 'users.title', direction: 'ASC'}
        ]
        return new Promise ((resolve, reject) => {
            api
                .get("/users",{
                    params: {
                        conditions: conditions,
                        order: order
                    }
                })
                .then(response => {
                    commit('setAllUsers',response.data.data);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getUserByEmailId ({commit, rootState}, email) {
        return new Promise((resolve, reject) => {
            api
                .get("/users/" + email)
                .then(response => {
                    commit('setUser', response.data.data);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getUserToUpdateById({commit}, user_id) {
        return new Promise((resolve, reject) => {
            api
                .get('/users/' + user_id + '/detail')
                .then(response => {
                    if(response.data.status == 'success') {
                        commit('setUserToBeUpdated', response.data.data[0]);
                        resolve('success')
                    } else {
                        resolve('error')
                    }
                })
                .catch(error => {
                    reject(error);
                })
                // .finally(()=>{
                //     resolve('done')
                // })
        })
    },
    logOutUserByEmailId({commit}, user_id){
        return new Promise((resolve, reject) => {
            api
                .put('/users/' + user_id + '/logout')
                .then(response => {
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                })
                .finally(()=>{
                    resolve('done')
                })
        })
    },
    resetAllUsers({commit}) {
        commit('resetAllUsersState')
    },
    resetNewUser ({commit}){
        return new Promise ((resolve, reject) => {
            try {
                commit('resetNewState')
                resolve('done')
            } catch(error) {
                reject(error)
            }
        })
    },
    resetUpdateState({commit}) {
        return new Promise ((resolve, reject) => {
            try {
                commit('resetUpdateState')
                resolve('done')
            } catch(error) {
                reject(error)
            }
        })
    },
    suspendUserByEmailId({commit}, email) {
        return new Promise((resolve, reject) => {
            api
                .put("/users/" + email + "/suspend")
                .then(response => {
                    if(response.data.status == 'success') {
                        resolve('done')
                    } else {
                        resolve('error')
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    updateLastActivity() {
        return new Promise((resolve, reject) => {
            api
                .put("/users/last-activity")
                .then(response => {
                    if(response.data.status == 'success') {
                        resolve('done')
                    } else {
                        resolve('error')
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    updateUser ({state}){
        let user = _.cloneDeep(state.update.User)
        //remove unneeded properties

        return new Promise((resolve, reject) => {
            api
                // .put('/users/' + state.update.User.id,{
                //         user: user
                // })
                .put('/users/' + state.update.User.uuid,{
                    user: user
                })
                .then((response)=>{
                    this.updatedUserSaved = true
                    this.updatedUserUnsaved = false
                    // resolve('success')
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}

export const user = {
    namespaced,
    state,
    getters,
    actions,
    mutations
}