var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-footer',{staticClass:"px-3 pb-0 pt-0",attrs:{"height":"50px"}},[_c('v-card',{staticClass:"flex",attrs:{"flat":"","tile":""}},[_c('v-card-actions',{staticClass:"justify-center"},[_c('strong',{staticClass:"subheading"},[_vm._v("Need help? Contact it@apptimber.com")]),_c('v-btn',{staticClass:"mx-3",attrs:{"icon":"","href":"mailto:it@apptimber.com"}},[_c('v-icon',{attrs:{"size":"24px"}},[_vm._v("ti-email")])],1),_vm._v(" ©"+_vm._s(new Date().getFullYear())+" "),_c('strong',[_vm._v(" APP Timber Ltd.")])],1),_c('v-btn',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
                    el: '#imatop',
                    container: 'body',
                    duration: 500,
                    easing: 'linear',
                    offset: -200,
                    force: true,
                    x: false,
                    y: true
                }),expression:"{\n                    el: '#imatop',\n                    container: 'body',\n                    duration: 500,\n                    easing: 'linear',\n                    offset: -200,\n                    force: true,\n                    x: false,\n                    y: true\n                }"}],attrs:{"fab":"","color":"warning","fixed":"","bottom":"","right":""}},[_c('v-icon',[_vm._v("ti-arrow-up")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }