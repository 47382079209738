import {getField, updateField} from "vuex-map-fields";
import { api } from "Api/index";
import _ from 'lodash';

const namespaced = {
	namespaced: true
}

const getDefaultState = () => {
	return {
		Contract: {
			Customer: {},
			Debitnote: {},
			Invoice: {},
			Office: {},
			Paymentstatement: {},
			PoPaymentstatement: {},
			Purchaseorder: {},
			Supplier: {}
		},
		Epr: {
			Customer: {},
			PoPaymentstatement: {},
			Supplier: {}
		},
		Localsale: {
			Customer: {},
			Office: {},
			Paymentstatement: {},
			Supplier: {}
		},
		Payment: {
			id: null,
			amount: 0,
			amountinpercent: null,
			bank_id: null,
			cashflow: null,
			code: null,
			contract_id: null,
			currency_id: 1,
			dppaid: null,
			dppaydateref_id: null,
			dppayday: null,
			dppaymentdate: null,
			dppayon_id: null,
			dprequired: null,
			fully_paid: null,
			inputtype_id: null,
			is_dppaid: null,
			key_id: null,
			latestpaymentdate: null,
			localsale_id: null,
			model: 'c',
			office_id: null,
			paydateref_id: null,
			payday: null,
			payee_id: null,
			payer_id: null,
			paymentdate: null,
			paymentstatement_id: 3,
			paymentstatus_id: null,
			paymentterm_id: null,
			paymenttype_id: null,
			payon_id: null,
			purchaseorder_id: null,
			title: null,
			totalamount: null,
			totalinvamount: null,
			unpaid: null
		},
		PoPayment: {
			id: null,
			amount: 0,
			amountinpercent: null,
			bank_id: null,
			cashflow: null,
			code: null,
			contract_id: null,
			currency_id: 1,
			dppaid: null,
			dppaydateref_id: null,
			dppayday: null,
			dppaymentdate: null,
			dppayon_id: null,
			dprequired: null,
			fully_paid: null,
			inputtype_id: null,
			is_dppaid: null,
			key_id: null,
			latestpaymentdate: null,
			localsale_id: null,
			model: 'p',
			office_id: null,
			paydateref_id: null,
			payday: null,
			payee_id: null,
			payer_id: null,
			paymentdate: null,
			paymentstatement_id: null,
			paymentstatus_id: 3,
			paymentterm_id: null,
			paymenttype_id: null,
			payon_id: null,
			purchaseorder_id: null,
			title: null,
			totalamount: null,
			totalinvamount: null,
			unpaid: null
		},
		paymentBuyers: [],
		paymentSuppliers: []
	}
}

const state = {
	current: {
		Contract: {
			Customer: {},
			Debitnote: {},
			Invoice: {},
			Office: {},
			Paymentstatement: {},
			PoPaymentstatement: {},
			Purchaseorder: {},
			Supplier: {}
		},
		Epr: {
			Customer: {},
			PoPaymentstatement: {},
			Supplier: {}
		},
		Localsale: {
			Customer: {},
			Localsalesorder: {},
			Office: {},
			Paymentstatement: {},
			Supplier: {}
		},
		Payment: {
			id: null,
			amount: 0,
			amountinpercent: null,
			bank_id: null,
			cashflow: null,
			code: null,
			contract_id: null,
			currency_id: 1,
			dppaid: null,
			dppaydateref_id: null,
			dppayday: null,
			dppaymentdate: null,
			dppayon_id: null,
			dprequired: null,
			fully_paid: null,
			inputtype_id: null,
			is_dppaid: null,
			key_id: null,
			latestpaymentdate: null,
			localsale_id: null,
			model: 'c',
			office_id: null,
			paydateref_id: null,
			payday: null,
			payee_id: null,
			payer_id: null,
			paymentdate: null,
			paymentstatement_id: null,
			paymentstatus_id: 3,
			paymentterm_id: null,
			paymenttype_id: null,
			payon_id: null,
			purchaseorder_id: null,
			title: null,
			totalamount: null,
			totalinvamount: null,
			unpaid: null
		},
		PoPayment: {
			id: null,
			amount: 0,
			amountinpercent: null,
			bank_id: null,
			cashflow: null,
			code: null,
			contract_id: null,
			currency_id: 1,
			dppaid: null,
			dppaydateref_id: null,
			dppayday: null,
			dppaymentdate: null,
			dppayon_id: null,
			dprequired: null,
			fully_paid: null,
			inputtype_id: null,
			is_dppaid: null,
			key_id: null,
			latestpaymentdate: null,
			localsale_id: null,
			model: 'p',
			office_id: null,
			paydateref_id: null,
			payday: null,
			payee_id: null,
			payer_id: null,
			paymentdate: null,
			paymentstatement_id: 3,
			paymentstatus_id: null,
			paymentterm_id: null,
			paymenttype_id: null,
			payon_id: null,
			purchaseorder_id: null,
			title: null,
			totalamount: null,
			totalinvamount: null,
			unpaid: null
		},
		paymentBuyers: [],
		paymentSuppliers: [],
	},
	exportConditions: [],
	update: {
		Contract: {
			Customer: {},
			Debitnote: {},
			Invoice: {},
			Office: {},
			Paymentstatement: {},
			PoPaymentstatement: {},
			Purchaseorder: {},
			Supplier: {}
		},
		Epr: {
			Customer: {},
			PoPaymentstatement: {},
			Supplier: {}
		},
		Localsale: {
			Customer: {},
			Localsalesorder: {},
			Office: {},
			Paymentstatement: {},
			Supplier: {}
		},
		Payment: {},
		PoPayment: {},
	},
	newPaymentSaved: false,
	newPaymentUnsaved: false,
	updatedPaymentUnsaved: false,
	updatedPaymentSaved: false
}

const getters = {
	getField
}

const mutations = {
	resetState (state){
		state.current.paymentBuyers = getDefaultState().paymentBuyers
		state.current.paymentSuppliers = getDefaultState().paymentSuppliers
	},
	resetCurrentState (){
		state.current.Payment = getDefaultState().Payment
		state.current.PoPayment = getDefaultState().PoPayment
	},
	setDocumentToBeUpdated (state, data) {
		state.update.Contract = getDefaultState().Contract
		state.update.Epr = getDefaultState().Epr
		state.update.Localsale = getDefaultState().Localsale
		state.update.Payment = getDefaultState().Payment
		state.update.PoPayment = getDefaultState().PoPayment

		if(data.contracts?.length > 0) state.update.Contract = data.contracts[0].Contract
		if(data.eprs?.length > 0) state.update.Epr = data.eprs[0].Epr
		if(data.localsales?.length > 0) state.update.Localsale = data.localsales[0].Localsale
		if(data.payments?.length > 0) state.update.Payment = data.payments[0].Payment
		if(data.popayments?.length > 0) state.update.PoPayment = data.popayments[0].PoPayment
	},
	setExportConditions (state, conditions) {
		state.exportConditions = conditions
	},
	setPaymentBuyers (state, data) {
		state.current.paymentBuyers = data;
	},
	setPaymentSuppliers (state, data) {
		state.current.paymentSuppliers = data;
	},
	updateField,
	updatePaymentDueDates (state, payload) {
		// console.log(payload.dates)
		state[payload.prefix].Payment.dppaymentdate = payload.dates.Payment.dppaymentdate
		state[payload.prefix].Payment.paymentdate = payload.dates.Payment.paymentdate
		state[payload.prefix].PoPayment.dppaymentdate = payload.dates.PoPayment.dppaymentdate
		state[payload.prefix].PoPayment.paymentdate = payload.dates.PoPayment.paymentdate
	}
}

const actions = {
	getAllPaymentBuyers ({commit}) {
		return new Promise((resolve, reject) => {
			api
				.get('/payments/buyers')
				.then(response=>{
					commit('setPaymentBuyers',response.data.data)
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
				.finally(()=>{
					resolve('done')
				})
		})
	},
	getAllPaymentSuppliers ({commit}) {
		return new Promise((resolve, reject) => {
			api
				.get('/payments/suppliers')
				.then(response=>{
					commit('setPaymentSuppliers',response.data.data)
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
				.finally(()=>{
					resolve('done')
				})
		})
	},
	getAllPayments ({commit}, payload) {
		let filterOptions = payload.filterOptions
		let conditions = []
		if (filterOptions.buyer) {
			let obj = {
				field: 'sales.customer_id',
				value: filterOptions.buyer
			}
			conditions.push(obj)
		}
		if(filterOptions.month && filterOptions.year){
			let endDate = new Date(filterOptions.year, filterOptions.month,0)
			let obj = {
				field: 'sales.salesdate',
				value: {
					from: [filterOptions.year.toString(),filterOptions.month.toString().padStart(2,'0'),'01'].join('-'),
					to: [endDate.getFullYear(),(endDate.getMonth() + 1).toString().padStart(2,'0'), endDate.getDate().toString().padStart(2,'0')].join('-')
				}
			}
			conditions.push(obj)
		} else if(filterOptions.month == null && filterOptions.year){
			let obj = {
				field: 'sales.salesdate',
				value: {
					from: [filterOptions.year.toString(),'01','01'].join('-'),
					to: [filterOptions.year.toString(),'12','31'].join('-')
				}
			}
			conditions.push(obj)
		}
		if (filterOptions.office) {
			let obj = {
				field: 'sales.office_id',
				value: filterOptions.office
			}
			conditions.push(obj)
		}
		if (filterOptions.paymentStatus) {
			let obj = {
				field: 'sales.paymentstatus_id',
				value: filterOptions.paymentStatus
			}
			conditions.push(obj)
		}
		if (filterOptions.salesType) {
			let obj = {
				field: 'sales.salestype_id',
				value: filterOptions.salesType
			}
			conditions.push(obj)
		}
		if (filterOptions.supplier) {
			let obj = {
				field: 'sales.supplier_id',
				value: filterOptions.supplier
			}
			conditions.push(obj)
		}
		if (filterOptions.year) {
			let obj = {
				field: 'sales.salesdate',
				value: {
					from: [filterOptions.year.toString(),'01','01'].join('-'),
					to: [filterOptions.year.toString(),'12','31'].join('-')
				}
			}
			conditions.push(obj)
		}
		return new Promise((resolve, reject) => {
			api
				.get('/payments',{
					params: {
						conditions: conditions,
						order: [
							{
								field: 'sales.salesdate',
								direction: 'DESC'
							},
						]
					}
				})
				.then(response => {
					commit('setExportConditions', conditions)
					let tableData = {
						rows: response.data.data,
						totalRows: response.data.totalRows
					}
					resolve(tableData)
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	getCleanedPaymentStatuses (){
		return new Promise((resolve, reject) => {
			api
				.get('/payments/statuses/cleaned')
				.then(response => {
					resolve(response.data.data)
				})
				.catch(error => {
					reject(error);
				})
				.finally(()=>{
					resolve('done')
				})
		})
	},
	getDocumentToUpdateById ({commit},payload) {
		let documentId = payload.documentId
		let salesType = payload.salesType
		return new Promise((resolve, reject) => {
			api
				.get('/'+ salesType + '/' + documentId + '/payments')
				.then(response => {
					commit('setDocumentToBeUpdated', response.data.data);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
		})
	},
	getPaymentStatuses (){
		return new Promise((resolve, reject) => {
			api
				.get('/payments/statuses')
				.then(response => {
					resolve(response.data.data)
				})
				.catch(error => {
					reject(error);
				})
				.finally(()=>{
					resolve('done')
				})
		})
	},
	updatePayments ({state, rootState}, payload){
		let salesType = payload.salesType
		let documentId = payload.documentId
		let data = {
			payment: _.cloneDeep(state.update.Payment),
			poPayment: _.cloneDeep(state.update.PoPayment),
			paymentTransactions: rootState.appic.paymenttransaction.update.Payment.PaymentTransactions,
			poPaymentTransactions: rootState.appic.paymenttransaction.update.PoPayment.PaymentTransactions
		}
		return new Promise((resolve, reject) => {
			api
				.put('/' + salesType + '/' + documentId + '/payments', data)
				.then((response)=>{
					this.updatedPaymentSaved = true
					this.updatedPaymentUnsaved = false
					// resolve('done')
					resolve(response.data.status)
				})
				.catch(error => {
					reject(error)
				})
				.finally(()=>{
					resolve('error')
				})
		})
	},
	updatePaymentDueDates ({ commit },payload ){
		let salesType = payload.salesType
		let documentId = payload.documentId
		let data = {
			paymentIds: payload.paymentIds
		}
		return new Promise((resolve, reject) => {
			api.put('/' + salesType + '/' + documentId + '/payment-due-dates', data)
				.then((response)=>{
					let updatePayload = {
						dates: response.data.dueDates,
						prefix: payload.prefix
					}
					commit('updatePaymentDueDates',updatePayload)
					resolve('done')
				})
				.catch(error => {
					reject(error)
				})
				.finally(()=>{
					resolve('error')
				})
		})
	}
}

export const payment = {
	namespaced,
	state,
	getters,
	actions,
	mutations
}