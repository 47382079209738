import {getField, updateField} from "vuex-map-fields";
import { api } from "../../../api";
import _ from 'lodash'

const namespaced = {
	namespaced: true
}

const getDefaultState = () => {
	return {
		allExchangeRates: [],
		Currencyrate: {
			id: null,
			currency_id: 2,
			dated: null,
			rate: 1
		}
	}
}

const state = {
	allExchangeRates: [],
	current: {
		Currencyrate: {
			id: null,
			currency_id: 2,
			dated: null,
			rate: 1
		},
	},
	update: {
		Currencyrate: {}
	}
}

const getters = {
	allExchangeRates: state => {
		return state.allExchangeRates;
	},
	getField
}

const actions = {
	createExchangeRate ({state, rootState}) {
		let currencyRate = _.cloneDeep(state.current.Currencyrate)

		return new Promise((resolve, reject) => {
			api
				.post('/currencyrates',{
						currencyrate: currencyRate,
					}
				)
				.then((response)=>{
					if(response.data.status == 'success') {
						resolve('done')
					} else {
						resolve('error')
					}
				})
				.catch(error => {
					reject(error)
				})
				.finally(()=>{
					resolve('error')
				})
		})
	},
	deleteExchangeRateById({commit}, exchangeRateId) {
		return new Promise((resolve, reject) => {
			api
				.delete('/currencyrates/' + exchangeRateId)
				.then(response => {
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
		})
	},
	getAllExchangeRates({commit}){
		return new Promise((resolve, reject) => {
			api
				.get("/currencyrates")
				.then(response => {
					commit('setAllExchangeRates',response.data.data);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	getExchangeRateToUpdateById ({commit}, exchangeRateId) {
		return new Promise((resolve, reject) => {
			api
				.get('/currencyrates/' + exchangeRateId )
				.then(response => {
					commit('setExchangeRateToUpdate', response.data.data[0])
					resolve('done')
				})
				.catch(error => {
					reject(error)
				})
		})
	},
	resetCurrentState ({commit}) {
		commit('resetCurrentState')
	},
	resetExchangeRateToUpdate ({commit}) {
		commit('resetExchangeRateToUpdate')
	},
	resetState({commit}) {
		commit('resetState')
	},
	resetAllExchangeRates({commit}) {
		commit('resetState')
	},
	updateExchangeRate ({state}) {
		let currencyRate = _.cloneDeep(state.update.Currencyrate)

		return new Promise((resolve, reject) => {
			api
				.put("/currencyrates/" + state.update.Currencyrate.id,{
					currencyrate: currencyRate
				})
				.then(response => {
					resolve('done')
				})
				.catch(error => {
					reject(error)
				})
				.then(()=>{
					resolve('done')
				});
		})
	}
}

const mutations = {
	setAllExchangeRates(state,data){
		state.allExchangeRates = data;
	},
	setExchangeRateToUpdate ( state, exchangeRate ){
		state.update.Currencyrate = exchangeRate.Currencyrate
	},
	resetCurrentState (state) {
		state.current.Currencyrate = getDefaultState().Currencyrate
	},
	resetExchangeRateToUpdate (state) {
		state.update.Currencyrate = getDefaultState().Currencyrate
	},
	resetState(state){
		state.allExchangeRates = getDefaultState().allExchangeRates
	},
	updateField
}

export const exchangerate = {
	namespaced,
	state,
	getters,
	actions,
	mutations
}