import { api } from "../../../api";
import {getField, updateField} from "vuex-map-fields";
import _ from 'lodash'
// import {log} from "Helpers/helpers";

const namespaced = {
    namespaced: true
}

const getDefaultState = () => {
    return {
        Container: {
            index: 0,
            Container: {
                id: null,
                sealno: null,
                title: null
            }
        },
        ContainersToDelete: [],
        Shipment: {
            Customer: {},
            Supplier: {},
            documents: [],
            geo_coords_link: null
        },
        Shipments: [],
        shipmentBuyers: [],
        shipmentDestinations: [],
        shipmentOrigins: [],
        shipmentStatuses: [],
        shipmentSuppliers: [],
        shipmentTypes: [],
        totalShipments: 0,
        TrackingInfo: [],
    }
}

const state = {
    current: {
        Containers: [
            {
                index: 0,
                Container: {
                    id: null,
                    sealno: null,
                    title: null
                }
            }
        ],
        ContainersToDelete: [],
        Shipment: {
            Customer: {},
            Supplier: {},
            documents: [],
            freetext1: null,
            freetext2: null,
            freetext3: null,
            freetext4: null,
            geo_coords_link: null
        },
        Shipments: [],
        shipmentBuyers: [],
        shipmentDestinations: [],
        shipmentDocuments: [],
        shipmentOrigins: [],
        shipmentStatuses: [],
        shipmentSuppliers: [],
        shipmentTypes: [],
        totalShipments: 0,
        TrackingInfo: [],
    },
    boardMode: false,
    updatedShipmentUnsaved: false,
}

const getters = {
    Containers: state => { return state.current.Containers },
    getField,
    shipmentBuyers: state => { return state.current.shipmentBuyers },
    shipmentDestinations: state => { return state.current.shipmentDestinations },
    shipmentDocuments: state => { return state.current.shipmentDocuments },
    shipmentOrigins: state => { return state.current.shipmentOrigins },
    shipmentStatuses: state => { return state.current.shipmentStatuses },
    shipmentSuppliers: state => { return state.current.shipmentSuppliers },
    shipmentTypes: state => { return state.current.shipmentTypes },
    TrackingInfo: state => { return state.current.TrackingInfo },
}

const mutations = {
    addContainer (state) {
        let i = 0;
        state.current.Containers.push(getDefaultState().Container)
        state.current.Containers.forEach((item) => {
            item.index = i
            i++
        })
    },
    deleteContainer (state, index) {
        //add container id to ContainersToDelete
        let container = state.current.Containers[index]
        if(container?.Container?.id != null){
            state.current.ContainersToDelete.push(container.Container.id)
        }
        state.current.Containers.splice(index, 1)
        let i = 0;
        state.current.Containers.forEach((item) => {
            item.index = i
            i++
        })
    },
    resetAllShipments(state) {
        state.current.Shipments = getDefaultState().current.Shipments
    },
    setCurrentContainers (state, containers) {
        state.current.ContainersToDelete = []
        state.current.Containers = []
        let i = 0;
        if(containers.length > 0) {
            state.current.Containers = containers
            state.current.Containers.forEach((item, index) => {
                state.current.Containers[index]['index'] = i;
                i++
            })
        }
        state.current.Containers.push(getDefaultState().Container)
        state.current.Containers[i]['index'] = i
    },
    setShipmentToBeUpdated (state,data) {
        state.current.Shipment = data.Shipment
    },
    setShipments (state,data) {
        state.current.Shipments = data;
        state.current.totalShipments = data.length
    },
    setShipmentBuyers (state,data) {
        state.current.shipmentBuyers = data
    },
    setShipmentDestinations (state,data) {
        state.current.shipmentDestinations = data
    },
    setShipmentDocuments (state,data) {
        state.current.shipmentDocuments = data
    },
    setShipmentOrigins (state,data) {
        state.current.shipmentOrigins = data
    },
    setShipmentStatuses (state,data) {
        state.current.shipmentStatuses = data
    },
    setShipmentSuppliers (state,data) {
        state.current.shipmentSuppliers = data
    },
    setShipmentTypes (state,data) {
        state.current.shipmentTypes = data
    },
    setTotalShipments (state,val) {
        state.current.totalShipments = val
    },
    setTrackingInfo (state,tracking_info) {
        state.current.TrackingInfo = []
        state.current.TrackingInfo = tracking_info
        let i = 0;
        state.current.TrackingInfo.forEach((item,index)=>{
            state.current.TrackingInfo[index]['index'] = i
            i++
        })
    },
    updateContainers (state, payload){
        let keys = payload['field'].split('.')
        state.current.Containers[payload['index']][keys[0]][keys[1]] = payload['value']
    },
    updateField
}

const actions = {
    completeShipmentById ({commit},val) {
        return new Promise((resolve, reject) => {
            api
                .put("/contracts/" + val , [
                    {
                        field: 'salestatus_id',
                        value: 2
                    }
                ])
                .then(response => {
                    if(response.data.status == 'success') {
                        resolve('done')
                    } else {
                        resolve('error')
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getAllShipments ({commit},payload) {
        // let filterOptions = payload.filterOptions
        // let conditions = []
        // //conditions.push({field: 'contracts.salestatus_id', value: [1,2]})
        // if(filterOptions.buyer?.Customer?.id){
        //     let obj = {
        //         field: 'contracts.customer_id',
        //         value: filterOptions.buyer.Customer.id
        //     }
        //     conditions.push(obj)
        // }
        // if(filterOptions.status?.Status?.id){
        //     let obj = {
        //         field: 'status__id',
        //         value: filterOptions.status.Status.id
        //     }
        //     conditions.push(obj)
        // }
        // if(filterOptions.origin?.Country?.id){
        //     let obj = {
        //         field: 'contracts.productorigin_id',
        //         value: filterOptions.origin.Country.id
        //     }
        //     conditions.push(obj)
        // }
        // if(filterOptions.destination?.Country?.id){
        //     let obj = {
        //         field: 'contracts.destinatione_id',
        //         value: filterOptions.destination.Country.id
        //     }
        //     conditions.push(obj)
        // }
        // if(filterOptions.salestype?.Salestype?.id){
        //     let obj = {
        //         field: 'contracts.salestype_id',
        //         value: filterOptions.salestype.Salestype.id
        //     }
        //     conditions.push(obj)
        // }
        // if(filterOptions.document?.Document?.id){
        //     let obj = {
        //         field: 'documents__id',
        //         value: filterOptions.document.Document.id
        //     }
        //     conditions.push(obj)
        // }
        // if(filterOptions.supplier?.Supplier?.id){
        //     let obj = {
        //         field: 'contracts.supplier_id',
        //         value: filterOptions.supplier.Supplier.id
        //     }
        //     conditions.push(obj)
        // }
        return new Promise((resolve, reject) => {
            if(payload.tableOptions.page != null) {
                api
                    .get("/shipments", {
                        params: {
                            // conditions: conditions,
                            pagination: {
                                skip: (payload.tableOptions.page - 1) * payload.tableOptions.itemsPerPage,
                                take: payload.tableOptions.itemsPerPage
                            },
                            order: [
                                {
                                    field: 'contracts.shipweekdate',
                                    direction: 'ASC'
                                },
                            ]
                        }
                    })
                    .then(response => {
                        commit('setShipments', response.data.data);
                        commit('setTotalShipments', response.data.totalRows);
                        resolve('done')
                    })
                    .catch(error => {
                        reject(error);
                    });
            } else {
                resolve('done')
            }
        })
    },
    getAllShipmentBuyers ({commit}) {
        return new Promise((resolve, reject) => {
            api
                .get("/shipments/buyers",{
                })
                .then(response => {
                    commit('setShipmentBuyers',response.data.data);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getAllShipmentDestinations ({commit}) {
        return new Promise((resolve, reject) => {
            api
                .get("/shipments/destinations",{
                })
                .then(response => {
                    commit('setShipmentDestinations',response.data.data);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getAllShipmentDocuments ({commit}) {
        return new Promise((resolve, reject) => {
            api
                .get("/shipments/documents",{
                })
                .then(response => {
                    commit('setShipmentDocuments',response.data.data);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getAllShipmentOrigins ({commit}) {
        return new Promise((resolve, reject) => {
            api
                .get("/shipments/origins",{
                })
                .then(response => {
                    commit('setShipmentOrigins',response.data.data);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getAllShipmentStatuses ({commit}) {
        return new Promise((resolve, reject) => {
            api
                .get("/shipments/status",{
                })
                .then(response => {
                    commit('setShipmentStatuses',response.data.data);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getAllShipmentSuppliers ({commit}) {
        return new Promise((resolve, reject) => {
            api
                .get("/shipments/suppliers",{
                })
                .then(response => {
                    commit('setShipmentSuppliers',response.data.data);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getAllShipmentTypes ({commit}) {
        return new Promise((resolve, reject) => {
            api
                .get("/shipments/types",{
                })
                .then(response => {
                    commit('setShipmentTypes',response.data.data);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getContainersByShipment ({commit},contract_id) {
        return new Promise((resolve, reject) => {
            api
                .get("/contracts/"+contract_id+"/containers",{

                })
                .then(response => {
                    commit('setCurrentContainers',response.data.data);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                })
        })
    },
    getShipmentById ({commit},contract_id) {
        return new Promise((resolve, reject) => {
            api
                .get("/shipments/" + contract_id,{
                })
                .then(response => {
                    commit('setShipmentToBeUpdated',response.data.data[0]);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getShipmentTracking ({commit},contract_id) {
        return new Promise((resolve, reject) => {
            api
                .get("/shipments/" + contract_id + "/tracking",{
                })
                .then(response => {
                    commit('setTrackingInfo',response.data.data);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    resetAllShipments ({commit}) {
        commit('resetAllShipments')
    },
    searchAllShipments ({commit},payload) {
        // let conditions = []
        // let obj = {
        //     field: payload.search.field,
        //     value: payload.search.value
        // }
        // conditions.push(obj)
        return new Promise((resolve, reject) => {
            api
                .get("/shipments",{
                    params: {
                        conditions: payload.conditions,
                        pagination: {
                            skip: (payload.tableOptions.page - 1) * payload.tableOptions.itemsPerPage,
                            take: payload.tableOptions.itemsPerPage
                            // skip: (payload.tableOptions.page - 1) * -1,
                            // take: -1
                        },
                        order: payload.order
                    }
                })
                .then(response => {
                    commit('setShipments',response.data.data);
                    commit('setTotalShipments',response.data.totalRows);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                })
                .then(()=>{
                    resolve('done')
                })
        })
    },
    sendComplianceDocumentToBuyer({commit}, payload) {
        return new Promise ((resolve, reject) => {
            api
                .put('/shipments/' + payload.contractId + '/send-eutr-to-buyer',{
                    contacts: payload.contacts,
                    documents: payload.documents
                })
                .then(response => {
                    if(response.data.status == 'success') {
                        resolve('done')
                    } else {
                        resolve('error')
                    }
                })
                .catch(error => {
                    reject(error);
                })
        })
    },
    sendCourierNotificationToBuyer({commit}, payload) {
        return new Promise ((resolve, reject) => {
            api
                .put('/shipments/' + payload.contractId + '/send-courier-info-to-buyer',{
                    contacts: payload.contacts,
                    courier_info: payload.courierInfo
                })
                .then(response => {
                    if(response.data.status == 'success') {
                        resolve('done')
                    } else {
                        resolve('error')
                    }
                })
                .catch(error => {
                    reject(error);
                })
        })
    },
    sendShipmentDocumentsToBuyer ({commit}, payload) {
        return new Promise ((resolve, reject) => {
            api
                .put('/shipments/' + payload.contractId + '/send-documents-to-buyer',{
                    contacts: payload.contacts,
                    documents: payload.documents,
                    tmsDocuments: payload.tmsDocuments
                })
                .then(response => {
                    if(response.data.status == 'success') {
                        resolve('done')
                    } else {
                        resolve('error')
                    }
                })
                .catch(error => {
                    reject(error);
                })
        })
    },
    sendDocumentNotificationToFinance ({commit}, payload) {
        return new Promise ((resolve, reject) => {
            api
                .put('/shipments/' + payload.contractId + '/send-notification-to-finance')
                .then(response => {
                    if(response.data.status == 'success') {
                        resolve('done')
                    } else {
                        resolve('error')
                    }
                })
                .catch(error => {
                    reject(error);
                })
        })
    },
    updateShipment ({dispatch, commit, state},val) {
        let containersToUpdate = [];
        state.current.Containers.forEach((container) =>{
            let obj = container.Container
            containersToUpdate.push(obj)
        })
        let shipment = _.cloneDeep(state.current.Shipment)
        let documents = _.cloneDeep(state.current.Shipment.documents)

        // remove unneeded properties
        delete shipment.documents
        delete shipment.Customer
        delete shipment.Supplier

        return new Promise((resolve, reject) => {
            api
                .put("/shipments/" + val ,
                    {
                        shipment: shipment,
                        documents: documents,
                        containers_to_update: containersToUpdate,
                        containers_to_delete: state.current.ContainersToDelete
                    }
                )
                .then(response => {
                    if(response.data.status == 'success') {
                        dispatch('getContainersByShipment',val);
                        resolve('done')
                    } else {
                        resolve('error')
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
}

export const shipment = {
    namespaced,
    state,
    getters,
    actions,
    mutations
}