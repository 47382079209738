import Vue from 'vue'
import Router from 'vue-router'

//routes
import defaultRoutes from './appic';
import printRoutes from  './appic-print';

// session components
const Auth0CallBack = () => import('Components/Auth0Callback/Auth0Callback');
// const LoginAppic = () => import('Views/v1/session/LoginAppic');
const LoginAppicNew = () => import('Views/v1/session/LoginAppicNew');
const NotFound = () => import('Views/v1/pages/NotFound');
const NoAccess = () => import('Views/v1/pages/NoAccess');

//containers
import Full from 'Container/Full';

Vue.use(Router)

export default new Router({
	mode: 'history',
	routes: [
		defaultRoutes,
		printRoutes,
		// {
		// 	path: '/callback',
		// 	component: Auth0CallBack
		// },
		{
			path: '/session/login',
			component: LoginAppicNew,
			meta: {
				pageTitle: 'message.login',
				title: 'message.login',
				breadcrumb: null
			}
		},
		{
			path: "/",
			component: Full,
			meta: {
				requiredAbility: {
					action: null,
					subject: null
				},
				requiresAuth: true
			},
			children: [
				{
					path: '/v1/no-access',
					component: NoAccess,
					meta: {
						pageTitle: 'message.noAccess',
						requiredAbility: {
							action: null,
							subject: null
						},
						requiresAuth: true
					}
				},
				{
					path: '/v1/not-found/:resource?',
					component: NotFound,
					name: 'not_found_main',
					meta: {
						pageTitle: 'message.notFound',
						requiredAbility: {
							action: null,
							subject: null
						},
						requiresAuth: true
					},
					props: true
				},
				{
					path: '/v1',
					component: NotFound,
					name: 'not_found',
					meta: {
						pageTitle: 'message.notFound',
						requiredAbility: {
							action: null,
							subject: null
						},
						requiresAuth: true
					}
				},
				{
					path: '/v1/*',
					component: NotFound,
					name: 'not_found_2',
					meta: {
						pageTitle: 'message.notFound',
						requiredAbility: {
							action: null,
							subject: null
						},
						requiresAuth: true
					},
				},
				{
					path: '*',
					component: NotFound,
					name: 'not_found_3',
					meta: {
						pageTitle: 'message.notFound',
						requiredAbility: {
							action: null,
							subject: null
						},
						requiresAuth: false
					},
				}
			]
		}
	]
})
