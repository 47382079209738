<template>
    <v-footer height="50px" class="px-3 pb-0 pt-0">
        <v-card class="flex" flat tile>
            <v-card-actions class="justify-center">
                <strong class="subheading">Need help? Contact it@apptimber.com</strong>
                <v-btn
                        class="mx-3"
                        icon
                        href="mailto:it@apptimber.com"
                ><v-icon size="24px">ti-email</v-icon></v-btn>
                &copy;{{ new Date().getFullYear() }} <strong>&nbsp;APP Timber Ltd.</strong>
            </v-card-actions>
            <v-btn
                    fab
                    color="warning"
                    fixed
                    bottom
                    right
                    v-scroll-to="{
                        el: '#imatop',
                        container: 'body',
                        duration: 500,
                        easing: 'linear',
                        offset: -200,
                        force: true,
                        x: false,
                        y: true
                    }"
            ><v-icon>ti-arrow-up</v-icon>
            </v-btn>
        </v-card>
    </v-footer>
</template>

<script>
    export default {
        name: "PageFooter",
        data(){
            return {
                page: 1
            }
        },
        methods: {
        }
    }
</script>

<style>
    .scroll-to-top--hidden {
        display: none !important;
    }
</style>