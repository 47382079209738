import { api } from "../../../api";
import {getField, updateField} from "vuex-map-fields";

const getDefaultState = () => {
    return {
        allProductSegments: [],
        allGroupedProductSegments: []
    }
}

const namespaced = {
    namespaced: true
}

const state = {
    allProductSegments: [],
    allGroupedProductSegments: []
}

const getters = {
    getField,
    allGroupedProductSegments: state => {
        return state.allGroupedProductSegments;
    },
    allProductSegments: state => {
        return state.allProductSegments;
    },
}

const actions = {
    getAllProductSegments ({commit}) {
        return new Promise ((resolve, reject) => {
            api
                .get("/marketsegments")
                .then(response => {
                    commit('setAllProductSegments',response.data.data)
                    commit('setAllGroupedProductSegments',response.data.data)
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    resetAllProductSegments ({commit}) {
        commit('resetState')
    }
}

const mutations = {
    resetState (state){
        state.allProductSegments = getDefaultState().allProductSegments
        state.allGroupedProductSegments = getDefaultState().allGroupedProductSegments
    },
    setAllProductSegments (state,data) {
        state.allProductSegments = data;
    },
    setAllGroupedProductSegments (state,data) {
        state.allGroupedProductSegments = data;
    },
    updateField
}

export const productsegment = {
    namespaced,
    state,
    getters,
    actions,
    mutations
}