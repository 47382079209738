import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

// Initialize Firebase
const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY, // Your Api key will be here
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN, // Your auth domain
    databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL, // data base url
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID, // project id
    storageBucket: '', // storage bucket
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID, // messaging sender id
    appId: process.env.VUE_APP_FIREBASE_APP_ID
};

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const database = getDatabase(firebaseApp);

export {
    auth,
    database,
    // recaptchaVerifier
}
