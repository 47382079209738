import {getField, updateField} from "vuex-map-fields";
import _ from 'lodash'
import {api} from "Api";

const namespaced = {
	namespaced: true
}

const getDefaultState = () => {
	return {
		contractScheduleCurrentTab: null,
		dashboardCurrentSalesColleague: null,
		dashboardCurrentTab: null,
		financeDataOverviewCurrentTab: null,
		financeReportsCurrentTab: null,
		fscCurrentAudit: null,
		fscPefcReportCurrentTab: null,
		fscPefcShowNoInvoices: 1,
		fscReportsCurrentTab: null,
		invoicesOverviewCurrentTab: null,
		miscellaneousAdminCurrentTab: null,
		openedTabs: [],
		operationsDataOverviewCurrentTab: null,
		pefcCurrentAudit: null,
		pefcReportsCurrentTab: null,
		salesCommissionsOverviewCurrentTab: null,
		salesDataOverviewCurrentTab: null,
		shippingDocumentsOverviewCurrentTab: null,
		shippingInstructionDocumentsManagerCurrentTab: null,
		shippingOverviewCurrentTab: null,
		specificationsDataOverviewCurrentTab: null,
		stockCurrentWarehouse: null,
		stockReportsCurrentTab: null,
		stockReportsCurrentWarehouse: null,
		updateAvailable: false,
		weeklySalesDetailCurrentTab: null,
		xeroIntegrationsCurrentTab: null
	}
}

const state = {
	contractScheduleCurrentTab: null,
	dashboardCurrentSalesColleague: null,
	dashboardCurrentTab: null,
	financeDataOverviewCurrentTab: null,
	financeReportsCurrentTab: null,
	fscCurrentAudit: null,
	fscPefcReportCurrentTab: null,
	fscPefcShowNoInvoices: 1,
	fscReportsCurrentTab: null,
	invoicesOverviewCurrentTab: null,
	miscellaneousAdminCurrentTab: null,
	openedTabs: [],
	operationsDataOverviewCurrentTab: null,
	pefcCurrentAudit: null,
	pefcReportsCurrentTab: null,
	salesCommissionsOverviewCurrentTab: null,
	salesDataOverviewCurrentTab: null,
	shippingDocumentsOverviewCurrentTab: null,
	shippingInstructionDocumentsManagerCurrentTab: null,
	shippingOverviewCurrentTab: null,
	specificationsDataOverviewCurrentTab: null,
	stockCurrentWarehouse: null,
	stockReportsCurrentTab: null,
	stockReportsCurrentWarehouse: null,
	updateAvailable: false,
	weeklySalesDetailCurrentTab: null,
	xeroIntegrationsCurrentTab: null
}

const getters = {
	getField
}

const mutations = {
	updateField,
	removeOpenedTab(state, tabName){
		let idx = state.openedTabs.indexOf(tabName)
		state.openedTabs.splice(idx, 1)
	}
}

const actions = {
	removeOpenedTab({commit}, tabName) {
		commit('removeOpenedTab',tabName)
	},

	pollMultipleAccessLog({commit}, asset) {
		return new Promise((resolve, reject) => {
			api
				.put('/utilities/multiple-access-log/' + asset.assetId , {
					event_id: asset.eventId,
					model: asset.model
				})
				.then(response => {
					resolve(response.data.data)
				})
				.catch(error => {
					reject(error);
				})
		})
	}
}

export const runtime = {
	namespaced,
	state,
	getters,
	actions,
	mutations
}