import PrintPdf from 'Container/PrintPdf'

//APPiC Print components
const PrintContract = () => import('Views/v1/contracts/PrintContract');
const PrintContractHtml = () => import('Views/v1/contracts/PrintContractHtml');
// const PrintContractProformaInvoice = () => import('Views/v1/contracts/PrintContractProformaInvoice');
const PrintContractProformaInvoiceV2 = () => import('Views/v1/contracts/PrintContractProformaInvoiceV2');
const PrintCoverNote = () => import('Views/v1/covernotes/PrintCoverNote');
const PrintDebitNote = () => import('Views/v1/debitnotes/PrintDebitNote');
const PrintDebitNoteBlob = () => import('Views/v1/debitnotes/PrintDebitNoteBlob');
const PrintDeliveryOrder = () => import('Views/v1/localsales/PrintDeliveryOrder');
const PrintDeliveryOrderV2 = () => import('Views/v1/localsales/PrintDeliveryOrderV2');
const PrintEpr = () => import('Views/v1/eprs/PrintEpr');
const PrintGrossMarginCalculation = () => import('Views/v1/accounts/PrintGrossMargin');
const PrintGrossMarginCalculationBlob = () => import('Views/v1/accounts/PrintGrossMarginBlob');
const PrintInvoice = () => import('Views/v1/invoices/PrintInvoice');
const PrintIpaNew = () => import('Views/v1/ipas/PrintIpaNew');
const PrintLca = () => import('Views/v1/lcas/PrintLca');
const PrintLocalSalesInvoice = () => import('Views/v1/localsales/PrintLocalSalesInvoice');
const PrintLocalSalesInvoiceV2 = () => import('Views/v1/localsales/PrintLocalSalesInvoiceV2');
const PrintNcrNew = () => import('Views/v1/ncrs/PrintNcrNew');
const PrintPackingList = () => import('Views/v1/pldos/PrintPackingList');
const PrintProformaInvoice = () => import('Views/v1/salesorders/PrintProformaInvoice');
const PrintProformaInvoiceV2 = () => import('Views/v1/salesorders/PrintProformaInvoiceV2');
const PrintPurchaseOrder = () => import('Views/v1/purchaseorders/PrintPurchaseOrder');
const PrintPurchaseOrderHtml = () => import('Views/v1/purchaseorders/PrintPurchaseOrderHtml');
const PrintSalesOrder = () => import('Views/v1/salesorders/PrintSalesOrder');
const PrintSalesOrderV2 = () => import('Views/v1/salesorders/PrintSalesOrderV2');
const PrintSda = () => import('Views/v1/sdas/PrintSda');
const PrintWpr = () => import('Views/v1/wprs/PrintWpr');

export default {
    path: '/',
    component: PrintPdf,
    children: [
        {
            path: '/v1/contracts/print/:contractId/:token',
            name: 'print_contract',
            component: PrintContract,
            meta: {
                requiresToken: true,
                printLayout: true,
            },
            props: true
        },
        {
            path: '/v1/contracts/html2pdf/:contractId/:token',
            name: 'print_contract_html',
            component: PrintContractHtml,
            meta: {
                requiresToken: true,
                printLayout: true,
            },
            props: true
        },
        {
            path: '/v1/contracts/proforma/print/:contractId/:token',
            name: 'print_proforma_contract',
            component: PrintContractProformaInvoiceV2,
            meta: {
                requiresToken: true,
                printLayout: true,
            },
            props: true
        },
        {
            path: '/v1/covernotes/print/:coverNoteId/:token',
            name: 'print_covernote',
            component: PrintCoverNote,
            meta: {
                requiresToken: true,
                printLayout: true,
            },
            props: true
        },
        {
            path: '/v1/debitnotes/print/:debitNoteId/:token',
            name: 'print_debitnote',
            component: PrintDebitNote,
            meta: {
                requiresToken: true,
                printLayout: true,
            },
            props: true
        },
        {
            path: '/v1/tax-debitnotes/print/:debitNoteId/:token',
            name: 'print_tax_debitnote',
            component: PrintDebitNote,
            meta: {
                requiresToken: true,
                printLayout: true,
            },
            props: true
        },
        {
            path: '/v1/debitnotes/print-v2/:debitNoteId',
            name: 'print_debitnote',
            component: PrintDebitNoteBlob,
            meta: {
                requiresToken: true,
                printLayout: true,
            },
            props: true
        },
        {
            path: '/v1/tax-debitnotes/print-v2/:debitNoteId',
            name: 'print_tax_debitnote',
            component: PrintDebitNoteBlob,
            meta: {
                requiresToken: true,
                printLayout: true,
            },
            props: true
        },
        {
            path: '/v1/eprs/print/:eprId/:token',
            name: 'print_epr',
            component: PrintEpr,
            meta: {
                requiresToken: true,
                printLayout: true,
            },
            props: true
        },
        {
            path: '/v1/eprs/po/print/:eprId/:token',
            name: 'print_epr_po',
            component: PrintEpr,
            meta: {
                requiresToken: true,
                printLayout: true,
            },
            props: true
        },
        {
            path: '/v1/invoices/print/:invoiceId/:token',
            name: 'print_invoice',
            component: PrintInvoice,
            meta: {
                requiresToken: true,
                printLayout: true,
            },
            props: true
        },
        {
            path: '/v1/gross-margins/print/:source/:grossMarginId/:token',
            name: 'print_gross_margin',
            component: PrintGrossMarginCalculation,
            meta: {
                requiresToken: true,
                printLayout: true,
            },
            props: true
        },
        {
            path: '/v1/gross-margins/print-v2/:source/:grossMarginId',
            name: 'print_gross_margin',
            component: PrintGrossMarginCalculationBlob,
            meta: {
                requiresToken: true,
                printLayout: true,
            },
            props: true
        },
        {
            path: '/v1/ipas/print/:ipaId/:token',
            name: 'print_ipa',
            component: PrintIpaNew,
            meta: {
                requiresToken: true,
                printLayout: true,
            },
            props: true
        },
        {
            path: '/v1/lcas/print/:lcaId/:token',
            name: 'print_lca',
            component: PrintLca,
            meta: {
                requiresToken: true,
                printLayout: true,
            },
            props: true
        },
        {
            path: '/v1/localsales/print/:localSalesInvoiceId/si/:token',
            name: 'print_localsales_si',
            component: PrintLocalSalesInvoice,
            meta: {
                requiresToken: true,
                printLayout: true
            },
            props: true
        },
        {
            path: '/v1/localsales/print-v2/:localSalesInvoiceId/si/:token',
            name: 'print_localsales_si_v2',
            component: PrintLocalSalesInvoiceV2,
            meta: {
                requiresToken: true,
                printLayout: true
            },
            props: true
        },
        {
            path: '/v1/localsales/print/:localSalesInvoiceId/do/:token',
            name: 'print_localsales_do',
            component: PrintDeliveryOrder,
            meta: {
                requiresToken: true,
                printLayout: true
            },
            props: true
        },
        {
            path: '/v1/localsales/print-v2/:localSalesInvoiceId/do/:token',
            name: 'print_localsales_do_v2',
            component: PrintDeliveryOrderV2,
            meta: {
                requiresToken: true,
                printLayout: true
            },
            props: true
        },
        {
            path: '/v1/localsales/print/:localSalesInvoiceId/ro/:token',
            name: 'print_localsales_ro',
            component: PrintDeliveryOrder, //release order has same template as delivery order
            meta: {
                requiresToken: true,
                printLayout: true
            },
            props: true
        },
        {
            path: '/v1/localsales/print-v2/:localSalesInvoiceId/ro/:token',
            name: 'print_localsales_ro_v2',
            component: PrintDeliveryOrderV2, //release order has same template as delivery order
            meta: {
                requiresToken: true,
                printLayout: true
            },
            props: true
        },
        {
            path: '/v1/ncrs/print/:ncrId/:token',
            name: 'print_ncr',
            component: PrintNcrNew,
            meta: {
                requiresToken: true,
                printLayout: true,
            },
            props: true
        },
        {
            path: '/v1/packinglists/print/:packingListId/:token',
            name: 'print_packinglist',
            component: PrintPackingList,
            meta: {
                requiresToken: true,
                printLayout: true,
            },
            props: true
        },
        {
            path: '/v1/purchaseorders/print/:purchaseOrderId/:token',
            name: 'print_purchaseorder',
            component: PrintPurchaseOrder,
            meta: {
                requiresToken: true,
                printLayout: true
            },
            props: true
        },
        {
            path: '/v1/purchaseorders/html2pdf/:purchaseOrderId/:token',
            name: 'print_purchaseorder_html',
            component: PrintPurchaseOrderHtml,
            meta: {
                requiresToken: true,
                printLayout: true
            },
            props: true
        },
        {
            path: '/v1/salesorders/print/:salesOrderId/pi/:token',
            name: 'print_salesorder_pi',
            component: PrintProformaInvoice,
            meta: {
                requiresToken: true,
                printLayout: true,
            },
            props: true
        },
        {
            path: '/v1/salesorders/print-v2/:salesOrderId/pi/:token',
            name: 'print_salesorder_pi_v2',
            component: PrintProformaInvoiceV2,
            meta: {
                requiresToken: true,
                printLayout: true,
            },
            props: true
        },
        {
            path: '/v1/salesorders/print/:salesOrderId/so/:token',
            name: 'print_salesorder_so',
            component: PrintSalesOrder,
            meta: {
                requiresToken: true,
                printLayout: true,
            },
            props: true
        },
        {
            path: '/v1/salesorders/print-v2/:salesOrderId/so/:token',
            name: 'print_salesorder_so_v2',
            component: PrintSalesOrderV2,
            meta: {
                requiresToken: true,
                printLayout: true,
            },
            props: true
        },
        {
            path: '/v1/sdas/print/:sdaId/:token',
            name: 'print_sda',
            component: PrintSda,
            meta: {
                requiresToken: true,
                printLayout: true,
            },
            props: true
        },
        {
            path: '/v1/tax-invoices/print/:invoiceId/:token',
            name: 'print_tax_invoice',
            component: PrintInvoice,
            meta: {
                requiresToken: true,
                printLayout: true,
            },
            props: true
        },
        {
            path: '/v1/converted-invoices/print/:invoiceId/:token',
            name: 'print_converted_invoice',
            component: PrintInvoice,
            meta: {
                requiresToken: true,
                printLayout: true,
            },
            props: true
        },
        {
            path: '/v1/wprs/print/:wprId/:token',
            name: 'print_wpr',
            component: PrintWpr,
            meta: {
                requiresToken: true,
                printLayout: true,
            },
            props: true
        },
    ]
}