import {getField, updateField} from "vuex-map-fields";
import { api } from "../../../api";
import _ from 'lodash'

const namespaced = {
    namespaced: true
}

const getDefaultState = () => {
    return {
        allBuyerPaymentTerms: [],
        allSupplierPaymentTerms: [],
        OtsTerm: {
            default: null,
            dppaid: null,
            fullypaid: null,
        },
        Paymentterm: {
            amountinpercent: null,
            dppaid_id: null,
            dppaydateref_id: null,
            dppayday: null,
            dppayon_id: null,
            dprequired: null,
            fullypaid_id: null,
            grossmargineffect_id: null,
            id: null,
            lc_payment_term: null,
            localsales_only: null,
            model: 'c',
            non_standard: null,
            paydateref_id: null,
            payday: null,
            paymentterm_id: null,
            payon_id: null,
            title: null
        }
    }
}

const state = {
    allBuyerPaymentTerms: [],
    allSupplierPaymentTerms: [],
    current: {
        Paymentterm: {
            amountinpercent: null,
            dppaid_id: null,
            dppaydateref_id: null,
            dppayday: null,
            dppayon_id: null,
            dprequired: null,
            fullypaid_id: null,
            grossmargineffect_id: null,
            id: null,
            lc_payment_term: null,
            localsales_only: null,
            model: 'c',
            non_standard: null,
            paydateref_id: null,
            payday: null,
            paymentterm_id: null,
            payon_id: null,
            title: null
        },
        OtsTerm: {
            default: null,
            dppaid: null,
            fullypaid: null,
        },
    },
    update: {
        Paymentterm: {},
        OtsTerm: {
            default: null,
            dppaid: null,
            fullypaid: null,
        },
    }
}

const getters = {
    allBuyerPaymentTerms: state => {
        return state.allBuyerPaymentTerms;
    },
    allBuyerLocalSalesPaymentTerms: state => {
        return state.allBuyerPaymentTerms.filter (paymentTerm => {
            return paymentTerm.Paymentterm.localsales_only === 1
        })
    },
    allSupplierPaymentTerms: state => {
        return state.allSupplierPaymentTerms;
    },
    findBuyerPaymentTermById: state => {
        return id => state.allBuyerPaymentTerms.find (paymentTerm => {
            return paymentTerm.Paymentterm.id === id
        })
    },
    findSupplierPaymentTermById: state => {
        return id => state.allSupplierPaymentTerms.find (paymentTerm => {
            return paymentTerm.Paymentterm.id === id
        })
    },
    getField
}

const actions = {
    createPaymentTerm ({state, rootState}) {
        let paymentTerm = _.cloneDeep(state.current.Paymentterm)

        return new Promise((resolve, reject) => {
            api
                .post('/paymentterms',{
                        paymentterm: paymentTerm,
                    }
                )
                .then((response)=>{
                    if(response.data.status == 'success') {
                        resolve('done')
                    } else {
                        resolve('error')
                    }
                })
                .catch(error => {
                    reject(error)
                })
                .finally(()=>{
                    resolve('error')
                })
        })
    },
    deletePaymentTermById ({commit}, paymentTermId) {
        return new Promise((resolve, reject) => {
            api
                .delete('/paymentterms/' + paymentTermId)
                .then(response => {
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                })
        })
    },
    getAllPaymentTerms({commit}){
        return new Promise((resolve, reject) => {
            api
                .get("/paymentterms")
                .then(response => {
                    commit('setAllBuyerPaymentTerms',response.data.data.filter(d=>d.Paymentterm.model == 'c'));
                    commit('setAllSupplierPaymentTerms',response.data.data.filter(d=>d.Paymentterm.model == 'p'));
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getAllBuyerContractPaymentTerms( {commit,state} ){
        return state.allBuyerPaymentTerms.filter(d=>d.Paymentterm.localsales_only == '0')
    },
    getCleanedPaymentStatuses (){
        return new Promise((resolve, reject) => {
            api
                .get('/payments/statuses/cleaned')
                .then(response => {
                    resolve(response.data.data)
                })
                .catch(error => {
                    reject(error);
                })
                .finally(()=>{
                    resolve('done')
                })
        })
    },
    getPaymentStatuses (){
        return new Promise((resolve, reject) => {
            api
                .get('/payments/statuses')
                .then(response => {
                    resolve(response.data.data)
                })
                .catch(error => {
                    reject(error);
                })
                .finally(()=>{
                    resolve('done')
                })
        })
    },
    getPaymentTermToUpdateById ({commit}, paymentTermId) {
        return new Promise((resolve, reject) => {
            api
                .get('/paymentterms/' + paymentTermId )
                .then(response => {
                    commit('setPaymentTermToUpdate', response.data.data[0])
                    resolve('done')
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    resetCurrentState ({commit}) {
        commit('resetCurrentState')
    },
    resetPaymentTermToUpdate ({commit}) {
        commit('resetPaymentTermToUpdate')
    },
    resetState({commit}) {
        commit('resetState')
    },
    resetAllPaymentTerms({commit}) {
        commit('resetState')
    },
    updatePaymentTerm ({state}) {
        let paymentTerm = _.cloneDeep(state.update.Paymentterm)

        return new Promise((resolve, reject) => {
            api
                .put("/paymentterms/" + state.update.Paymentterm.id,{
                    paymentterm: paymentTerm
                })
                .then(response => {
                    resolve('done')
                })
                .catch(error => {
                    reject(error)
                })
                .then(()=>{
                    resolve('done')
                });
        })
    }
}

const mutations = {
    setAllBuyerPaymentTerms(state,data){
        state.allBuyerPaymentTerms = data;
    },
    setAllSupplierPaymentTerms(state,data){
        state.allSupplierPaymentTerms = data;
    },
    setPaymentTermToUpdate ( state, paymentterm ){
        state.update.Paymentterm = paymentterm.Paymentterm
        state.update.OtsTerm.default = null
        state.update.OtsTerm.dppaid = null
        state.update.OtsTerm.fullypaid = null
    },
    resetPaymentTermToUpdate (state) {
        state.update.Paymentterm = getDefaultState().Paymentterm
        state.update.OtsTerm = getDefaultState().OtsTerm
    },
    resetCurrentState (state) {
        state.current.Paymentterm = getDefaultState().Paymentterm
        state.current.OtsTerm = getDefaultState().OtsTerm
    },
    resetState(state){
        state.allBuyerPaymentTerms = getDefaultState().allBuyerPaymentTerms
        state.allSupplierPaymentTerms = getDefaultState().allSupplierPaymentTerms
    },
    updateField
}

export const paymentterm = {
    namespaced,
    state,
    getters,
    actions,
    mutations
}