import {getField, updateField} from "vuex-map-fields";
import { api } from "../../../api";
import _ from 'lodash'

const namespaced = {
	namespaced: true
}

const getDefaultState = () => {
	return {
		SalesBudget: {
			id: null,
			contract_date: null,
			destination_id: null,
			salestype_id: null,
			salescontact_id: null,
			total_intake_sales: 0,
			total_intake_margin: 0
		}
	}
}

const state = {
	allSalesBudgets: [],
	current: {
		SalesBudget: {
			id: null,
			contract_date: null,
			destination_id: null,
			salestype_id: null,
			salescontact_id: null,
			total_intake_sales: 0,
			total_intake_margin: 0
		}
	},
	update: {
		SalesBudget: {}
	}
}

const getters = {
	allSalesBudgets: state => {
		return state.allSalesBudgets
	},
	getField
}

const mutations = {
	setAllSalesBudgets(state, data){
		state.allSalesBudgets = data;
	},
	setSalesBudgetToUpdate( state, salesBudget ) {
		state.update.SalesBudget = salesBudget.SalesBudget
	},
	resetCurrentState (state) {
		state.current.SalesBudget = getDefaultState().SalesBudget
	},
	resetSalesBudgetToUpdate (state) {
		state.update.SalesBudget = getDefaultState().SalesBudget
	},
	resetState(state){
		state.allSalesBudgets = []
	},
	updateField
}

const actions = {
	createSalesBudget ( {state} ) {
		let salesBudget = _.cloneDeep(state.current.SalesBudget)
		return new Promise((resolve, reject) => {
			api
				.post('/sales-budgets',{
					salesBudget: salesBudget,
				})
				.then((response)=>{
					if(response.data.status == 'success') {
						resolve('done')
					} else {
						resolve('error')
					}
				})
				.catch(error => {
					reject(error)
				})
				.finally(()=>{
					resolve('error')
				})
		})
	},
	deleteSalesBudgetById ({commit}, salesBudgetId) {
		return new Promise((resolve, reject) => {
			api
				.delete('/sales-budgets/' + salesBudgetId)
				.then(response => {
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
		})
	},
	getAllSalesBudgets ({commit}) {
		return new Promise((resolve, reject) => {
			api
				.get("/sales-budgets")
				.then(response => {
					commit('setAllSalesBudgets',response.data.data);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	getSalesBudgetToUpdateById ( {commit}, salesBudgetId ) {
		return new Promise((resolve, reject) => {
			api
				.get('/sales-budgets/' + salesBudgetId )
				.then(response => {
					commit('setSalesBudgetToUpdate', response.data.data[0])
					resolve('done')
				})
				.catch(error => {
					reject(error)
				})
		})
	},
	resetCurrentState ( {commit} ) {
		commit('resetCurrentState')
	},
	resetSalesBudgetToUpdate ( {commit} ) {
		commit('resetSalesBudgetToUpdate')
	},
	resetState ( {commit} ){
		commit('resetState')
	},
	updateSalesBudget ( {state} ) {
		let salesBudget = _.cloneDeep(state.update.SalesBudget)
		//remove unneeded properties
		delete salesBudget.Contract
		delete salesBudget.SalesContact
		delete salesBudget.SalesGroup
		delete salesBudget.SalesType

		return new Promise((resolve, reject) => {
			api
				.put("/sales-budgets/" + state.update.SalesBudget.id,{
					salesBudget: salesBudget
				})
				.then(response => {
					resolve('done')
				})
				.catch(error => {
					reject(error)
				})
				.then(()=>{
					resolve('done')
				});
		})
	}
}

export const salesbudget = {
	namespaced,
	state,
	getters,
	actions,
	mutations
}