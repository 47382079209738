<template>
    <div>
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: "PrintPdf",
        data() {
            return {
                loading: false
            };
        },
        mounted() {}
    }
</script>

<style scoped>
    .app-default-layout {
        height: 100vh;
    }
</style>