import {getField, updateField} from "vuex-map-fields";
import { api } from "../../../api";
import _ from 'lodash'
import {isNumeric} from "Helpers/helpers";

const namespaced = {
	namespaced: true
}

const getDefaultState = () => {
	return {
		index: 0,
		lineNumber: 1,
		Grade: {
			id: null,
			title: null
		},
		Measurement: {
			id: 1,
			title: 'M3'
		},
		EprItem: {
			amount: 0,
			certification_id: 0,
			cost: 0,
			costprice: 0,
			description: null,
			epr_id: null,
			grade_id: 1924, //N/A
			id: null,
			itemqty: 0,
			length: null,
			mc_id: null,
			measurement_id: 1,
			productgroup_id: null,
			size_id: null,
			size_text: null,
			spec_id: null,
			species_id: null,
			thickness_id: null,
			unitprice: 0,
			width: null
		},
		Size: {
			id: null,
			title: null
		},
		Spec: {
			id: null,
			title: null
		},
		Species: {
			id: null,
			title: null
		},
		Options: {
			grades: [],
			species: [],
			specs: []
		}
	}
}

const state = {
	current: {
		EprItems: [
			{
				index: 0,
				lineNumber: 1,
				Grade: {
					id: null,
					title: null
				},
				Measurement: {
					id: 1,
					title: 'M3'
				},
				EprItem: {
					amount: 0,
					certification_id: 0,
					cost: 0,
					costprice: 0,
					description: null,
					grade_id: 1924, //N/A
					id: null,
					itemqty: 0,
					length: null,
					mc_id: null,
					measurement_id: 1,
					epr_id: null,
					productgroup_id: null,
					size_id: null,
					size_text: null,
					spec_id: null,
					species_id: null,
					thickness_id: null,
					unitprice: 0,
					width: null
				},
				Size: {
					id: null,
					title: null
				},
				Spec: {
					id: null,
					title: null
				},
				Species: {
					id: null,
					title: null
				},
				Options: {
					grades: [],
					species: [],
					specs: []
				}
			}
		],
		itemsTotalAmount: 0,
		itemsTotalCost: 0,
		itemsTotalMargin: 0,
		itemsMarginPct: 0,
		itemsTotalQty: []
	},
	update: {
		EprItems: [
			{
				Grade: {},
				Measurement: {},
				EprItem: {},
				Size: {},
				Spec: {},
				Species: {},
				Options: {
					grades: [],
					species: [],
					specs: []
				}
			}
		],
		itemsTotalAmount: 0,
		itemsTotalCost: 0,
		itemsTotalMargin: 0,
		itemsMarginPct: 0,
		itemsTotalQty: []
	},
}

const getters = {
	getField
}

const mutations = {
	addEprItemRow (state, payload){
		let i = 0;
		let epr = payload.epr
		let row = getDefaultState();
		row.EprItem.epr_id = epr.id
		state[payload.prefix].EprItems.push(row)
		state[payload.prefix].EprItems.forEach((item) => {
			item.index = i
			item.lineNumber = i + 1
			i++
		})
	},
	copyContractFscToEprItems(state, payload) {
		state[payload.prefix].EprItems.forEach((item) => {
			if(item.EprItem.certification_id == null && payload.fsc != null) {
				item.EprItem.certification_id = payload.fsc
			}
		})
	},
	copyPreviousEprFscToEprItems(state, payload) {
		state[payload.prefix].EprItems.forEach((item) => {
			if(item.EprItem.certification_id == null && payload.fsc != null) {
				item.EprItem.certification_id = payload.fsc
			}
		})
	},
	deleteEprItemRow (state, payload) {
		state[payload.prefix].EprItems.splice(payload.index, 1)
		let i = 0;
		state[payload.prefix].EprItems.forEach((item) => {
			item.index = i
			item.lineNumber = i + 1
			i++
		})
	},
	resetState (state, prefix){
		console.log('Resetting EPR items')
		state[prefix].EprItems = []
		state[prefix].EprItems.push(getDefaultState())
		state[prefix].itemsTotalAmount = 0
		state[prefix].itemsTotalCost = 0
		state[prefix].itemsTotalQty = [{Total: {qty: 0.0, uofm: 'M3'}}]
	},
	setContractToEprItems(state, payload) {
		let prefix = payload.prefix
		let items = payload.data

		state[prefix].EprItems = []

		items.forEach((item) => {
			if(item.Salesitem.type == 'default') {
				let newItem = getDefaultState()
				newItem.Grade.id = item.Grade.id
				newItem.Grade.title = item.Grade.title
				newItem.Measurement.id = item.Measurement.id
				newItem.Measurement.title = item.Measurement.title
				newItem.EprItem.amount = 0
				newItem.EprItem.cost = 0

				newItem.EprItem.certification_id = item.Salesitem.certification_id
				newItem.EprItem.description = item.Salesitem.description
				newItem.EprItem.costprice = 0
				newItem.EprItem.grade_id = item.Salesitem.grade_id
				newItem.EprItem.itemqty = 0
				newItem.EprItem.length = item.Salesitem.length
				newItem.EprItem.measurement_id = item.Salesitem.measurement_id
				newItem.EprItem.mc_id = item.Salesitem.mc_id
				newItem.EprItem.productgroup_id = item.Salesitem.productgroup_id
				newItem.EprItem.size_id = item.Salesitem.size_id
				newItem.EprItem.size_text = item.Salesitem.size
				newItem.EprItem.species_id = item.Salesitem.species_id
				newItem.EprItem.thickness_id = item.Salesitem.thickness_id
				newItem.EprItem.unitprice = 0
				newItem.EprItem.width = item.Salesitem.width
				newItem.Size.id = item.Size.id
				newItem.Size.title = item.Size.title
				newItem.Spec.id = item.Spec.id
				newItem.Spec.title = item.Spec.title
				newItem.Species.id = item.Species.id
				newItem.Species.title = item.Species.title
				state[prefix].EprItems.push(newItem)
			}
		})
		let i = 0;
		state[prefix].EprItems.forEach((item) => {
			item.index = i
			item.lineNumber = i + 1
			i++
		})
	},
	setEprItems (state, payload) {
		state[payload.prefix].EprItems = payload.data
		let i = 0;
		state[payload.prefix].EprItems.forEach((item) => {
			item.index = i
			item.lineNumber = i + 1
			//add Options
			item['Options'] = {
				grades: [],
				species: [],
				specs: []
			}
			i++
		})
	},
	setPreviousEprItemsToEprItems(state, payload) {
		let prefix = payload.prefix
		let items = payload.data

		state[prefix].EprItems = []
		items.forEach((item) => {
			let newItem = getDefaultState()
			newItem.Grade.id = item.Grade.id
			newItem.Grade.title = item.Grade.title
			newItem.Measurement.id = item.Measurement.id
			newItem.Measurement.title = item.Measurement.title
			newItem.EprItem.amount = 0
			newItem.EprItem.cost = 0
			newItem.EprItem.certification_id = item.EprItem.certification_id
			newItem.EprItem.description = item.EprItem.description
			newItem.EprItem.costprice = 0
			newItem.EprItem.grade_id = item.EprItem.grade_id
			newItem.EprItem.itemqty = 0
			newItem.EprItem.length = item.EprItem.length
			newItem.EprItem.measurement_id = item.EprItem.measurement_id
			newItem.EprItem.mc_id = item.EprItem.mc_id
			newItem.EprItem.productgroup_id = item.EprItem.productgroup_id
			newItem.EprItem.size_id = item.EprItem.size_id
			newItem.EprItem.size_text = item.EprItem.size
			newItem.EprItem.species_id = item.EprItem.species_id
			newItem.EprItem.thickness_id = item.EprItem.thickness_id
			newItem.EprItem.unitprice = 0
			newItem.EprItem.width = item.EprItem.width
			newItem.Size.id = item.Size.id
			newItem.Size.title = item.Size.title
			newItem.Spec.id = item.Spec.id
			newItem.Spec.title = item.Spec.title
			newItem.Species.id = item.Species.id
			newItem.Species.title = item.Species.title
			state[prefix].EprItems.push(newItem)
		})
		let i = 0;
		state[prefix].EprItems.forEach((item) => {
			item.index = i
			item.lineNumber = i + 1
			i++
		})
	},
	updateField,
	updateEprItems(state, payload) {
		let keys = payload['field'].split('.')
		let prefix = payload['prefix']
		state[prefix].EprItems[payload['index']][keys[0]][keys[1]] = payload['value']
		let numberFields = ['itemqty','costprice','unitprice']
		if(keys[0] == 'EprItem' && numberFields.indexOf(keys[1]) !== -1){
			let amount = parseFloat(state[prefix].EprItems[payload['index']]['EprItem']['itemqty']) * parseFloat(state[prefix].EprItems[payload['index']]['EprItem']['unitprice'])
			let cost = parseFloat(state[prefix].EprItems[payload['index']]['EprItem']['itemqty']) * parseFloat(state[prefix].EprItems[payload['index']]['EprItem']['costprice'])
			state[prefix].EprItems[payload['index']]['EprItem']['amount'] = amount
			state[prefix].EprItems[payload['index']]['EprItem']['cost'] = cost
		}
	},
	updateTotals(state, prefix) {
		let amount =  0, cost = 0, quantities = []
		state[prefix].EprItems.forEach((item) => {
			amount += isNumeric(item.EprItem.cost) ? parseFloat(item.EprItem.amount) : 0
			cost += isNumeric(item.EprItem.cost) ? parseFloat(item.EprItem.cost) : 0
			let idx = quantities.findIndex(t=>t.Total.uofm == item.Measurement.title);
			if(idx !== -1){
				quantities[idx]['Total']['qty'] += isNumeric(item.EprItem.itemqty) ? parseFloat(item.EprItem.itemqty) : 0;
			} else {
				let obj = {'Total': {}}
				obj['Total']['uofm'] = item.Measurement.title;
				obj['Total']['qty'] = isNumeric(item.EprItem.itemqty) ? parseFloat(item.EprItem.itemqty) : 0;
				quantities.push(obj)
			}
		})
		state[prefix].itemsTotalAmount = amount
		state[prefix].itemsTotalCost = cost
		state[prefix].itemsTotalMargin = amount - cost

		if(state[prefix].itemsTotalAmount > 0){
			state[prefix].itemsMarginPct = (state[prefix].itemsTotalMargin/state[prefix].itemsTotalAmount)*100
		} else {
			state[prefix].itemsMarginPct = 0
		}

		state[prefix].itemsTotalQty = quantities
		console.log('updating totals...')
	}
}

const actions = {
	addEprItemRow ({commit},payload){
		commit('addEprItemRow',payload)
	},
	copyContractFscToEprItems({commit},payload){
		commit('copyContractFscToEprItems',payload)
	},
	copyPreviousEprFscToEprItems({commit},payload){
		commit('copyPreviousEprFscToEprItems',payload)
	},
	copyContractItemsToEprItems( {commit},payload ) {
		let contractId = payload.contractId
		let prefix = payload.prefix
		return new Promise((resolve, reject) => {
			api
				.get("/contracts/" + contractId + "/salesitems")
				.then(response => {
					let payload = {
						data: response.data.data,
						prefix: prefix
					}
					commit('setContractToEprItems', payload);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
				.finally(function(){
					commit('updateTotals',prefix);
				});
		})
	},
	copyPreviousEprItemsToEprItems( {commit}, payload ) {
		let eprId = payload.eprId
		let prefix = payload.prefix
		return new Promise((resolve, reject) => {
			api
				.get("/eprs/" + eprId + "/items")
				.then(response => {
					let payload = {
						data: response.data.data,
						prefix: prefix
					}
					commit('setPreviousEprItemsToEprItems', payload);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
				.finally(function(){
					commit('updateTotals',prefix);
				});
		})
	},
	deleteEprItemRow ({commit},payload){
		commit('deleteEprItemRow',payload)
	},
	deleteEprItem({commit},payload) {
		return new Promise((resolve, reject) => {
			let epr_id = payload.epr_id
			let item_id = payload.item_id
			api
				.delete('/eprs/' + epr_id + '/items/' + item_id)
				.then(response => {
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
		})
	},
	getItemsByEpr ({commit}, payload) {
		let epr_id = payload.epr_id
		let prefix = payload.prefix
		return new Promise((resolve, reject) => {
			api
				.get("/eprs/" + epr_id + "/items")
				.then(response => {
					let payload = {
						data: response.data.data,
						prefix: prefix
					}
					commit('setEprItems',payload)
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
				.finally(function(){
					commit('updateTotals',prefix);
				});
		})
	},
	resetEprItems({commit},prefix) {
		return new Promise ((resolve, reject) => {
			commit('resetState',prefix)
			resolve('done')
		})
	},
	updateTotals ({commit},prefix) {
		return new Promise ((resolve, reject) => {
			commit('updateTotals',prefix)
			resolve('done')
		})
	}
}

export const epritem = {
	namespaced,
	state,
	getters,
	actions,
	mutations
}