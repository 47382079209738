/**
 * Auth Module using direct API backend
 */
import Vue from 'vue'
import Nprogress from 'nprogress';
import router from '../../../router';
import { api } from "Api";
import {openDB} from "idb";

const state = {
    user: localStorage.getItem('user'),
    enableResendCode: false,
    sendingMfaCode: false,
    showMfaCodeInput: false,
    startVerificationCodeTimer: false
}

// getters
const getters = {
    enableResendCode: state => {
        return state.enableResendCode;
    },
    getUser: state => {
        return state.user;
    },
    sendingMfaCode: state => {
        return state.sendingMfaCode;
    },
    showMfaCodeInput: state => {
        return state.showMfaCodeInput;
    },
    startVerificationCodeTimer: state => {
        return state.startVerificationCodeTimer;
    }
}

const actions= {
    signInUserToAppic(context, payload) {
        return new Promise((resolve, reject) => {
            const { user } = payload
            api
                .post('/login', {
                    email: payload.user.email,
                    password: payload.user.password
                })
                .then((response) => {
                    const tokens = response.data

                    let userCredential = {
                        apiToken: null,
                        refreshToken: null,
                        sessionId: null,
                        user: {
                            email: null
                        }
                    }
                    userCredential.apiToken = tokens.token
                    userCredential.refreshToken = tokens.refreshToken
                    userCredential.sessionId = tokens.sessionId
                    userCredential.user.email = tokens.userId

                    // user.apiToken = tokens.token
                    // user.refreshToken = tokens.refreshToken
                    // user.sessionId = tokens.sessionId
                    context.commit('loginUserSuccess', userCredential);
                    context.dispatch('updateStore', tokens.tableUpdates)
                    if(tokens.closedSessions > 0) {
                        context.dispatch('updateClosedSessions', tokens.closedSessions)
                    }
                })
                .then(() => {
                    context.dispatch('resetConstantsState')
                    context.dispatch('user/getUserByEmailId', user.email)
                    resolve('login success')
                })
                .catch( async (error) => {
                    //check for 2FA
                    if (error.response?.data?.code == 'multi-factor-auth-required') {
                        context.commit('showSendingMfaCodeIndicator');

                        const mfaAuth = Promise.resolve()

                        return await mfaAuth
                            .then(() => {
                                context.commit('showMfaCodeInputField');
                            })
                            .then(async () => {
                                context.commit('hideSendingMfaCodeIndicator');
                                function continueVerification() {
                                    return new Promise((resolve, reject) => {
                                        const verificationButton = document.getElementById('continue-verification-button');
                                        verificationButton.addEventListener('click', function () {
                                            const userInput = document.getElementById('verification-code-input').value;
                                            if (userInput !== null && userInput !== '') {
                                                resolve(userInput);
                                            } else {
                                                reject('user-empty-input');
                                            }
                                        })

                                        const cancelVerificationButton = document.getElementById('cancel-verification-button');
                                        cancelVerificationButton.addEventListener('click', function () {
                                            context.commit('hideMfaCodeInputField');
                                            reject('user-cancelled-verification');
                                        })
                                    })
                                }

                                let verificationCode = null;

                                const resendCodeButton = document.getElementById('resend-verification-button');
                                if(resendCodeButton){
                                    resendCodeButton.addEventListener('click', function () {
                                        api
                                            .post('/resend-mfa-code', {
                                                email: payload.user.email,
                                                attemptToken: error.response.data.token
                                            })
                                            .then(() => {
                                                context.commit('restartTimer')
                                                context.commit('disableResendCodeLink')
                                            })
                                            .catch(() => {
                                                Vue.prototype.$toast.error('Unable to resend a new code.',
                                                    {
                                                        classes: ['icon-float-left'],
                                                        icon: 'check_circle_outline',
                                                        x: 'center',
                                                        y: 'top'
                                                    }
                                                )
                                            })
                                    })
                                }

                                await continueVerification()
                                    .then((val) => {
                                        verificationCode = val
                                        context.commit('hideMfaCodeInputField');
                                    })
                                    .catch((error) => {
                                        if(error == 'user-cancelled-verification') {
                                            throw new Error('user-cancelled-verification');
                                        } else if(error == 'user-empty-input'){
                                            throw new Error('user-empty-input');
                                        } else {
                                            throw new Error('login-error');
                                        }
                                    })

                                if(verificationCode){
                                    return await api
                                        .post('/login-mfa', {
                                            email: payload.user.email,
                                            password: payload.user.password,
                                            code: verificationCode
                                        })
                                        .then((response) => {
                                            return response.data
                                        })
                                        .catch(error => {
                                            throw new Error('Login error');
                                        });
                                }
                            })
                            .then((tokens) => {
                                let userCredential = {
                                    apiToken: null,
                                    refreshToken: null,
                                    sessionId: null,
                                    user: {
                                        email: null
                                    }
                                }
                                userCredential.apiToken = tokens.token
                                userCredential.refreshToken = tokens.refreshToken
                                userCredential.sessionId = tokens.sessionId
                                userCredential.user.email = tokens.userId
                                context.commit('loginUserSuccess', userCredential);
                                context.dispatch('updateStore', tokens.tableUpdates)
                                if (tokens.closedSessions > 0) {
                                    context.dispatch('updateClosedSessions', tokens.closedSessions)
                                }
                                return userCredential
                            })
                            .then((userCredential) => {
                                context.dispatch('resetConstantsState')
                                context.dispatch('user/getUserByEmailId', userCredential.user.email)
                            })
                            .catch(error => {
                                context.commit('hideSendingMfaCodeIndicator');

                                const verificationCodeInput = document.getElementById('verification-code-input')
                                verificationCodeInput.value = null

                                let errorText = 'Invalid credentials.'
                                if(error.message == 'user-cancelled-verification') errorText = 'Login cancelled.'
                                if(error.message == 'user-empty-input') errorText = 'No code entered.'
                                const errorObj = {
                                    message: errorText,
                                    reload: false
                                }
                                context.commit('loginUserFailure', errorObj);
                                reject(error)
                            });
                    } else if (error?.response?.status == 429) {
                        const errorObj = {
                            message: 'Too many attempts. Try again later.',
                            reload: false
                        }
                        context.commit('loginUserFailure', errorObj);
                        reject(error)
                    } else if (error?.response?.status == 500) {
                        const errorObj = {
                            message: 'Something went wrong.',
                            reload: false
                        }
                        context.commit('loginUserFailure', errorObj);
                        reject(error)
                    } else {
                        const errorObj = {
                            message: 'Invalid credentials.',
                            reload: true
                        }
                        context.commit('loginUserFailure', errorObj);
                        reject(error)
                    }
                })
        })
    },
    logOutUserFromAppic(context) {
        Nprogress.start();
        const deleteRefreshToken = new Promise((resolve, reject) => {
            const user = JSON.parse(localStorage.getItem('user'))
            api
                .delete("/refresh-token/" + user.user.email + '/' + user.refreshToken)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error);
                });
        })
        deleteRefreshToken
            .then(()=>{
                context.commit('user/resetState');
                context.state.user = null
                localStorage.removeItem('user');

                //close all open tabs
                if(context.rootState.appic.runtime.openedTabs.length > 0) {
                    context.rootState.appic.runtime.openedTabs.forEach(tab => {
                        let openTab = window.open('', tab)
                        try {
                            openTab.close()
                        } catch(err){
                            console.log('error')
                        }
                    })
                }

                //delete vuex key from indexedDB
                const deleteVuex = async() => {
                    console.log('Deleting store')
                    const dbName = 'localforage'
                    const storeName = 'keyvaluepairs'
                    const version = 2
                    const db = await openDB(dbName, version, {
                        // upgrade(db, oldVersion, newVersion, transaction) {
                        //     const store = db.createObjectStore(storeName)
                        // }
                    })
                    const tx = await db.transaction(storeName, 'readwrite')
                    const store = await tx.objectStore(storeName)
                    const key = 'vuex'
                    await store.delete(key)
                    await tx.done
                }
                deleteVuex()
                    .then(() => {
                        localStorage.removeItem('tableUpdates')
                        localStorage.removeItem('closedSessions')
                        localStorage.removeItem('vuex')
                        localStorage.removeItem('sessionId')
                        try {
                            let openerWindow = window.opener
                            if(openerWindow){
                                openerWindow.close()
                            }
                        } catch (err){
                            // console.log(err)
                        }
                        location.reload()
                    })
            })
            .catch(() => {
                context.commit('logOutUserFailure', 'Logout failed.');
            });
    },
    updateStore(context, tableUpdates) {
        localStorage.setItem('tableUpdates',JSON.stringify(tableUpdates))
    },
    updateStores(context, tableUpdates) {
        const currentTableUpdates = JSON.parse(localStorage.getItem('tableUpdates'))
        const currentAgents = currentTableUpdates?.agents
        const currentBankAccounts = currentTableUpdates?.bankaccounts
        const currentBuyers = currentTableUpdates?.customers
        const currentCertifications = currentTableUpdates?.certifications
        const currentCodeVersion = currentTableUpdates?.code_version
        const currentConstants = currentTableUpdates?.constants
        const currentContracts = currentTableUpdates?.contracts
        const currentCountries = currentTableUpdates?.countries
        const currentDoctypes = currentTableUpdates?.doctypes
        const currentGrades = currentTableUpdates?.grades
        const currentInvoices = currentTableUpdates?.invoices
        const currentIpas = currentTableUpdates?.ipas
        const currentLcas = currentTableUpdates?.lcas
        const currentMoistureContent = currentTableUpdates?.mcs
        const currentPaymentstatements = currentTableUpdates?.paymentstatements
        const currentPackinglists = currentTableUpdates?.pldos
        const currentProductGroups = currentTableUpdates?.productgroups
        const currentPurchaseorders = currentTableUpdates?.purchaseorders
        const currentSdas = currentTableUpdates?.sdas
        const currentSizes = currentTableUpdates?.sizes
        const currentShippingports = currentTableUpdates?.shippingports
        const currentSpecs = currentTableUpdates?.specs
        const currentSpecies = currentTableUpdates?.species
        const currentSuppliers = currentTableUpdates?.suppliers
        const currentThickness = currentTableUpdates?.thicknesses
        const currentTtas = currentTableUpdates?.ttas
        const currentWprs = currentTableUpdates?.wprs
        const currentStandardAssetDocument = currentTableUpdates?.standard_asset_documents

        // check agents
        if (currentAgents != currentTableUpdates?.agents) {
            context.commit('agent/resetState')
            context.dispatch('agent/getAllAgents')
        }
        // check bankaccounts
        if(currentBankAccounts != tableUpdates?.bankaccounts){
            context.commit('bankaccount/resetState')
            context.dispatch('bankaccount/getAllBankAccounts')
            context.dispatch('bankaccount/getAllSupplierBankAccounts')
        }
        // check buyers
        if(currentBuyers != tableUpdates?.customers){
            context.commit('buyer/resetState')
            context.dispatch('buyer/getAllActiveBuyers')
        }
        // check constants
        if (currentConstants != tableUpdates?.constants) {
            context.commit('resetConstantsState')
        }
        // check countries
        if(currentCountries != tableUpdates?.countries){
            context.commit('country/resetState')
            context.dispatch('country/getAllCountries')
        }
        // check doctypes
        if (currentDoctypes != tableUpdates?.doctypes) {
            context.commit('document/resetState')
        }
        //check contracts
        if(currentContracts != tableUpdates?.contracts){
            context.commit('contract/resetState')
            context.dispatch('contract/getAllActiveContracts')
        }
        //check grades
        if(currentGrades != tableUpdates?.grades){
            context.commit('grade/resetState')
            context.dispatch('grade/getAllGrades')
        }
        //check invoices
        if(currentInvoices != tableUpdates?.invoices){
            context.commit('invoice/resetState')
            context.dispatch('invoice/getAllActiveInvoices')
        }
        //check ipas
        if(currentIpas != tableUpdates?.ipas){
            context.commit('ipa/resetState')
            context.dispatch('ipa/getAllActiveIpas')
        }
        //check lcas
        if(currentLcas != tableUpdates?.lcas){
            context.commit('lca/resetState')
            context.dispatch('lca/getAllActiveLcas')
        }
        //check mcs
        if(currentMoistureContent != tableUpdates?.mcs){
            context.commit('mc/resetState')
            context.dispatch('mc/getAllMoistureContent')
        }
        //check paymentstatements
        if(currentPaymentstatements != tableUpdates?.paymentstatements){
            context.commit('paymentterm/resetState')
            context.dispatch('paymentterm/getAllPaymentTerms')
        }
        //check pldos
        if(currentPackinglists != tableUpdates?.pldos){
            context.commit('packinglist/resetState')
            context.dispatch('packinglist/getAllActivePackingLists')
        }
        //check productgroups
        if(currentProductGroups != tableUpdates?.productgroups){
            context.commit('productgroup/resetState')
            context.dispatch('productgroup/getAllProductGroups')
        }
        //check purchaseorders
        if(currentPurchaseorders != tableUpdates?.purchaseorders){
            context.commit('purchaseorder/resetState')
            context.dispatch('purchaseorder/getAllActivePurchaseOrders')
        }
        //check shipping instructions
        if(currentSdas != tableUpdates?.sdas){
            context.commit('sda/resetState')
            context.dispatch('sda/getAllActiveSdas')
        }
        //check sizes
        if(currentSizes != tableUpdates?.sizes){
            context.commit('size/resetState')
            context.dispatch('size/getAllSizes')
        }
        //check shipping ports
        if(currentShippingports != tableUpdates?.shippingports){
            context.commit('shippingport/resetState')
            context.dispatch('shippingport/getAllShippingPorts')
        }
        //check species
        if(currentSpecies != tableUpdates?.species){
            context.commit('species/resetState')
            context.dispatch('species/getAllSpecies')
        }
        //check specs
        if(currentSpecs != tableUpdates?.specs){
            context.commit('spec/resetState')
            context.dispatch('spec/getAllSpecs')
        }
        //check suppliers
        if(currentSuppliers != tableUpdates?.suppliers){
            context.commit('supplier/resetState')
            context.dispatch('supplier/getAllSuppliers')
        }
        //check thickness
        if(currentThickness != tableUpdates?.thicknesses){
            context.commit('thickness/resetState')
            context.dispatch('thickness/getAllThickness')
        }
        //check cover notes/ttas
        if (currentTtas != tableUpdates?.ttas) {
            context.commit('covernote/resetState')
            context.dispatch('covernote/getAllCoverNotes')
        }
        //check warehouse purchase requests/wprs
        if (currentWprs != tableUpdates?.wprs) {
            context.commit('wpr/resetState')
            context.dispatch('wpr/getAllActiveWprs')
        }
        //check standard asset documents
        if(currentStandardAssetDocument != tableUpdates?.standard_asset_documents){
            context.commit('document/resetStandardShipmentDocumentState')
            context.dispatch('document/getAllStandardShippingDocuments')
        }
        // update localstorage
        localStorage.setItem('tableUpdates',JSON.stringify(tableUpdates))
    },
    resetStores(context) {
        //reset agents
        context.commit('agent/resetState')
        context.dispatch('agent/getAllAgents')

        //reset bankaccounts
        context.commit('bankaccount/resetState')
        context.dispatch('bankaccount/getAllBankAccounts')
        context.dispatch('bankaccount/getAllSupplierBankAccounts')

        //reset buyers
        context.commit('buyer/resetState')
        context.dispatch('buyer/getAllActiveBuyers')

        //reset constants
        //context.commit('resetConstantsState')

        //reset countries
        context.commit('country/resetState')
        context.dispatch('country/getAllCountries')

        //reset doctypes
        context.commit('document/resetState')

        //reset contracts
        context.commit('contract/resetState')
        context.dispatch('contract/getAllActiveContracts')

        //reset grades
        context.commit('grade/resetState')
        context.dispatch('grade/getAllGrades')

        //reset invoices
        context.commit('invoice/resetState')
        context.dispatch('invoice/getAllActiveInvoices')

        //reset ipas
        context.commit('ipa/resetState')
        context.dispatch('ipa/getAllActiveIpas')

        //reset lcas
        context.commit('lca/resetState')
        context.dispatch('lca/getAllActiveLcas')

        //reset mcs
        context.commit('mc/resetState')
        context.dispatch('mc/getAllMoistureContent')

        //reset paymentstatements
        context.commit('paymentterm/resetState')
        context.dispatch('paymentterm/getAllPaymentTerms')

        //reset pldos
        context.commit('packinglist/resetState')
        context.dispatch('packinglist/getAllActivePackingLists')

        //reset productgroups
        context.commit('productgroup/resetState')
        context.dispatch('productgroup/getAllProductGroups')

        //reset purchaseorders
        context.commit('purchaseorder/resetState')
        context.dispatch('purchaseorder/getAllActivePurchaseOrders')

        //reset shipping instructions
        context.commit('sda/resetState')
        context.dispatch('sda/getAllActiveSdas')

        //reset sizes
        context.commit('size/resetState')
        context.dispatch('size/getAllSizes')

        //check shipping ports
        context.commit('shippingport/resetState')
        context.dispatch('shippingport/getAllShippingPorts')

        //reset species
        context.commit('species/resetState')
        context.dispatch('species/getAllSpecies')

        //reset specs
        context.commit('spec/resetState')
        context.dispatch('spec/getAllSpecs')

        //reset suppliers
        context.commit('supplier/resetState')
        context.dispatch('supplier/getAllSuppliers')

        //reset thickness
        context.commit('thickness/resetState')
        context.dispatch('thickness/getAllThickness')

        //reset cover notes/ttas
        context.commit('covernote/resetState')
        context.dispatch('covernote/getAllCoverNotes')

        //reset warehouse purchase requests/wprs
        context.commit('wpr/resetState')
        context.dispatch('wpr/getAllActiveWprs')

        //reset standard asset documents
        context.commit('document/resetStandardShipmentDocumentState')
        context.dispatch('document/getAllStandardShippingDocuments')

        localStorage.setItem('storesUpdated','1');
    },
    updateClosedSessions(context, closedSessions) {
        localStorage.setItem('closedSessions', closedSessions)
    }
}

const mutations = {
    disableResendCodeLink(state) {
        state.enableResendCode = false
    },
    enableResendCodeLink(state) {
        state.enableResendCode = true
    },
    hideMfaCodeInputField(state) {
        state.showMfaCodeInput = false
    },
    hideSendingMfaCodeIndicator(state) {
        state.sendingMfaCode = false
    },
    loginUser(state) {
        Nprogress.start();
    },
    loginUserFailure(state, errorObj) {
        Nprogress.done();
        Vue.prototype.$toast.error(errorObj.message,
            {
                classes: ['icon-float-left'],
                icon: 'check_circle_outline',
                x: 'center',
                y: 'top'
            }
        )
        if(errorObj.reload) {
            setTimeout(function () {
                window.location.replace('/session/login')
            }, 1000)
        }
    },
    loginUserSuccess(state, user) {
        state.user = user;
        localStorage.setItem('storesUpdated','0');
        localStorage.setItem('user',JSON.stringify(user));
        localStorage.removeItem('sessionExpired');
        // state.isUserSignInWithAuth0 = false
        if(localStorage.getItem('lastRoute') != null && localStorage.getItem('lastRoute').length > 0){
            router.push(localStorage.getItem('lastRoute'))
        } else {
            router
                .push("/v1/dashboard/standard")
                .catch(err => {
                    console.log('error')
                })
        }
        setTimeout(function(){
            Vue.prototype.$toast.success('Logged in.',
                {
                    timeout: 6000,
                    classes: ['icon-float-left'],
                    icon: 'check_circle_outline',
                    x: 'center',
                    y: 'top'
                }
            )
            //empty last route
            localStorage.removeItem('lastRoute')
        },500);
    },
    logOutUser( state ) {
        state.user = null
        localStorage.removeItem('storesUpdated');
        localStorage.removeItem('user');
        const deleteVuex = async() => {
            console.log('deleting vuex')
            const dbName = 'localforage'
            const storeName = 'keyvaluepairs'
            const version = 2
            const db = await openDB(dbName, version, {})
            const tx = await db.transaction(storeName, 'readwrite')
            const store = await tx.objectStore(storeName)
            const key = 'vuex'
            await store.delete(key)
            await tx.done
        }
        deleteVuex();
    },
    logOutUserFailure(state, error) {
        Nprogress.done();
        Vue.prototype.$toast.error(error,
            {
                classes: ['icon-float-left'],
                icon: 'check_circle_outline',
                x: 'center',
                y: 'top'
            }
        )
    },
    resetTimer(state) {
        state.startVerificationCodeTimer = false
    },
    restartTimer(state) {
        state.startVerificationCodeTimer = true
    },
    showSendingMfaCodeIndicator(state) {
        state.sendingMfaCode = true
    },
    showMfaCodeInputField(state) {
        state.showMfaCodeInput = true
    },
    updateUserToken(state, user) {
        state.user = user;
        localStorage.setItem('user',JSON.stringify(user));
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}