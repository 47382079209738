import { api } from "../../../api";
import {getField, updateField} from "vuex-map-fields";
import {isNumeric, log} from "../../../helpers/helpers";

const namespaced = {
    namespaced: true
}

const getOtherChargesRow = () => {
    return {
        index: 0,
        lineNumber: 1,
        Certification: {
            title: null
        },
        Grade: {
            id: 39,
            title: 'Other Charges'
        },
        Mc: {
            id: null,
            title: ''
        },
        Measurement: {
            id: null,
            title: '',
        },
        Salesdescription: {
            id: null,
            spec_id: null,
            species_id: null,
            title: ''
        },
        Salesitem: {
            amount: null,
            comm_pct: null,
            comm_unit: null,
            grade_id: 39,
            id: null,
            invoiceamount: 0,
            invoiceamount_converted: 0,
            invprice: null,
            invprice_converted: 0,
            invqty: 1,
            itemqty: null,
            measurement_id: null,
            othercharge: 1,
            pograde_id: null,
            poqty: 1,
            salesdescription_id: 32940,
            size_id: null,
            type: 'othercharge',
            unitmargin: null,
            unitprice: null
        },
        Size: {
            id: null,
            title: null
        },
        Spec: {
            id: null,
            title: ''
        },
        Species: {
            id: null,
            title: null
        },
        type: 'othercharge'
    }
}

const getDefaultState = () => {
    return {
        index: 0,
        lineNumber: 1,
        Salesitem: {},
        type: 'default'
    }
}

const state = {
    current: {
        Salesitems: [],
        commissionByUnit: false,
        itemsCurrencyRate: 1,
        itemsTotalAmount: 0,
        itemsTotalAmountConverted: 0,
        itemsTotalCommission: 0,
        itemsTotalCost: 0,
        itemsTotalMargin: 0,
        itemsTotalQty: [],
        itemsTotalTax: 0,
        itemsTotalTaxConverted: 0,
        itemsGrandTotal: 0,
        itemsGrandTotalConverted: 0,
        gstpercent: 0,
        specificationNotes: ''
    },
    update: {
        Salesitems: [],
        commissionByUnit: false,
        itemsCurrencyRate: 1,
        itemsTotalAmount: 0,
        itemsTotalAmountConverted: 0,
        itemsTotalCommission: 0,
        itemsTotalCost: 0,
        itemsTotalMargin: 0,
        itemsTotalQty: [],
        itemsTotalTax: 0,
        itemsTotalTaxConverted: 0,
        itemsGrandTotal: 0,
        itemsGrandTotalConverted: 0,
        gstpercent: 0,
        specificationNotes: ''
    }
}

const getters = {
    getField,
    // itemsCurrencyRate: state => {return state.current.itemsCurrencyRate },
    // itemsTotalAmount: state => { return state.current.itemsTotalAmount },
    // itemsTotalAmountConverted: state => { return state.current.itemsTotalAmountConverted },
    // itemsTotalCommission: state => { return state.current.itemsTotalCommission },
    // itemsTotalCost: state => { return state.current.itemsTotalCost },
    // itemsTotalMargin: state => { return state.current.itemsTotalMargin },
    // itemsTotalQty: state => { return state.current.itemsTotalQty },
    // itemsTotalTax: state => { return state.current.itemsTotalTax },
    // itemsTotalTaxConverted: state => { return state.current.itemsTotalTaxConverted },
    // itemsGrandTotal: state => { return state.current.itemsGrandTotal },
    // itemsGrandTotalConverted: state => { return state.current.itemsGrandTotalConverted },
    // commissionByUnit: state => {return state.current.commissionByUnit},
    // specificationNotes: state => {return state.current.specificationNotes}
}

const mutations = {
    addOtherChargesItemRow (state, prefix) {
        let i = 0;
        state[prefix].Salesitems.push(getOtherChargesRow())
        state[prefix].Salesitems.forEach((item) => {
            item.index = i
            item.lineNumber = i + 1
            i++
        })
    },
    convertPriceAndAmount(state, prefix) {
        state[prefix].Salesitems.forEach((item, index) => {
            state[prefix].Salesitems[index]['Salesitem']['invprice_converted'] = parseFloat(state[prefix].Salesitems[index]['Salesitem']['invprice']) * state[prefix].itemsCurrencyRate
            state[prefix].Salesitems[index]['Salesitem']['invoiceamount_converted'] = parseFloat(state[prefix].Salesitems[index]['Salesitem']['invqty']) * (parseFloat(state[prefix].Salesitems[index]['Salesitem']['invprice']) * state[prefix].itemsCurrencyRate)
        })
    },
    deleteInvoiceSalesItemRow (state, payload) {
        state[payload.prefix].Salesitems.splice(payload.index, 1)
        let i = 0;
        state[payload.prefix].Salesitems.forEach((item) => {
            item.index = i
            item.lineNumber = i + 1
            i++
        })
    },
    resetState (state, prefix){
        console.log('Resetting invoice sales items...')
        state[prefix].Salesitems = []
        // state[prefix].Salesitems.push(getDefaultState())
        state[prefix].itemsCurrencyRate = 1
        state[prefix].itemsTotalAmount = 0
        state[prefix].itemsTotalAmountConverted = 0
        state[prefix].itemsTotalCommission = 0
        state[prefix].itemsTotalCost = 0
        state[prefix].itemsTotalMargin = 0
        state[prefix].itemsTotalQty = []
        state[prefix].itemsTotalTax = 0
        state[prefix].itemsTotalTaxConverted = 0
        state[prefix].itemsGrandTotal = 0
        state[prefix].itemsGrandTotalConverted = 0
        state[prefix].gstPercent = 0
        state[prefix].commissionByUnit = false
        state[prefix].specificationNotes = ''
    },
    // setCurrentSalesItems (state,data) {
    //     state.current.Salesitems = data;
    //     let i = 0;
    //     state.current.Salesitems.forEach((item,index)=>{
    //         state.current.Salesitems[index]['index'] = i;
    //         state.current.Salesitems[index]['lineNumber'] = i + 1;
    //         i++
    //     })
    // },
    setInvoiceItems (state,payload) {
        state[payload.prefix].Salesitems = payload.data
        let i = 0;
        state[payload.prefix].Salesitems.forEach((item) => {
            item.index = i
            item.lineNumber = i + 1
            i++
        })
    },
    updateField,
    updateSalesItems (state, payload){
        let keys = payload['field'].split('.')
        let prefix = payload['prefix']
        state[prefix].Salesitems[payload['index']][keys[0]][keys[1]] = payload['value']
        let numberFields = ['invqty','invprice','invoiceamount']
        if(keys[0] == 'Salesitem' && numberFields.indexOf(keys[1]) !== -1){
            //update amount
            if(state[prefix].Salesitems[payload['index']]['type'] == 'default') {
                let amount = parseFloat(state[prefix].Salesitems[payload['index']]['Salesitem']['invqty']) * parseFloat(state[prefix].Salesitems[payload['index']]['Salesitem']['invprice'])
                state[prefix].Salesitems[payload['index']]['Salesitem']['invoiceamount'] = amount
            }
            //update converted fields
            if(state[prefix].Salesitems[payload['index']]['type'] == 'default') {
                // state[prefix].Salesitems[payload['index']]['Salesitem']['invprice_converted'] = parseFloat(state[prefix].Salesitems[payload['index']]['Salesitem']['invprice']) * state[prefix].itemsCurrencyRate
                // state[prefix].Salesitems[payload['index']]['Salesitem']['invoiceamount_converted'] = parseFloat(state[prefix].Salesitems[payload['index']]['Salesitem']['invqty']) * (parseFloat(state[prefix].Salesitems[payload['index']]['Salesitem']['invprice']) * state[prefix].itemsCurrencyRate)
                let invoicePriceConverted = Math.round(((parseFloat(state[prefix].Salesitems[payload['index']]['Salesitem']['invprice']) * state[prefix].itemsCurrencyRate) + Number.EPSILON) * 100) / 100
                state[prefix].Salesitems[payload['index']]['Salesitem']['invprice_converted'] = invoicePriceConverted
                state[prefix].Salesitems[payload['index']]['Salesitem']['invoiceamount_converted'] = parseFloat(state[prefix].Salesitems[payload['index']]['Salesitem']['invqty']) * invoicePriceConverted
            } else if(state[prefix].Salesitems[payload['index']]['type'] == 'othercharge') {
                state[prefix].Salesitems[payload['index']]['Salesitem']['invprice_converted'] = 0;
                // state[prefix].Salesitems[payload['index']]['Salesitem']['invoiceamount_converted'] = parseFloat(state[prefix].Salesitems[payload['index']]['Salesitem']['invoiceamount']) * state[prefix].itemsCurrencyRate
                state[prefix].Salesitems[payload['index']]['Salesitem']['invoiceamount_converted'] = Math.round(((parseFloat(state[prefix].Salesitems[payload['index']]['Salesitem']['invoiceamount']) * state[prefix].itemsCurrencyRate) + Number.EPSILON) * 100) / 100
            }
        }
    },
    updateTotals (state,prefix) {
        let amount = 0, amountConverted = 0, tax = 0, taxConverted = 0, grandTotal = 0, grandTotalConverted = 0, quantities = []
        state[prefix].Salesitems.forEach((item) => {
            if(item.type == 'credit') {
                amount -= isNumeric(item.Salesitem.invoiceamount) ? parseFloat(item.Salesitem.invoiceamount) : 0;
                amountConverted -= isNumeric(item.Salesitem.invoiceamount_converted) ? parseFloat(item.Salesitem.invoiceamount_converted) : 0;
            } else {
                amount += isNumeric(item.Salesitem.invoiceamount) ? parseFloat(item.Salesitem.invoiceamount) : 0;
                amountConverted += isNumeric(item.Salesitem.invoiceamount_converted) ? parseFloat(item.Salesitem.invoiceamount_converted) : 0;
            }
            if(item.type == 'default') {
                let idx = quantities.findIndex(t => t.Total.uofm == item.Measurement.title);
                if (idx !== -1) {
                    quantities[idx]['Total']['qty'] += isNumeric(item.Salesitem.invqty) ? parseFloat(item.Salesitem.invqty) : 0;
                } else {
                    let obj = {'Total': {}}
                    obj['Total']['uofm'] = item.Measurement.title;
                    obj['Total']['qty'] = isNumeric(item.Salesitem.invqty) ? parseFloat(item.Salesitem.invqty) : 0;
                    quantities.push(obj)
                }
            }
        })
        state[prefix].itemsTotalAmount = amount
        state[prefix].itemsTotalAmountConverted = amountConverted
        tax = amount * state[prefix].gstpercent/100
        taxConverted = amountConverted * state[prefix].gstpercent/100
        state[prefix].itemsTotalTax = tax
        state[prefix].itemsTotalTaxConverted = taxConverted
        grandTotal = amount + tax
        grandTotalConverted = amountConverted + taxConverted
        state[prefix].itemsGrandTotal = grandTotal
        state[prefix].itemsGrandTotalConverted = grandTotalConverted
        state[prefix].itemsTotalQty = quantities
        console.log('updating totals...')
    }
}

const actions = {
    addOtherChargesItemRow ({commit}, prefix) {
        commit('addOtherChargesItemRow', prefix)
    },
    convertPriceAndAmount ({commit},prefix) {
        console.log('Converting')
        commit('convertPriceAndAmount',prefix)
        commit('updateTotals',prefix)
    },
    deleteInvoiceSalesItemRow ({commit},payload){
        commit('deleteInvoiceSalesItemRow',payload)
    },
    deleteInvoiceItem ({commit},payload){
        return new Promise((resolve, reject) => {
            let contract_id = payload.contract_id
            let salesitem_id = payload.salesitem_id
            api
                .delete('/contracts/' + contract_id + '/salesitems/' + salesitem_id)
                .then(response => {
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                })
        })
    },
    getSalesItemsByContract ({commit},payload) {
        // commit('resetState')
        let contract_id = payload.contract_id
        let prefix = payload.prefix
        let module = 'invoices'
        // let module = 'contracts'
        // if(prefix == 'update'){
        //    module = 'invoices'
        // }
        return new Promise((resolve, reject) => {
            api
                .get("/" + module + "/" + contract_id + "/salesitems")
                .then(response => {
                    let payload = {
                        data: response.data.data,
                        prefix: prefix
                    }
                    commit('setInvoiceItems',payload);
                    resolve('done');
                })
                .catch(error => {
                    reject(error);
                })
                .finally(function(){
                    commit('updateTotals',prefix);
                });
        })
    },
    // getSalesItemsByInvoice ({commit},invoice_id) {
    //     commit('resetState')
    //     return new Promise((resolve, reject) => {
    //         api
    //             .get("/invoice/"+invoice_id+"/salesitems",{
    //
    //             })
    //             .then(response => {
    //                 commit('setCurrentSalesItems',response.data.data);
    //                 resolve('done');
    //             })
    //             .catch(error => {
    //                 reject(error);
    //             })
    //             .then(function(){
    //                 commit('updateTotals',prefix);
    //             });
    //     })
    // },
    resetInvoiceSalesItems ({commit},prefix) {
        return new Promise ((resolve, reject) => {
            commit('resetState',prefix)
            resolve('done')
        })
    },
    updateTotals ({commit},prefix) {
        console.log('updating totals...')
        commit('updateTotals',prefix)
    }
}

export const invoicesalesitem = {
    namespaced,
    state,
    getters,
    mutations,
    actions
}