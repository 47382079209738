import {getField, updateField} from "vuex-map-fields";
import { api } from "../../../api";
import _ from 'lodash'
// import {log} from "Helpers/helpers";

const namespaced = {
	namespaced: true
}

const getDefaultState = () => {
	return {
		selectedPage: null,
		HelpPage: {
			id: null,
			ability_filter: null,
			component: null,
			title: null,
			group: [],
			html: null,
			sort_id: 1,
			author_id: null
		},
		TableOfContents: []
	}
}

const state = {
	selectedPage: null,
	current: {
		HelpPage: {
			id: null,
			ability_filter: null,
			component: null,
			title: null,
			html: null,
			group: [],
			sort_id: 1,
			author_id: null
		},
		TableOfContents: []
	},
	update: {
		HelpPage: {},
		TableOfContents: []
	},
}

const getters = {
	getField
}

const mutations = {
	setTableOfContents (state, tableOfContents) {
		state.current.TableOfContents = tableOfContents
	},
	setHelpPage (state, helpPage) {
		state.current.HelpPage = helpPage.HelpPage
		state.update.HelpPage = helpPage.HelpPage
	},
	resetCurrentState (state) {
		state.current.HelpPage = getDefaultState().HelpPage
	},
	resetState(state){
		state.allHelpPages = getDefaultState().allHelpPages
	},
	updateField
}

const actions = {
	getHelpPageById ({commit}, helpPageId) {
		return new Promise((resolve, reject) => {
			api
				.get('/admin/help-page/' + helpPageId )
				.then(response => {
					commit('setHelpPage', response.data.data[0]);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
				.finally(()=>{
					resolve('done')
				})
		})
	},
	getAdminTableOfContents ( {commit} ) {
		return new Promise((resolve, reject) => {
			api
				.get('/admin/help-pages/admin-table-of-contents')
				.then(response => {
					commit('setTableOfContents', response.data.data);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
				.finally(()=>{
					resolve('done')
				})
		})
	},
	getHelpPageSetupOptions ( {commit} ) {
		return new Promise((resolve, reject) => {
			api
				.get('/admin/help-page/setup/options')
				.then(response => {
					resolve(response.data.data)
				})
				.catch(error => {
					reject(error);
				})
				.finally(()=>{
					resolve('done')
				})
		})
	},
	getUserTableOfContents ( {commit} ) {
		return new Promise((resolve, reject) => {
			api
				.get('/admin/help-pages/user-table-of-contents')
				.then(response => {
					commit('setTableOfContents', response.data.data);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
				.finally(()=>{
					resolve('done')
				})
		})
	},
	resetCurrentState ({commit}) {
		commit('resetCurrentState')
	},
	resetState ({commit}){
		commit('resetState')
	},
	updateHelpPage ( {state} ) {
		let helpPage = _.cloneDeep(state.update.HelpPage)
		return new Promise((resolve, reject) => {
			api
				.put("/admin/help-page/" + state.update.HelpPage.id,{
					helpPage: helpPage,
				})
				.then(response => {
					resolve('done')
				})
				.catch(error => {
					reject(error)
				})
				.then(()=>{
					resolve('done')
				});
		})
	},
	updateHelpPageSetup ( {state} ) {
		return new Promise((resolve, reject) => {
			let setup = {
				title: state.update.HelpPage.title,
				component: state.update.HelpPage.component,
				group: state.update.HelpPage.group,
				ability_filter: state.update.HelpPage.ability_filter,
				sort_id: state.update.HelpPage.sort_id
			}
			api
				.put("/admin/help-page/" + state.update.HelpPage.id + "/setup",{
					setup: setup,
				})
				.then(response => {
					resolve('done')
				})
				.catch(error => {
					reject(error)
				})
				.then(()=>{
					resolve('done')
				});
		})
	}
}

export const help = {
	namespaced,
	state,
	getters,
	actions,
	mutations
}