// import { Ability } from "@casl/ability";
//
// export const ability = new Ability()
//
// export const abilityPlugin = (store)=> {
//
// 	ability.update(store.state.rules)
//
// 	return store.subscribe((mutation) => {
// 		switch (mutation.type){
// 			case 'user/setUser':
// 				ability.update(mutation.payload.AbilityRules)
// 				console.log(ability.rules)
// 				break
// 			case 'user/resetState':
// 				ability.update([{actions: null, subject: null}])
// 				break
// 		}
// 	})
// }

export default (store) => {
	const ability = store.getters.ability

	// console.log(store.state.rules)
	ability.update(store.state.rules)

	return store.subscribe((mutation) => {
		switch (mutation.type){
			case 'user/setUser':
				store.commit('createRules', mutation.payload.AbilityRules)
				// console.log(mutation.payload.AbilityRules)
				ability.update(mutation.payload.AbilityRules)
				break
			case 'user/resetState':
				store.commit('destroyRules')
				ability.update([])
				break
		}
	})
}