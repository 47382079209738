import {getField, updateField} from "vuex-map-fields";
import { api } from "../../../api";
import _ from 'lodash'

const getDefaultState = () => {
    return {
        allBankAccounts: [],
        allSupplierBankAccounts: [],
        Bank: {
            abano: null,
            account: null,
            address: null,
            agent: null,
            agentswiftcode: null,
            beneficiary: null,
            beneficiaryaccount: null,
            country_id: 1,
            currency_id: 2,
            displayname: null,
            id: null,
            office_id: null,
            supplier_id: null,
            swiftcode: null,
            title: null,
        }
    }
}

const namespaced = {
    namespaced: true
}

const state = {
    allBankAccounts: [],
    allSupplierBankAccounts: [],
    current: {
        Bank: {
            abano: null,
            account: null,
            address: null,
            agent: null,
            agentswiftcode: null,
            beneficiary: null,
            beneficiaryaccount: null,
            country_id: 1,
            currency_id: 2,
            displayname: null,
            id: null,
            office_id: null,
            supplier_id: null,
            swiftcode: null,
            title: null,
        }
    },
    update: {
        Bank: {}
    }
}

const getters = {
    allBankAccounts: state => {
        return state.allBankAccounts;
    },
    allSupplierBankAccounts: state => {
        return state.allSupplierBankAccounts;
    },
    findBankAccountById: state => {
        return id => state.allBankAccounts.find (bankAccount => {
            return bankAccount.Bank.id === id
        })
    },
    findBankAccountsByOfficeAndCurrency: state => (office_id, currency_id) => {
        return state.allBankAccounts.filter(d => {
            return d.Bank.office_id == office_id  && d.Bank.currency_id == currency_id
        })
    },
    findBankAccountsBySupplier: state => (supplier_id) => {
        return state.allSupplierBankAccounts.filter(d => {
            return d.Bank.supplier_id == supplier_id
        })
    },
    // getBankAccountToUpdateById ({commit}, bankId) {
    //     return new Promise((resolve, reject) => {
    //         api
    //             .get('/banks/' + bankId )
    //             .then(response => {
    //                 commit('setBankAccountToUpdate', response.data.data[0])
    //                 resolve('done')
    //             })
    //             .catch(error => {
    //                 reject(error)
    //             })
    //     })
    // },
    getField
}

const actions  = {
    createBankAccount ({state, rootState}) {
        let bankAccount = _.cloneDeep(state.current.Bank)

        return new Promise((resolve, reject) => {
            api
                .post('/banks',{
                        bankaccount: bankAccount,
                    }
                )
                .then((response)=>{
                    if(response.data.status == 'success') {
                        resolve('done')
                    } else {
                        resolve('error')
                    }
                })
                .catch(error => {
                    reject(error)
                })
                .finally(()=>{
                    resolve('error')
                })
        })
    },
    deleteBankAccountById ({commit}, bank_id) {
        return new Promise((resolve, reject) => {
            api
                .delete('/banks/' + bank_id)
                .then(response => {
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                })
        })
    },
    getAllBankAccounts ({commit}) {
        return new Promise ((resolve, reject) => {
            api
                .get("/banks",{
                    params: {
                        conditions: [
                            {
                                field: 'banks.office_id',
                                value: [1,3,20,21,22]
                            }
                        ]
                    }
                })
                .then(response => {
                    commit('setAllBankAccounts',response.data.data)
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getBankAccountToUpdateById ({commit}, bankId) {
        return new Promise((resolve, reject) => {
            api
                .get('/banks/' + bankId )
                .then(response => {
                    commit('setBankAccountToUpdate', response.data.data[0])
                    resolve('done')
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getAllSupplierBankAccounts ({commit}) {
        return new Promise ((resolve, reject) => {
            api
                .get("/banks",{
                    params: {
                        conditions: [
                            {
                                field: 'banks.office_id',
                                value: 'null'
                            }
                        ]
                    }
                })
                .then(response => {
                    commit('setAllSupplierBankAccounts',response.data.data)
                    resolve('done');
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    resetAllBankAccounts ({commit}) {
        commit('resetState')
    },
    resetBankAccountToUpdate ({commit}) {
        commit('resetBankAccountToUpdate')
    },
    resetCurrentState ({commit}) {
        commit('resetCurrentState')
    },
    updateBankAccount ({state} ){
        let bankAccount = _.cloneDeep(state.update.Bank)

        return new Promise((resolve, reject) => {
            api
                .put("/banks/" + state.update.Bank.id,{
                    bankaccount: bankAccount,
                })
                .then(response => {
                    resolve('done')
                })
                .catch(error => {
                    reject(error)
                })
                .then(()=>{
                    resolve('done')
                });
        })
    },
}

const mutations = {
    resetBankAccountToUpdate (state) {
        state.update.Bank = getDefaultState().Bank
    },
    resetCurrentState (state) {
        state.current.Bank = getDefaultState().Bank
    },
    resetState (state){
        state.allBankAccounts = getDefaultState().allBankAccounts
        state.allSupplierBankAccounts = getDefaultState().allSupplierBankAccounts
    },
    setAllBankAccounts (state,data) {
        state.allBankAccounts = data;
    },
    setAllSupplierBankAccounts (state, data) {
        state.allSupplierBankAccounts = data;
    },
    setBankAccountToUpdate ( state, bankAccount ){
        state.update.Bank = bankAccount.Bank
    },
    updateField
}

export const bankaccount = {
    namespaced,
    state,
    getters,
    actions,
    mutations
}