import arrayToObject from 'vuex-map-fields/src/lib/array-to-object';

export function mapFieldsPrefixed(namespace, fields, prefix) {
    const fieldsObject = Array.isArray(fields) ? arrayToObject(fields) : fields;

    return Object.keys(fieldsObject).reduce((prev, key) => {
        const field = {
            get() {
                // 'this' refer to vue component
                // const path = fieldsObject[key].replace('[]', `[${this[indexField]}]`);
                const path = this[prefix] + '.' + fieldsObject[key]
                return this.$store.getters[`${namespace}/getField`](path);
            },
            set(value) {
                // 'this' refer to vue component
                // const path = fieldsObject[key].replace('[]', `[${this[indexField]}]`);
                const path = this[prefix] + '.' + fieldsObject[key]
                this.$store.commit(`${namespace}/updateField`, { path, value });
            },
        };
        prev[key] = field;
        return prev;
    }, {});
};

export function mapMultiRowFieldsPrefixed(namespace, paths, prefix) {
    const pathsObject = Array.isArray(paths) ? arrayToObject(paths) : paths;

    return Object.keys(pathsObject).reduce((entries, key) => {
        // eslint-disable-next-line no-param-reassign
        entries[key] = {
            get() {
                const store = this.$store;
                // const path = pathsObject[key].replace("[]",`[${this[indexField]}]`);
                const path = this[prefix] + '.' + pathsObject[key]
                const rows = store.getters[`${namespace}/getField`](path);

                return rows.map((fieldsObject, index) =>
                    Object.keys(fieldsObject).reduce((prev, fieldKey) => {
                        const fieldPath = `${path}[${index}].${fieldKey}`;

                        return Object.defineProperty(prev, fieldKey, {
                            get() {
                                return store.getters[`${namespace}/getField`](fieldPath);
                            },
                            set(value) {
                                store.commit(`${namespace}/updateField`, {
                                    path: fieldPath,
                                    value
                                });
                            }
                        });
                    }, {})
                );
            }
        };

        return entries;
    }, {});
}

export function mapGettersPrefixed(namespace, fields, prefix){
    const fieldsObject = Array.isArray(fields) ? arrayToObject(fields) : fields;
    return Object.keys(fieldsObject).reduce((prev, key) => {
        const field = {
            get() {
                // 'this' refer to vue component
                const path = this[prefix] + '.' + fieldsObject[key]
                return this.$store.getters[`${namespace}/getField`](path);
            }
        };
        prev[key] = field;
        return prev;
    }, {});
}