<!-- Header Structure -->
<template>
	<div>
		<v-navigation-drawer
			v-if="!horizontal"
			app
			fixed
			v-model="drawer"
            mini-variant
			mini-variant-width="60"
			:width="250"
			class="Vuely-sidebar"
			:style="{backgroundImage: 'url(' + selectedSidebarBgImage.url + ')'}"
			:class="{'background-none': !backgroundImage}"
			:right="rtlLayout"
			expand-on-hover
		>
			<!-- App Sidebar -->
			<app-sidebar></app-sidebar>
		</v-navigation-drawer>
		<v-app-bar
            :color="activeHeaderFilter.class"
			app
            class="Vuely-toolbar"
            dense
            fixed
		>
			<div class="d-custom-flex align-items-center navbar-left">
				<div v-if="!horizontal">
					<v-tooltip bottom>
						<template v-slot:activator="{ on }">
							<v-app-bar-nav-icon icon large @click.stop="drawer = !drawer" class="v-step-0" v-on="on"></v-app-bar-nav-icon>
						</template>
						<span>{{ $t('message.removeSidebar') }}</span>
					</v-tooltip>
				</div>
				<div class="site-logo-wrap d-custom-flex ml-0 align-items-center" v-else>
					<router-link to="/horizontal/dashboard/ecommerce" class="grayish-blue site-logo-img">
						<img src="/static/img/site-logo.png" alt="site logo" width="100" height="30" v-on="on">
					</router-link>
				</div>
				<v-text-field
                    :placeholder="$t('message.search')"
                    autocomplete="off"
                    class="search-bar ml-3"
                    clearable
                    flat
                    hide-details
                    prepend-icon="search"
                    solo
                    text
                    v-model="searchKeyword"
                    @click:prepend="keywordSearch"
                    @keyup.enter="keywordSearch"
				></v-text-field>
				<div class="mobile-search d-none" v-if="false">
					<v-btn text icon  small class="mobile-search-btn" @click="toggleSearchForm">
						<v-icon class="font-md">search</v-icon>
					</v-btn>
				</div>
			</div>
			<div class="navbar-right">
				<v-tooltip bottom>
					<template v-slot:activator="{ on }">
						<v-btn icon large @click="toggleFullScreen" class="full-screen ma-0" v-on="on">
							<v-icon color="grey">fullscreen</v-icon>
						</v-btn>
					</template>
					<span>{{ $t('message.toggleFullscreen') }}</span>
				</v-tooltip>
				<user></user>
			</div>
		</v-app-bar>
		<!-- Chat Searchbar -->
		<!-- <v-navigation-drawer fixed v-model="chatSidebar" :right="!rtlLayout" temporary app class="chat-sidebar-wrap">
			<chat-sidebar></chat-sidebar>
		</v-navigation-drawer>
		<mobile-search-form></mobile-search-form> -->
	</div>
</template>

<script>
import Sidebar from "../Sidebar/Sidebar.vue";
// import ChatSidebar from "../ChatSidebar/ChatSidebar.vue";
import screenfull from "screenfull";
// import LanguageProvider from "./LanguageProvider";
// import Notifications from "./Notifications";
import User from "./User";
// import Cart from "./Cart";
// import QuickLinks from "./QuickLinks";
// import MobileSearchForm from "./MobileSearchForm";
import { getCurrentAppLayout } from "Helpers/helpers";
import { mapGetters } from "vuex";

export default {
	props: {
		horizontal: {
			default: false,
			type: Boolean
		}
	},
	data() {
		return {
			collapsed: false, // collapse sidebar
			drawer: null, // sidebar drawer default true
			chatSidebar: false, // chat component right sidebar
			sidebarImages: "", // sidebar background images
			enableDefaultSidebar: false,
			searchKeyword: "",
            sideMenu: 'menus'
		};
	},
	computed: {
		...mapGetters('user', {
            User: 'User'
        }),
        ...mapGetters([
			"rtlLayout",
			"backgroundImage",
			"selectedSidebarBgImage",
			"darkMode",
			"activeHeaderFilter"
		]),
		collapseSidebar: {
			get(){
				return this.$store.state.collapseSidebar
			},
			set(value){
				this.$store.commit('collapseSidebarHandler',value)
			}
		}
	},
	methods: {
		// toggle full screen method
		toggleFullScreen() {
			if (screenfull.enabled) {
			screenfull.toggle()
			}
		},
		sidebarPath(link){
			this.$store.dispatch('setActiveMenuGroup',{ pathURL: link, menu: this.sideMenu });
			this.$router.push(this.getMenuLink(link))
		},
		getMenuLink(link) {
			return "/" + getCurrentAppLayout(this.$router) + link
		},
		toggleSearchForm() {
			this.$store.dispatch('toggleSearchForm')
		},
        keywordSearch() {
            // this.$router.push('/v1/search/' + this.searchKeyword)
            this.$router.push({ name:'search', params: { searchKeyword: this.searchKeyword } }).catch(error => {
                if (
                    error.name !== 'NavigationDuplicated' &&
                    !error.message.includes('Avoided redundant navigation to current location')
                ) {
                    console.log(error)
                }
            })
		}
	},
	components: {
		appSidebar: Sidebar,
		// ChatSidebar,
		// QuickLinks,
		// MobileSearchForm,
		User
	},
    created() {
        if(this.User.role == 'ROLE_DEMO_OPERATIONS') {
            this.sideMenu = 'demo_menu_operations'
        } else if(this.User.role == 'ROLE_DEMO_SALES') {
            this.sideMenu = 'demo_menu_sales'
        }
    },
	mounted() {
	    if(this.$route.matched.some(({name}) => name === 'search')){
            if(this.$route.params?.searchKeyword) {
                this.searchKeyword = this.$route.params?.searchKeyword
            }
        }
    }
};
</script>
<style>
    .search-bar input {
        text-align: left !important;
    }
    .search-bar .v-input__slot {
        padding-left: 0px !important;
    }
</style>
