import { api } from "../../../api"
import {getField, updateField} from "vuex-map-fields"
import _ from 'lodash'

const namespaced = {
	namespaced: true
}

const getDefaultState = () => {
	return {
		index: 0,
		lineNumber: 1,
		Address: {
			id: null,
			addresstypes: [],
			title: null,
			address: null,
			model: 'customer',
			key_id: null,
		}
	}
}

const state = {
	addressTypes: [
		{value: 9, text: 'additionalNote'},
		{value: 7, text: 'alsoNotifyPartBlNameAddress'},
		{value: 4, text: 'coPcConsignee'},
		{value: 3, text: 'consigneeBl'},
		{value: 6, text: 'customerBankInfo'},
		{value: 1, text: 'deliveryAddressContainer'},
		{value: 8, text: 'invoiceTo'},
		{value: 2, text: 'notifyPartyBl'},
		{value: 5, text: 'originalShippingDocument'},
	],
	current: {
		Addresses: [
			{
				index: 0,
				lineNumber: 1,
				Address: {
					id: null,
					addresstypes: [],
					title: null,
					address: null,
					model: 'customer',
					key_id: null,
				}
			}
		],
		Address: {
			id: null,
			addresstypes: [],
			title: null,
			address: null,
			model: 'customer',
			key_id: null,
		}
	},
	update: {
		Addresses: [],
		Address: {}
	}
}

const getters = {
	getField,
	addressTypes: state => {
		return state.addressTypes
	}
}

const mutations = {
	addAddressRow (state, prefix){
		let i = 0;
		let row = getDefaultState();
		state[prefix].Addresses.push(row)
		state[prefix].Addresses.forEach((item) => {
			item.index = i
			item.lineNumber = i + 1
			i++
		})
	},
	addAddressesToState(state, payload){
		let prefix = payload.prefix
		let addresses = payload.data
		if(prefix == 'current') {
			addresses.forEach((value) => {
				let id = value.Address.id
				let exists = state[prefix].Addresses.find(contact => contact.Address.id === id)
				if (!exists) {
					state[prefix].Addresses.push(value)
				}
			});
		} else if(prefix == 'update'){
			state[prefix].Addresses = addresses
		}
		let i = 0;
		state[prefix].Addresses.forEach((item) => {
			item.index = i
			item.lineNumber = i + 1
			i++
		})
	},
	deleteAddressRow (state, payload) {
		state[payload.prefix].Addresses.splice(payload.index, 1)
		let i = 0;
		state[payload.prefix].Addresses.forEach((item) => {
			item.index = i
			item.lineNumber = i + 1
			i++
		})
	},
	resetState (state,prefix){
		state[prefix].Addresses = []
		state[prefix].Addresses.push(getDefaultState())
	},
	updateAddresses (state, payload){
		let keys = payload['field'].split('.')
		let prefix = payload['prefix']
		state[prefix].Addresses[payload['index']][keys[0]][keys[1]] = payload['value']
	},
	updateField
}

const actions = {
	addAddressRow ({commit},prefix){
		commit('addAddressRow',prefix)
	},
	deleteAddressRow ({commit},payload){
		commit('deleteAddressRow',payload)
	},
	deleteAddress({commit},payload){
		return new Promise((resolve, reject) => {
			let customer_id = payload.customer_id
			let address_id = payload.address_id
			api
				.delete('/customers/' + customer_id + '/addresses/' + address_id)
				.then(response => {
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
		})
	},
	getActiveAddressesByBuyer ({commit},payload){
		let customer_id = payload.customer_id
		let prefix = payload.prefix
		return new Promise((resolve, reject) => {
			api
				.get("/customers/" + customer_id + "/addresses")
				.then(response => {
					let payload = {
						prefix: prefix,
						data: response.data.data
					}
					commit('addAddressesToState',payload);
					resolve('done');
				})
				.catch(error => {
					reject(error);
				})
				.finally(()=>{
					resolve('done');
				})
		})
	},
	resetCurrentAddresses ({commit}) {
		return new Promise ((resolve, reject) => {
			commit('resetState', 'current')
			resolve('done');
		})
	},
	resetAllAddresses ({commit}, prefix) {
		return new Promise ((resolve, reject) => {
			commit('resetState', prefix)
			resolve('done');
		})
	},
}

export const customeraddress = {
	namespaced,
	state,
	getters,
	actions,
	mutations
}