import {getField, updateField} from "vuex-map-fields";
import {api} from "../../../api";
import _ from 'lodash'

const namespaced = {
	namespaced: true
}

const getDefaultState = () => {
	return {
		PendingContacts: [],
		PendingInvoices: [],
		IntegratedContacts: [],
		IntegratedInvoices: [],
		isAuthorized: false
	}
}

const state = {
	PendingContacts: [],
	PendingInvoices: [],
	IntegratedContacts: [],
	IntegratedInvoices: [],
	isAuthorized: false
}

const getters = {
	getField
}

const mutations = {
	updateField,
	resetIntegratedLists (state) {
		state.IntegratedContacts = getDefaultState().IntegratedContacts
		state.IntegratedInvoices = getDefaultState().IntegratedInvoices
	},
	resetPendingLists (state) {
		state.PendingContacts = getDefaultState().PendingContacts
		state.PendingInvoices = getDefaultState().PendingInvoices
	},
	setIntegratedContacts (state,data) {
		state.IntegratedContacts = data
	},
	setIntegratedInvoices (state,data) {
		state.IntegratedInvoices = data
	},
	setPendingContacts (state,data) {
		state.PendingContacts = data;
	},
	setPendingInvoices (state,data) {
		state.PendingInvoices = data;
	}
}

const actions = {
	getIntegratedContacts({ commit }) {
		return new Promise((resolve, reject) => {
			api
				.get("/xero/integrated-contacts",{
				})
				.then(response => {
					commit('setIntegratedContacts',response.data.data);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	getIntegratedInvoices({ commit }) {
		return new Promise((resolve, reject) => {
			api
				.get("/xero/integrated-invoices",{
				})
				.then(response => {
					commit('setIntegratedInvoices',response.data.data);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	getPendingContacts( { commit } ) {
		return new Promise((resolve, reject) => {
			api
				.get("/xero/pending-contacts",{
				})
				.then(response => {
					commit('setPendingContacts',response.data.data);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	getPendingInvoices( { commit } ) {
		return new Promise((resolve, reject) => {
			api
				.get("/xero/pending-invoices",{
				})
				.then(response => {
					commit('setPendingInvoices',response.data.data);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	resetIntegratedLists ({commit}) {
		commit('resetIntegratedLists')
	},
	resetPendingLists ({commit}) {
		commit('resetPendingLists')
	},
	uploadContacts({commit}, contacts) {
		let contactIds = contacts.map(contact => contact.Contact.id)
		return new Promise((resolve, reject) => {
			api
				.put("/xero/pending-contacts",{
					contacts: contactIds
				})
				.then(response => {
					resolve('done')
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	uploadInvoices({commit}, invoices) {
		let invoiceIds = invoices.map(invoice => invoice.Invoice.id)
		return new Promise((resolve, reject) => {
			api
				.put("/xero/pending-invoices",{
					invoices: invoiceIds
				})
				.then(response => {
					resolve('done')
				})
				.catch(error => {
					reject(error);
				});
		})
	}
}

export const xero = {
	namespaced,
	state,
	getters,
	actions,
	mutations
}