import {getField, updateField} from "vuex-map-fields";
import {api} from "../../../api";
import {log} from "Helpers/helpers";

const namespaced = {
    namespaced: true
}

const getDefaultState = () => {
    return {
        allNewContractDocuments: [
            {Document: {doctype_id: 91, customeraccess: 0, fieldname: 'signedContract', folder: 'contracts', title: 'Signed Contract', standard: true, fileName: 'Signed'}},
            {Document: {doctype_id: 92, customeraccess: 0, fieldname: 'signedPO', folder: 'pos', title: 'Signed PO', standard: true, fileName: 'Signed_PO'}},
            {Document: {doctype_id: 93, customeraccess: 0, fieldname: 'buyerPO', folder: 'pos', title: 'Buyer\'s PO', standard: true, fileName: 'Buyer_PO'}},
            {Document: {doctype_id: 94, customeraccess: 0, fieldname: 'supplierPI', folder: 'invoices', title: 'Supplier\'s PI', standard: true, fileName: 'Supplier_PI'}},
            {Document: {doctype_id: 95, customeraccess: 0, fieldname: 'appendixGrading', folder: 'others', title: 'Appendix/Grading', standard: true, fileName: 'Appendix_Grading'}},
            {Document: {doctype_id: 101, customeraccess: 0, fieldname: 'appendixGrading2', folder: 'others', title: 'Appendix/Grading 2', standard: true, fileName: 'Appendix_Grading_2'}},
            {Document: {doctype_id: 102, customeraccess: 0, fieldname: 'appendixGrading3', folder: 'others', title: 'Appendix/Grading 3', standard: true, fileName: 'Appendix_Grading_3'}},
            {Document: {doctype_id: 201, customeraccess: 0, fieldname: 'certificate', folder: 'others', title: 'FSC/PEFC Certificate', standard: true, fileName: 'FSC_PEFC'}},
        ],
        allNewCommContractDocuments: [
            {Document: {doctype_id: 91, customeraccess: 0, fieldname: 'signedContract', folder: 'contracts', title: 'Signed Contract', standard: true, fileName: 'Signed'}},
            {Document: {doctype_id: 92, customeraccess: 0, fieldname: 'signedPO', folder: 'pos', title: 'Signed PO', standard: true, fileName: 'Signed_PO'}},
            {Document: {doctype_id: 93, customeraccess: 0, fieldname: 'buyerPO', folder: 'pos', title: 'Buyer\'s PO', standard: true, fileName: 'Buyer_PO'}},
            {Document: {doctype_id: 94, customeraccess: 0, fieldname: 'supplierPI', folder: 'invoices', title: 'Supplier\'s PI', standard: true, fileName: 'Supplier_PI'}},
            {Document: {doctype_id: 95, customeraccess: 0, fieldname: 'appendixGrading', folder: 'others', title: 'Appendix/Grading', standard: true, fileName: 'Appendix_Grading'}},
            {Document: {doctype_id: 201, customeraccess: 0, fieldname: 'certificate', folder: 'others', title: 'FSC/PEFC Certificate', standard: true, fileName: 'FSC_PEFC'}},
        ],
        allNewExportPurchaseRequestDocuments: [
            {Document: {doctype_id: 95, customeraccess: 0, fieldname: 'appendixGrading', folder: 'others', title: 'Appendix/Grading', standard: true, fileName: 'Appendix_Grading'}},
            {Document: {doctype_id: 201, customeraccess: 0, fieldname: 'certificate', folder: 'others', title: 'FSC/PEFC Certificate', standard: true, fileName: 'FSC_PEFC'}},
        ],
        allContractDocuments: [
            {Document: {doctype_id: 91, customeraccess: 0, fieldname: 'signedContract', folder: 'contracts', title: 'Signed Contract', standard: true, fileName: 'Signed'}},
            {Document: {doctype_id: 92, customeraccess: 0, fieldname: 'signedPO', folder: 'pos', title: 'Signed PO', standard: true, fileName: 'Signed_PO'}},
            {Document: {doctype_id: 93, customeraccess: 0, fieldname: 'buyerPO', folder: 'pos', title: 'Buyer\'s PO', standard: true, fileName: 'Buyer_PO'}},
            {Document: {doctype_id: 94, customeraccess: 0, fieldname: 'supplierPI', folder: 'invoices', title: 'Supplier\'s PI', standard: true, fileName: 'Supplier_PI'}},
            {Document: {doctype_id: 95, customeraccess: 0, fieldname: 'appendixGrading', folder: 'others', title: 'Appendix/Grading', standard: true, fileName: 'Appendix_Grading'}},
            {Document: {doctype_id: 101, customeraccess: 0, fieldname: 'appendixGrading2', folder: 'others', title: 'Appendix/Grading 2', standard: true, fileName: 'Appendix_Grading_2'}},
            {Document: {doctype_id: 102, customeraccess: 0, fieldname: 'appendixGrading3', folder: 'others', title: 'Appendix/Grading 3', standard: true, fileName: 'Appendix_Grading_3'}},
            {Document: {doctype_id: 201, customeraccess: 0, fieldname: 'certificate', folder: 'others', title: 'FSC/PEFC Certificate', standard: true, fileName: 'FSC_PEFC'}},
        ],
        allCommContractDocuments: [
            {Document: {doctype_id: 91, customeraccess: 0, fieldname: 'signedContract', folder: 'contracts', title: 'Signed Contract', standard: true, fileName: 'Signed'}},
            {Document: {doctype_id: 92, customeraccess: 0, fieldname: 'signedPO', folder: 'pos', title: 'Signed PO', standard: true, fileName: 'Signed_PO'}},
            {Document: {doctype_id: 93, customeraccess: 0, fieldname: 'buyerPO', folder: 'pos', title: 'Buyer\'s PO', standard: true, fileName: 'Buyer_PO'}},
            {Document: {doctype_id: 94, customeraccess: 0, fieldname: 'supplierPI', folder: 'invoices', title: 'Supplier\'s PI', standard: true, fileName: 'Supplier_PI'}},
            {Document: {doctype_id: 95, customeraccess: 0, fieldname: 'appendixGrading', folder: 'others', title: 'Appendix/Grading', standard: true, fileName: 'Appendix_Grading'}},
            {Document: {doctype_id: 201, customeraccess: 0, fieldname: 'certificate', folder: 'others', title: 'FSC/PEFC Certificate', standard: true, fileName: 'FSC_PEFC'}},
        ],
        allExportPurchaseRequestDocuments: [
            {Document: {doctype_id: 92, customeraccess: 0, fieldname: 'signedPO', folder: 'pos', title: 'Signed PO', standard: true, fileName: 'Signed_PO'}},
            {Document: {doctype_id: 95, customeraccess: 0, fieldname: 'appendixGrading', folder: 'others', title: 'Appendix/Grading', standard: true, fileName: 'Appendix_Grading'}},
            {Document: {doctype_id: 201, customeraccess: 0, fieldname: 'certificate', folder: 'others', title: 'FSC/PEFC Certificate', standard: true, fileName: 'FSC_PEFC'}},
        ],
        allFinanceDocuments: [
            {Document: {doctype_id: 96, customeraccess: 0, fieldname: 'supplierPaymentSlip', folder: 'payments', title: 'Supplier Payment Slip', standard: true, fileName: 'Supplier_Payment_Slip'}},
            {Document: {doctype_id: 97, customeraccess: 0, fieldname: 'customerPaymentSlip', folder: 'payments', title: 'Customer Payment Slip', standard: true, fileName: 'Buyer_Payment_Slip'}},
        ],
        allLocalSaleDocuments: [],
        allNcrDocuments: [],
        allNewNcrDocuments: [],
        allNewPurchaseOrderDocuments: [
            {Document: {doctype_id: 92, customer: 0, fieldname: 'signedPO', folder: 'pos', title: 'Signed PO', standard: true, fileName: 'Signed_PO'}},
            {Document: {doctype_id: 201, customeraccess: 0, fieldname: 'certificate', folder: 'others', title: 'FSC/PEFC Certificate', standard: true, fileName: 'FSC_PEFC'}},
        ],
        allPurchaseOrderDocuments: [
            {Document: {doctype_id: 92, customeraccess: 0, fieldname: 'signedPO', folder: 'pos', title: 'Signed PO', standard: true, fileName: 'Signed_PO'}},
            {Document: {doctype_id: 201, customeraccess: 0, fieldname: 'certificate', folder: 'others', title: 'FSC/PEFC Certificate', standard: true, fileName: 'FSC_PEFC'}},
        ],
        allShippingDirectDocuments: [
            {Document: {doctype_id: 200, customeraccess: 1, fieldname: 'fullSet', folder: 'others', title: 'Full Document Set', standard: true, fileName: 'Full_Set'}},
            {Document: {doctype_id: 98, customeraccess: 1, fieldname: 'ipa', folder: 'others', title: 'IPA', standard: true, fileName: 'IPA'}},
            {Document: {doctype_id: 7, customeraccess: 1, fieldname: 'lca', folder: 'others', title: 'Letter of Credit Advice', standard: true, fileName: 'LCA'}},
            {Document: {doctype_id: 8, customeraccess: 1, fieldname: 'taxInvoice', folder: 'invoices', title: 'Invoice', standard: true, fileName: 'Inv'}},
            {Document: {doctype_id: 3, customeraccess: 1, fieldname: 'packingList', folder: 'packing-lists', title: 'Packing List', standard: true, fileName: 'PL'}},
            {Document: {doctype_id: 52, customeraccess: 0, fieldname: 'supplierInvoice', folder: 'invoices', title: 'Supplier Invoice', standard: true, fileName: 'Supplier_Inv'}},
            {Document: {doctype_id: 81, customeraccess: 0, fieldname: 'supplierPackingList', folder: 'packing-lists', title: 'Supplier Packing List', standard: true, fileName: 'Supplier_PL'}},
            {Document: {doctype_id: 82, customeraccess: 1, fieldname: 'dummyInvoice', folder: 'invoices', title: 'Dummy Invoice', standard: false, fileName: 'Dummy_Inv'}},
            {Document: {doctype_id: 83, customeraccess: 1, fieldname: 'dummyPackingList', folder: 'packing-lists', title: 'Dummy Packing List', standard: false, fileName: 'Dummy_PL'}},
            {Document: {doctype_id: 51, customeraccess: 1, fieldname: 'billOfLading', folder: 'others', title: 'Bill of Lading', standard: true, fileName: 'BL'}},
            {Document: {doctype_id: 53, customeraccess: 1, fieldname: 'certificateOrigin', folder: 'others', title: 'Certificate of Origin', standard: true, fileName: 'COO'}},
            {Document: {doctype_id: 57, customeraccess: 1, fieldname: 'phytoCertificate', folder: 'others', title: 'Phyto Certificate', standard: true, fileName: 'Phyto'}},
            // {Document: {doctype_id: 64, customeraccess: 1, fieldname: 'cooPhytoCertificate', folder: 'others', title: 'COO / Phyto Cert', standard: false, fileName: null}},
            {Document: {doctype_id: 65, customeraccess: 1, fieldname: 'packingDeclaration', folder: 'others', title: 'Packing Declaration', standard: false, fileName: 'Packing_Declaration'}},
            {Document: {doctype_id: 66, customeraccess: 1, fieldname: 'insuranceCertificate', folder: 'others', title: 'Insurance Certificate', standard: false, fileName: 'Insurance'}},
            // {Document: {doctype_id: 54, customeraccess: 1, fieldname: 'packingInsurance', folder: 'others', title: 'Packing/Insurance', standard: false, fileName: null}},
            {Document: {doctype_id: 56, customeraccess: 1, fieldname: 'clearanceInvoice', folder: 'invoices', title: 'Clearance Invoice', standard: false, fileName: 'Clearance_Inv'}},
            {Document: {doctype_id: 58, customeraccess: 1, fieldname: 'clearancePackingList', folder: 'packing-lists', title: 'Clearance Packing List', standard: false, fileName: 'Clearance_PL'}},
            {Document: {doctype_id: 59, customeraccess: 1, fieldname: 'kilnDryCertificate', folder: 'others', title: 'Kiln Dry Certificate', standard: false, fileName: 'KD_Certificate'}},
            // {Document: {doctype_id: 60, customeraccess: 1, fieldname: 'clearanceInvPlKdcert', folder: 'others', title: 'Clearance Inv,PL,KD Cert', standard: false, fileName: null}},
            {Document: {doctype_id: 69, customeraccess: 1, fieldname: 'tallySheet', folder: 'others', title: 'Tally Sheet', standard: true, fileName: 'Tally_Sheet'}},
            {Document: {doctype_id: 70, customeraccess: 1, fieldname: 'forexExchange', folder: 'others', title: 'Forex Exchange', standard: true, fileName: 'Forex'}},
            {Document: {doctype_id: 71, customeraccess: 1, fieldname: 'fumigationCertificate', folder: 'others', title: 'Fumigation Certificate', standard: false, fileName: 'Fumigation'}},
            {Document: {doctype_id: 72, customeraccess: 1, fieldname: 'eutr', folder: 'others', title: 'EUTR Letter', standard: false, fileName: 'EUTR_Letter'}},
            {Document: {doctype_id: 74, customeraccess: 1, fieldname: 'importPermit', folder: 'others', title: 'Import Permit', standard: false, fileName: 'IP'}},
            {Document: {doctype_id: 202, customeraccess: 1, fieldname: 'beneficiaryCertificate', folder: 'others', title: 'Beneficiary Certificate', standard: false, fileName: 'BC'}},
            {Document: {doctype_id: 203, customeraccess: 1, fieldname: 'gradeCertificate', folder: 'others', title: 'Certificate of Grade', standard: false, fileName: 'Grade_Certificate'}},
            {Document: {doctype_id: 204, customeraccess: 1, fieldname: 'clearanceLetter', folder: 'others', title: 'Clearance Letter', standard: false, fileName: 'Clearance_Letter'}},
            {Document: {doctype_id: 205, customeraccess: 1, fieldname: 'declarationForm', folder: 'others', title: 'Export Customs Declaration Form', standard: false, fileName: 'Export_Declaration'}},
            {Document: {doctype_id: 206, customeraccess: 1, fieldname: 'ispmDeclaration', folder: 'others', title: 'ISPM Certificate', standard: false, fileName: 'ISPM'}},
            {Document: {doctype_id: 207, customeraccess: 1, fieldname: 'mytlasCertficate', folder: 'others', title: 'MYTLAS Certificate', standard: false, fileName: 'MYTLAS'}},
            {Document: {doctype_id: 208, customeraccess: 1, fieldname: 'vlegalFlegtLicense', folder: 'others', title: 'VLegal/Flegt License', standard: false, fileName: 'V-Legal_FLEGT'}},
            {Document: {doctype_id: 209, customeraccess: 1, fieldname: 'eutrComplianceFile', folder: 'others', title: 'EUTR Compliance File', standard: false, fileName: 'EUTR'}},
            {Document: {doctype_id: 210, customeraccess: 1, fieldname: 'inspectionReport', folder: 'others', title: 'Inspection Report', standard: false, fileName: 'Inspection_Report'}},
            {Document: {doctype_id: 999, customeraccess: 0, fieldname: 'others', folder: 'others', title: 'Others', standard: false, fileName: 'Others'}}
        ],
        allShippingCommissionDocuments: [
            {Document: {doctype_id: 200, customeraccess: 1, fieldname: 'fullSet', folder: 'others', title: 'Full Document Set', standard: true, fileName: 'Full_Set'}},
            {Document: {doctype_id: 5, customeraccess: 1, fieldname: 'coverNote', folder: 'others', title: 'Cover Note', standard: true, fileName: 'Cover_Note'}},
            {Document: {doctype_id: 74, customeraccess: 1, fieldname: 'importPermit', folder: 'others', title: 'Import Permit', standard: true, fileName: 'IP'}},
            {Document: {doctype_id: 98, customeraccess: 1, fieldname: 'ipa', folder: 'others', title: 'IPA', standard: false, fileName: 'IPA'}},
            {Document: {doctype_id: 7, customeraccess: 1, fieldname: 'lca', folder: 'others', title: 'Letter of Credit Advice', standard: true, fileName: 'LCA'}},
            {Document: {doctype_id: 8, customeraccess: 1, fieldname: 'taxInvoice', folder: 'invoices', title: 'Invoice', standard: false, fileName: 'Tax_Inv'}},
            {Document: {doctype_id: 3, customeraccess: 1, fieldname: 'packingList', folder: 'packing-lists', title: 'Packing List', standard: false, fileName: 'PL'}},
            {Document: {doctype_id: 52, customeraccess: 0, fieldname: 'supplierInvoice', folder: 'invoices', title: 'Supplier Invoice', standard: false, fileName: 'Supplier_Inv'}},
            {Document: {doctype_id: 81, customeraccess: 0, fieldname: 'supplierPackingList', folder: 'packing-lists', title: 'Supplier Packing List', standard: false, fileName: 'Supplier_PL'}},
            {Document: {doctype_id: 82, customeraccess: 1, fieldname: 'dummyInvoice', folder: 'invoices', title: 'Dummy Invoice', standard: false, fileName: 'Dummy_Inv'}},
            {Document: {doctype_id: 83, customeraccess: 1, fieldname: 'dummyPackingList', folder: 'packing-lists', title: 'Dummy Packing List', standard: false, fileName: 'Dummy_PL'}},
            {Document: {doctype_id: 51, customeraccess: 1, fieldname: 'billOfLading', folder: 'others', title: 'Bill of Lading', standard: false, fileName: 'BL'}},
            {Document: {doctype_id: 53, customeraccess: 1, fieldname: 'certificateOrigin', folder: 'others', title: 'Certificate of Origin', standard: false, fileName: 'COO'}},
            {Document: {doctype_id: 57, customeraccess: 1, fieldname: 'phytoCertificate', folder: 'others', title: 'Phyto Certificate', standard: false, fileName: 'Phyto'}},
            {Document: {doctype_id: 64, customeraccess: 1, fieldname: 'cooPhytoCertificate', folder: 'others', title: 'COO / Phyto Certificate', standard: false, fileName: 'COO_Phyto'}},
            {Document: {doctype_id: 65, customeraccess: 1, fieldname: 'packingDeclaration', folder: 'others', title: 'Packing Declaration', standard: false, fileName: 'Packing_Declaration'}},
            {Document: {doctype_id: 66, customeraccess: 1, fieldname: 'insuranceCertificate', folder: 'others', title: 'Insurance Certificate', standard: false, fileName: 'Insurance'}},
            {Document: {doctype_id: 54, customeraccess: 1, fieldname: 'packingInsurance', folder: 'others', title: 'Packing/Insurance', standard: false, fileName: 'Packing_Insurance'}},
            {Document: {doctype_id: 56, customeraccess: 1, fieldname: 'clearanceInvoice', folder: 'invoices', title: 'Clearance Invoice', standard: false, fileName: 'Clearance_Inv'}},
            {Document: {doctype_id: 58, customeraccess: 1, fieldname: 'clearancePackingList', folder: 'packing-lists', title: 'Clearance Packing List', standard: false, fileName: 'Clearance_PL'}},
            {Document: {doctype_id: 59, customeraccess: 1, fieldname: 'kildDryCertificate', folder: 'others', title: 'Kiln Dry Certificate', standard: false, fileName: 'KD_Certificate'}},
            {Document: {doctype_id: 60, customeraccess: 1, fieldname: 'clearanceInvPlKdcert', folder: 'others', title: 'Clearance Inv,PL,KD Cert', standard: false, fileName: 'Inv_PL_KD'}},
            {Document: {doctype_id: 69, customeraccess: 1, fieldname: 'tallySheet', folder: 'others', title: 'Tally Sheet', standard: false, fileName: 'Tally_Sheet'}},
            {Document: {doctype_id: 70, customeraccess: 1, fieldname: 'forexExchange', folder: 'others', title: 'Forex Exchange', standard: false, fileName: 'Forex'}},
            {Document: {doctype_id: 71, customeraccess: 1, fieldname: 'fumigationCertificate', folder: 'others', title: 'Fumigation Certificate', standard: false, fileName: 'Fumigation'}},
            {Document: {doctype_id: 72, customeraccess: 1, fieldname: 'eutr', folder: 'others', title: 'EUTR Letter', standard: false, fileName: 'EUTR_Letter'}},
            {Document: {doctype_id: 999, customeraccess: 0, fieldname: 'others', folder: 'others', title: 'Others', standard: false, fileName: 'Others'}}
            // {Document: {doctype_id: 73, customeraccess: 1, fieldname: 'others', folder: 'others', title: 'Others', standard: false, fileName: null}}
        ],
        allWarehousePurchaseRequestDocuments: [],
        allSupplierDocuments: [
            {Document: {doctype_id: 301, customeraccess: 0, fieldname: 'supplierFscCert', folder: 'others', title: 'Supplier FSC Certificate', standard: true, fileName: 'FSC'}},
            {Document: {doctype_id: 302, customeraccess: 0, fieldname: 'supplierPefcCert', folder: 'others', title: 'Supplier PEFC Certificate', standard: true, fileName: 'PEFC'}}
        ],
        allDocuments: {
            CommContractDocuments: [],
            ContractDocuments: [],
            ExportPurchaseRequestDocuments: [],
            FinanceDocuments: [],
            LocalSaleDocuments: [],
            NcrDocuments: [],
            NewCommContractDocuments: [],
            NewContractDocuments: [],
            NewContractRequestDocuments: [],
            NewExportPurchaseRequestDocuments: [],
            NewNcrDocuments: [],
            NewPurchaseOrderDocuments: [],
            PurchaseOrderDocuments: [],
            ShippingCommissionDocuments: [],
            ShippingDirectDocuments: [],
            ShippingEutrDocuments: [],
            SupplierDocuments: [],
            WarehousePurchaseRequestDocuments: []
        },
        allStandardShippingDocuments: [],
        Document: {
            id: null,
            categories: null,
            doctype_id: 2000,
            categories_types: {},
            customeraccess: 0,
            fieldname: null,
            folder: 'others',
            title: null,
            standard: true,
            fileName: null,
            autogenerated: null,
            linked: null,
            linked_url: null
        },
        documentCategories: [
            { value: 'CommContractDocuments', text: 'CommContractDocuments' },
            { value: 'ContractDocuments', text: 'ContractDocuments' },
            { value: 'ExportPurchaseRequestDocuments', text: 'ExportPurchaseRequestDocuments' },
            { value: 'FinanceDocuments', text: 'FinanceDocuments' },
            { value: 'LocalSaleDocuments', text: 'LocalSaleDocuments' },
            { value: 'NcrDocuments', text: 'NcrDocuments' },
            { value: 'NewCommContractDocuments', text: 'NewCommContractDocuments' },
            { value: 'NewContractDocuments', text: 'NewContractDocuments' },
            { value: 'NewExportPurchaseRequestDocuments', text: 'NewExportPurchaseRequestDocuments' },
            { value: 'NewNcrDocuments', text: 'NewNcrDocuments' },
            { value: 'NewPurchaseOrderDocuments', text: 'NewPurchaseOrderDocuments' },
            { value: 'PurchaseOrderDocuments', text: 'PurchaseOrderDocuments' },
            { value: 'ShippingCommissionDocuments', text: 'ShippingCommissionDocuments' },
            { value: 'ShippingDirectDocuments', text: 'ShippingDirectDocuments' },
            { value: 'ShippingEutrDocuments', text: 'ShippingEutrDocuments'},
            { value: 'SupplierDocuments', text: 'SupplierDocuments' },
            { value: 'WarehousePurchaseRequestDocuments', text: 'WarehousePurchaseRequestDocuments' }
        ],
        documentFolders: [
            { value: 'contracts', text: 'contracts' },
            { value: 'invoices', text: 'invoices' },
            { value: 'others', text: 'others' },
            { value: 'packing-lists', text: 'packingLists' },
            { value: 'payments', text: 'payments' },
            { value: 'pos', text: 'pos' }
        ]
    }
}

const state = {
    allNewContractDocuments: [
        {Document: {doctype_id: 91, customeraccess: 0, fieldname: 'signedContract', folder: 'contracts', title: 'Signed Contract', standard: true, fileName: 'Signed'}},
        {Document: {doctype_id: 92, customeraccess: 0, fieldname: 'signedPO', folder: 'pos', title: 'Signed PO', standard: true, fileName: 'Signed_PO'}},
        {Document: {doctype_id: 93, customeraccess: 0, fieldname: 'buyerPO', folder: 'pos', title: 'Buyer\'s PO', standard: true, fileName: 'Buyer_PO'}},
        {Document: {doctype_id: 94, customeraccess: 0, fieldname: 'supplierPI', folder: 'invoices', title: 'Supplier\'s PI', standard: true, fileName: 'Supplier_PI'}},
        {Document: {doctype_id: 95, customeraccess: 0, fieldname: 'appendixGrading', folder: 'others', title: 'Appendix/Grading', standard: true, fileName: 'Appendix_Grading'}},
        {Document: {doctype_id: 101, customeraccess: 0, fieldname: 'appendixGrading2', folder: 'others', title: 'Appendix/Grading 2', standard: true, fileName: 'Appendix_Grading_2'}},
        {Document: {doctype_id: 102, customeraccess: 0, fieldname: 'appendixGrading3', folder: 'others', title: 'Appendix/Grading 3', standard: true, fileName: 'Appendix_Grading_3'}},
        {Document: {doctype_id: 201, customeraccess: 0, fieldname: 'certificate', folder: 'others', title: 'FSC/PEFC Certificate', standard: true, fileName: 'FSC_PEFC'}},
    ],
    allNewCommContractDocuments: [
        {Document: {doctype_id: 91, customeraccess: 0, fieldname: 'signedContract', folder: 'contracts', title: 'Signed Contract', standard: true, fileName: 'Signed'}},
        {Document: {doctype_id: 92, customeraccess: 0, fieldname: 'signedPO', folder: 'pos', title: 'Signed PO', standard: true, fileName: 'Signed_PO'}},
        {Document: {doctype_id: 93, customeraccess: 0, fieldname: 'buyerPO', folder: 'pos', title: 'Buyer\'s PO', standard: true, fileName: 'Buyer_PO'}},
        {Document: {doctype_id: 94, customeraccess: 0, fieldname: 'supplierPI', folder: 'invoices', title: 'Supplier\'s PI', standard: true, fileName: 'Supplier_PI'}},
        {Document: {doctype_id: 95, customeraccess: 0, fieldname: 'appendixGrading', folder: 'others', title: 'Appendix/Grading', standard: true, fileName: 'Appendix_Grading'}},
        {Document: {doctype_id: 201, customeraccess: 0, fieldname: 'certificate', folder: 'others', title: 'FSC/PEFC Certificate', standard: true, fileName: 'FSC_PEFC'}},
    ],
    allNewExportPurchaseRequestDocuments: [
        {Document: {doctype_id: 95, customeraccess: 0, fieldname: 'appendixGrading', folder: 'others', title: 'Appendix/Grading', standard: true, fileName: 'Appendix_Grading'}},
        {Document: {doctype_id: 201, customeraccess: 0, fieldname: 'certificate', folder: 'others', title: 'FSC/PEFC Certificate', standard: true, fileName: 'FSC_PEFC'}},
    ],
    allContractDocuments: [
        {Document: {doctype_id: 91, customeraccess: 0, fieldname: 'signedContract', folder: 'contracts', title: 'Signed Contract', standard: true, fileName: 'Signed'}},
        {Document: {doctype_id: 92, customeraccess: 0, fieldname: 'signedPO', folder: 'pos', title: 'Signed PO', standard: true, fileName: 'Signed_PO'}},
        {Document: {doctype_id: 93, customeraccess: 0, fieldname: 'buyerPO', folder: 'pos', title: 'Buyer\'s PO', standard: true, fileName: 'Buyer_PO'}},
        {Document: {doctype_id: 94, customeraccess: 0, fieldname: 'supplierPI', folder: 'invoices', title: 'Supplier\'s PI', standard: true, fileName: 'Supplier_PI'}},
        {Document: {doctype_id: 95, customeraccess: 0, fieldname: 'appendixGrading', folder: 'others', title: 'Appendix/Grading', standard: true, fileName: 'Appendix_Grading'}},
        {Document: {doctype_id: 101, customeraccess: 0, fieldname: 'appendixGrading2', folder: 'others', title: 'Appendix/Grading 2', standard: true, fileName: 'Appendix_Grading_2'}},
        {Document: {doctype_id: 102, customeraccess: 0, fieldname: 'appendixGrading3', folder: 'others', title: 'Appendix/Grading 3', standard: true, fileName: 'Appendix_Grading_3'}},
        {Document: {doctype_id: 201, customeraccess: 0, fieldname: 'certificate', folder: 'others', title: 'FSC/PEFC Certificate', standard: true, fileName: 'FSC_PEFC'}},
    ],
    allCommContractDocuments: [
        {Document: {doctype_id: 91, customeraccess: 0, fieldname: 'signedContract', folder: 'contracts', title: 'Signed Contract', standard: true, fileName: 'Signed'}},
        {Document: {doctype_id: 92, customeraccess: 0, fieldname: 'signedPO', folder: 'pos', title: 'Signed PO', standard: true, fileName: 'Signed_PO'}},
        {Document: {doctype_id: 93, customeraccess: 0, fieldname: 'buyerPO', folder: 'pos', title: 'Buyer\'s PO', standard: true, fileName: 'Buyer_PO'}},
        {Document: {doctype_id: 94, customeraccess: 0, fieldname: 'supplierPI', folder: 'invoices', title: 'Supplier\'s PI', standard: true, fileName: 'Supplier_PI'}},
        {Document: {doctype_id: 95, customeraccess: 0, fieldname: 'appendixGrading', folder: 'others', title: 'Appendix/Grading', standard: true, fileName: 'Appendix_Grading'}},
        {Document: {doctype_id: 201, customeraccess: 0, fieldname: 'certificate', folder: 'others', title: 'FSC/PEFC Certificate', standard: true, fileName: 'FSC_PEFC'}},
    ],
    allExportPurchaseRequestDocuments: [
        {Document: {doctype_id: 92, customeraccess: 0, fieldname: 'signedPO', folder: 'pos', title: 'Signed PO', standard: true}, fileName: 'Signed_PO'},
        {Document: {doctype_id: 95, customeraccess: 0, fieldname: 'appendixGrading', folder: 'others', title: 'Appendix/Grading', standard: true, fileName: 'Appendix_Grading'}},
        {Document: {doctype_id: 201, customeraccess: 0, fieldname: 'certificate', folder: 'others', title: 'FSC/PEFC Certificate', standard: true, fileName: 'FSC_PEFC'}},
    ],
    allFinanceDocuments: [
        {Document: {doctype_id: 96, customeraccess: 0, fieldname: 'supplierPaymentSlip', folder: 'payments', title: 'Supplier Payment Slip', standard: true, fileName: 'Supplier_Payment_Slip'}},
        {Document: {doctype_id: 97, customeraccess: 0, fieldname: 'customerPaymentSlip', folder: 'payments', title: 'Customer Payment Slip', standard: true, fileName: 'Buyer_Payment_Slip'}},
    ],
    allLocalSaleDocuments: [],
    allNcrDocuments: [],
    allNewNcrDocuments: [],
    allNewPurchaseOrderDocuments: [
        {Document: {doctype_id: 92, customeraccess: 0, fieldname: 'signedPO', folder: 'pos', title: 'Signed PO', standard: true, fileName: 'Signed_PO'}},
        {Document: {doctype_id: 201, customeraccess: 0, fieldname: 'certificate', folder: 'others', title: 'FSC/PEFC Certificate', standard: true, fileName: 'FSC_PEFC'}},
    ],
    allPurchaseOrderDocuments: [
        {Document: {doctype_id: 92, customeraccess: 0, fieldname: 'signedPO', folder: 'pos', title: 'Signed PO', standard: true, fileName: 'Signed_PO'}},
        {Document: {doctype_id: 201, customeraccess: 0, fieldname: 'certificate', folder: 'others', title: 'FSC/PEFC Certificate', standard: true, fileName: 'FSC_PEFC'}},
    ],
    allShippingDirectDocuments: [
        {Document: {doctype_id: 200, customeraccess: 1, fieldname: 'fullSet', folder: 'others', title: 'Full Document Set', standard: true, fileName: 'Full_Set'}},
        {Document: {doctype_id: 98, customeraccess: 1, fieldname: 'ipa', folder: 'others', title: 'IPA', standard: true, fileName: 'IPA'}},
        {Document: {doctype_id: 7, customeraccess: 1, fieldname: 'lca', folder: 'others', title: 'Letter of Credit Advice', standard: true, fileName: 'LCA'}},
        {Document: {doctype_id: 8, customeraccess: 1, fieldname: 'taxInvoice', folder: 'invoices', title: 'Invoice', standard: true, fileName: 'Tax_Inv'}},
        {Document: {doctype_id: 3, customeraccess: 1, fieldname: 'packingList', folder: 'packing-lists', title: 'Packing List', standard: true, fileName: 'PL'}},
        {Document: {doctype_id: 52, customeraccess: 0, fieldname: 'supplierInvoice', folder: 'invoices', title: 'Supplier Invoice', standard: true, fileName: 'Supplier_Inv'}},
        {Document: {doctype_id: 81, customeraccess: 0, fieldname: 'supplierPackingList', folder: 'packing-lists', title: 'Supplier Packing List', standard: true, fileName: 'Supplier_PL'}},
        {Document: {doctype_id: 82, customeraccess: 1, fieldname: 'dummyInvoice', folder: 'invoices', title: 'Dummy Invoice', standard: false, fileName: 'Dummy_Inv'}},
        {Document: {doctype_id: 83, customeraccess: 1, fieldname: 'dummyPackingList', folder: 'packing-lists', title: 'Dummy Packing List', standard: false, fileName: 'Dummy_PL'}},
        {Document: {doctype_id: 51, customeraccess: 1, fieldname: 'billOfLading', folder: 'others', title: 'Bill of Lading', standard: true, fileName: 'BL'}},
        {Document: {doctype_id: 53, customeraccess: 1, fieldname: 'certificateOrigin', folder: 'others', title: 'Certificate of Origin', standard: true, fileName: 'COO'}},
        {Document: {doctype_id: 57, customeraccess: 1, fieldname: 'phytoCertificate', folder: 'others', title: 'Phyto Certificate', standard: true, fileName: 'Phyto'}},
        // {Document: {doctype_id: 64, customeraccess: 1, fieldname: 'cooPhytoCertificate', folder: 'others', title: 'COO / Phyto Cert', standard: false, fileName: null}},
        {Document: {doctype_id: 65, customeraccess: 1, fieldname: 'packingDeclaration', folder: 'others', title: 'Packing Declaration', standard: false, fileName: 'Packing_Declaration'}},
        {Document: {doctype_id: 66, customeraccess: 1, fieldname: 'insuranceCertificate', folder: 'others', title: 'Insurance Certificate', standard: false, fileName: 'Insurance'}},
        // {Document: {doctype_id: 54, customeraccess: 1, fieldname: 'packingInsurance', folder: 'others', title: 'Packing/Insurance', standard: false, fileName: null}},
        {Document: {doctype_id: 56, customeraccess: 1, fieldname: 'clearanceInvoice', folder: 'invoices', title: 'Clearance Invoice', standard: false, fileName: 'Clearance_Inv'}},
        {Document: {doctype_id: 58, customeraccess: 1, fieldname: 'clearancePackingList', folder: 'packing-lists', title: 'Clearance Packing List', standard: false, fileName: 'Clearance_PL'}},
        {Document: {doctype_id: 59, customeraccess: 1, fieldname: 'kilnDryCertificate', folder: 'others', title: 'Kiln Dry Certificate', standard: false, fileName: 'KD_Certificate'}},
        // {Document: {doctype_id: 60, customeraccess: 1, fieldname: 'clearanceInvPlKdcert', folder: 'others', title: 'Clearance Inv,PL,KD Cert', standard: false, fileName: null}},
        {Document: {doctype_id: 69, customeraccess: 1, fieldname: 'tallySheet', folder: 'others', title: 'Tally Sheet', standard: true, fileName: 'Tally_Sheet'}},
        {Document: {doctype_id: 70, customeraccess: 1, fieldname: 'forexExchange', folder: 'others', title: 'Forex Exchange', standard: true, fileName: 'Forex'}},
        {Document: {doctype_id: 71, customeraccess: 1, fieldname: 'fumigationCertificate', folder: 'others', title: 'Fumigation Certificate', standard: false, fileName: 'Fumigation'}},
        {Document: {doctype_id: 72, customeraccess: 1, fieldname: 'eutr', folder: 'others', title: 'EUTR Letter', standard: false, fileName: 'EUTR_Letter'}},
        {Document: {doctype_id: 74, customeraccess: 1, fieldname: 'importPermit', folder: 'others', title: 'Import Permit', standard: false, fileName: 'IP'}},
        // {Document: {doctype_id: 73, customeraccess: 1, fieldname: 'others', folder: 'others', title: 'Others', standard: false, fileName: null}},
        {Document: {doctype_id: 202, customeraccess: 1, fieldname: 'beneficiaryCertificate', folder: 'others', title: 'Beneficiary Certificate', standard: false, fileName: 'BC'}},
        {Document: {doctype_id: 203, customeraccess: 1, fieldname: 'gradeCertificate', folder: 'others', title: 'Certificate of Grade', standard: false, fileName: 'Grade_Certificate'}},
        {Document: {doctype_id: 204, customeraccess: 1, fieldname: 'clearanceLetter', folder: 'others', title: 'Clearance Letter', standard: false, fileName: 'Clearance_Letter'}},
        {Document: {doctype_id: 205, customeraccess: 1, fieldname: 'declarationForm', folder: 'others', title: 'Export Customs Declaration Form', standard: false, fileName: 'Export_Declaration'}},
        {Document: {doctype_id: 206, customeraccess: 1, fieldname: 'ispmDeclaration', folder: 'others', title: 'ISPM Certificate', standard: false, fileName: 'ISPM'}},
        {Document: {doctype_id: 207, customeraccess: 1, fieldname: 'mytlasCertficate', folder: 'others', title: 'MYTLAS Certificate', standard: false, fileName: 'MYTLAS'}},
        {Document: {doctype_id: 208, customeraccess: 1, fieldname: 'vlegalFlegtLicense', folder: 'others', title: 'VLegal/Flegt License', standard: false, fileName: 'V-Legal_FLEGT'}},
        {Document: {doctype_id: 209, customeraccess: 1, fieldname: 'eutrComplianceFile', folder: 'others', title: 'EUTR Compliance File', standard: false, fileName: 'EUTR'}},
        {Document: {doctype_id: 210, customeraccess: 1, fieldname: 'inspectionReport', folder: 'others', title: 'Inspection Report', standard: false, fileName: 'Inspection_Report'}},
        {Document: {doctype_id: 999, customeraccess: 0, fieldname: 'others', folder: 'others', title: 'Others', standard: false, fileName: 'Others'}}
    ],
    allShippingCommissionDocuments: [
        {Document: {doctype_id: 200, customeraccess: 1, fieldname: 'fullSet', folder: 'others', title: 'Full Document Set', standard: true, fileName: 'Full_Set'}},
        {Document: {doctype_id: 5, customeraccess: 1, fieldname: 'coverNote', folder: 'others', title: 'Cover Note', standard: true, fileName: 'Cover_Note'}},
        {Document: {doctype_id: 74, customeraccess: 1, fieldname: 'importPermit', folder: 'others', title: 'Import Permit', standard: true, fileName: 'IP'}},
        {Document: {doctype_id: 98, customeraccess: 1, fieldname: 'ipa', folder: 'others', title: 'IPA', standard: false, fileName: 'IPA'}},
        {Document: {doctype_id: 7, customeraccess: 1, fieldname: 'lca', folder: 'others', title: 'Letter of Credit Advice', standard: true, fileName: 'LCA'}},
        {Document: {doctype_id: 8, customeraccess: 1, fieldname: 'taxInvoice', folder: 'invoices', title: 'Invoice', standard: false, fileName: 'Tax_Inv'}},
        {Document: {doctype_id: 3, customeraccess: 1, fieldname: 'packingList', folder: 'packing-lists', title: 'Packing List', standard: false, fileName: 'PL'}},
        {Document: {doctype_id: 52, customeraccess: 0, fieldname: 'supplierInvoice', folder: 'invoices', title: 'Supplier Invoice', standard: false, fileName: 'Supplier_Inv'}},
        {Document: {doctype_id: 81, customeraccess: 0, fieldname: 'supplierPackingList', folder: 'packing-lists', title: 'Supplier Packing List', standard: false, fileName: 'Supplier_PL'}},
        {Document: {doctype_id: 82, customeraccess: 1, fieldname: 'dummyInvoice', folder: 'invoices', title: 'Dummy Invoice', standard: false, fileName: 'Dummy_Inv'}},
        {Document: {doctype_id: 83, customeraccess: 1, fieldname: 'dummyPackingList', folder: 'packing-lists', title: 'Dummy Packing List', standard: false, fileName: 'Dummy_PL'}},
        {Document: {doctype_id: 51, customeraccess: 1, fieldname: 'billOfLading', folder: 'others', title: 'Bill of Lading', standard: false, fileName: 'BL'}},
        {Document: {doctype_id: 53, customeraccess: 1, fieldname: 'certificateOrigin', folder: 'others', title: 'Certificate of Origin', standard: false, fileName: 'COO'}},
        {Document: {doctype_id: 57, customeraccess: 1, fieldname: 'phytoCertificate', folder: 'others', title: 'Phyto Certificate', standard: false, fileName: 'Phyto'}},
        {Document: {doctype_id: 64, customeraccess: 1, fieldname: 'cooPhytoCertificate', folder: 'others', title: 'COO / Phyto Cert', standard: false, fileName: 'COO_Phyto'}},
        {Document: {doctype_id: 65, customeraccess: 1, fieldname: 'packingDeclaration', folder: 'others', title: 'Packing Declaration', standard: false, fileName: 'Packing_Declaration'}},
        {Document: {doctype_id: 66, customeraccess: 1, fieldname: 'insuranceCertificate', folder: 'others', title: 'Insurance Certificate', standard: false, fileName: 'Insurance'}},
        {Document: {doctype_id: 54, customeraccess: 1, fieldname: 'packingInsurance', folder: 'others', title: 'Packing/Insurance', standard: false, fileName: 'Packing_Insurance'}},
        {Document: {doctype_id: 56, customeraccess: 1, fieldname: 'clearanceInvoice', folder: 'invoices', title: 'Clearance Invoice', standard: false, fileName: 'Clearance_Inv'}},
        {Document: {doctype_id: 58, customeraccess: 1, fieldname: 'clearancePackingList', folder: 'packing-lists', title: 'Clearance Packing List', standard: false, fileName: 'Clearance_PL'}},
        {Document: {doctype_id: 59, customeraccess: 1, fieldname: 'kilnDryCertificate', folder: 'others', title: 'Kiln Dry Certificate', standard: false, fileName: 'KD_Certificate'}},
        {Document: {doctype_id: 60, customeraccess: 1, fieldname: 'clearanceInvPlKdcert', folder: 'others', title: 'Clearance Inv,PL,KD Cert', standard: false, fileName: 'Inv_PL_KD'}},
        {Document: {doctype_id: 69, customeraccess: 1, fieldname: 'tallySheet', folder: 'others', title: 'Tally Sheet', standard: false, fileName: 'Tally_Sheet'}},
        {Document: {doctype_id: 70, customeraccess: 1, fieldname: 'forexExchange', folder: 'others', title: 'Forex Exchange', standard: false, fileName: 'Forex'}},
        {Document: {doctype_id: 71, customeraccess: 1, fieldname: 'fumigationCertificate', folder: 'others', title: 'Fumigation Certificate', standard: false, fileName: 'Fumigation'}},
        {Document: {doctype_id: 72, customeraccess: 1, fieldname: 'eutr', folder: 'others', title: 'EUTR Letter', standard: false, fileName: 'EUTR_Letter'}},
        {Document: {doctype_id: 999, customeraccess: 0, fieldname: 'others', folder: 'others', title: 'Others', standard: false, fileName: 'Others'}}
        // {Document: {doctype_id: 73, customeraccess: 1, fieldname: 'others', folder: 'others', title: 'Others', standard: false, fileName: null}}
    ],
    allWarehousePurchaseRequestDocuments: [],
    allSupplierDocuments: [
        {Document: {doctype_id: 301, customeraccess: 0, fieldname: 'supplierFscCert', folder: 'others', title: 'Supplier FSC Certificate', standard: true, fileName: 'FSC'}},
        {Document: {doctype_id: 302, customeraccess: 0, fieldname: 'supplierPefcCert', folder: 'others', title: 'Supplier PEFC Certificate', standard: true, fileName: 'PEFC'}}
    ],
    allDocuments: {
        CommContractDocuments: [],
        ContractDocuments: [],
        ExportPurchaseRequestDocuments: [],
        FinanceDocuments: [],
        LocalSaleDocuments: [],
        NcrDocuments: [],
        NewCommContractDocuments: [],
        NewContractDocuments: [],
        NewContractRequestDocuments: [],
        NewExportPurchaseRequestDocuments: [],
        NewNcrDocuments: [],
        NewPurchaseOrderDocuments: [],
        PurchaseOrderDocuments: [],
        ShippingCommissionDocuments: [],
        ShippingDirectDocuments: [],
        ShippingEutrDocuments: [],
        SupplierDocuments: [],
        WarehousePurchaseRequestDocuments: []
    },
    allStandardShippingDocuments: [],
    current: {
        Document: {
            id: null,
            categories: null,
            doctype_id: null,
            categories_types: {},
            customeraccess: 2000,
            fieldname: null,
            folder: 'others',
            title: null,
            standard: true,
            fileName: null,
            autogenerated: null,
            linked: null,
            linked_url: null
        }
    },
    update: {
        Document: {}
    },
    documentCategories: [
        { value: 'CommContractDocuments', text: 'CommContractDocuments' },
        { value: 'ContractDocuments', text: 'ContractDocuments' },
        { value: 'ExportPurchaseRequestDocuments', text: 'ExportPurchaseRequestDocuments' },
        { value: 'FinanceDocuments', text: 'FinanceDocuments' },
        { value: 'LocalSaleDocuments', text: 'LocalSaleDocuments' },
        { value: 'NcrDocuments', text: 'NcrDocuments' },
        { value: 'NewCommContractDocuments', text: 'NewCommContractDocuments' },
        { value: 'NewContractDocuments', text: 'NewContractDocuments' },
        { value: 'NewContractRequestDocuments', text: 'NewContractRequestDocuments' },
        { value: 'NewExportPurchaseRequestDocuments', text: 'NewExportPurchaseRequestDocuments' },
        { value: 'NewNcrDocuments', text: 'NewNcrDocuments' },
        { value: 'NewPurchaseOrderDocuments', text: 'NewPurchaseOrderDocuments' },
        { value: 'PurchaseOrderDocuments', text: 'PurchaseOrderDocuments' },
        { value: 'ShippingCommissionDocuments', text: 'ShippingCommissionDocuments' },
        { value: 'ShippingDirectDocuments', text: 'ShippingDirectDocuments' },
        { value: 'ShippingEutrDocuments', text: 'ShippingEutrDocuments'},
        { value: 'SupplierDocuments', text: 'SupplierDocuments' },
        { value: 'WarehousePurchaseRequestDocuments', text: 'WarehousePurchaseRequestDocuments' }
    ],
    documentFolders: [
        { value: 'contracts', text: 'contracts' },
        { value: 'invoices', text: 'invoices' },
        { value: 'ncrs', text: 'ncrs' },
        { value: 'others', text: 'others' },
        { value: 'packing-lists', text: 'packingLists' },
        { value: 'payments', text: 'payments' },
        { value: 'pos', text: 'pos' },
        { value: 'eutr', text: 'eutr' }
    ],
}

const getters = {
    allDocuments: state => {
        return state.allDocuments;
    },
    allNewContractDocuments: state => {
        return state.allNewContractDocuments;
    },
    allNewCommContractDocuments: state => {
        return state.allNewCommContractDocuments;
    },
    allContractDocuments: state => {
        return state.allContractDocuments;
    },
    allExportPurchaseRequestDocuments: state => {
        return state.allExportPurchaseRequestDocuments;
    },
    allFinanceDocuments: state => {
        return state.allFinanceDocuments;
    },
    allCommContractDocuments: state => {
        return state.allCommContractDocuments;
    },
    allLocalSaleDocuments: state => {
        return state.allLocalSaleDocuments;
    },
    allNcrDocuments: state => {
        return state.allNcrDocuments;
    },
    allNewExportPurchaseRequestDocuments: state => {
        return state.allNewExportPurchaseRequestDocuments;
    },
    allNewNcrDocuments: state => {
        return state.allNewNcrDocuments;
    },
    allNewPurchaseOrderDocuments: state => {
        return state.allNewPurchaseOrderDocuments;
    },
    allPurchaseOrderDocuments: state => {
        return state.allPurchaseOrderDocuments;
    },
    allShippingCommissionDocuments: state => {
        return state.allShippingCommissionDocuments
    },
    allShippingDirectDocuments: state => {
        return state.allShippingDirectDocuments
    },
    allWarehousePurchaseRequestDocuments: state => {
        return state.allWarehousePurchaseRequestDocuments;
    },
    allSupplierDocuments: state => {
        return state.allSupplierDocuments;
    },
    allStandardShippingDocuments: state => {
        return state.allStandardShippingDocuments;
    },
    documentCategories: state => {
        return state.documentCategories
    },
    documentFolders: state => {
        return state.documentFolders
    },
    getField
}

const actions = {
    createStandardShippingDocument({ state }) {
        const document = _.cloneDeep( state.current.Document )
        return new Promise((resolve, reject) => {
            api
                .post('/documents/shipping/standard',{
                    document: document,
                })
                .then((response)=>{
                    // if(response.data.status == 'success') {
                    //     resolve('done')
                    // } else {
                    //     resolve('error')
                    // }
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
                .finally(()=>{
                    resolve('error')
                })
        })
    },
    deleteDocument({commit},document_id){
        return new Promise((resolve, reject) => {
            api
                .delete("/documents/" + document_id)
                .then(response => {
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    deleteStandardShippingDocumentById({commit},document_id) {
        return new Promise((resolve, reject) => {
            api
                .delete("/documents/shipping/standard/" + document_id)
                .then(response => {
                    // resolve('done')
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    searchAssetDocument({commit},conditions){
        return new Promise((resolve, reject) => {
            api.get('/documents',{
                    params: {
                        conditions: conditions,
                    }
                })
                .then((response)=>{
                    resolve(response.data)
                })
                .catch(error=>{
                    reject(error);
                })
        })
    },
    getContractDocumentsById({commit},contract_id){
        return new Promise((resolve, reject) => {
            api
                .get("/documents/contract/" + contract_id,{
                })
                .then(response => {
                    resolve(response.data.data)
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getExportPurchaseRequestDocumentsById({commit},epr_id) {
        return new Promise((resolve, reject) => {
            api
                .get("/documents/epr/" + epr_id,{
                })
                .then(response => {
                    resolve(response.data.data)
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getNcrDocumentsById({commit}, ncrId) {
        return new Promise((resolve, reject) => {
            api
                .get("/documents/ncr/" + ncrId,{
                })
                .then(response => {
                    resolve(response.data.data)
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getPurchaseOrderDocumentsById({state}, ids){
        let docTypes = []
        state.allDocuments.PurchaseOrderDocuments.forEach((document)=>{
            docTypes.push(document.Document.doctype_id)
        })
        //remove the appendix grading documents - need to change this to robust code
        docTypes = docTypes.filter(docType => {
            return [95,101,102].includes(docType) == false
        })
        //
        return new Promise((resolve, reject) => {
            api
                .get("/documents/contract/" + ids.contract_id,{
                    params: {
                        conditions: [
                            {
                                field: 'asset_documents.doctype_id',
                                value: docTypes
                            }
                        ]
                    }
                })
                .then(response => {
                    const mainDocs = response.data.data
                    if(ids.purchaseorder_id) {
                        api
                            .get("/documents/purchaseorder/" + ids.purchaseorder_id)
                            .then((response) => {
                                if(Array.isArray(response.data.data)) {
                                    const otherDocs = response.data.data
                                    resolve([...mainDocs, ...otherDocs])
                                } else {
                                    resolve(mainDocs)
                                }
                            })
                            .catch(()=> {
                                resolve(mainDocs)
                            })
                    } else {
                        resolve(mainDocs)
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getShippingDocumentsById({commit},contract_id){
        return new Promise((resolve, reject) => {
            api
                .get("/documents/shipping/" + contract_id)
                .then(response => {
                    resolve(response.data.data)
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getStandardDocuments({commit}){
        return new Promise((resolve, reject) => {
            api
                .get('/documents/standard')
                .then(response => {
                    commit('setStandardDocuments',response.data.data);
                    resolve('done')
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getAllStandardShippingDocuments({commit}) {
        return new Promise((resolve, reject) => {
            api
                .get('/documents/shipping/standard')
                .then(response => {
                    commit('setStandardShippingDocuments',response.data.data);
                    resolve('done')
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getStandardShippingDocumentToUpdateById({commit}, documentId) {
        return new Promise((resolve, reject) => {
            api
                .get('/documents/shipping/standard/' + documentId)
                .then(response => {
                    commit('setStandardShippingDocumentToUpdate', response.data.data[0]);
                    resolve('done')
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    resetAllDocuments ({commit}) {
        log('Resetting all documents')
        commit('resetState')
        return true
    },
    resetCurrentState({commit}) {
        commit('resetCurrentState')
        return true
    },
    resetStandardShipmentDocumentState ({commit}) {
        commit('resetStandardShipmentDocumentState')
        return true
    },
    resetStandardShippingDocumentToUpdate({commit}) {
        commit('resetStandardShippingDocumentToUpdate')
        return true
    },
    sendContainerInspectionReportRequestToSupplier({commit}, payload) {
        return new Promise ((resolve, reject) => {
            api
                .put('/shipments/' + payload.contractId + '/send-container-report-request-to-supplier',{
                    contacts: payload.contacts,
                    fileLocation: payload.fileLocation
                })
                .then(response => {
                    if(response.data.status == 'success') {
                        resolve('done')
                    } else {
                        resolve('error')
                    }
                })
                .catch(error => {
                    reject(error);
                })
        })
    },
    sendImportPermitToSupplier({commit}, payload) {
        return new Promise ((resolve, reject) => {
            api
                .put('/shipments/' + payload.contractId + '/send-import-permit-to-supplier',{
                    contacts: payload.contacts,
                    fileLocation: payload.fileLocation
                })
                .then(response => {
                    if(response.data.status == 'success') {
                        resolve('done')
                    } else {
                        resolve('error')
                    }
                })
                .catch(error => {
                    reject(error);
                })
        })
    },
    updateStandardShippingDocument({ state }) {
        const document = _.cloneDeep(state.update.Document)
        return new Promise((resolve, reject) => {
            api
                .put('/documents/shipping/standard/' + state.update.Document.id,{
                    document: document
                })
                .then((response)=>{
                    // if(response.data.status == 'success') {
                    //     resolve('done')
                    // } else {
                    //     resolve('error')
                    // }
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
                .finally(()=>{
                    resolve('error')
                })
        })
    },
}

const mutations = {
    resetCurrentState(state) {
        state.current.Document = getDefaultState().Document
    },
    resetState(state){
        log('Resetting all documents')
        state.allNewContractDocuments = getDefaultState().allNewContractDocuments
        state.allNewCommContractDocuments = getDefaultState().allNewCommContractDocuments
        state.allNcrDocuments = getDefaultState().allNcrDocuments
        state.allNewNcrDocuments = getDefaultState().allNewNcrDocuments
        state.allContractDocuments = getDefaultState().allContractDocuments
        state.allCommContractDocuments = getDefaultState().allCommContractDocuments
        state.allExportPurchaseRequestDocuments = getDefaultState().allExportPurchaseRequestDocuments
        state.allNewPurchaseOrderDocuments = getDefaultState().allNewPurchaseOrderDocuments
        state.allNewExportPurchaseRequestDocuments = getDefaultState().allNewExportPurchaseRequestDocuments
        state.allPurchaseOrderDocuments = getDefaultState().allPurchaseOrderDocuments
        state.allShippingCommissionDocuments = getDefaultState().allShippingCommissionDocuments
        state.allShippingDirectDocuments = getDefaultState().allShippingDirectDocuments
        state.allSupplierDocuments = getDefaultState().allSupplierDocuments
        return true
    },
    resetStandardShipmentDocumentState(state) {
        log('Resetting all shipping documents')
        state.allDocuments = getDefaultState().allDocuments
        state.allStandardShippingDocuments = getDefaultState().allStandardShippingDocuments
        state.documentCategories = getDefaultState().documentCategories
        state.documentFolders = getDefaultState().documentFolders
        return true
    },
    resetStandardShippingDocumentToUpdate() {
        state.update.Document = getDefaultState().Document
    },
    setStandardDocuments(state, data) {
        log('Getting all standard documents')
        state.allCommContractDocuments = data.CommContractDocuments
        state.allContractDocuments = data.ContractDocuments
        state.allExportPurchaseRequestDocuments = data.ExportPurchaseRequestDocuments
        state.allNcrDocuments = data.NcrDocuments
        state.allNewCommContractDocuments = data.NewCommContractDocuments
        state.allNewContractDocuments = data.NewContractDocuments
        state.allNewExportPurchaseRequestDocuments = data.NewExportPurchaseRequestDocuments
        state.allNewNcrDocuments = data.NewNcrDocuments
        state.allNewPurchaseOrderDocuments = data.NewPurchaseOrderDocuments
        state.allPurchaseOrderDocuments = data.PurchaseOrderDocuments
        state.allShippingCommissionDocuments = data.ShippingCommissionDocuments
        state.allShippingDirectDocuments = data.ShippingDirectDocuments
        state.allSupplierDocuments = data.SupplierDocuments
        return true
    },
    setStandardShippingDocuments(state, data) {
        log('setting all standard shipping documents')
        state.allDocuments.CommContractDocuments = data.CommContractDocuments
        state.allDocuments.ContractDocuments = data.ContractDocuments
        state.allDocuments.ExportPurchaseRequestDocuments = data.ExportPurchaseRequestDocuments
        state.allDocuments.FinanceDocuments = data.FinanceDocuments
        state.allDocuments.NcrDocuments = data.NcrDocuments
        state.allDocuments.NewCommContractDocuments = data.NewCommContractDocuments
        state.allDocuments.NewContractDocuments = data.NewContractDocuments
        state.allDocuments.NewExportPurchaseRequestDocuments = data.NewExportPurchaseRequestDocuments
        state.allDocuments.NewNcrDocuments = data.NewNcrDocuments
        state.allDocuments.NewPurchaseOrderDocuments = data.NewPurchaseOrderDocuments
        state.allDocuments.PurchaseOrderDocuments = data.PurchaseOrderDocuments
        state.allDocuments.ShippingCommissionDocuments = data.ShippingCommissionDocuments
        state.allDocuments.ShippingDirectDocuments = data.ShippingDirectDocuments
        state.allDocuments.ShippingEutrDocuments = data.ShippingEutrDocuments
        state.allDocuments.SupplierDocuments = data.SupplierDocuments

        Object.entries(state.allDocuments).forEach((cat) => {
            if(cat[1] != null && cat[1].length > 0) {
                cat[1].forEach((document, idx) => {
                    //check type
                    const types = document['Document']['categories_types']
                    const standard = types[cat[0]] == 1 ? true : false
                    state.allDocuments[cat[0]][idx]['Document']['standard'] = standard
                    //add sortId
                    const sortIds = document['Document']['categories_sortid']
                    if (cat[0] in sortIds) {
                        state.allDocuments[cat[0]][idx]['Document']['sortId'] = sortIds[cat[0]]
                    } else {
                        state.allDocuments[cat[0]][idx]['Document']['sortId'] = 99
                    }
                })
                state.allDocuments[cat[0]].sort((a, b) => {
                    if (a.Document.sortId > b.Document.sortId) return 1
                    if (a.Document.sortId < b.Document.sortId) return -1
                    return 0
                })
            }
        })

        let standardShippingDocuments = []
        Object.entries(data).forEach((cat) => {
            if(cat[1] != null && cat[1].length > 0) {
                cat[1].forEach(document => {
                    const check = standardShippingDocuments.find(d => d.Document.id === document.Document.id)
                    if (!check) {
                        standardShippingDocuments.push(document)
                    }
                })
            }
        })
        if(standardShippingDocuments.length > 1) {
            standardShippingDocuments.sort((a, b) => {
                if (a.Document.doctype_id > b.Document.doctype_id) return -1
                if (a.Document.doctype_id < b.Document.doctype_id) return 1
                return 0
            })
        }
        state.allStandardShippingDocuments = standardShippingDocuments
        return true
    },
    setStandardShippingDocumentToUpdate(state, document) {
        state.update.Document = document.Document
        if(Array.isArray(state.update.Document.categories_types)) state.update.Document.categories_types = {}
        if(Array.isArray(state.update.Document.categories_sortid)) state.update.Document.categories_sortid = {}
    },
    updateField
}

export const document = {
    namespaced,
    state,
    getters,
    actions,
    mutations
}