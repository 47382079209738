export function idleTime (options){
    let defaultOptions = {
        timeoutPeriod: 60000 * 60 * 2, //milliseconds x minutes x hour
    };
    options = {...defaultOptions, ...options}
    let timeOut = null
    // localStorage.setItem("counter", '0');

    window.onload = incrementCounter;
    window.onscroll = resetUserActivity;
    window.onmousemove = resetUserActivity;
    window.ondblclick = resetUserActivity;
    window.oncontextmenu = resetUserActivity;
    window.onclick = resetUserActivity;
    window.onkeypress = resetUserActivity;
    window.onpageshow = resetUserActivity;
    window.onresize = resetUserActivity;
    window.onfocus = incrementCounter;
    window.ondrag = resetUserActivity;
    window.oncopy = resetUserActivity;
    window.oncut = resetUserActivity;
    window.onpaste = resetUserActivity;

    function resetUserActivity() {
        resetTimer();
        return true
    }
    function incrementCounter() {
        // checkIfUserLoggedOut();
        if (localStorage.getItem("counter") == "NaN") {
            localStorage.setItem("counter", "0");
        } else {
            const counter = parseInt(localStorage.getItem("counter")) + 1;
            localStorage.setItem("counter", counter.toString());
        }
        console.log('Counter incremented - ' + new Date()) //temp
        resetTimer();
        return true
    }

    function handleIdleTimedOut() {
        window.sharedCounter = localStorage.getItem("counter");
        if (window.localCounter == window.sharedCounter) {
            localStorage.setItem("timedOut", "true");
            window.dispatchEvent(new Event('storage'));
            console.log('Timed out ' + new Date())
        }
        return true
    }

    function resetTimer() {
        window.localCounter = localStorage.getItem("counter");
        clearTimeout(timeOut);
        timeOut = setTimeout(handleIdleTimedOut, options.timeoutPeriod);
        localStorage.removeItem("timedOut")
        console.log('Timer reset - ' + new Date());
        return true
    };

    function checkIfIdle() {
        if (localStorage.getItem("timedOut") == "true") {
            return true
        }
        return false
    }
    function resetCounter() {
        localStorage.setItem("counter", '0');
    }

    return {
        checkIfIdle
    }
}