//messages.js
export default {
    general: '一般',
    overview: '概要',
    customizer: 'カスタマイザ',
    applications: 'アプリケーション',
    features: '特徴',
    components: 'コンポーネント',
    salesAndVisitStats: 'セールス＆ビジター統計',
    orderAndProjectStats: '注文とプロジェクトの統計',
    fitnessStats: 'フィットネスの統計',
    supportAndUsefulWidgets: 'サポートと便利なウィジェット',
    dashboard: 'ダッシュボード',
    dashboardOverview: 'ダッシュボードの概要',
    dashboardv1: 'Dashboard V1',
    dashboardv2: 'Dashboard V2',
    widgets: 'ウィジェット',
    horizontalMenu: '水平メニュー',
    pages: 'ページ',
    gallery: 'ギャラリー',
    pricing: '価格設定',
    pricing1: '価格設定1',
    pricing2: '価格設定2',
    blank: 'ブランク',
    session: 'セッション',
    signUp1: 'サインアップ1',
    signUp2: 'サインアップ2',
    login1: 'ログイン1',
    login2: 'ログイン2',
    lockScreen: 'ロック画面',
    uiElements: 'UI要素',
    buttons: 'ボタン',
    cards: 'カード',
    checkbox: 'チェックボックス',
    carousel: 'カルーセル',
    chips: 'チップス',
    datepicker: 'Datepicker',
    dialog: 'ダイアログ',
    grid: 'グリッド',
    input: '入力',
    list: 'リスト',
    menu: 'メニュー',
    progress: '進捗',
    radio: '無線',
    select: '選択',
    slider: 'スライダー',
    snackbar: 'スナックバー',
    tabs: 'タブ',
    toolbar: 'ツールバー',
    tooltip: 'ツールチップ',
    timepicker: 'タイムピッカー',
    forms: 'フォーム',
    formValidation: 'フォーム検証',
    stepper: 'ステッパー',
    charts: 'チャート',
    vueChartjs: 'Chartjsビュー',
    vueEcharts: 'Vue Echarts',
    icons: 'アイコン',
    themify: 'テーマ',
    material: '材料',
    tables: 'テーブル',
    standard: '標準',
    slots: 'スロット',
    selectable: '選択可能',
    searchRow: '検索行',
    maps: '地図',
    googleMaps: 'グーグルマップ',
    leafletMaps: 'リーフレットマップ',
    jvectorMap: 'Jvectorマップ',
    inbox: '受信トレイ',
    users: 'ユーザー',
    userProfile: 'ユーザープロファイル',
    usersList: 'ユーザーリスト',
    calendar: 'カレンダー',
    editor: '編集者',
    quillEditor: 'クイルエディタ',
    wYSIWYG: 'WYSIWYG',
    vue2Dragula: 'Vue2 Dragula',
    dragAndDrop: 'ドラッグアンドドロップ',
    vueDraggable: 'Vue Draggable',
    draggableResizeable: 'ドラッグ可能なサイズ変更可能',
    chat: 'チャット',
    todo: 'すべて',
    modules: 'モジュール',
    user: 'ユーザー',
    miscellaneous: '雑',
    promo: 'プロモーション',
    connections: '接続',
    follow: 'フォローする',
    unfollow: 'アンフォロー',
    basicValidation: '基本検証',
    validationWithSubmitAndclear: 'サブミット＆クリアによる検証',
    step1: 'ステップ1',
    step2: 'ステップ2',
    step3: 'ステップ3',
    continue: '持続する',
    cancel: 'キャンセル',
    totalEarnings: '総収入',
    onlineRevenue: 'オンライン収入',
    offlineRevenue: 'オフライン収益',
    marketingExpenses: 'マーケティング費用',
    newCustomers: '新しいお客様',
    visitAndSalesStatistics: '訪問＆セールス統計',
    collapse: '崩壊',
    reload: 'リロード',
    close: '閉じる',
    bandwidthUsage: '帯域幅の使用',
    shoppingCart: 'ショッピングカート',
    totalDownloads: '合計ダウンロード数',
    productSales: '製品販売',
    projectStatus: 'プロジェクトのステータス',
    averageSteps: '平均ステップ',
    todaysDistance: "今日の距離",
    todaysStep: "今日のステップ",
    todaysGoal: "今日のゴール",
    calories: 'カロリー',
    weeklySummary: 'ウィークリーサマリー',
    supportTickets: 'チケットをサポートする',
    todoList: 'To Doリスト',
    newsletterCampaign: 'ニュースレターキャンペーン',
    currentVisitors: '現在の訪問者',
    newMembers: '新メンバー',
    addNewBlog: '新しいブログを追加',
    add: '追加',
    logOut: 'ログアウト',
    totalAppMemory: 'アプリケーションメモリの合計',
    totalMemoryUsed: '使用された合計メモリ',
    unreadMail: '12未読メール',
    feedback: 'フィードバック',
    flatButton: 'フラットボタン',
    raisedButton: '上げたボタン',
    gradientButton: 'グラデーションボタン',
    buttonDropdownVariants: 'ボタンドロップダウンバリアント',
    buttonToggle: 'ボタントグル',
    icon: 'アイコン',
    normal: 'ノーマル',
    disabled: '無効',
    floating: 'フローティング',
    loaders: 'ローダー',
    sizing: 'サイジング',
    outline: 'アウトライン',
    round: '円形',
    block: 'ブロック',
    search: 'サーチ',
    checkboxesBoolean: 'チェックボックスブール値',
    checkboxesArray: 'チェックボックス配列',
    checkboxesStates: 'チェックボックス - 状態',
    checkboxesColors: 'チェックボックス - 色',
    switches: 'スイッチ',
    default: 'デフォルト',
    customTransition: 'カスタムトランジション',
    customDelimiter: 'カスタム区切り文字',
    hideControls: 'コントロールを隠す',
    hideDelimiters: 'デリミタを隠す',
    colored: '色つき',
    label: 'ラベル',
    closeable: '閉じる',
    datePickerHorizontal: '日付ピッカー水平',
    datePickerVertical: '日付ピッカーの縦',
    datePickersInDialogAndMenu: '日付ピッカー - ダイアログとメニューで',
    datePickersWithModal: 'モーダルを持つ日付ピッカー',
    datePickersFormattingDate: '日付ピッカー - 日付の書式設定',
    simpleDialogs: 'シンプルなダイアログ',
    withoutActivator: 'アクチベータなし',
    scrollable: 'スクロール可能な',
    listOfAllMaterialIcons: 'すべてのマテリアルアイコンのリスト',
    arrowsAndDirectionsIcons: '矢印と方向アイコン',
    webAppIcons: 'WEB APPアイコン',
    CONTROLICONS: 'コントロールアイコン',
    TEXTEDITOR: 'テキストエディタ',
    LAYOUTICONS: 'レイアウト・アイコン',
    BRANDICONS: 'ブランドアイコン',
    newClients: '新しいクライアント',
    recurringClients: '定期的なクライアント',
    bounceRate: '直帰率',
    pageViews: 'ページビュー',
    usefulWidgets: '便利なウィジェット',
    sale: '販売',
    sales: '販売',
    invoices: '請求書',
    referrals: '紹介',
    serverLoad: 'サーバー負荷',
    recentSale: '最近の販売',
    supportRequest: 'サポートリクエスト',
    productSale: '製品販売',
    viewAll: 'すべてを見る',
    barChart: '棒グラフ',
    polarAreaChart: 'ポーラーエリアチャート',
    lineChart: '折れ線グラフ',
    radarChart: 'レーダーチャート',
    doughnutChart: 'ドーナツチャート',
    bubbleChart: 'バブルチャート',
    gradientLineChart: 'グラデーションラインチャート',
    pieChart: '円グラフ',
    funnelChart: 'ファンネルチャート',
    loginNow: 'ここでログイン',
    createAccount: 'アカウントを作成する',
    termsOfService: '利用規約',
    bySigningUpYouAgreeTo: 'サインアップすることで同意する',
    loginToAdmin: '管理者へのログイン',
    enterUsernameAndPasswordToAccessControlPanelOf: 'コントロールパネルにアクセスするためのユーザー名とパスワードを入力',
    havingAnAccount: 'アカウントをお持ちですか？',
    quickLinks: 'クイックリンク',
    plans: '予定',
    chooseThePlanThatWorksForYou: 'あなたに適したプランを選択してください。',
    monthly: '毎月',
    yearlyGet2MonthExtra: '毎年（2ヶ月追加',
    basic: 'ベーシック',
    pro: 'プロ',
    advanced: '上級',
    basicFree: '基本（無料）',
    startToBasic: 'ベーシックスタート',
    upgradeToPro: 'プロ仕様にアップグレードする。',
    upgradeToAdvance: 'アドバンスにアップグレードする',
    comparePlans: '計画比較',
    frequentlyAskedQuestions: 'よくある質問',
    defaultInput: 'デフォルト入力',
    activator: 'アクチベータ',
    hover: 'ホバー',
    menus: 'メニュー',
    indeterminate: '不確定',
    sizeAndWidth: 'サイズと幅',
    rotate: '回転する',
    determinate: '決定する',
    buffer: 'バッファ',
    radiosDefault: 'ラジオデフォルト',
    radiosDirectionRow: 'ラジオ方向行',
    radiosDirectionColumn: 'ラジオ方向欄',
    radiosColors: 'ラジオ - 色',
    switchesColors: 'スイッチ - 色',
    continuous: '連続',
    discrete: '離散',
    customColors: 'カスタム色',
    editableNumericValue: '編集可能な数値',
    contextualSnackbar: 'コンテクストスナックバー',
    showSnackbar: 'スナックバーを表示',
    centeredTabs: '中央のタブ',
    toolbarTabs: 'ツールバーのタブ',
    theDefaultColorToolbar: 'デフォルトカラーツールバー',
    appBar: 'アプリバー',
    appBarWithExtension: '拡張子付きのApp Bar',
    visibility: '可視性',
    right:'右',
    bottom: 'ボトム',
    left:'左',
    toggle: 'トグル',
    timePicker: '時間ピッカー',
    timePickerInDialogAndMenu: '時間ピッカー - ダイアログとメニューで',
    itemsAndHeaders: 'アイテムとヘッダー',
    selectableRows: '選択可能な行',
    headerCell: 'ヘッダーセル',
    expand: '展開する',
    recentChat: '最近のチャット',
    previousChats: '以前のチャット',
    addNewItems: '新しいアイテムを追加',
    addToDo: '追加する',
    yes: 'はい',
    ok: 'OK',
    activeUsers: 'アクティブユーザー',
    addNew: '新しく追加する',
    readMore: '続きを読む',
    assignNow: '今すぐ割り当て',
    totalRequest: '総リクエスト',
    pending: '保留中',
    update: '更新',
    updated10MinAgo: '1時間前に更新されました',
    addNewCustomer: '新規顧客を追加',
    name: '名',
    email: 'Eメール',
    editMember: 'メンバーの編集',
    scheduleDate: '予定日',
    title: 'タイトル',
    newEmails: '新しいメール',
    newEmail: '新しいメール',
    employeePayroll: '従業員の給与計算',
    forMostOfTheUsers: 'ほとんどのユーザーにとって',
    choosePlan: 'プランを選択',
    mega: 'メガ',
    master: 'マスター',
    forDeveloper: '開発者向け',
    forLargeEnterprises: '大企業向け',
    composeEmail: 'Eメールの作成',
    mailboxes: 'メールボックス',
    folders: 'フォルダ',
    inbox5: '受信トレイ（5）',
    draft: 'ドラフト',
    starred: 'スター付き',
    sentMessages: 'メッセージを送った',
    spam: 'スパム',
    delete: '削除',
    work: '作業',
    personal: 'パーソナル',
    manageFolders: 'フォルダの管理',
    contacts: '連絡先',
    settings: '設定',
    themeOptions: 'テーマのオプション',
    darkMode: 'ダークモード',
    collapseSidebar: '折りたたみサイドバー',
    boxLayout: 'ボックスレイアウト',
    rtlLayout: 'RTLレイアウト',
    backgroundImage: '背景画像',
    sidebarFilters: 'サイドバーフィルタ',
    danger: '危険',
    primary: '一次',
    warning: '警告',
    success: '成功',
    info: '情報',
    dailySales: '毎日の販売',
    trafficChannel: 'トラフィックチャネル',
    campaignPerformance: 'キャンペーンの掲載結果',
    goToCampaign: 'キャンペーンへ移動',
    fullScreen: '全画面表示',
    ecommerce: '電子商取引',
    shop: 'ショップ',
    cart: 'カート',
    total: '合計',
    totalPrice: '合計金額',
    viewCart: 'かごの中身を見る',
    checkout: 'チェックアウト',
    apply: '適用',
    noItemsFound: '項目は見つかりませんでした',
    billingDetails: '支払明細',
    placeOrder: '注文する',
    addNewCard: '新しいカードを追加',
    devicesShare: 'デバイスの共有',
    customerOverview: '顧客の概要',
    reviews: 'レビュー',
    weeklySales: '週刊セールス',
    recentOrders: '最近の注文',
    categorySales: 'カテゴリ販売',
    webAnalytics: 'ウェブ解析',
    topSelling: 'トップセリング',
    social: 'ソーシャル',
    newPost: '新しい投稿',
    publish: '公開する',
    magazine: 'マガジン',
    editUser: 'ユーザーの編集',
    addNewUser: '新しいユーザーを追加',
    dark: 'ダーク',
    light: '光',
    recentUsers: '最近のユーザー',
    activityAroundWorld: '世界のアクティビティ',
    adsIncome: '広告収入',
    recentComments: '最近のコメント',
    recentNotifications: '最近のお知らせ',
    messages: 'メッセージ',
    edit: '編集',
    topAuthors: 'トップ著者',
    marketingCampaign: 'マーケティングキャンペーン',
    articles: '記事',
    websiteTraffic: 'ウェブサイトのトラフィック',
    agency: '代理店',
    recent: '最近',
    totalPageViews: '合計ページビュー',
    impressions: 'インプレッション',
    deviceSeparations: 'デバイスの分割',
    browserStatics: 'ブラウザ統計',
    totalDownloading: 'ダウンロード総数',
    notifications: '通知',
    totalBookmarked: '合計ブックマーク済み',
    itemsDownloads: 'アイテムのダウンロード',
    itemsUploaded: 'アップロードされたアイテム',
    totalAnalytics: '合計分析',
    hotKeywords: '注目キーワード',
    MaleUsers: '男性ユーザー',
    serverStatus: 'サーバーの状態',
    purchaseVuely: 'Vuelyを購入する',
    letsGetInTouch: 'タッチしてみましょう',
    maleUsers: '男性ユーザー',
    femaleUsers: '女性ユーザー',
    androidUsers: 'Androidユーザー',
    iOSUsers: 'iOSユーザー',
    advance: '前進',
    routerAnimation: 'ルーターアニメーション',
    salesAndEarning: '販売と収益',
    netProfit: '純利益',
    totalExpences: '総輸出額',
    photos: '写真',
    adCampaignPerfomance: '広告キャンペーンのパフォーマンス',
    profitShare: '利益分配',
    topSellingTheme: 'トップセリングテーマ',
    newRequest: '新しいリクエスト',
    followers: 'フォロワー',
    mailbox: 'メールボックス',
    sent: '送信済み',
    birthdays: '誕生日',
    events: 'イベント',
    newsletter: 'ニュースレター',
    lastMonth: '先月',
    allTime: 'すべての時間',
    composeNewEmail: '新しいメールを作成する',
    activity: 'アクティビティ',
    message: 'メッセージ',
    selectTheme: 'テーマを選択',
    continueShopping: 'ショッピングを続ける',
    proceedToCheckout: 'チェックアウトに進む',
    headerFilters: 'ヘッダフィルタ',
    blog: 'ブログ',
    signUp: 'サインアップ',
    login: 'ログイン',
    news: 'ニュース',
    topHeadlines: 'トップ見出し',
    visitors: '訪問者',
    subscribers: '購読者',
    twitterFeeds: 'Twitterのフィード',
    extensions: '拡張機能',
    imageCropper: 'Image Cropper',
    videoPlayer: 'ビデオプレーヤー',
    dropzone: 'Dropzone',
    baseConfig: '基本構成',
    audioTrackAndPlaysInline: 'オーディオトラックとインライン再生',
    hlsLive: 'HLSライブ',
    forgotPassword: 'パスワードをお忘れですか',
    resetPassword: 'パスワードを再設定する',
    backToSignIn: '戻るにサインインする',
    enterYourEmailToSendYouAResetLink: 'リセットリンクを送信するためのメールアドレスを入力してください',
    pleaseEnterYourPasswordToReset: 'リセットするパスワードを入力してください',
    saas: 'SAAS',
    overallTrafficStatus: '全体のトラフィック状況',
    projectManagement: 'プロジェクト管理',
    projectTaskManagement: 'プロジェクトタスク管理',
    totalSales: 'トータルセールス',
    simple: "シンプル",
    transitions: "トランジション",
    tax: "税金",
    expenses: "経費",
    images: "イメージ",
    ratings: "評価",
    containAndCover: '内容物とカバー',
    height: '高さ',
    fixedRatio: '固定比率',
    gradients: 'グラジエント',
    sizeVariants: 'サイズの変更',
    newIcons: '新しいアイコン',
    webApplication: 'ウェブアプリケーション',
    person: '人',
    file: 'ファイル',
    comment: 'コメント',
    form: '形',
    hardware: 'ハードウェア',
    directional: '方向性',
    mapAliases: 'マップ（エイリアス）',
    dateAndTime: '日時',
    groups: "グループ",
    buttonGroups: "ボタングループ",
    itemGroups: "アイテムグループ",
    windows: "Windows",
    onBoarding: "乗車中",
    accountCreation: "アカウント作成",
    treeview: "ツリー表示",
    customSelectableIcons: "カスタム選択可能なアイコン",
    timelines: "タイムライン",
    scopedSlots: "スコープ付きスロット",
    asyncItems: "非同期項目",
    smallDots: "小さな点",
    iconDots: "アイコンドット",
    coloredDots: "色付きドット",
    oppositeSlot: "反対側のスロット",
    denseAlert: "密な警告",
    fixedTabs: "固定タブ",
    rightAlignedTabs: "右揃えのタブ",
    content: "コンテンツ",
    withSearch: "検索で",
    iconsAndText: "アイコンとテキスト",
    grow: "成長する",
    pagination: "ページネーション",
    customIcons: "カスタムアイコン",
    courses:"コース",
    coursesList:"コース一覧",
    courseDetail:"コース詳細",
    signIn:"サインイン",
    payment:"支払い",
    LearnWithYourConvenience:"便利に学ぶ",
    management:"管理",
    design:"設計",
    development:"開発",
    top:"上",
    new:"新しい",
    trending:"トレンド",
    bestseller:"ベスト・セラー",
    addToCart:"カートに追加",
    popularInstructors:"人気のインストラクター",
    moreCoursesFromJamesColt:"James Coltによる他のコース",
    whatYoWillLearn:"あなたが学ぶこと",
    description:"説明",
    aboutInstructor:"講師について",
    userSignIn:"ユーザーサインイン",
    guestCheckout:"ゲストチェックアウト",
    continueAsGuest:"ゲストとして続行",
    paymentOptions:"支払いオプション",
    offerCode:"オファーコード",
    enterCardDetails:"カードの詳細を入力",
    submit:"提出する",
    clear:"クリア",
    makePayment:"支払う",
    usage: "使用法",
    withContent: "コンテンツあり",
    customHeight: "カスタムの高さ"
}
