import { api } from "../../../api";
import {getField, updateField} from "vuex-map-fields";
import {isNumeric} from "../../../helpers/helpers";

const namespaced = {
    namespaced: true
}

const getDefaultState = () => {

}

const state = {
    current: {
        Salesitems: [
            {
                index: 0,
                Salesitem: {

                }
            }
        ]
    }
}

const getters = {
    getField,
    getSalesItems: (state) => (contract_id) => {}
}

const mutations = {
    updateField
}

const actions = {

}

export const posalesitem = {
    namespaced,
    state,
    getters,
    mutations,
    actions
}