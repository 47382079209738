import {getField, updateField} from "vuex-map-fields";
import {api} from "../../../api";
import _ from 'lodash';

const namespaced = {
    namespaced: true
}

const getDefaultState = () => {
    return {
        Purchaseorder: {
            id: null,
            contract_id: null,
            currency_id: 2,
            documents: [],
            footer_notes: [1,3,4,5,6,7,8,9],
            fsc: 0,
            incoterm_id: 2,
            incotermport: 0,
            incotermremark: null,
            notify_address_show: 0,
            paymentstatement_id: null,
            podate: new Date().toISOString().substr(0, 10),
            shippingweekstatement: null,
            showcustomerasbuyer: 1,
            title: null,
            Contract: {
                id: null,
                currency_id: 2,
                destinatione_id: null,
                is_export: null,
                isposigned: null,
                loadingport_id: null,
                ms_id: 1,
                office_id: 1,
                partial_no: null,
                partialShipments: [],
                productorigin_id: null,
                productgroup_id: null,
                revision_no: null,
                salescontact_id: null,
                salestype_id: 1,
                shippingport_id: null,
                title: null,
                version: null
            },
            Customer: {
                id: null,
                title: null
            },
            Office: {
                id: null,
                title: null
            },
            Supplier: {
                id: null,
                paymentstatement_id: null,
                title: null
            },
            Wpr: {
                id: null,
                title: null
            }
        },
    }
}

const state = {
    allActivePurchaseOrders: [],
    clone: {
        copyPurchaseOrder: 1,
        OriginalContract: {
            id: null,
            title: null,
        },
        Contract: {
            Purchaseorder: {}
        },
        Salesitems: []
    },
    current: {
        Purchaseorder: {
            id: null,
            contract_id: null,
            currency_id: 2,
            currencyrate: 0,
            documents: [],
            footer_notes: [1,3,4,5,6,7,8,9],
            fsc: 0,
            incoterm_id: 2,
            incotermport: 0,
            incotermremark: null,
            notify_address_show: 0,
            paymentstatement_id: null,
            podate: new Date().toISOString().substr(0, 10),
            shippingweekstatement: null,
            showcustomerasbuyer: 1,
            title: null,
            Contract: {
                id: null,
                currency_id: 2,
                destinatione_id: null,
                is_export: null,
                isposigned: null,
                loadingport_id: null,
                ms_id: 1,
                office_id: 1,
                partial_no: null,
                partialShipments: [],
                productgroup_id: null,
                productorigin_id: null,
                revision_no: null,
                salescontact_id: null,
                salestype_id: 1,
                shippingport_id: null,
                title: null,
                version: null
            },
            Customer: {
                id: null,
                title: null
            },
            Office: {
                id: null,
                title: null
            },
            Supplier: {
                id: null,
                paymentstatement_id: null,
                title: null
            },
            Wpr: {
                id: null,
                title: null
            }
        },
        PurchaseOrders: [],
        purchaseOrderBuyers: [],
        purchaseOrderSuppliers: [],
        totalPurchaseOrders: 0
    },
    partial: {
        copyPurchaseOrder: 1,
        OriginalContract: {
            id: null,
            title: null,
        },
        Contract: {
            Purchaseorder: {}
        },
        Salesitems: []
    },
    update: {
        Purchaseorder: {
            documents: [],
            Contract: {},
            Customer: {},
            Office: {},
            Supplier: {},
            Wpr: {}
        },
    },
    openForexView: false,
    newPurchaseOrderSaved: false,
    newPurchaseOrderUnsaved: false,
    updatedPurchaseOrderUnsaved: false,
    updatedPurchaseOrderSaved: false
}

const getters = {
    allActivePurchaseOrders: state => { return state.allActivePurchaseOrders },
    getField,
    openForexView: state => { return state.openForexView },
    purchaseOrderBuyers: state => { return state.current.purchaseOrderBuyers },
    purchaseOrderSuppliers: state => { return state.current.purchaseOrderSuppliers },
}

const mutations = {
    copyPurchaseOrder(state, payload) {
        state[payload.destination].OriginalContract.id = _.cloneDeep(state[payload.source].Purchaseorder.Contract.id)
        state[payload.destination].OriginalContract.title = _.cloneDeep(state[payload.source].Purchaseorder.Contract.title)
        state[payload.destination].Contract = _.cloneDeep(state[payload.source].Purchaseorder.Contract)
        state[payload.destination].Contract.id = null
        state[payload.destination].Contract.contractdate = new Date().toISOString().substr(0, 10)
        state[payload.destination].Contract.partial_no = null
        state[payload.destination].Contract.revision_no = null
        state[payload.destination].Contract.salestatus_id = 1
        state[payload.destination].Contract.shipweekdate = null
        state[payload.destination].Contract.shippingweekstatement = null
        state[payload.destination].Contract.title = null

        state[payload.destination].Contract.customer_pi_no = null
        state[payload.destination].Contract.date_signed = null
        state[payload.destination].Contract.documents = []
        state[payload.destination].Contract.issigned = 0
        state[payload.destination].Contract.isposigned = 0
        state[payload.destination].Contract.issplit = 0
        state[payload.destination].Contract.issplitcomplete = null

        state[payload.destination].Contract.customer_id = null
        state[payload.destination].Contract.salescontact_id = null

        state[payload.destination].Salesitems = _.cloneDeep(payload.salesItemsToClone)
        state[payload.destination].Salesitems.forEach((item)=>{
            item.Salesitem.id = null
        })

        state[payload.destination].copyPurchaseOrder = 1
        if(state[payload.destination].Contract.salestype_id == 2) state[payload.destination].copyPurchaseOrder = 0
        if(state[payload.destination].Contract.salestype_id == 3) state[payload.destination].Contract.salestype_id = 1
    },
    partialPurchaseOrder (state, payload) {
        state[payload.destination].OriginalContract.id = _.cloneDeep(state[payload.source].Purchaseorder.Contract.id)
        state[payload.destination].OriginalContract.title = _.cloneDeep(state[payload.source].Purchaseorder.Contract.title)
        state[payload.destination].OriginalContract.revision_no = _.cloneDeep(state[payload.source].Purchaseorder.Contract.revision_no)
        state[payload.destination].OriginalContract.partial_no = _.cloneDeep(state[payload.source].Purchaseorder.Contract.partial_no)
        state[payload.destination].OriginalContract.version = _.cloneDeep(state[payload.source].Purchaseorder.Contract.version)
        state[payload.destination].Contract = _.cloneDeep(state[payload.source].Purchaseorder.Contract)
        state[payload.destination].Contract.id = null
        state[payload.destination].Contract.contractdate = new Date().toISOString().substr(0, 10)
        state[payload.destination].Contract.partial_no = null
        state[payload.destination].Contract.issplit = 0
        state[payload.destination].Contract.issplitcomplete = null
        state[payload.destination].Contract.parent_id = _.cloneDeep(state[payload.source].Purchaseorder.Contract.id)
        state[payload.destination].Contract.ispartialshipment = 1
        state[payload.destination].Salesitems = _.cloneDeep(payload.salesItemsToSplit)
        state[payload.destination].Salesitems.forEach((item)=>{
            item.Salesitem.id = null
            item.Salesitem.invprice = item.Salesitem.unitprice
        })
        state[payload.destination].Contract.partial_no = (state[payload.source].Purchaseorder.Contract.partialShipments.length + 1).toString()
        state[payload.destination].Contract.salestatus_id = 1

        state[payload.destination].copyPurchaseOrder = 1
        if(state[payload.destination].Contract.salestype_id == 2) state[payload.destination].copyPurchaseOrder = 0
    },
    resetCurrentState (state){
        console.log('Resetting this purchase order...')
        state.current.Purchaseorder = getDefaultState().Purchaseorder
        // state.current.Contract = getDefaultState().Contract
        // state.current.Customer = getDefaultState().Customer
        // state.current.Office = getDefaultState().Office
        // state.current.Supplier = getDefaultState().Supplier
        // state.current.Wpr = getDefaultState().Wpr
        state.newPurchaseOrderUnsaved = false
        state.newPurchaseOrderSaved = false
    },
    resetState (state){
        console.log('Resetting purchaseorders...')
        state.allActivePurchaseOrders = []
    },
    setAllActivePurchaseOrders (state, data) {
        state.allActivePurchaseOrders = data;
    },
    setContractToBeUpdated (state, data) {
        state.update.Contract = data.Contract
    },
    setOpenForexView (state,data) {
        state.openForexView = data
    },
    setCurrentPurchaseOrder(state,payload){
        let contract = payload
        state.current.Purchaseorder.title = contract.Contract.version != null ? [contract.Contract.title, contract.Contract.revision_no , contract.Contract.partial_no].filter(Boolean).join('-') : contract.Contract.title
        state.current.Purchaseorder.contract_id = contract.Contract?.id
        state.current.Purchaseorder.currency_id = contract.Contract?.currency_id
        state.current.Purchaseorder.paymentstatement_id = contract.Supplier?.paymentstatement_id
        state.current.Purchaseorder.Contract.id = contract.Contract?.id
        state.current.Purchaseorder.Contract.containercount = contract.Contract?.containercount
        state.current.Purchaseorder.Contract.containersize_id = contract.Contract?.containersize_id
        state.current.Purchaseorder.Contract.currency_id = contract.Contract?.currency_id
        state.current.Purchaseorder.Contract.destinatione_id = contract.Contract?.destinatione_id
        state.current.Purchaseorder.Contract.is_export = contract.Contract?.is_export
        state.current.Purchaseorder.Contract.loadingport_id = contract.Contract?.loadingport_id
        state.current.Purchaseorder.Contract.ms_id = contract.Contract?.ms_id
        state.current.Purchaseorder.Contract.productgroup_id = contract.Contract?.productgroup_id
        state.current.Purchaseorder.Contract.productorigin_id = contract.Contract?.productorigin_id
        state.current.Purchaseorder.Contract.salescontact_id = contract.Contract?.salescontact_id
        state.current.Purchaseorder.Contract.salestype_id = contract.Contract?.salestype_id
        state.current.Purchaseorder.Contract.shippingport_id = contract.Contract?.shippingport_id
        state.current.Purchaseorder.Contract.title = contract.Contract?.title
        state.current.Purchaseorder.Customer.title = contract.Customer?.title
        state.current.Purchaseorder.Office.title = contract.Office?.title
        state.current.Purchaseorder.Supplier.title = contract?.Supplier?.title
        state.current.Purchaseorder.Wpr.title = contract?.Wpr?.title
    },
    setPurchaseOrderBuyers (state,data) {
        state.current.purchaseOrderBuyers = data;
    },
    setPurchaseOrderSuppliers (state,data) {
        state.current.purchaseOrderSuppliers = data;
    },
    setPurchaseOrders (state,data) {
        state.current.PurchaseOrders = data;
        // state.current.totalPurchaseOrders = data.length;
    },
    setPurchaseOrderToBeUpdated (state, data) {
        state.update.Purchaseorder = data.Purchaseorder
    },
    setTotalPurchaseOrders (state,val) {
        state.current.totalPurchaseOrders = val
    },
    updateField
}

const actions = {
    cancelPurchaseOrderById ({commit},val){
        return new Promise((resolve, reject) => {
            api
                .delete("/purchaseorders/" + val)
                .then(response => {
                    // if(response.data.status == 'success') {
                    //     resolve('done')
                    // } else {
                    //     resolve('error')
                    // }
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    clonePurchaseOrder({commit, rootState}){
        return new Promise((resolve, reject) => {
            let payload = {
                source: 'update',
                destination: 'clone',
                salesItemsToClone: rootState.appic.purchaseorderitem.update.PurchaseOrderItems
            }
            commit('copyPurchaseOrder', payload)
            resolve('done')
        })
    },
    //special clone contract method for WH PO
    createClonedContract({state}) {
        let contractToCopy = _.cloneDeep(state.clone.Contract)
        let originalContract = _.cloneDeep(state.clone.OriginalContract)
        let salesItemsToCopy = _.cloneDeep(state.clone.Salesitems)
        // remove unneeded properties
        delete contractToCopy.id
        delete contractToCopy.documents
        delete contractToCopy.Bank
        delete contractToCopy.Customer
        delete contractToCopy.Debitnote
        delete contractToCopy.Deliveryaddress
        delete contractToCopy.Destination
        delete contractToCopy.Invoice
        delete contractToCopy.Loadingport
        delete contractToCopy.Office
        delete contractToCopy.Origin
        delete contractToCopy.partialShipments
        delete contractToCopy.Paymentterm
        delete contractToCopy.Purchaseorder
        delete contractToCopy.Salesperson
        delete contractToCopy.Shipment
        delete contractToCopy.Shippingport
        delete contractToCopy.Supplier
        return new Promise((resolve, reject) => {
            api
                .post("/contracts/"+ originalContract['id'] + "/clone" ,{
                    copyPurchaseOrder: state.clone.copyPurchaseOrder,
                    originalContract: originalContract,
                    contractToCopy: contractToCopy,
                    salesItemsToCopy: salesItemsToCopy
                })
                .then(response => {
                    if(response.data.status == 'success') {
                        resolve(response.data)
                    } else {
                        resolve('error')
                    }
                })
                .catch(error => {
                    reject(error);
                })

        })
    },
    //end
    //special split contract method for WH PO
    createPartialContract ({state}) {
        let contractToSplit = _.cloneDeep(state.partial.Contract)
        let originalContract = _.cloneDeep(state.partial.OriginalContract)
        let salesItemsToSplit = _.cloneDeep(state.partial.Salesitems)
        // remove unneeded properties
        delete contractToSplit.id
        delete contractToSplit.documents
        delete contractToSplit.Bank
        delete contractToSplit.Customer
        delete contractToSplit.Debitnote
        delete contractToSplit.Deliveryaddress
        delete contractToSplit.Destination
        delete contractToSplit.Invoice
        delete contractToSplit.Loadingport
        delete contractToSplit.Office
        delete contractToSplit.Origin
        delete contractToSplit.partialShipments
        delete contractToSplit.Paymentterm
        delete contractToSplit.Purchaseorder
        delete contractToSplit.Salesperson
        delete contractToSplit.Shipment
        delete contractToSplit.Shippingport
        delete contractToSplit.Supplier
        return new Promise((resolve, reject) => {
            api
                .post("/contracts/"+ originalContract['id'] + "/partial" ,{
                    copyPurchaseOrder: state.partial.copyPurchaseOrder,
                    originalContract: originalContract,
                    contractToSplit: contractToSplit,
                    salesItemsToSplit: salesItemsToSplit
                })
                .then(response => {
                    if(response.data.status == 'success') {
                        resolve(response.data)
                    } else {
                        resolve('error')
                    }
                })
                .catch(error => {
                    reject(error);
                })

        })
    },
    //end
    createPurchaseOrder ({state, rootState}) {
        let purchaseorder = _.cloneDeep(state.current.Purchaseorder)
        let documents = _.cloneDeep(state.current.Purchaseorder.documents)
        let contract = _.cloneDeep(state.current.Purchaseorder.Contract)
        //remove unneeded properties
        delete purchaseorder.documents
        delete purchaseorder.Contract
        delete purchaseorder.Customer
        delete purchaseorder.Office
        delete purchaseorder.Supplier
        delete purchaseorder.Wpr
        delete contract.destinatione_id
        delete contract.loadingport_id
        delete contract.partialShipments
        delete contract.productorigin_id
        delete contract.salescontact_id
        delete contract.salestype_id
        delete contract.shippingport_id
        delete contract.title

        return new Promise((resolve, reject) => {
            api
                .post("/purchaseorders" , {
                        contract: contract,
                        purchaseorder: purchaseorder,
                        purchaseorderitems: rootState.appic.purchaseorderitem.current.PurchaseOrderItems,
                        documents: documents,
                    }
                )
                .then(response => {
                    // if(response.data.status == 'success') {
                    //     resolve(response.data)
                    // } else {
                    //     resolve('error')
                    // }
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error);
                })
                .finally(()=>{
                    resolve('error')
                });
        })
    },
    getAllActivePurchaseOrders ({commit}) {
        return new Promise((resolve, reject) => {
            api
                .get("/purchaseorders/active")
                .then(response => {
                    commit('setAllActivePurchaseOrders',response.data.data);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getAllPurchaseOrders ({commit},payload) {
        return new Promise((resolve, reject) => {
            if(payload.tableOptions.page != null) {
                api
                    .get("/purchaseorders", {
                        params: {
                            // conditions: conditions,
                            pagination: {
                                skip: (payload.tableOptions.page - 1) * payload.tableOptions.itemsPerPage,
                                take: payload.tableOptions.itemsPerPage
                            },
                            order: [
                                {
                                    field: 'Purchaseorder.podate',
                                    direction: 'DESC'
                                },
                            ]
                        }
                    })
                    .then(response => {
                        commit('setPurchaseOrders', response.data.data);
                        commit('setTotalPurchaseOrders', response.data.totalRows);
                        resolve('done')
                    })
                    .catch(error => {
                        reject(error);
                    });
            } else {
                resolve('done')
            }
        })
    },
    getAllPurchaseOrderBuyers ({commit}) {
        return new Promise((resolve, reject) => {
            api
                .get("/purchaseorders/buyers",{
                })
                .then(response => {
                    commit('setPurchaseOrderBuyers',response.data.data);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getAllPurchaseOrderSuppliers ({commit}) {
        return new Promise((resolve, reject) => {
            api
                .get("/purchaseorders/suppliers",{
                })
                .then(response => {
                    commit('setPurchaseOrderSuppliers',response.data.data);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getPurchaseOrderToUpdateById ({commit},purchaseorder_id) {
        return new Promise((resolve, reject) => {
            api
                .get("/purchaseorders/" + purchaseorder_id,{
                })
                .then(response => {
                    commit('setPurchaseOrderToBeUpdated',response.data.data[0]);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    loadUnpurchasedContract({commit},payload){
        return new Promise((resolve, reject) => {
            commit('setCurrentPurchaseOrder', payload)
            resolve('done')
        })
    },
    resetAllActivePurchaseOrders ({commit}){
        return new Promise ((resolve, reject) => {
            commit('resetState')
            resolve('done')
        })
    },
    resetCurrentPurchaseOrder ({commit}) {
        return new Promise ((resolve, reject) => {
            commit('resetCurrentState')
            resolve('done')
        })
    },
    searchAllPurchaseOrders({commit},payload) {
        // let conditions = []
        // let obj = {
        //     field: payload.search.field,
        //     value: payload.search.value
        // }
        // conditions.push(obj)
        return new Promise((resolve, reject) => {
            api
                .get("/purchaseorders",{
                    params: {
                        conditions: payload.conditions,
                        pagination: {
                            skip: (payload.tableOptions.page - 1) * payload.tableOptions.itemsPerPage,
                            take: payload.tableOptions.itemsPerPage
                            // skip: (payload.tableOptions.page - 1) * -1,
                            // take: -1
                        },
                        order: payload.order
                    }
                })
                .then(response => {
                    commit('setPurchaseOrders',response.data.data);
                    commit('setTotalPurchaseOrders',response.data.totalRows);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                })
                .then(()=>{
                    resolve('done')
                });
        })
    },
    splitPurchaseOrder({commit, rootState}){
        return new Promise((resolve, reject) => {
            //TODO get partial shipments

            let payload = {
                source: 'update',
                destination: 'partial',
                salesItemsToSplit: rootState.appic.purchaseorderitem.update.PurchaseOrderItems
            }
            commit('partialPurchaseOrder', payload)
            resolve('done')
        })
    },
    updatePurchaseOrder ({state, rootState}) {
        let purchaseorder = _.cloneDeep(state.update.Purchaseorder)
        let documents = _.cloneDeep(state.update.Purchaseorder.documents)
        let contract = _.cloneDeep(state.update.Purchaseorder.Contract)
        //remove unneeded properties
        delete purchaseorder.documents
        delete purchaseorder.Contract
        delete purchaseorder.Customer
        delete purchaseorder.Deliveryaddress
        delete purchaseorder.Destination
        delete purchaseorder.Loadingport
        delete purchaseorder.Office
        delete purchaseorder.Origin
        delete purchaseorder.Paymentterm
        delete purchaseorder.Salesperson
        delete purchaseorder.Shippingport
        delete purchaseorder.Supplier
        delete purchaseorder.Wpr

        delete contract.containercount
        delete contract.containersize_id
        delete contract.cost
        delete contract.currency_id
        // delete contract.destinatione_id
        delete contract.etadate
        delete contract.etddate
        delete contract.grade
        delete contract.grade_ids
        // delete contract.loadingport_id
        // delete contract.productorigin_id
        delete contract.partialShipments
        delete contract.salescontact_id
        // delete contract.salestype_id
        // delete contract.shippingport_id
        delete contract.shipmentstatus_id
        delete contract.shipmentstatus_text
        delete contract.shipweekdate
        delete contract.size
        delete contract.size_ids
        delete contract.species_ids
        delete contract.title
        delete contract.version

        //delete these fields if salestype_id is in [1,2,5]
        if([1,2,5].includes(contract.salestype_id)) {
            delete contract.destinatione_id
            delete contract.loadingport_id
            delete contract.productorigin_id
            delete contract.shippingport_id
        }
        // delete contract.salestype_id
        return new Promise((resolve, reject) => {
            api
                .put('/purchaseorders/' + state.update.Purchaseorder.id, {
                    contract: contract,
                    purchaseorder: purchaseorder,
                    purchaseorderitems: rootState.appic.purchaseorderitem.update.PurchaseOrderItems,
                    documents: documents,
                })
                .then(response => {
                    this.updatedPurchaseOrderSaved = true
                    this.updatedPurchaseOrderUnsaved = false
                    // resolve('done')
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                    resolve(error)
                })
                .then(()=>{
                    resolve('done')
                });
        })
    }
}

export const purchaseorder = {
    namespaced,
    state,
    getters,
    actions,
    mutations
}