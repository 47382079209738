import {getField, updateField} from "vuex-map-fields";
import { api } from "../../../api";
import _ from 'lodash'

const namespaced = {
	namespaced: true
}

const getDefaultState = () => {
	return {
		SdaAddress: {
			agent: null,
			attention: null,
			building: null,
			city: null,
			country: null,
			customer_id: null,
			description: null,
			destination_id: null,
			id: null,
			phone: null,
			postcode: null,
			remarks: null,
			salestype_id: null,
			shippingport_id: null,
			street: null
		}
	}
}

const state = {
	allDeliveryAddresses: [],
	current: {
		SdaAddress: {
			agent: null,
			attention: null,
			building: null,
			city: null,
			country: null,
			customer_id: null,
			description: null,
			destination_id: null,
			id: null,
			phone: null,
			postcode: null,
			remarks: null,
			salestype_id: null,
			shippingport_id: null,
			street: null
		}
	},
	update: {
		SdaAddress: {}
	}
}

const getters = {
	allDeliveryAddresses: state => {
		return state.allDeliveryAddresses
	},
	getField
}

const mutations = {
	setAllDeliveryAddresses(state, data){
		state.allDeliveryAddresses = data;
	},
	setDeliveryAddressToUpdate( state, sdaAddress ) {
		state.update.SdaAddress = sdaAddress.SdaAddress
	},
	resetCurrentState (state) {
		state.current.SdaAddress = getDefaultState().SdaAddress
	},
	resetDeliveryAddressToUpdate (state) {
		state.update.SdaAddress = getDefaultState().SdaAddress
	},
	resetState(state){
		state.allDeliveryAddresses = []
	},
	updateField
}

const actions = {
	createDeliveryAddress ( {state} ) {
		let sdaAddress = _.cloneDeep(state.current.SdaAddress)
		return new Promise((resolve, reject) => {
			api
				.post('/sda-addresses',{
						sdaaddress: sdaAddress,
					}
				)
				.then((response)=>{
					// if(response.data.status == 'success') {
					// 	resolve('done')
					// } else {
					// 	resolve('error')
					// }
					resolve(response.data)
				})
				.catch(error => {
					reject(error)
				})
				// .finally(()=>{
				// 	resolve('error')
				// })
		})
	},
	deleteDeliveryAddressById ({commit}, deliveryAddressId) {
		return new Promise((resolve, reject) => {
			api
				.delete('/sda-addresses/' + deliveryAddressId)
				.then(response => {
					// resolve('done')
					resolve(response.data)
				})
				.catch(error => {
					reject(error);
				})
		})
	},
	getAllDeliveryAddresses ({commit}) {
		return new Promise((resolve, reject) => {
			api
				.get("/sda-addresses")
				.then(response => {
					commit('setAllDeliveryAddresses',response.data.data);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	getDeliveryAddressToUpdateById ( {commit}, deliveryAddressId ) {
		return new Promise((resolve, reject) => {
			api
				.get('/sda-addresses/' + deliveryAddressId )
				.then(response => {
					commit('setDeliveryAddressToUpdate', response.data.data[0])
					resolve('done')
				})
				.catch(error => {
					reject(error)
				})
		})
	},
	resetCurrentState ( {commit} ) {
		commit('resetCurrentState')
	},
	resetDeliveryAddressToUpdate ( {commit} ) {
		commit('resetDeliveryAddressToUpdate')
	},
	resetState ( {commit} ){
		commit('resetState')
	},
	updateDeliveryAddress ( {state} ) {
		let sdaAddress = _.cloneDeep(state.update.SdaAddress)
		//remove unneeded properties
		delete sdaAddress.Customer
		delete sdaAddress.ShippingPort
		delete sdaAddress.Country
		delete sdaAddress.SalesType

		return new Promise((resolve, reject) => {
			api
				.put("/sda-addresses/" + state.update.SdaAddress.id,{
					sdaaddress: sdaAddress
				})
				.then(response => {
					// resolve('done')
					resolve(response.data)
				})
				.catch(error => {
					reject(error)
				})
				// .then(()=>{
				// 	resolve('done')
				// });
		})
	}
}

export const sdaaddress = {
	namespaced,
	state,
	getters,
	actions,
	mutations
}
