/**
 * Sidebar Module
 */

import { menus } from './data.js';
import { demo_menus } from "@/store/modules/sidebar/demo_menus";

const getDefaultMenus = () => {
    return menus
}

const getDefaultDemoMenus = () => {
    return demo_menus
}

const state = {
    menus,
    demo_menus
}

const getters = {
    menus: state => {
        return state.menus;
    },
    demo_menu_sales: state => {
        return state.demo_menus.sales
    },
    demo_menu_operations: state => {
        return state.demo_menus.operations
    }
}

const actions = {
    setActiveMenuGroup(context, payload) {
        context.commit('setActiveMenuGroupHandler', payload);
    },
    resetMenus ({commit}) {
        commit('resetMenus')
    }
}

const mutations = {
    setActiveMenuGroupHandler(state, router) {
        let fullPath = '';
        if(router.pathURL){
            fullPath = router.pathURL;
        } else {
            fullPath = router.history.current.fullPath;
        }

        const menu = router.sideMenu

        let path = fullPath.split('/');
        let matchedPath = '/' + path[2] + '/' + path[3];
        for (const category in state[menu]) {
            for(const menuGroup in state[menu][category]) {
                if(state[menu][category][menuGroup].items !== null) {
                    for(const matched in state[menu][category][menuGroup].items){
                        if(state[menu][category][menuGroup].items[matched].path == matchedPath || state[menu][category][menuGroup].items[matched].path == fullPath ){
                            state[menu][category][menuGroup].active = true;
                        }
                    }
                }
            }
        }
        // for (const category in state.menus) {
        //     for(const menuGroup in state.menus[category]) {
        //         if(state.menus[category][menuGroup].items !== null) {
        //             for(const matched in state.menus[category][menuGroup].items){
        //                 if(state.menus[category][menuGroup].items[matched].path == matchedPath || state.menus[category][menuGroup].items[matched].path == fullPath ){
        //                    state.menus[category][menuGroup].active = true;
        //                 }
        //             }
        //         }
        //     }
        // }
    },
    resetMenus(state){
        state.menus = getDefaultMenus()
        state.demo_menus = getDefaultDemoMenus()
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}