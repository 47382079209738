import {getField, updateField} from "vuex-map-fields";
import { api } from "../../../api";
import _ from 'lodash'

const namespaced = {
    namespaced: true
}

const getDefaultState = () => {
    return {
        allShippingLoadingPorts: [],
        allShippingDischargePorts: [],
        allShippingPorts: [],
        Shippingport: {
            id: null,
            country_id: 1,
            title: null,
            unlocode: null
        }
    }
}

const state = {
    allShippingLoadingPorts: [],
    allShippingDischargePorts: [],
    allShippingPorts: [],
    current: {
        Shippingport: {
            id: null,
            country_id: 1,
            title: null,
            unlocode: null
        }
    },
    update: {
        Shippingport: {}
    }
}

const getters = {
    allShippingLoadingPorts: state => {
        return state.allShippingLoadingPorts;
    },
    allShippingDischargePorts: state => {
        return state.allShippingDischargePorts;
    },
    allShippingPorts: state => {
        return state.allShippingPorts;
    },
    findShippingPortById: state => {
        return id => state.allShippingPorts.find (shippingPort => {
            return shippingPort.Shippingport.id === id
        })
    },
    getField
}

const actions = {
    createShippingPort ({state}){
        let shippingport = _.cloneDeep(state.current.Shippingport)

        return new Promise((resolve, reject) => {
            api
                .post('/shippingports',{
                        shippingport: shippingport,
                    }
                )
                .then((response)=>{
                    // if(response.data.status == 'success') {
                    //     resolve('done')
                    // } else {
                    //     resolve('error')
                    // }
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
                // .finally(()=>{
                //     resolve('error')
                // })
        })
    },
    deleteShippingPortById ({commit}, port_id) {
        return new Promise((resolve, reject) => {
            api
                .delete('/shippingports/' + port_id)
                .then(response => {
                    // resolve('done')
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error);
                })
        })
    },
    getAllShippingPorts({commit}){
        return new Promise((resolve, reject) => {
            api
                .get("/shippingports")
                .then(response => {
                    commit('setAllShippingLoadingPorts',response.data.data);
                    commit('setAllShippingDischargePorts',response.data.data);
                    commit('setAllShippingPorts',response.data.data);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getShippingPortToUpdateById ({commit}, shippingPortId) {
        return new Promise((resolve, reject) => {
            api
                .get('/shippingports/' + shippingPortId )
                .then(response => {
                    commit('setShippingPortToUpdate', response.data.data[0])
                    resolve('done')
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    resetShippingPortToUpdate ({commit}) {
        commit('resetShippingPortToUpdate')
    },
    resetCurrentState ({commit}) {
        commit('resetCurrentState')
    },
    resetState ({commit}){
        commit('resetState')
    },
    updateShippingPort( {state} ) {
        const shippingPort = _.cloneDeep(state.update.Shippingport)
        return new Promise((resolve, reject) => {
            api
                .put("/shippingports/" + state.update.Shippingport.id,{
                    shippingport: shippingPort
                })
                .then(response => {
                    // resolve('done')
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
                // .then(()=>{
                //     resolve('done')
                // });
        })
    },
}

const mutations = {
    setAllShippingLoadingPorts(state,data){
        state.allShippingLoadingPorts = data;
    },
    setAllShippingDischargePorts(state,data){
        state.allShippingDischargePorts = data.filter(port => {
            return port.Shippingport.title != 'Any port'
        });
    },
    setAllShippingPorts(state,data){
        state.allShippingPorts = data;
    },
    setShippingPortToUpdate ( state, shippingport ){
        state.update.Shippingport = shippingport.Shippingport
    },
    resetShippingPortToUpdate (state) {
        state.update.Shippingport = getDefaultState().Shippingport
    },
    resetCurrentState (state) {
        state.current.Shippingport = getDefaultState().Shippingport
    },
    resetState(state){
        state.allShippingLoadingPorts = getDefaultState().allShippingLoadingPorts
        state.allShippingDischargePorts = getDefaultState().allShippingDischargePorts
        state.allShippingPorts = getDefaultState().allShippingPorts
    },
    updateField
}

export const shippingport = {
    namespaced,
    state,
    getters,
    actions,
    mutations
}