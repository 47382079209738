import { api } from "../../../api";

const namespaced = {
    namespaced: true
}

const getDefaultState = () => {
    return {
        allSizes: []
    }
}

const state = {
    allSizes: []
}

const getters = {
    allSizes: state => {
        return state.allSizes;
    },
    findSizeById: state => {
        return id => state.allSizes.find (size => {
            return size.Size.id === id
        })
    }
}

const actions = {
    getAllSizes({commit}){
        return new Promise((resolve, reject) => {
            api
                .get("/sizes")
                .then(response => {
                    commit('setAllSizes',response.data.data);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    resetAllSizes({commit}){
        commit('resetAllSizes')
    }
}

const mutations = {
    setAllSizes(state,data){
        state.allSizes = data;
    },
    resetAllSizes(state){
        state.allSizes = getDefaultState().allSizes
    },
    resetState(){
        state.allSizes = getDefaultState().allSizes
    },
}

export const size = {
    namespaced,
    state,
    getters,
    actions,
    mutations
}