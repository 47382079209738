import {getField, updateField} from "vuex-map-fields";
import { api } from "../../../api";
import _ from 'lodash'

const namespaced = {
	namespaced: true
}

const getDefaultState = () => {
	return {
		Currency: {
			id: null,
			code: null,
			name: null,
			format: '0,0.00',
			precision: 2,
			symbol: null
		}
	}
}

const state = {
	allCurrencies: [],
	current: {
		Currency: {
			id: null,
			code: null,
			name: null,
			format: '0,0.00',
			precision: 2,
			symbol: null
		}
	},
	update: {
		Currency: {}
	}
}

const getters = {
	allCurrencies: state => {
		return state.allCurrencies
	},
	getField
}

const actions = {
	createCurrency({state}) {
		const currency = _.cloneDeep(state.current.Currency)
		return new Promise((resolve, reject) => {
			api
				.post('/currencies',{
						currency: currency
					}
				)
				.then((response)=>{
					if(response.data.status == 'success') {
						resolve('done')
					} else {
						resolve('error')
					}
				})
				.catch(error => {
					reject(error)
				})
				.finally(()=>{
					resolve('error')
				})
		})
	},
	deleteCurrencyById ({commit}, currencyId) {
		return new Promise((resolve, reject) => {
			api
				.delete('/currencies/' + currencyId)
				.then(response => {
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
		})
	},
	getCurrencyToUpdateById ({commit}, currencyId) {
		return new Promise((resolve, reject) => {
			api
				.get('/currencies/' + currencyId )
				.then(response => {
					commit('setCurrencyToUpdate', response.data.data[0])
					resolve('done')
				})
				.catch(error => {
					reject(error)
				})
		})
	},
	getAllCurrencies ({commit}){
		return new Promise((resolve, reject) => {
			api
				.get("/currencies")
				.then(response => {
					commit('setAllCurrencies',response.data.data);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	resetCurrencyToUpdate ({commit}) {
		commit('resetCurrencyToUpdate')
	},
	resetCurrentState ({commit}) {
		commit('resetCurrentState')
	},
	resetState ({commit}){
		commit('resetState')
	},
	updateCurrency ({state} ){
		let currency = _.cloneDeep(state.update.Currency)

		return new Promise((resolve, reject) => {
			api
				.put("/currencies/" + state.update.Currency.id,{
					currency: currency
				})
				.then(response => {
					resolve('done')
				})
				.catch(error => {
					reject(error)
				})
				.then(()=>{
					resolve('done')
				});
		})
	},
}

const mutations = {
	setAllCurrencies (state,data) {
		state.allCurrencies = data;
	},
	setCurrencyToUpdate ( state, currency ){
		state.update.Currency.id = currency.Currency.id
		state.update.Currency.code = currency.Currency.code
		state.update.Currency.name = currency.Currency.title
		state.update.Currency.precision = currency.Currency.decimals
		state.update.Currency.symbol = currency.Currency.symbol
		state.update.Currency.format = currency.Currency.formatPrecision
	},
	resetCurrencyToUpdate (state) {
		state.update.Currency = getDefaultState().Currency
	},
	resetCurrentState (state) {
		state.current.Currency = getDefaultState().Currency
	},
	resetState (state, data) {
		state.allCurrencies = []
	},
	updateField
}

export const currency = {
	namespaced,
	state,
	getters,
	actions,
	mutations
}