import {
    altInvoiceFooterNotes,
    appConstants,
    appicUrls,
    buyerCaptionTypes,
    buyerContractOffices,
    defaultDeliveryAddresses,
    deliveryAddressTypes,
    calendarWeeks,
    certifications,
    certificationsNew,
    companyDepartments,
    continents,
    contractFooterNotes,
    couriers,
    coverNoteFooterNotes,
    currencies,
    dryingMethods,
    eprStatuses,
    gpTaxCodes,
    grossMarginEffects,
    gstRates,
    incoterms,
    internalRemarks,
    invoiceFooterNotes,
    ipaConstants,
    localSalesInvoiceFooterNotes,
    months,
    ncrStatuses,
    offices,
    otherSalesContacts,
    payDateReferences,
    productGroups,
    productGroupsNew,
    purchaseOrderFooterNotes,
    salesOrderFooterNotes,
    salesTypes,
    shipmentStatuses,
    speciesTypes,
    statuses,
    stockFscTypes,
    supplierTypes,
    taxCodes,
    territories,
    thicknessGroups,
    tmsUrls,
    uofm,
	userRoles,
    validationRules,
    wprStatuses
} from './constants';

import {agent} from "./agent";
import {bankaccount} from  './bankaccount';
import {buyer} from  './buyer';
import {certification} from "./certification";
import {commission} from "./commission";
import {contact} from "./contact";
import {contract} from  './contract';
import {containerSizes} from "./constants";
import {country} from "./country";
import {courier} from "./courier";
import {covernote} from "./covernote";
import {currency} from "./currency";
import {customeraddress} from "./customeraddress";
import {debitnote} from "./debitnote";
import {document} from  './document';
import {documentfooter} from './documentfooter'
import {etdadjustment} from './etdadjustment';
import {epr} from "./epr";
import {epritem} from "./epritem";
import {exchangerate} from "./exchangerate";
import {grade} from  './grade';
import {grossmargin} from "./grossmargin";
import {help} from "./help";
import {incoterm} from  './incoterm';
import {internalremark} from './internalremark';
import {invoice} from  './invoice';
import {invoicesalesitem} from  './invoicesalesitem';
import {ipa} from './ipa';
import {lca} from './lca';
import {lcaclause} from './lcaclause';
import {localsalesinvoice} from "./localsalesinvoice";
import {localsalesitem} from  './localsalesitem';
import {manualinvoiceitem} from "./manualinvoiceitem";
import {manualinvoice} from "./manualinvoice";
import {mc} from "./mc";
import {ncr} from "./ncr";
import {ncritem} from "./ncritem";
import {packinglist} from "./packinglist";
import {packinglistitem} from "./packlinglistitem";
import {payment} from './payment';
import {paymentterm} from  './paymentterm';
import {paymenttransaction} from './paymenttransaction';
import {posalesitem} from "./posalesitem";
import {productcategory} from "@/store/modules/appic/productcategory";
import {productgroup} from "./productgroup";
import {productsegment} from './productsegment';
import {purchaseorder} from './purchaseorder';
import {purchaseorderitem} from  './purchaseorderitem';
import {report} from "./report";
import {runtime} from "./runtime";
import {salesbudget} from  './salesbudget';
import {salescolleague} from  './salescolleague';
import {salesorder} from  './salesorder';
import {salesorderitem} from "./salesorderitem";
import {salesitem} from  './salesitem';
import {sda} from './sda';
import {sdaaddress} from './sdaaddress';
import {sdadocument} from './sdadocument';
import {sdaclause} from './sdaclause';
import {shipment} from "./shipment";
import {shippingport} from  './shippingport';
import {size} from  './size';
import {spec} from  './spec';
import {species} from  './species';
import {stock} from  './stock';
import {stockorder} from "./stockorder";
import {supplier} from  './supplier';
import {thickness} from "./thickness";
import {user} from './user';
import {wpr} from './wpr';
import {wpritem} from './wpritem';
import {xero} from './xero';

const getDefaultState = () => {
    return {
        altInvoiceFooterNotes: altInvoiceFooterNotes,
        appConstants: appConstants,
        appicUrls: appicUrls,
        buyerCaptionTypes: buyerCaptionTypes,
        buyerContractOffices: buyerContractOffices,
        defaultDeliveryAddresses: defaultDeliveryAddresses,
        deliveryAddressTypes: deliveryAddressTypes,
        certifications: certifications,
        certificationsNew: certificationsNew,
        companyDepartments: companyDepartments,
        continents: continents,
        containerSizes: containerSizes,
        contractFooterNotes: contractFooterNotes,
        couriers: couriers,
        coverNoteFooterNotes: coverNoteFooterNotes,
        currencies: currencies,
        dryingMethods: dryingMethods,
        eprStatuses: eprStatuses,
        gpTaxCodes: gpTaxCodes,
        grossMarginEffects: grossMarginEffects,
        gstRates: gstRates,
        incoterms: incoterms,
        internalRemarks: internalRemarks,
        invoiceFooterNotes: invoiceFooterNotes,
        ipaConstants: ipaConstants,
        localSalesInvoiceFooterNotes: localSalesInvoiceFooterNotes,
        months: months,
        ncrStatuses: ncrStatuses,
        offices: offices,
        otherSalesContacts: otherSalesContacts,
        payDateReferences: payDateReferences,
        productGroups: productGroups,
        productGroupsNew: productGroupsNew,
        // productGroupsNew: productgroup.state.allProductGroups,
        purchaseOrderFooterNotes: purchaseOrderFooterNotes,
        salesOrderFooterNotes: salesOrderFooterNotes,
        salesTypes: salesTypes,
        speciesTypes: speciesTypes,
        statuses: statuses,
        shipmentStatuses: shipmentStatuses,
        stockFscTypes: stockFscTypes,
        supplierTypes: supplierTypes,
        taxCodes: taxCodes,
        territories: territories,
        thicknessGroups: thicknessGroups,
        tmsUrls: tmsUrls,
        uofm: uofm,
		userRoles: userRoles,
        validationRules: validationRules,
        wprStatuses: wprStatuses
    }
}

const state = {
    allNotifications: null,
    altInvoiceFooterNotes: altInvoiceFooterNotes,
    appConstants: appConstants,
    appicUrls: appicUrls,
    buyerCaptionTypes: buyerCaptionTypes,
    buyerContractOffices: buyerContractOffices,
    calendarWeeks: calendarWeeks,
    certifications: certifications,
    certificationsNew: certificationsNew,
    companyDepartments: companyDepartments,
    continents: continents,
    containerSizes: containerSizes,
    contractFooterNotes: contractFooterNotes,
    couriers: couriers,
    coverNoteFooterNotes: coverNoteFooterNotes,
    currencies: currencies,
    currentOffice: null,
    currentUser: null,
    currentDashboard: null,
    defaultDeliveryAddresses: defaultDeliveryAddresses,
    deliveryAddressTypes: deliveryAddressTypes,
    dryingMethods: dryingMethods,
    eprStatuses: eprStatuses,
    gpTaxCodes: gpTaxCodes,
    grossMarginEffects: grossMarginEffects,
    gstRates: gstRates,
    incoterms: incoterms,
    internalRemarks: internalRemarks,
    invoiceFooterNotes: invoiceFooterNotes,
    ipaConstants: ipaConstants,
    localSalesInvoiceFooterNotes: localSalesInvoiceFooterNotes,
    months: months,
    ncrStatuses: ncrStatuses,
    offices: offices,
    otherSalesContacts: otherSalesContacts,
    payDateReferences: payDateReferences,
    productGroups: productGroups,
    productGroupsNew: productGroupsNew,
    // productGroupsNew: productgroup.state.allProductGroups,
    purchaseOrderFooterNotes: purchaseOrderFooterNotes,
    salesOrderFooterNotes: salesOrderFooterNotes,
    salesTypes: salesTypes,
    shipmentStatuses: shipmentStatuses,
    statuses: statuses,
    stockFscTypes: stockFscTypes,
    speciesTypes: speciesTypes,
    supplierTypes: supplierTypes,
    taxCodes: taxCodes,
    territories: territories,
    thicknessGroups: thicknessGroups,
    tmsUrls: tmsUrls,
    unreadNotifications: null,
    uofm: uofm,
    validationRules: validationRules,
	userRoles: userRoles,
    wprStatuses: wprStatuses
};

const getters = {
    altInvoiceFooterNotes: state => {
        return state.altInvoiceFooterNotes
    },
    appConstants: state => {
        return state.appConstants
    },
    appicUrls: state =>{
        return state.appicUrls
    },
    buyerCaptionTypes: state => {
        return state.buyerCaptionTypes
    },
    buyerContractOffices: state => {
        return state.buyerContractOffices
    },
    calendarWeeks: state => {
        return state.calendarWeeks
    },
    certifications: state => {
        return state.certifications
    },
    certificationsNew: state => {
        return state.certificationsNew
    },
    companyDepartments: state => {
        return state.companyDepartments
    },
    continents: state => {
        return state.continents
    },
    containerSizes: state => {
        return state.containerSizes
    },
    contractFooterNotes: state => {
        return state.contractFooterNotes
    },
    couriers: state => {
        return state.couriers
    },
    coverNoteFooterNotes: state => {
        return state.coverNoteFooterNotes
    },
    currencies: state => {
        return state.currencies
    },
    currentOffice: state => {
        return state.currentOffice
    },
    currentUser: state => {
        return state.currentUser
    },
    defaultDeliveryAddresses: state => {
        return state.defaultDeliveryAddresses
    },
    deliveryAddressTypes: state => {
        return state.deliveryAddressTypes
    },
    dryingMethods: state => {
        return state.dryingMethods
    },
    eprStatuses: state => {
        return state.eprStatuses
    },
    gpTaxCodes: state => {
        return state.gpTaxCodes
    },
    grossMarginEffects: state => {
        return state.grossMarginEffects
    },
    gstRates: state => {
        return state.gstRates
    },
    internalRemarks: state => {
        return state.internalRemarks
    },
    incoterms: state => {
        return state.incoterms
    },
    invoiceFooterNotes: state => {
        return state.invoiceFooterNotes
    },
    ipaConstants: state => {
        return state.ipaConstants
    },
    localSalesInvoiceFooterNotes: state => {
        return state.localSalesInvoiceFooterNotes
    },
    months: state => {
        return state.months
    },
    ncrStatuses: state => {
        return state.ncrStatuses
    },
    offices: state => {
        return state.offices
    },
    officeCountries: state => {
        let offices = state.offices.map(office => office.Office.country_id)
        //add other countries
        offices.push(5)
        return offices
    },
    otherSalesContacts: state => {
        return state.otherSalesContacts
    },
    payDateReferences: state => {
        return state.payDateReferences
    },
    productGroups: state => {
        return state.productGroups
    },
    productGroupsNew: state => {
        return state.productGroupsNew
    },
    purchaseOrderFooterNotes: state => {
        return state.purchaseOrderFooterNotes
    },
    salesOrderFooterNotes: state => {
        return state.salesOrderFooterNotes
    },
    salesTypes: state => {
        return state.salesTypes
    },
    shipmentStatuses: state => {
        return state.shipmentStatuses
    },
    speciesTypes: state => {
        return state.speciesTypes
    },
    statuses: state => {
        return state.statuses
    },
    stockFscTypes: state => {
        return state.stockFscTypes
    },
    supplierTypes: state => {
        return state.supplierTypes
    },
    taxCodes: state => {
        return state.taxCodes
    },
    territories: state => {
        return state.territories
    },
    thicknessGroups: state => {
        return state.thicknessGroups
    },
    uofmAll: state => {
        return state.uofm
    },
    uofmDimensions: state => {
        return state.uofm.dimensions
    },
    uofmQty: state => {
        return state.uofm.qty
    },
    uofmVolumes: state => {
        return state.uofm.volumes
    },
    uofmAreas: state => {
        return state.uofm.areas
    },
    uofmWeights: state => {
        return state.uofm.weights
    },
	userRoles: state => {
    	return state.userRoles
	},
    validationRules: state => {
        return state.validationRules
    },
    wprStatuses: state => {
        return state.wprStatuses
    }
};

const actions = {
    setCurrentOffice(context) {},
    resetConstantsState ({commit}) {
        commit('resetConstantsState')
    },
};

const mutations = {
    setCurrentOffice(state, office) {
        state.currentOffice = office;
    },
    setCurrentUser(state, user) {
        state.currentUser = user;
    },
    resetConstantsState(state){
        console.log('Resetting app constants')
        state.appConstants = getDefaultState().appConstants
        state.appicUrls = getDefaultState().appicUrls
        state.buyerContractOffices = getDefaultState().buyerContractOffices
        state.certifications = getDefaultState().certifications
        state.certificationsNew = getDefaultState().certificationsNew
        state.companyDepartments = getDefaultState().companyDepartments
        state.continents = getDefaultState().continents
        state.containerSizes = getDefaultState().containerSizes
        state.contractFooterNotes = getDefaultState().contractFooterNotes
        state.coverNoteFooterNotes = getDefaultState().coverNoteFooterNotes
        state.currencies = getDefaultState().currencies
        state.defaultDeliveryAddresses = getDefaultState().defaultDeliveryAddresses
        state.deliveryAddressTypes = getDefaultState().deliveryAddressTypes
        state.dryingMethods = getDefaultState().dryingMethods
        state.eprStatuses = getDefaultState().eprStatuses
        state.gpTaxCodes = getDefaultState().gpTaxCodes
        state.grossMarginEffects = getDefaultState().grossMarginEffects
        state.gstRates = getDefaultState().gstRates
        state.internalRemarks = getDefaultState().internalRemarks
        state.ipaConstants = getDefaultState().ipaConstants
        state.localSalesInvoiceFooterNotes = getDefaultState().localSalesInvoiceFooterNotes
        state.months = getDefaultState().months
        state.ncrStatuses = getDefaultState().ncrStatuses
        state.offices = getDefaultState().offices
        state.otherSalesContacts = getDefaultState().otherSalesContacts
        state.payDateReferences = getDefaultState().payDateReferences
        state.productGroups = getDefaultState().productGroups
        state.productGroupsNew = getDefaultState().productGroupsNew
        state.purchaseOrderFooterNotes = getDefaultState().purchaseOrderFooterNotes
        state.salesOrderFooterNotes = getDefaultState().salesOrderFooterNotes
        state.shipmentStatuses = getDefaultState().shipmentStatuses
        state.speciesTypes = getDefaultState().speciesTypes
        state.stockFscTypes = getDefaultState().stockFscTypes
        state.supplierTypes = getDefaultState().supplierTypes
        state.taxCodes = getDefaultState().taxCodes
        state.territories = getDefaultState().territories
        state.thicknessGroups = getDefaultState().thicknessGroups
        state.uofm = getDefaultState().uofm
		state.userRoles = getDefaultState().userRoles
        state.wprStatuses = getDefaultState().wprStatuses
    }
};

const modules = {
    agent,
    bankaccount,
    buyer,
    certification,
    commission,
    contact,
    contract,
    country,
    courier,
    covernote,
    currency,
    customeraddress,
    debitnote,
    document,
    documentfooter,
    etdadjustment,
    exchangerate,
    epr,
    epritem,
    grade,
    grossmargin,
    help,
    incoterm,
    internalremark,
    invoice,
    invoicesalesitem,
    ipa,
    lca,
    lcaclause,
    localsalesinvoice,
    localsalesitem,
    manualinvoice,
    manualinvoiceitem,
    mc,
    ncr,
    ncritem,
    packinglist,
    packinglistitem,
    payment,
    paymentterm,
    paymenttransaction,
    posalesitem,
    productcategory,
    productgroup,
    productsegment,
    purchaseorder,
    purchaseorderitem,
    report,
    runtime,
    salesbudget,
    salescolleague,
    salesitem,
    salesorder,
    salesorderitem,
    sda,
    sdaaddress,
    sdadocument,
    sdaclause,
    shipment,
    shippingport,
    size,
    spec,
    species,
    stock,
    stockorder,
    supplier,
    thickness,
    user,
    wpr,
    wpritem,
    xero
};

export default {
    state,
    getters,
    actions,
    mutations,
    modules
}