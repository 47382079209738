import axios from 'axios';
import { store } from '@/store/store';

let axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
});

let isCalled = false;

const obtainNewAccessToken = () =>{
    return new Promise((resolve,reject)=>{
        let user = JSON.parse(localStorage.getItem('user'))
        axiosInstance
            .post('/token/refresh',{
                email: user.user.email,
                refresh_token: user.refreshToken
            })
            .then(response => {
                //update user object in localStorage
                user.apiToken = response.data.token
                store.commit('updateUserToken', user);
                //update table updates
                store.dispatch('updateStores', response.data.tableUpdates).then();
                resolve(response.data.token)
            })
            .catch(error => {
                reject(error);
            });
    })
}

const refreshExpiredTokenClosure = () => {
    let runningPromise = undefined;
    return async () => {
        if (isCalled) {
            return runningPromise;
        } else {
            isCalled = true;
            runningPromise = await obtainNewAccessToken();
            return runningPromise;
        }
    };
};

const refreshExpiredToken = refreshExpiredTokenClosure();

axiosInstance.interceptors.request.use((config) => {
    let user = JSON.parse(localStorage.getItem('user'))
    if (user?.apiToken) {
        config.headers['Authorization'] = 'Bearer ' + user.apiToken
        config.headers['User-Id'] = user.user.email
    }
    return config
}, error => {
    return Promise.reject(error)
})

axiosInstance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    isCalled = false;
    return response;
}, async function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.status !== 401) {
        return Promise.reject(error)
    }

    if (error.response.data.message == 'The refresh token is expired' || error.response.data.message == 'Token not found') {
        // await store.dispatch("logoutUserFromFirebase"); //AuthModular
        await store.dispatch("logOutUserFromAppic"); //AuthDirect
        return Promise.reject(error)
    }

    //get new token and try the request again with the new token
    if(error.response.status === 401) {
        const newToken = await refreshExpiredToken()
        const user = JSON.parse(localStorage.getItem('user'))
        let config = error.config;
        config.headers['Authorization'] = 'Bearer ' + newToken
        config.headers['User-Id'] = user.user.email
        return axiosInstance(config)
    }
    return Promise.reject(error)
});

export const api = axiosInstance
