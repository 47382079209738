import {getField, updateField} from "vuex-map-fields";
import {api} from "../../../api";

const namespaced = {
    namespaced: true
}

const getDefaultState = () => {
    return {}
}

const state = {
    current: {
        Manual: {
            Invoice: {

            }
        }
    },
    update: {
        Manual: {
            Invoice: {

            }
        }
    }
}

const getters = {
    getField
}

const mutations = {
    updateField
}

const actions = {

}

export const manualinvoice = {
    namespaced,
    state,
    getters,
    actions,
    mutations
}