// import MiniSidebarLayout from 'Container/MiniSidebarLayout'
import Full from 'Container/Full'

//APPiC components
const AddBuyer = () => import('Views/v1/customers/AddBuyer');
const AddCommissionInvoice = () => import('Views/v1/debitnotes/AddCommissionInvoice');
const AddContract = () => import('Views/v1/contracts/AddContract');
// const AddCoverNote = () => import('Views/v1/covernotes/AddCoverNote');
const AddEpr = () => import('Views/v1/eprs/AddEpr');
const AddGrossMargin = () => import('Views/v1/accounts/AddGrossMargin');
const AddInvoice = () => import('Views/v1/invoices/AddInvoice');
const AddIpa = () => import('Views/v1/ipas/AddIpa');
const AddLca = () => import('Views/v1/lcas/AddLca');
const AddLocalSalesInvoice = () => import('Views/v1/localsales/AddLocalSalesInvoice');
const AddNcr = () => import('Views/v1/ncrs/AddNcr');
const AddPackingList = () => import('Views/v1/pldos/AddPackingList');
const AddPayment = () => import('Views/v1/payments/AddPayment')
const AddPurchaseOrder = () => import('Views/v1/purchaseorders/AddPurchaseOrder');
const AddSalesOrder = () => import('Views/v1/salesorders/AddSalesOrder');
const AddSda = () => import('Views/v1/sdas/AddSda');
const AddStockOrder = () => import('Views/v1/stockorders/AddStockOrder');
const AddSupplier = () => import('Views/v1/suppliers/AddSupplier');
const AddUser = () => import('Views/v1/users/AddUser');
const AddWarehousePurchaseRequest = () => import('Views/v1/wprs/AddWpr');
const AppicReport = () => import('Views/v1/reports/AppicReport');
const AppicSettings = () => import('Views/v1/admin/AppicSettings');
const AppicXeroComparison = () => import('Views/v1/reports/AppicXeroComparison.vue');
const AppicXeroCostOfSalesComparison =() => import('Views/v1/reports/AppicXeroCostOfSalesComparison.vue')
// const BankAccountsListing = () => import('Views/v1/bankaccounts/Listing');
// const BankAccountProfile = () => import('Views/v1/bankaccounts/Profile');
const BuyerLocator = () => import('Views/v1/customers/BuyerLocator');
const BuyersOverview = () => import('Views/v1/customers/BuyersOverview');
// const CommissionsListing = () => import('Views/v1/contracts/CommissionsListing');
const ContractsOverview = () => import('Views/v1/contracts/ContractsOverview');
const CustomerLifeCycle = () => import('Views/v1/reports/CustomerLifeCycle');
// const Currencyrates = () => import('Views/v1/currencyrates/Listing');
// const CustomersListing = () => import('Views/v1/customers/Listing');
// const CountriesListing = () => import('Views/v1/datamanagement/Countries');
const Dashboard = () => import('Views/v1/dashboard/Dashboard');
const DemoSalesDashboard = () => import('Views/v1/dashboard/DemoSales');
// const DocumentManager = () => import('Views/v1/sdas/DocumentManager');
const EprsOverview = () => import('Views/v1/eprs/EprsOverview')
// const ExportStatusBoard = () => import('Views/v1/boards/ExportBoard');
const FinanceDataOverview = () => import('Views/v1/datamanagement/FinanceDataOverview');
const FinanceReports = () => import('Views/v1/reports/FinanceReports');
const FscPefcReport = () => import('Views/v1/reports/FscPefcReport');
const FscPefcDemoReport = () => import('Views/v1/reports/FscPefcDemoReport');
const GrossMarginsOverview = () => import('Views/v1/accounts/GrossMarginsOverview');
const HelpManual = () => import('Views/v1/pages/HelpManual');
const HubSpotDeals = () => import('Views/v1/reports/HubSpotDeals');
// const InternalRemarksListing = () => import('Views/v1/datamanagement/InternalRemarks');
const InvoicesOverview = () => import('Views/v1/invoices/InvoicesOverview');
// const MarketingReportV4 = () => import('Views/v1/reports/MarketingReportV4');
const MarketingReportV5 = () => import('Views/v1/reports/MarketingReportV5');
// const MarketSegmentsListing = () => import('Views/v1/datamanagement/MarketSegments');
const MiscellaneousAdmin = () => import('Views/v1/admin/MiscellaneousAdmin')
const NcrsOverview = () => import('Views/v1/ncrs/NcrsOverview')
// const NotFound = () => import('Views/v1/pages/NotFound');
// const PaymentStatementsListing = () => import('Views/v1/datamanagement/PaymentStatements');
const OperationsDataOverview = () => import('Views/v1/datamanagement/OperationsDataOverview');
const OperationsDemoDataOverview = () => import('Views/v1/datamanagement/OperationsDemoDataOverview.vue');
const PaymentsSchedule = () => import('Views/v1/payments/PaymentsOverview');
const PdfServer = () => import('Views/v1/admin/PdfServer');
const ProfitAndLossForecast = () => import('Views/v1/reports/ProfitAndLossForecast');
const ProductTable = () => import('Views/v1/suppliers/ProductTable');
const PurchaseOrdersOverview = () => import('Views/v1/purchaseorders/PurchaseOrdersOverview');
// const PurchaseOrderStatusBoard = () => import('Views/v1/boards/PoBoard');
// const ProductSegmentsListing = () => import('Views/v1/datamanagement/ProductSegments');
// const ProductGroupsListing = () => import('Views/v1/datamanagement/ProductGroups');
const ReportDetail = () => import('Components/Appic/MarketingReport/ReportDetail');
const SalesCommissionsOverview = () => import('Views/v1/commissions/SalesCommissionsOverview');
const SalesDataOverview = () => import('Views/v1/datamanagement/SalesDataOverview');
const SalesOrdersOverview = () => import('Views/v1/salesorders/SalesOrdersOverview');
const SalesPersonsListing = () => import('Views/v1/datamanagement/SalesPersons');
const SearchResults = () => import('Views/v1/pages/SearchResults');
const ShipmentsOverview = () => import('Views/v1/shipments/ShipmentsOverview');
const ShipmentsDemoOverview = () => import('Views/v1/shipments/ShipmentsDemoOverview');
// const ShippingInstructionsListing = () => import('Views/v1/sdas/Listing');
// const ShippingInstructionsForm = () => import('Views/v1/sdas/ShippingInstructionForm');
// const ShippingAgentsListing = () => import('Views/v1/datamanagement/ShippingAgents');
// const ShippingDocumentsOverview = () => import('Views/v1/shipments/ShippingDocumentsOverview');
// const ShippingPortsListing = () => import('Views/v1/datamanagement/ShippingPorts');
// const Specifications = () => import('Views/v1/datamanagement/Specifications');
const SpecificationsDataOverview = () => import('Views/v1/datamanagement/SpecificationsDataOverview');
const SpecificationsDemoDataOverview = () => import('Views/v1/datamanagement/SpecificationsDemoDataOverview.vue');
// const StocksListing = () => import('Views/v1/stocks/Listing');
const StandardDataFilters = () => import('Views/v1/admin/StandardDataFilters');
const StocksOverview = () => import('Views/v1/stocks/StocksOverview');
// const StockOrderListing = () => import('Views/v1/stocks/StockOrderListing');
const StockOrdersOverview = () => import('Views/v1/stockorders/StockOrdersOverview');
const StockReports = () => import('Views/v1/reports/StockReports');
const SuppliersOverview = () => import('Views/v1/suppliers/SuppliersOverview');
const SupplierProductSearchV3 = () => import('Views/v1/suppliers/SupplierProductSearchV3');
const UpdateContract = () => import('Views/v1/contracts/AddContract');
const UpdateShipment = () => import('Views/v1/shipments/UpdateShipment');
// const UsersListing = () => import('Views/v1/users/Listing');
// const UserAccessManager = () => import('Views/v1/users/UserAccessManager');
const UsersOverview = () => import('Views/v1/users/UsersOverview');
const UserLog = () => import('Views/v1/admin/UserLog');
const WarehousePurchaseRequestOverview = () => import('Views/v1/wprs/WprsOverview');
const XeroOverview = () => import('Views/v1/xero/XeroOverview');
const ZohoReports = () => import('Views/v1/admin/ZohoReports');

export default {
    path: '/',
    component: Full,
    redirect: '/v1/dashboard/standard',
    children: [
        {
            path: '/v1/dashboard/standard',
            name: 'my_dashboard',
            component: Dashboard,
            meta: {
                pageTitle: 'message.titles.myDashboard',
                requiredAbility: {
                    // action: 'access',
                    // subject: 'Dashboard',
                    action: null,
                    subject: null
                },
                requiresAuth: true,
                title: 'message.myDashboard',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'Dashboards'
                    },
                    {
                        breadcrumbActive: 'Zoho'
                    }
                ]
            }
        },
        {
            path: '/v1/dashboard/demo-sales',
            name: 'demo_sales_dashboard',
            component: DemoSalesDashboard,
            meta: {
                pageTitle: 'message.titles.myDashboard',
                requiredAbility: {
                    action: null,
                    subject: null
                },
                requiresAuth: true,
                title: 'message.myDashboard',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'Dashboards'
                    },
                    {
                        breadcrumbActive: 'Zoho'
                    }
                ]
            }
        },
        {
           path: '/v1/reports/appic-report',
           component: AppicReport,
           meta: {
               pageTitle: 'message.titles.appicReport',
               requiredAbility: {
                   action: 'access',
                   subject: 'AppicReport'
               },
               requiresAuth: true,
               title: 'message.appicReport',
               breadcrumb: [
                   {
                       breadcrumbInactive: 'message.reports'
                   },
                   {
                       breadcrumbActive: 'message.appic'
                   }
               ],
           }
        },
        {
           path: '/v1/reports/marketing-report',
           component: MarketingReportV5,
           meta: {
               pageTitle: 'message.titles.marketingReport',
               requiredAbility: {
                   action: 'access',
                   subject: 'MarketingReport'
               },
               requiresAuth: true,
               title: 'message.marketingReport',
               breadcrumb: [
                   {
                       breadcrumbInactive: 'message.reports'
                   },
                   {
                       breadcrumbActive: 'message.marketing'
                   }
               ]
           }
        },
        {
            path: '/v1/reports/fsc-pefc',
            name: 'fsc_pefc_report',
            component: FscPefcReport,
            meta: {
                pageTitle: 'message.titles.fscPefcReport',
                requiredAbility: {
                   action: 'access',
                   subject: 'FscPefcReport'
                },
                requiresAuth: true,
                title: 'message.fscPefcReport',
                breadcrumb: [
                   {
                       breadcrumbInactive: 'message.reports'
                   },
                   {
                       breadcrumbActive: 'message.fscPefc'
                   }
               ]
}
        },
        {
            path: '/v1/reports/fsc-pefc-demo',
            name: 'fsc_pefc_demo_report',
            component: FscPefcDemoReport,
            meta: {
                pageTitle: 'message.titles.fscPefcReport',
                requiredAbility: {
                    action: 'access',
                    subject: 'FscPefcReport'
                },
                requiresAuth: true,
                title: 'message.fscPefcReport',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.reports'
                    },
                    {
                        breadcrumbActive: 'message.fscPefc'
                    }
                ]
            }
        },
        // {
        //     path: '/v1/reports/pl-forecast',
        //     component: ProfitAndLossForecast,
        //     meta: {
        //         requiredAbility: {
        //             action: 'access',
        //             subject: 'FinanceData'
        //         },
        //         requiresAuth: true,
        //         title: 'message.profitAndLossForecast',
        //         breadcrumb: [
        //             {
        //                 breadcrumbInactive: 'message.reports'
        //             },
        //             {
        //                 breadcrumbActive: 'message.profitAndLoss'
        //             }
        //         ]
        //     }
        // },
        {
            path: '/v1/contracts/add',
            name: 'add_contract',
            component: AddContract,
            meta: {
                pageTitle: 'message.titles.newContract',
                requiredAbility: {
                    action: 'create',
                    subject: 'Contract'
                },
                requiresAuth: true,
                title: 'message.addContract',
                breadcrumb: [
                    {
                       breadcrumbInactive: 'message.contract'
                    },
                    {
                       breadcrumbActive: 'message.add'
                    }
                ],
                stateToUse: 'current'
            }
        },
        {
            path: '/v1/contracts/overview',
            name: 'contract_listing',
            component: ContractsOverview,
            meta: {
                pageTitle: 'message.titles.contracts',
                requiredAbility: {
                    action: 'access',
                    subject: 'Contracts'
                },
                requiresAuth: true,
                title: 'message.contracts',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.contract'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/contracts/update/:contractId',
            name: 'update_contract',
            component: UpdateContract,
            meta: {
                pageTitle: 'message.titles.contract',
                requiredAbility: {
                    action: 'update',
                    subject: 'Contract'
                },
                requiresAuth: true,
                title: 'message.updateContract',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.contract'
                    },
                    {
                        breadcrumbActive: 'message.update'
                    }
                ],
                stateToUse: 'update'
            },
            props: true
        },
        {
            path: '/v1/debitnotes/add/:contractId?',
            name: 'add_debitnote',
            component: AddCommissionInvoice,
            meta: {
                pageTitle: 'message.titles.newDebitNote',
                requiredAbility: {
                    action: 'create',
                    subject: 'DebitNote'
                },
                requiresAuth: true,
                title: 'message.addDebitNote',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.debitNote'
                    },
                    {
                        breadcrumbActive: 'message.add'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/debitnotes/update/:debitNoteId?',
            name: 'update_debitnote',
            component: AddCommissionInvoice,
            meta: {
                pageTitle: 'message.titles.debitNote',
                requiredAbility: {
                    action: 'update',
                    subject: 'DebitNote'
                },
                requiresAuth: true,
                title: 'message.updateDebitNote',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.debitNote'
                    },
                    {
                        breadcrumbActive: 'message.update'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/gross-margins/overview',
            name: 'gross_margin_listing',
            component: GrossMarginsOverview,
            meta: {
                pageTitle: 'message.titles.grossMargins',
                requiredAbility: {
                    action: 'access',
                    subject: 'GrossMargins'
                },
                requiresAuth: true,
                title: 'message.grossMargins',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.grossMargin'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/gross-margins/add/:source/:sourceId',
            name: 'add_gross_margin',
            component: AddGrossMargin,
            meta: {
                pageTitle: 'message.titles.newGrossMargin',
                requiredAbility: {
                    action: 'create',
                    subject: 'GrossMargin'
                },
                requiresAuth: true,
                title: 'message.addGrossMarginCalculation',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.grossMarginCalculation'
                    },
                    {
                        breadcrumbActive: 'message.add'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/gross-margins/update/:grossMarginId',
            name: 'update_gross_margin',
            component: AddGrossMargin,
            meta: {
                pageTitle: 'message.titles.grossMargin',
                requiredAbility: {
                    action: 'update',
                    subject: 'GrossMargin'
                },
                requiresAuth: true,
                title: 'message.updateGrossMargin',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.grossMargin'
                    },
                    {
                        breadcrumbActive: 'message.update'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/invoices/add/:contractId?',
            name: 'add_invoice',
            component: AddInvoice,
            meta: {
                pageTitle: 'message.titles.newInvoice',
                requiredAbility: {
                    action: 'create',
                    subject: 'Invoice'
                },
                requiresAuth: true,
                title: 'message.addInvoice',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.invoice'
                    },
                    {
                        breadcrumbActive: 'message.add'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/invoices/update/:invoiceId?',
            name: 'update_invoice',
            component: AddInvoice,
            meta: {
                pageTitle: 'message.titles.invoice',
                requiredAbility: {
                    action: 'update',
                    subject: 'Invoice'
                },
                requiresAuth: true,
                title: 'message.updateInvoice',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.invoice'
                    },
                    {
                        breadcrumbActive: 'message.update'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/invoices/overview',
            name: 'invoice_listing',
            component: InvoicesOverview,
            meta: {
                pageTitle: 'message.titles.invoices',
                requiredAbility: {
                    action: 'access',
                    subject: 'Invoices'
                },
                requiresAuth: true,
                title: 'message.invoices',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.invoice'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/packinglists/add/:contractId?',
            name: 'add_packinglist',
            component: AddPackingList,
            meta: {
                pageTitle: 'message.titles.newPackingList',
                requiredAbility: {
                    action: 'update',
                    subject: 'Shipment'
                },
                requiresAuth: true,
                title: 'message.addPackingList',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.packingList'
                    },
                    {
                        breadcrumbActive: 'message.add'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/packinglists/update/:packingListId',
            name: 'update_packinglist',
            component: AddPackingList,
            meta: {
                pageTitle: 'message.titles.packingList',
                requiredAbility: {
                    action: 'update',
                    subject: 'Shipment'
                },
                requiresAuth: true,
                title: 'message.updatePackingList',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.packingList'
                    },
                    {
                        breadcrumbActive: 'message.update'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/ipas/add/:contractId?',
            name: 'add_ipa',
            component: AddIpa,
            meta: {
                pageTitle: 'message.titles.newIpa',
                requiredAbility: {
                    action: 'update',
                    subject: 'Shipment'
                },
                requiresAuth: true,
                title: 'message.addImportPermitAdvice',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.ipa'
                    },
                    {
                        breadcrumbActive: 'message.add'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/ipas/update/:ipaId?',
            name: 'update_ipa',
            component: AddIpa,
            meta: {
                pageTitle: 'message.titles.ipa',
                requiredAbility: {
                    action: 'update',
                    subject: 'Shipment'
                },
                requiresAuth: true,
                title: 'message.updateImportPermitAdvice',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.ipa'
                    },
                    {
                        breadcrumbActive: 'message.update'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/lcas/add/:contractId?',
            name: 'add_lca',
            component: AddLca,
            meta: {
                pageTitle: 'message.titles.newLca',
                requiredAbility: {
                    action: 'update',
                    subject: 'Shipment'
                },
                requiresAuth: true,
                title: 'message.addLetterOfCreditAdvice',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.lca'
                    },
                    {
                        breadcrumbActive: 'message.add'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/lcas/update/:lcaId?',
            name: 'update_lca',
            component: AddLca,
            meta: {
                pageTitle: 'message.titles.lca',
                requiredAbility: {
                    action: 'update',
                    subject: 'Shipment'
                },
                requiresAuth: true,
                title: 'message.updateLetterCreditAdvice',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.lca'
                    },
                    {
                        breadcrumbActive: 'message.update'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/localsales/add/:salesOrderId?',
            name: 'add_localsale',
            component: AddLocalSalesInvoice,
            meta: {
                pageTitle: 'message.titles.newInvoice',
                requiredAbility: {
                    action: 'create',
                    subject: 'LocalSale'
                },
                requiresAuth: true,
                title: 'message.addLocalSalesInvoice',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.localSalesInvoice'
                    },
                    {
                        breadcrumbActive: 'message.add'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/localsales/update/:localSalesInvoiceId?',
            name: 'update_localsale',
            component: AddLocalSalesInvoice,
            meta: {
                pageTitle: 'message.titles.invoice',
                requiredAbility: {
                    action: 'update',
                    subject: 'LocalSale'
                },
                requiresAuth: true,
                title: 'message.updateLocalSalesInvoice',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.localSalesInvoice'
                    },
                    {
                        breadcrumbActive: 'message.update'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/purchaseorders/overview',
            name: 'purchaseorder_listing',
            component: PurchaseOrdersOverview,
            meta: {
                pageTitle: 'message.titles.purchaseOrders',
                requiredAbility: {
                    action: 'access',
                    subject: 'Pos'
                },
                requiresAuth: true,
                title: 'message.purchaseOrders',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.purchaseOrder'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/purchaseorders/update/:purchaseOrderId?',
            name: 'update_purchaseorder',
            component: AddPurchaseOrder,
            meta: {
                pageTitle: 'message.titles.po',
                requiredAbility: {
                    action: 'update',
                    subject: 'Po'
                },
                requiresAuth: true,
                title: 'message.updatePurchaseOrder',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.purchaseOrder'
                    },
                    {
                        breadcrumbActive: 'message.update'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/purchaseorders/add',
            name: 'add_purchaseorder',
            component: AddPurchaseOrder,
            meta: {
                pageTitle: 'message.titles.newPo',
                requiredAbility: {
                    action: 'create',
                    subject: 'Po'
                },
                requiresAuth: true,
                title: 'message.addPurchaseOrder',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.purchaseOrder'
                    },
                    {
                        breadcrumbActive: 'message.add'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/payments/overview',
            component: PaymentsSchedule,
            meta: {
                pageTitle: 'message.titles.payments',
                requiredAbility: {
                    action: 'access',
                    subject: 'Payments'
                },
                requiresAuth: true,
                title: 'message.payments',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.payment'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/payments/update/:salesType/:documentId',
            name: 'update_payments',
            component: AddPayment,
            meta: {
                pageTitle: 'message.titles.payments',
                requiredAbility: {
                    action: 'update',
                    subject: 'Payment'
                },
                requiresAuth: true,
                title: 'message.updatePayments',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.payments'
                    },
                    {
                        breadcrumbActive: 'message.update'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/salesorders/overview',
            name: 'salesorder_listing',
            component: SalesOrdersOverview,
            meta: {
                pageTitle: 'message.titles.salesOrders',
                requiredAbility: {
                    action: 'access',
                    subject: 'SalesOrders'
                },
                requiresAuth: true,
                title: 'message.salesOrders',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.salesOrder'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/salesorders/add',
            name: 'add_salesorder',
            component: AddSalesOrder,
            meta: {
                pageTitle: 'message.titles.newSo',
                requiredAbility: {
                    action: 'create',
                    subject: 'SalesOrder'
                },
                requiresAuth: true,
                title: 'message.addSalesOrder',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.salesOrder'
                    },
                    {
                        breadcrumbActive: 'message.add'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/salesorders/update/:salesOrderId?',
            name: 'update_salesorder',
            component: AddSalesOrder,
            meta: {
                pageTitle: 'message.titles.so',
                requiredAbility: {
                    action: 'update',
                    subject: 'SalesOrder'
                },
                requiresAuth: true,
                title: 'message.updateSalesOrder',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.salesOrder'
                    },
                    {
                        breadcrumbActive: 'message.update'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/sdas/add/:contractId?',
            name: 'add_sda',
            component: AddSda,
            meta: {
                pageTitle: 'message.titles.newSda',
                requiredAbility: {
                    action: 'update',
                    subject: 'Shipment'
                },
                requiresAuth: true,
                title: 'message.addShippingInstruction',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.sda'
                    },
                    {
                        breadcrumbActive: 'message.add'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/sdas/update/:sdaId?',
            name: 'update_sda',
            component: AddSda,
            meta: {
                pageTitle: 'message.titles.sda',
                requiredAbility: {
                    action: 'update',
                    subject: 'Shipment'
                },
                requiresAuth: true,
                title: 'message.updateShippingInstruction',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.sda'
                    },
                    {
                        breadcrumbActive: 'message.update'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/stocks/overview',
            name: 'stocks_listing',
            component: StocksOverview,
            meta: {
                pageTitle: 'message.titles.masterStockList',
                requiredAbility: {
                    action: 'access',
                    subject: 'Bundles'
                },
                requiresAuth: true,
                title: 'message.masterStockList',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.stocks'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            }
        },
        {
            path: '/v1/stocks/reports',
            name: 'stocks_reports',
            component: StockReports,
            meta: {
                pageTitle: 'message.titles.stockReports',
                requiredAbility: {
                    action: 'access',
                    subject: 'StockReports'
                },
                requiresAuth: true,
                title: 'message.stockReport',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.stocks'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            }
        },
        {
            path: '/v1/customers',
            name: 'customer_listing',
            component: BuyersOverview,
            meta: {
                pageTitle: 'message.titles.buyers',
                requiredAbility: {
                    action: 'access',
                    subject: 'Buyers'
                },
                requiresAuth: true,
                title: 'message.buyerProfiles',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.buyer'
                    },
                    {
                        breadcrumbActive: 'message.profiles'
                    }
                ]
            }
        },
        {
            path: '/v1/customers/map',
            name: 'customer_locator',
            component: BuyerLocator,
            meta: {
                pageTitle: 'message.titles.buyerLocations',
                requiredAbility: {
                    action: 'access',
                    subject: 'BuyerLocations'
                },
                requiresAuth: true,
                title: 'message.buyerSupplierLocations',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.buyer'
                    },
                    {
                        breadcrumbActive: 'message.locations'
                    }
                ]
            }
        },
        {
            path: '/v1/customers/update/:buyerId?',
            name: 'update_customer',
            component: AddBuyer,
            meta: {
                pageTitle: 'message.titles.buyer',
                requiredAbility: {
                    action: 'update',
                    subject: 'Buyer'
                },
                requiresAuth: true,
                title: 'message.buyerProfile',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.buyer'
                    },
                    {
                        breadcrumbActive: 'message.profile'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/customers/add',
            name: 'add_customer',
            component: AddBuyer,
            meta: {
                pageTitle: 'message.titles.newBuyer',
                requiredAbility: {
                    action: 'create',
                    subject: 'Buyer'
                },
                requiresAuth: true,
                title: 'message.newBuyerProfile',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.buyer'
                    },
                    {
                        breadcrumbActive: 'message.newProfile'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/suppliers',
            name: 'supplier_listing',
            component: SuppliersOverview,
            meta: {
                pageTitle: 'message.titles.suppliers',
                requiredAbility: {
                    action: 'access',
                    subject: 'Suppliers'
                },
                requiresAuth: true,
                title: 'message.supplierProfiles',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.suppliers'
                    },
                    {
                        breadcrumbActive: 'message.profiles'
                    }
                ]
            }
        },
        {
            path: '/v1/suppliers/update/:supplierId?',
            name: 'update_supplier',
            component: AddSupplier,
            meta: {
                pageTitle: 'message.titles.supplier',
                requiredAbility: {
                    action: 'update',
                    subject: 'Supplier'
                },
                requiresAuth: true,
                title: 'message.supplierProfile',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.supplier'
                    },
                    {
                        breadcrumbActive: 'message.profile'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/suppliers/add',
            name: 'add_supplier',
            component: AddSupplier,
            meta: {
                pageTitle: 'message.titles.newSupplier',
                requiredAbility: {
                    action: 'create',
                    subject: 'Supplier'
                },
                requiresAuth: true,
                title: 'message.supplierProfile',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.suppliers'
                    },
                    {
                        breadcrumbActive: 'message.newProfile'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/suppliers/:supplierId?/product-table',
            name: 'supplier_product_table',
            component: ProductTable,
            meta: {
                pageTitle: 'message.titles.productTable',
                requiredAbility: {
                    action: 'update',
                    subject: 'Supplier'
                },
                requiresAuth: true,
                title: 'message.productTable',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.supplier'
                    },
                    {
                        breadcrumbActive: 'message.products'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/search/:searchKeyword?',
            component: SearchResults,
            name: 'search',
            meta: {
                pageTitle: 'message.titles.searchResults',
                requiredAbility: {
                    action: null,
                    subject: null
                },
                requiresAuth: true,
                title: 'message.searchResults',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.search'
                    },
                    {
                        breadcrumbActive: 'message.results'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/stockorders/overview',
            name: 'stockorders_listing',
            component: StockOrdersOverview,
            meta: {
                pageTitle: 'message.titles.stockPos',
                requiredAbility: {
                    action: 'access',
                    subject: 'StockOrders'
                },
                requiresAuth: true,
                title: 'message.stockPoSummary',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.stocks'
                    },
                    {
                        breadcrumbActive: 'message.incomingAndArrivals'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/stockorders/add',
            name: 'add_stockorder',
            component: AddStockOrder,
            meta: {
                pageTitle: 'message.titles.newStockEntry',
                requiredAbility: {
                    action: 'create',
                    subject: 'StockOrder'
                },
                requiresAuth: true,
                title: 'message.newStockEntry',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.stockArrival'
                    },
                    {
                        breadcrumbActive: 'message.add'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/stockorders/update/:stockOrderId?',
            name: 'update_stockorder',
            component: AddStockOrder,
            meta: {
                pageTitle: 'message.titles.stockEntry',
                requiredAbility: {
                    action: 'update',
                    subject: 'StockOrder'
                },
                requiresAuth: true,
                title: 'message.updateStockPo',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.stockPo'
                    },
                    {
                        breadcrumbActive: 'message.update'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/shipments/overview',
            component: ShipmentsOverview,
            meta: {
                pageTitle: 'message.titles.shipments',
                requiredAbility: {
                    action: 'access',
                    subject: 'Shipments'
                },
                requiresAuth: true,
                title: 'message.shipments',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.shipments'
                    },
                    {
                        breadcrumbActive: 'Overview'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/shipments/demo-overview',
            name: 'shipments_demo_overview',
            component: ShipmentsDemoOverview,
            meta: {
                pageTitle: 'message.titles.shipments',
                requiredAbility: {
                    action: 'access',
                    subject: 'Shipments'
                },
                requiresAuth: true,
                title: 'message.shipments',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.shipments'
                    },
                    {
                        breadcrumbActive: 'Overview'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/shipments/update/:shipmentContractId',
            name: 'update_shipment',
            component: UpdateShipment,
            meta: {
                pageTitle: 'message.titles.shipment',
                requiredAbility: {
                    action: 'update',
                    subject: 'Shipment'
                },
                requiresAuth: true,
                title: 'message.updateShipment',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.shipments'
                    },
                    {
                        breadcrumbActive: 'message.update'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/wprs/add',
            name: 'add_wpr',
            component: AddWarehousePurchaseRequest,
            meta: {
                pageTitle: 'message.titles.newStockPr',
                requiredAbility: {
                    action: 'create',
                    subject: 'Wpr'
                },
                requiresAuth: true,
                title: 'message.addStockPurchaseRequest',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.stockPurchaseRequests'
                    },
                    {
                        breadcrumbActive: 'message.add'
                    }
                ]
            }
        },
        {
            path: '/v1/wprs/overview',
            name: 'wpr_listing',
            component: WarehousePurchaseRequestOverview,
            meta: {
                pageTitle: 'message.titles.stockPrs',
                requiredAbility: {
                    action: 'access',
                    subject: 'Wprs'
                },
                requiresAuth: true,
                title: 'message.stockPurchaseRequests',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.stockPurchaseRequests'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            }
        },
        {
            path: '/v1/wprs/update/:wprId',
            name: 'update_wpr',
            component: AddWarehousePurchaseRequest,
            meta: {
                pageTitle: 'message.titles.stockPr',
                requiredAbility: {
                    action: 'update',
                    subject: 'Wpr'
                },
                requiresAuth: true,
                title: 'message.updateStockPurchaseRequest',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.stockPurchaseRequests'
                    },
                    {
                        breadcrumbActive: 'message.update'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/wprs/operations-overview',
            name: 'wpr_operations_listing',
            component: WarehousePurchaseRequestOverview,
            meta: {
                pageTitle: 'message.titles.stockPrs',
                requiredAbility: {
                    action: 'access',
                    subject: 'Wprs'
                },
                requiresAuth: true,
                title: 'message.stockPurchaseRequests',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.stockPurchaseRequests'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            }
        },
        //Add all data management routes
        {
            path: '/v1/operations-data',
            name: 'operations_data',
            component: OperationsDataOverview,
            meta: {
                pageTitle: 'message.titles.operationsDataManagement',
                requiredAbility: {
                    action: 'access',
                    subject: 'OperationsData'
                },
                requiresAuth: true,
                title: 'message.operationsData',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.operationsData'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/operations-demo-data',
            name: 'operations_demo_data',
            component: OperationsDemoDataOverview,
            meta: {
                pageTitle: 'message.titles.operationsDataManagement',
                requiredAbility: {
                    action: 'access',
                    subject: 'OperationsData'
                },
                requiresAuth: true,
                title: 'message.operationsData',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.operationsData'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/specifications-data',
            name: 'specifications_data',
            component: SpecificationsDataOverview,
            meta: {
                pageTitle: 'message.titles.specificationsDataManagement',
                requiredAbility: {
                    action: 'access',
                    subject: 'SpecificationsData'
                },
                requiresAuth: true,
                title: 'message.specificationsData',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.specificationsData'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/specifications-demo-data',
            name: 'specifications_demo_data',
            component: SpecificationsDemoDataOverview,
            meta: {
                pageTitle: 'message.titles.specificationsDataManagement',
                requiredAbility: {
                    action: 'access',
                    subject: 'SpecificationsData'
                },
                requiresAuth: true,
                title: 'message.specificationsData',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.specificationsData'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/finance-data',
            name: 'finance_data',
            component: FinanceDataOverview,
            meta: {
                pageTitle: 'message.titles.financeDataManagement',
                requiredAbility: {
                    action: 'access',
                    subject: 'FinanceData'
                },
                requiresAuth: true,
                title: 'message.financeData',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.financeData'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/sales-data',
            name: 'sales_data',
            component: SalesDataOverview,
            meta: {
                pageTitle: 'message.titles.salesDataManagement',
                requiredAbility: {
                    action: 'access',
                    subject: 'SalesData'
                },
                requiresAuth: true,
                title: 'message.salesData',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.salesData'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/sales-commissions/overview',
            name: 'commission_listing',
            component: SalesCommissionsOverview,
            meta: {
                pageTitle: 'message.titles.salesCommissions',
                requiredAbility: {
                    action: 'access',
                    subject: 'Commissions'
                },
                requiresAuth: true,
                title: 'message.salesCommissions',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.salesCommissions'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/ncrs/overview',
            name: 'ncr_listing',
            component: NcrsOverview,
            meta: {
                pageTitle: 'message.titles.contractRequests',
                requiredAbility: {
                    action: 'access',
                    subject: 'Ncrs'
                },
                requiresAuth: true,
                title: 'message.contractRequests',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.contractRequests'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/ncrs/add/:salesContactId?/:buyerId?',
            name: 'add_ncr',
            component: AddNcr,
            meta: {
                pageTitle: 'message.titles.newNcr',
                requiredAbility: {
                    action: 'create',
                    subject: 'Ncr'
                },
                requiresAuth: true,
                title: 'message.addContractRequest',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.contractRequests'
                    },
                    {
                        breadcrumbActive: 'message.add'
                    }
                ],
                stateToUse: 'current'
            },
            props: true
        },
        {
            path: '/v1/ncrs/update/:ncrId',
            name: 'update_ncr',
            component: AddNcr,
            meta: {
                pageTitle: 'message.titles.ncr',
                requiredAbility: {
                    action: 'update',
                    subject: 'Ncr'
                },
                requiresAuth: true,
                title: 'message.updateContractRequest',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.contractRequest'
                    },
                    {
                        breadcrumbActive: 'message.update'
                    }
                ],
                stateToUse: 'update'
            },
            props: true
        },
        {
            path: '/v1/users/add',
            name: 'add_user',
            component: AddUser,
            meta: {
                pageTitle: 'message.titles.newUser',
                requiredAbility: {
                    action: 'create',
                    subject: 'User'
                },
                requiresAuth: true,
                title: 'message.addUser',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.users'
                    },
                    {
                        breadcrumbActive: 'message.add'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/users/overview',
            name: 'user_listing',
            component: UsersOverview,
            meta: {
                pageTitle: 'message.titles.users',
                requiredAbility: {
                    action: 'access',
                    subject: 'Users'
                },
                requiresAuth: true,
                title: 'message.users',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.users'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/users/update/:userId',
            name: 'update_user',
            component: AddUser,
            meta: {
                pageTitle: 'message.titles.user',
                requiredAbility: {
                    action: 'update',
                    subject: 'User'
                },
                requiresAuth: true,
                title: 'message.updateUser',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.users'
                    },
                    {
                        breadcrumbActive: 'message.update'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/finance/reports',
            name: 'finance_reports',
            component: FinanceReports,
            meta: {
                pageTitle: 'message.titles.financeReports',
                requiredAbility: {
                    action: 'access',
                    subject: 'FinanceReports'
                },
                requiresAuth: true,
                title: 'message.financeReports',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.finance'
                    },
                    {
                        breadcrumbActive: 'message.reports'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/finance/xero-integrations',
            name: 'xero_integrations',
            component: XeroOverview,
            meta: {
                pageTitle: 'message.titles.xeroIntegrations',
                requiredAbility: {
                    action: 'access',
                    subject: 'Xero'
                },
                requiresAuth: true,
                title: 'message.xeroIntegrations',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.finance'
                    },
                    {
                        breadcrumbActive: 'message.reports'
                    }
                ]
            }
        },
        {
            path: '/v1/finance/pl-tool',
            name: 'pl-tool',
            component: AppicXeroComparison,
            meta: {
                pageTitle: 'message.titles.profitLossTool',
                requiredAbility: {
                    action: 'access',
                    subject: 'Xero'
                },
                requiresAuth: true,
                title: 'message.appicXeroComparisonSales',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.finance'
                    },
                    {
                        breadcrumbActive: 'message.reports'
                    }
                ]
            }
        },
        {
            path: '/v1/finance/cos-tool',
            name: 'cos-tool',
            component: AppicXeroCostOfSalesComparison,
            meta: {
                pageTitle: 'message.titles.costOfSalesTool',
                requiredAbility: {
                    action: 'access',
                    subject: 'Xero'
                },
                requiresAuth: true,
                title: 'message.appicXeroComparisonCostOfSales',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.finance'
                    },
                    {
                        breadcrumbActive: 'message.reports'
                    }
                ]
            }
        },
        {
            path: '/v1/eprs/overview',
            name: 'epr_listing',
            component: EprsOverview,
            meta: {
                pageTitle: 'message.titles.eprs',
                requiredAbility: {
                    action: 'access',
                    subject: 'Eprs'
                },
                requiresAuth: true,
                title: 'message.exportPurchaseRequests',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.exportPurchaseRequests'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/eprs/add',
            name: 'add_epr',
            component: AddEpr,
            meta: {
                pageTitle: 'message.titles.newEpr',
                requiredAbility: {
                    action: 'create',
                    subject: 'Epr'
                },
                requiresAuth: true,
                title: 'message.addExportPurchaseRequest',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.exportPurchaseRequests'
                    },
                    {
                        breadcrumbActive: 'message.add'
                    }
                ],
                stateToUse: 'current'
            },
            props: true
        },
        {
            path: '/v1/eprs/update/:eprId',
            name: 'update_epr',
            component: AddEpr,
            meta: {
                pageTitle: 'message.titles.epr',
                requiredAbility: {
                    action: 'update',
                    subject: 'Epr'
                },
                requiresAuth: true,
                title: 'message.updateExportPurchaseRequest',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.exportPurchaseRequests'
                    },
                    {
                        breadcrumbActive: 'message.update'
                    }
                ],
                stateToUse: 'update'
            },
            props: true
        },
        {
            path: '/v1/admin/data-filters',
            name: 'data_filters',
            component: StandardDataFilters,
            meta: {
                pageTitle: 'message.titles.standardDataFilters',
                requiredAbility: {
                    action: 'update',
                    subject: 'AdminFunctions'
                },
                requiresAuth: true,
                title: 'message.standardDataFilters',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.standardDataFilters'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            },
            props: false
        },
        {
            path: '/v1/admin/appic-settings',
            name: 'appic_settings',
            component: AppicSettings,
            meta: {
                pageTitle: 'message.titles.appicSettings',
                requiredAbility: {
                    action: 'update',
                    subject: 'AdminFunctions'
                },
                requiresAuth: true,
                title: 'message.appicSettings',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.appicSettings'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            },
            props: false
        },
        {
            path: '/v1/admin/pdf-server',
            name: 'pdf_server',
            component: PdfServer,
            meta: {
                pageTitle: 'message.titles.pdfServer',
                requiredAbility: {
                    action: 'update',
                    subject: 'AdminFunctions'
                },
                requiresAuth: true,
                title: 'message.pdfServer',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.pdfServer'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            },
            props: false
        },
        {
            path: '/v1/admin/zoho-reports',
            name: 'zoho_reports',
            component: ZohoReports,
            meta: {
                pageTitle: 'message.titles.dashboardReports',
                requiredAbility: {
                    action: 'update',
                    subject: 'AdminFunctions'
                },
                requiresAuth: true,
                title: 'message.zohoReports',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.zohoReports'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            },
            props: false
        },
        {
            path: '/v1/admin/others',
            name: 'admin_others',
            component: MiscellaneousAdmin,
            meta: {
                pageTitle: 'message.titles.miscellaneous',
                requiredAbility: {
                    action: 'update',
                    subject: 'AdminFunctions'
                },
                requiresAuth: true,
                title: 'message.others',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.others'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            },
            props: false
        },
        {
            path: '/v1/admin/user-activity',
            name: 'user_activity',
            component: UserLog,
            meta: {
                pageTitle: 'message.titles.userActivity',
                requiredAbility: {
                    action: 'update',
                    subject: 'AdminFunctions'
                },
                requiresAuth: true,
                title: 'message.userActivity',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.userActivity'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            },
            props: false
        },
        {
            path: '/v1/marketing-report/detail',
            name: 'marketing_report_detail',
            component: ReportDetail,
            meta: {
                pageTitle: 'message.titles.marketingReportDetail',
                requiredAbility: {
                    action: 'access',
                    subject: 'MarketingReport'
                },
                requiresAuth: true,
                title: 'message.marketingReportDetail',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.reports'
                    },
                    {
                        breadcrumbActive: 'message.marketing'
                    }
                ]
            },
            props: false
        },
        {
            path: '/v1/help',
            name: 'help_manual',
            component: HelpManual,
            meta: {
                pageTitle: null,
                requiredAbility: {
                    action: 'access',
                    subject: 'HelpPages'
                },
                requiresAuth: true,
                title: 'message.appicManual',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.help'
                    },
                    {
                        breadcrumbActive: 'message.manual'
                    }
                ]
            },
            props: false
        },
        {
            path: '/v1/reports/hubspot-deals',
            name: 'hubspot_deals',
            component: HubSpotDeals,
            meta: {
                pageTitle: null,
                requiredAbility: {
                    action: 'access',
                    subject: 'HubSpotDeals'
                },
                requiresAuth: true,
                title: 'message.hubSpotDeals',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.hubSpotDeals'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            },
            props: false
        },
        {
            path: '/v1/suppliers/product-search',
            name: 'supplier_product_search',
            component: SupplierProductSearchV3,
            meta: {
                pageTitle: null,
                requiredAbility: {
                    action: 'access',
                    subject: 'ExportProducts'
                },
                requiresAuth: true,
                title: 'message.productSearch',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.suppliers'
                    },
                    {
                        breadcrumbActive: 'message.productSearch'
                    }
                ]
            },
        },
        {
            path: '/v1/reports/customer-life-cycle',
            name: 'customer_life_cycle',
            component: CustomerLifeCycle,
            meta: {
                pageTitle: null,
                requiredAbility: {
                    action: 'access',
                    subject: 'BuyerLifeCycle'
                },
                requiresAuth: true,
                title: 'message.buyerLifeCycle',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.buyer'
                    },
                    {
                        breadcrumbActive: 'message.lifeCycle'
                    }
                ]
            },
        }
   ]
}
