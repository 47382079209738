// Sidebar Routes
export const menus = {
	'message.general': [
		{
			id: 1,
			action: 'appic-dashboard',
			icon: 'mdi-monitor-dashboard',
			title: 'message.myDashboard',
			active: true,
			items: null,
			path: '/dashboard/standard',
			ability: {
				action: 'access',
				subject: 'Dashboard'
			}
		},
		{
			id: 2,
			action: 'appic-reports',
			active: false,
			icon: 'mdi-file-chart-outline',
			items: null,
			path: '/reports/appic-report',
			title: 'message.appicReport',
			ability: {
				action: 'access',
				subject: 'AppicReport'
			}
		},
		{
			id: 4,
			icon: 'mdi-finance',
			action: 'appic-sales-marketing',
			title: 'message.salesAndMarketing',
			active: false,
			items: [
				{
					id: 401,
					title: 'message.hubSpotDeals',
					path: '/v1/reports/hubspot-deals',
					exact: true,
					ability: {
						action: 'access',
						subject: 'HubSpotDeals'
					}
				},
				{
					id: 402,
					title: 'message.buyerSupplierLocator',
					path: '/v1/customers/map',
					exact: true,
					ability: {
						action: 'access',
						subject: 'BuyerLocations'
					}
				},
				{
					id: 403,
					title: 'message.buyerLifeCycle',
					path: '/v1/reports/customer-life-cycle',
					exact: true,
					ability: {
						action: 'access',
						subject: 'BuyerLifeCycle'
					}
				},
				{
					id: 404,
					title: 'message.marketingReport',
					path: '/v1/reports/marketing-report',
					exact: true,
					ability: {
						action: 'access',
						subject: 'MarketingReport'
					}
				},
				{
					id: 405,
					title: 'message.newContractRequest',
					path: '/v1/ncrs/add',
					exact: true,
					ability: {
						action: 'create',
						subject: 'Ncr'
					}
				},
				{
					id: 406,
					title: 'message.contractRequests',
					path: '/v1/ncrs/overview',
					exact: true,
					ability: {
						action: 'access',
						subject: 'Ncrs'
					}
				},
				// {
				// 	id: 406,
				// 	title: 'message.contracts',
				// 	path: '/v1/contracts/overview',
				// 	exact: true,
				// 	ability: {
				// 		action: 'access',
				// 		subject: 'Contracts'
				// 	}
				// },
				{
					id: 407,
					title: 'message.newExportPR',
					path: '/v1/eprs/add',
					exact: true,
					ability: {
						action: 'create',
						subject: 'Epr'
					}
				},
				{
					id: 408,
					title: 'message.exportPR',
					path: '/v1/eprs/overview',
					exact: true,
					ability: {
						action: 'access',
						subject: 'Eprs'
					}
				},
				{
					id: 409,
					title: 'message.newStockPR',
					path: '/v1/wprs/add',
					exact: true,
					ability: {
						action: 'create',
						subject: 'Wpr'
					}
				},
				{
					id: 410,
					title: 'message.stockPR',
					path: '/v1/wprs/overview',
					exact: true,
					ability: {
						action: 'access',
						subject: 'Wprs'
					}
				},
				{
					id: 411,
					title: 'message.productSearch',
					path: '/v1/suppliers/product-search',
					exact: true,
					ability: {
						action: 'access',
						subject: 'ExportProducts'
					}
				}
			],
			ability: {
				action: 'access',
				subject: 'SalesMarketing'
			}
		},
		{
			id: 5,
			action: 'appic-procurement',
			active: false,
			icon: 'mdi-cog-sync-outline',
			title: 'message.operations',
			items: [
				{
					id: 501,
					title: 'message.addContract',
					path: '/v1/contracts/add',
					exact: true,
					ability: {
						action: 'create',
						subject: 'Contract'
					}
				},
				{
					id: 502,
					title: 'message.addPO',
					path: '/v1/purchaseorders/add',
					exact: true,
					ability: {
						action: 'create',
						subject: 'Po'
					}
				},
				{
					id: 503,
					title: 'message.addSalesOrder',
					path: '/v1/salesorders/add',
					exact: true,
					ability: {
						action: 'create',
						subject: 'SalesOrder'
					}
				},
				{
					id: 504,
					title: 'message.contracts',
					path: '/v1/contracts/overview',
					exact: true,
					ability: {
						action: 'access',
						subject: 'Contracts'
					}
				},
				{
					id: 505,
					title: 'message.pos',
					path: '/v1/purchaseorders/overview',
					exact: true,
					ability: {
						action: 'access',
						subject: 'Pos'
					}
				},
				{
					id: 506,
					title: 'message.salesOrders',
					path: '/v1/salesorders/overview',
					exact: true,
					ability: {
						action: 'access',
						subject: 'SalesOrders'
					}
				},
				{
					id: 507,
					title: 'message.shipments',
					path: '/v1/shipments/overview',
					exact: true,
					ability: {
						action: 'access',
						subject: 'Shipments'
					}
				},
				{
					id: 508,
					title: 'message.stockPR',
					path: '/v1/wprs/operations-overview',
					exact: true,
					ability: {
						action: 'access',
						subject: 'Wprs'
					}
				},
				{
					id: 509,
					title: 'message.fscPefc',
					path: '/v1/reports/fsc-pefc',
					exact: true,
					ability: {
						action: 'access',
						subject: 'FscPefcReport'
					}
				}
			],
			ability: {
				action: 'access',
				subject: 'Operations'
			}
		},
		{
			id: 6,
			action: 'appic-finance',
			icon: 'mdi-cash-usd-outline',
			title: 'message.finance',
			active: false,
			items: [
				{
					id: 601,
					title: 'message.addInvoice',
					path: '/v1/invoices/add',
					exact: true,
					ability: {
						action: 'create',
						subject: 'Invoice'
					}
				},
				{
					id: 602,
					title: 'message.addDebitNote',
					path: '/v1/debitnotes/add',
					exact: true,
					ability: {
						action: 'create',
						subject: 'DebitNote'
					}
				},
				{
					id: 603,
					title: 'message.invoices',
					path: '/v1/invoices/overview',
					exact: true,
					ability: {
						action: 'access',
						subject: 'Invoices'
					}
				},
				{
					id: 604,
					title: 'message.payments',
					path: '/v1/payments/overview',
					exact: true,
					ability: {
						action: 'access',
						subject: 'Payments'
					}
				},
				{
					id: 605,
					title: 'message.grossMargins',
					path: '/v1/gross-margins/overview',
					exact: true,
					ability: {
						action: 'access',
						subject: 'GrossMargins'
					}
				},
				{
					id: 606,
					title: 'message.salesCommissions',
					path: '/v1/sales-commissions/overview',
					exact: true,
					ability: {
						action: 'access',
						subject: 'Commissions'
					}
				},
				{
					id: 607,
					title: 'message.reports',
					path: '/v1/finance/reports',
					exact: true,
					ability: {
						action: 'access',
						subject: 'FinanceReports'
					}
				},
				{
					id: 608,
					title: 'message.xeroIntegrations',
					path: '/v1/finance/xero-integrations',
					exact: true,
					ability: {
						action: 'access',
						subject: 'Xero'
					}
				},
				{
					id: 609,
					action: 'pl-tool',
					active: false,
					icon: 'compare',
					items: null,
					path: '/finance/pl-tool',
					title: 'message.profitAndLossTool',
					ability: {
						action: 'access',
						subject: 'ProfitAndLossTool'
					}
				},
				{
					id: 610,
					action: 'cos-tool',
					active: false,
					icon: 'compare',
					items: null,
					path: '/finance/cos-tool',
					title: 'message.costOfSalesTool',
					ability: {
						action: 'access',
						subject: 'ProfitAndLossTool'
					}
				}
			],
			ability: {
				action: 'access',
				subject: 'Finance'
			}
		},
		{
			id: 7,
			action: 'appic-stock',
			icon: 'mdi-warehouse',
			title: 'message.stockManagement',
			active: false,
			items: [
				{
					id: 701,
					title: 'message.masterList',
					path: '/v1/stocks/overview',
					exact: true,
					ability: {
						action: 'access',
						subject: 'Bundles'
					}
				},
				{
					id: 702,
					title: 'message.newStockEntry',
					path: '/v1/stockorders/add',
					exact: true,
					ability: {
						action: 'create',
						subject: 'StockOrder'
					}
				},
				{
					id: 703,
					title: 'message.stockPoSummary',
					path: '/v1/stockorders/overview',
					exact: true,
					ability: {
						action: 'access',
						subject: 'StockOrders'
					}
				},
				{
					id: 704,
					title: 'message.stockReport',
					path: '/v1/stocks/reports',
					exact: true,
					ability: {
						action: 'access',
						subject: 'StockReports'
					}
				},
				// {
				// 	id: 705,
				// 	title: 'message.stockUploadTemplate',
				// 	path: null,
				// 	directLink: process.env.VUE_APP_API_BASE_URL + '/stockorders/stock-upload-template',
				// 	exact: true,
				// 	ability: {
				// 		action: 'access',
				// 		subject: 'StockOrders'
				// 	}
				// }
			],
			ability: {
				action: 'access',
				subject: 'Stocks'
			}
		},
		{
			id: 8,
			action: 'appic-data-mgt',
			icon: 'mdi-database-sync-outline',
			title: 'message.dataManagement',
			active: false,
			items: [
				{
					id: 801,
					title: 'message.addBuyer',
					path: '/v1/customers/add',
					exact: true,
					ability: {
						action: 'create',
						subject: 'Buyer'
					}
				},
				{
					id: 802,
					title: 'message.addSupplier',
					path: '/v1/suppliers/add',
					exact: true,
					ability: {
						action: 'create',
						subject: 'Supplier'
					}
				},
				{
					id: 803,
					title: 'message.buyerProfiles',
					path: '/v1/customers',
					exact: true,
					ability: {
						action: 'access',
						subject: 'Buyers'
					}
				},
				{
					id: 804,
					title: 'message.supplierProfiles',
					path: '/v1/suppliers',
					exact: true,
					ability: {
						action: 'access',
						subject: 'Suppliers'
					}
				},
				{
					id: 805,
					title: 'message.operationsData',
					path: '/v1/operations-data',
					exact: true,
					ability: {
						action: 'access',
						subject: 'OperationsData'
					}
				},
				{
					id: 806,
					title: 'message.specificationsData',
					path: '/v1/specifications-data',
					exact: true,
					ability: {
						action: 'access',
						subject: 'SpecificationsData'
					}
				},
				{
					id: 807,
					title: 'message.financeData',
					path: '/v1/finance-data',
					exact: true,
					ability: {
						action: 'access',
						subject: 'FinanceData'
					}
				},
				{
					id: 808,
					title: 'message.salesData',
					path: '/v1/sales-data',
					exact: true,
					ability: {
						action: 'access',
						subject: 'SalesData'
					}
				},
			],
			ability: {
				action: 'access',
				subject: 'DataManagement'
			}
		},
		{
			id: 9,
			action: 'appic-user-mgt',
			active: false,
			icon: 'mdi-account-group-outline',
			items: null,
			path: '/users/overview',
			title: 'message.userManagement',
			ability: {
				action: 'access',
				subject: 'Users'
			}
		},
		{
			id: 10,
			action: 'appic-admin-function',
			icon: 'tune',
			title: 'message.adminTools',
			active: false,
			items: [
				{
					id: 1001,
					title: 'message.standardDataFilters',
					path: '/v1/admin/data-filters',
					exact: true,
					ability: {
						action: 'update',
						subject: 'AdminFunctions'
					}
				},
				{
					id: 1002,
					title: 'message.zohoReports',
					path: '/v1/admin/zoho-reports',
					exact: true,
					ability: {
						action: 'update',
						subject: 'AdminFunctions'
					}
				},
				{
					id: 1003,
					title: 'message.appicSettings',
					path: '/v1/admin/appic-settings',
					exact: true,
					ability: {
						action: 'update',
						subject: 'AdminFunctions'
					}
				},
				{
					id: 1004,
					title: 'message.pdfServer',
					path: '/v1/admin/pdf-server',
					exact: true,
					ability: {
						action: 'update',
						subject: 'AdminFunctions'
					}
				},
				{
					id: 1005,
					title: 'message.others',
					path: '/v1/admin/others',
					exact: true,
					ability: {
						action: 'update',
						subject: 'AdminFunctions'
					}
				},
				{
					id: 1006,
					title: 'message.userActivity',
					path: '/v1/admin/user-activity',
					exact: true,
					ability: {
						action: 'update',
						subject: 'AdminFunctions'
					}
				},
			],
			ability: {
				action: 'access',
				subject: 'AdminFunctions'
			}
		},
		{
			id: 10,
			action: 'appic-help',
			active: false,
			icon: 'help_outline',
			path: '/help',
			title: 'message.help',
			items: null,
			ability: {
				action: 'access',
				subject: 'HelpPages'
			}
		}
	]
}
